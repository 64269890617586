let node_env = process.env.NODE_ENV
let ENV = process.env.NODE_ENV || 'development';
let SERVICE_BASE_URL;
let SERVICE_BASE_URL_BACKOFFICE;
let ANALYTICS_DASHBOARD_URL;

switch (ENV) {
    case 'staging':
        SERVICE_BASE_URL = 'https://staging-api.flexipayment.in';
        SERVICE_BASE_URL_BACKOFFICE = 'https://staging-api.flexipayment.in/backoffice';
        break;
    case 'production':
        SERVICE_BASE_URL = 'https://api.flexipayment.in';
        SERVICE_BASE_URL_BACKOFFICE = 'https://api.flexipayment.in/backoffice';
        break;
    case 'development':
        SERVICE_BASE_URL = 'http://localhost:8888';
        SERVICE_BASE_URL_BACKOFFICE = 'http://localhost:5000/backoffice'
        ANALYTICS_DASHBOARD_URL = "http://localhost:3000/analytics";
}
export const ENVIRONMENT = ENV;
export const SERVICE_BASE = SERVICE_BASE_URL;
export const SERVICE_BASE_BACKOFFICE = SERVICE_BASE_URL_BACKOFFICE;
export const ORG_ID = "d95c2b06-c14d-4b2f-a0dd-ca9d2bc30d75";
// constants.js



export const REQUEST = {
    get: 1,
    post: 2
};
export const API_LIMIT = {
    limit: 20,
    skip: 0
};
export const TOAST = {
    success: 1,
    fail: 2,
    info: 3
};
export const ELEMENT_STATE = {
    show: 1,
    hide: 0
};
export const UAT = false;