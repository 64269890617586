import { DocumentUtils, Utils } from "../core/utils";
import * as Constants from "../core/constants";
import Mustache from 'mustache';

export class AnchorApplications {
    constructor() {
        Utils.pageSanityHandler();
    }

    handler() {
        this.dtHandler();
    }

    mainTableTransformer(data) {
        data.application_details_link = "/scf/application/details/" + data.id;
        data.product = Utils.capitalizeFirstLetter(
            data.product.split("_").join(" ")
            );
        data.required_amount = data.required_amount.toLocaleString("en-IN");
        data.formatted_created_at = Utils.formatTime(data.created_at);
        data.created_at_ago = Utils.formatTimeRelative(data.created_at);
        data.formatted_updated_at = Utils.formatTimeRelative(data.updated_at);
        data.formatted_created_at = Utils.formatTimeRelative(data.created_at);
        data.status_human = Utils.capitalizeFirstLetter(
            data.status.split("_").join(" ")
        );
        if(data.trade_name != null){
            data.business_applicant_name = data.trade_name;
        }
        return data;
    }

    dtHandler() {
        let table_container_id = "#dt-table-anchor-applications";
        let dt_instance = Utils.renderDataTable(table_container_id + "> table");
        let currentUrl = window.location.href;
        let match = currentUrl.match(/\/anchor\/anchor-applicant\/([^\/]+)\/all_applications/);
        let anchor_id = match ? match[1] : '';
        Utils.initializeDataTable(
            table_container_id,
            dt_instance,
            "/anchor/api/applicant/" + anchor_id + "/all_applications",
            Constants.REQUEST.get,
            {},
            "dt-main-tmpl",
            this.mainTableTransformer,
            "data",
            {
            business_application_id: "Application ID",
            business_applicant_name: "Applicant",
            }
        );
    }
}