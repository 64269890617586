import {Utils} from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from 'mustache';

export default class ScfPreApproval{

    constructor(){
        let that = this;
        that.checkZipcodeEligibility();
    }

    validateParams(){
        let pass = true;
        if(!this.elem_company_cred_period.value){
            Utils.showInputError(this.elem_company_cred_period, 'Credit period is mandatory');
            pass = false;
        }
        if(!this.elem_company_zipcode_pre.value){
            Utils.showInputError(this.elem_company_zipcode_pre, 'Pincode is mandatory');
            pass = false;
        }
        if(!Utils.validatorZipcode(this.elem_company_zipcode_pre.value)){
            Utils.showInputError(this.elem_company_zipcode_pre, 'Incorrect Pincode');
            pass = false;
        }
        return pass
    }

    calculateAmount(){
        let that = this;
        document.getElementById('btn-submit-pre-offer').addEventListener('click', function (){
            if(that.validateParams()){
                that.elem_pre_app_amount_div.classList.remove('no-display');
                that.elem_company_pre_amount.innerText = Utils.indianRepresentation(that.preApprovalFormula());
            }
        })
    }

    preApprovalFormula(){
        return 100000 * parseInt(this.elem_company_m_sales_inp.value) * (this.elem_company_cred_period.value) * 1.25/30;
    }

    checkZipcodeEligibility(){

        Utils.requestHandler("/scf/api/application/" + document.getElementById('scf_application_id').value, Constants.REQUEST.get)
        .then(function (response){
            if(response.message === 'OK'){
                const zipcode_allowed = response.data.scf_application.serving_zipcode;
                if (zipcode_allowed){
                    document.getElementById('success_indicative_offer').classList.remove('d-none');
                    document.getElementById('Build your Application_onb_module').classList.remove('d-none');
                } else {
                    document.getElementById('fail_indicative_offer').classList.remove('d-none');
                }
            }else{
                Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
        })

    }

}