import {Utils} from "../core/utils";
import * as Constants from "../core/constants";
import Mustache from 'mustache';

class LanDetails {
    constructor(){
        this.elem_lan_details = document.getElementById('div_lan_details');
        this.elem_sub_lan_details = document.getElementById('div_sub_lan_details');
    }

    transformSubLanDetails(data){
        data.principal_amount = data.principal_amount == null ? "N/A" : data.principal_amount.toLocaleString('en-IN');
        data.dpd = data.dpd == null ? "N/A" : data.dpd;
        data.total_paid = data.total_paid == null ? "N/A" : data.total_paid.toLocaleString('en-IN');
        data.interest = data.interest == null ? "N/A" : data.interest;
        data.loan_sanction_date = data.loan_sanction_date == null ? "N/A" : data.loan_sanction_date;
        data.loan_start_date = data.loan_start_date == null ? "N/A" : data.loan_start_date;
        data.loan_end_date = data.loan_end_date == null ? "N/A" : data.loan_end_date;
        return data;
    }
}

export class CollectionApplicationHandler{
	constructor(){
        this.lan_id = document.getElementById('lan_id');
        this.sub_lan_id = document.getElementById('sub_lan_id');
	}

	handlerDashboard(){
		this.dtHandler();
        Utils.pageSanityHandler();

	}

    handlerDetails(){
        Utils.initializePagers('app-pager-parent');
        this.getLan();
        this.getSubLan();
        this.emiScheduleTableHandler();
        this.emiPaymentsTableHandler();
        this.ledgerTableHandler();
        Utils.pageSanityHandler();
    }

	mainTableTransformer(data){
        data.total_paid = data.total_paid == null ? "N/A" : data.total_paid.toLocaleString('en-IN');
        data.total_due = data.total_due == null ? "N/A" : data.total_due.toLocaleString('en-IN');
        data.dpd = data.dpd == null ? 0 : data.dpd;
        data.created_at = Utils.formatTimeRelative(data.created_at);
        return data;
    }

    dtHandler(){
        let table_container_id = '#dt-table-application'
        let dt_instance = Utils.renderDataTable(table_container_id + '> table');
        Utils.initializeDataTable(
            table_container_id,
            dt_instance,
            '/collections/api/lan_applications',
            Constants.REQUEST.get,
            {},
            'dt-main-tmpl',
            this.mainTableTransformer,
            'data',
            {business_application_id: 'Application ID',
                business_applicant_name: 'Applicant'});
    }

    getLan(){
        let that = this;
        Utils.requestHandler('/collections/api/lan_applications/' + that.lan_id.value, Constants.REQUEST.get, {})
            .then(function (response){
                if(response.message === 'OK'){
                    that.renderLanDetails(response.data);
                }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
    }

    renderLanDetails(data){
        const that = this;
        let elem_lan_details = document.getElementById('div_lan_details');
        let tmpl = document.getElementById('lan-details-tmpl');
        let rendered = Mustache.render(tmpl.innerHTML, data);
        elem_lan_details.append($.parseHTML(rendered)[1]);
    }


    getSubLan(){
        let that = this;
        document.getElementById('nav-sub-lan').addEventListener('click', function(){
            Utils.requestHandler('/collections/api/lan_applications/' + that.lan_id.value + '/sub-lan/' + that.sub_lan_id.value, Constants.REQUEST.get, {})
                .then(function (response){
                    if(response.message === 'OK'){
                        that.renderSubLanDetails(response.data);
                    }else{
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                })
        });
    }

    transformSubLanDetails(data){
        data.principal_amount = data.principal_amount == null ? "N/A" : data.principal_amount.toLocaleString('en-IN');
        data.dpd = data.dpd == null ? "N/A" : data.dpd;
        data.total_paid = data.total_paid == null ? "N/A" : data.total_paid.toLocaleString('en-IN');
        data.interest = data.interest == null ? "N/A" : data.interest;
        data.loan_sanction_date = data.loan_sanction_date == null ? "N/A" : data.loan_sanction_date;
        data.loan_start_date = data.loan_start_date == null ? "N/A" : data.loan_start_date;
        data.loan_end_date = data.loan_end_date == null ? "N/A" : data.loan_end_date;
        return data;
    }

    renderSubLanDetails(data){
        const that = this;
        let elem_sub_lan_details = document.getElementById('div_sub_lan_details');
        let tmpl = document.getElementById('sub-lan-details-tmpl');
        let d = that.transformSubLanDetails(data);
        let rendered = Mustache.render(tmpl.innerHTML, d);
        elem_sub_lan_details.innerHTML = '';
        elem_sub_lan_details.append($.parseHTML(rendered)[1]);
    }

    emiScheduleTableTransformer(data){
        data.interest = data.interest == null ? "N/A" : data.interest;
        data.due_date = data.due_date == null ? "N/A" : Utils.formatTimeRelative(data.due_date);
        data.status = data.status == null ? "N/A" : data.status;
        data.principal_amount = data.principal_amount == null ? "N/A" : data.principal_amount.toLocaleString('en-IN');
        data.due_date = data.due_date == null ? "N/A" : Utils.formatTimeRelative(data.due_date);
        return data;
    }

    emiScheduleTableHandler(){
        let that = this;
        document.getElementById('nav-emi-schedule').addEventListener('click', function(){
            let table_container_id = '#dt-table-emi-schedule';
            let dt_instance = Utils.renderDataTable(table_container_id + '> table');
            Utils.initializeDataTable(
                table_container_id,
                dt_instance,
                '/collections/api/lan_applications/' + that.lan_id.value + '/sub-lan/' + that.sub_lan_id.value + '/emi-schedule',
                Constants.REQUEST.get,
                {},
                'dt-emi-schedule-tmpl',
                this.emiScheduleTableTransformer,
                'emi_schedule',
                {});
        });
    }

    emiPaymentsTableTransformer(data){
        data.amount_paid = data.amount_paid == null ? "N/A" : data.amount_paid.toLocaleString('en-IN');
        data.paid_on = Utils.formatTimeRelative(data.paid_on);
        data.payment_mode = data.payment_mode == null ? "N/A" : data.payment_mode;
        data.source = data.source == null ? "N/A" : data.source;
        data.client_ref_id = data.client_ref_id == null ? "N/A" : data.client_ref_id;
        return data;
    }

    emiPaymentsTableHandler(){
        let that = this;
        document.getElementById('nav-emi-payments').addEventListener('click', function(){
            let table_container_id = '#dt-table-emi-payments'
            let dt_instance = Utils.renderDataTable(table_container_id + '> table');
            Utils.initializeDataTable(
                table_container_id,
                dt_instance,
                '/collections/api/lan_applications/' + that.lan_id.value + '/sub-lan/' + that.sub_lan_id.value + '/emi-payments',
                Constants.REQUEST.get,
                {},
                'dt-emi-payments-tmpl',
                that.emiPaymentsTableTransformer,
                'data',
                {});
        });
    }    

    ledgerTableTransformer(data){
        const that = this;
        data.principal_amount = data.principal_amount == null ? "N/A" : data.principal_amount.toLocaleString('en-IN');
        data.interest = data.interest == null ? "N/A" : data.interest.toLocaleString('en-IN');
        data.charges = data.charges == null ? "N/A" : data.charges.toLocaleString('en-IN');
        data.debit = data.debit == null ? "N/A" : data.debit.toLocaleString('en-IN');
        data.credit = data.credit == null ? "N/A" : data.credit.toLocaleString('en-IN');
        data.updated_at = data.updated_at == null ? "N/A" : Utils.formatTimeRelative(data.updated_at);
        return data;
    }

    ledgerTableHandler(){
        let that = this;
        document.getElementById('nav-emi-ledgers').addEventListener('click', function(){
            let table_container_id = '#dt-table-ledgers'
            let dt_instance = Utils.renderDataTable(table_container_id + '> table');
            Utils.initializeDataTable(
                table_container_id,
                dt_instance,
                '/collections/api/lan_applications/' + that.lan_id.value + '/sub-lan/' + that.sub_lan_id.value + '/ledgers',
                Constants.REQUEST.get,
                {},
                'dt-ledgers-tmpl',
                that.ledgerTableTransformer,
                'data',
                {});
        });
    }

}



document.addEventListener("turbolinks:load", function() {
    let path = window.location.pathname;
    if(path.startsWith("/collections/lan/details")){
        new CollectionApplicationHandler().handlerDetails();
    } else if(path.startsWith("/collections/lan")){
        new CollectionApplicationHandler().handlerDashboard();
            
    }
});