import {ScfApplicationDashboard, ScfApplicationDetails} from './application/scf_application_handler';
import {ScfSignIn, ScfSignUp} from "./scf_auth_handler";
import {CollectionApplicationHandler} from "../collections/collection_application_handler";
import {ScfOnboarding} from "./onboarding/scf_onboarding_handler";
import {ScfInvoice} from "./invoice/scf_invoice";
import {ScfInvoiceDashboard} from "./invoice/scf_invoice_dashboard";
import {ScfCounterParty} from "./counterparty/scf_counterparty_handler";
import {ScfCounterPartyConfirmation} from "./counterparty/scf_counterparty_confirmation";
import {ScfUserDashboard} from  "./users/scf_user"
import {SupportPage} from "../core/static_pages"
import {ScfInvoiceConfirmation} from "./invoice/scf_invoice_confirmation"
import {ScfSalesNewApplicationPage} from "./sales/scf_sales_new_application"
import {ScfUserSettings} from "./users/scf_user_settings"
import {ScfUserNewBorrower} from "./users/scf_new_borrower"
import {AnchorNewLeadsLot} from "../anchor/new_anchor_leads_lot"
import {LeadsLotDetails} from "../anchor/leads_lot_details"
import {AnchorNewInvoicesLot} from "../anchor/new_anchor_invoices_lot"
import {AnchorBorrower} from "../anchor/anchor_borrower"
import {AnchorApplications} from "../anchor/anchor_applications"
import {AllAnchorsList} from "../anchor/all_anchors_list"

document.addEventListener("turbolinks:load", function() {
    let scrpt = document.getElementById('js_script').getAttribute('data-script');
    switch (scrpt){
        case 'ScfSignIn':
            new ScfSignIn().handler()
            break;
        case 'ScfSignUp':
            new ScfSignUp().handler()
            break;
        case 'ScfOnboarding':
            new ScfOnboarding().handler()
            break;
        case 'ScfApplicationDashboard':
            new ScfApplicationDashboard().handler()
            break;
        case 'ScfApplicationDetails':
            new ScfApplicationDetails().handler()
            break;
        case 'ScfCollectionDashboard':
            new CollectionApplicationHandler().handlerDashboard();
            break;
        case 'ScfCollectionDetails':
            new CollectionApplicationHandler().handlerDetails();
            break;
        case 'ScfInvoiceDashboard':
            new ScfInvoiceDashboard().handler();
            break;
        case 'ScfInvoiceDetails':
            new ScfInvoice().handler();
            break;
        case 'ScfCounterPartyDetails':
            new ScfCounterParty().handler();
            break;
        case 'ScfCounterPartyConfirmation':
            new ScfCounterPartyConfirmation().handler();
            break;
        case 'SupportPage':
            new SupportPage().handler();
            break;
        case 'ScfUserDashboard':
            new ScfUserDashboard().handler();
            break;
        case 'ScfInvoiceConfirmation':
            new ScfInvoiceConfirmation().handler();
            break;
        case 'ScfSalesNewApplicationPage':
            new ScfSalesNewApplicationPage().handler();
            break;
        case 'ScfUserSettings':
            new ScfUserSettings().handler();
            break;
        case 'ScfUserNewBorrower':
            new ScfUserNewBorrower().handler();
            break;
        case 'AnchorNewLeadsLot':
            new AnchorNewLeadsLot().handler();
            break;
        case 'AnchorNewInvoicesLot':
            new AnchorNewInvoicesLot().handler();
            break; 
        case 'AnchorApplications':
            new AnchorApplications().handler();
            break;    
        case 'AnchorBorrower':
            new AnchorBorrower().handler();
        break; 
        case 'LeadsLotDetails':
            new LeadsLotDetails().handler();
        break;    
        case 'AllAnchorsList':
            new AllAnchorsList().handler();
        break; 
        default:
           console.log("No JS for the page");
    }
});

