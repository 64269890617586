import { Utils } from "../core/utils";
import * as Constants from "../core/constants";

export class SupportPage {
    constructor() {
        document.getElementById('supportSubmitBtn').addEventListener('click', (event) => {
            event.preventDefault();
            this.sendSupportEmailHandler();
        });

        document.getElementById('support-ticket').addEventListener('click', () => {
            this.goToDashBoardHandler();
        });
    }

    sendSupportEmailHandler() {
        const subject = document.getElementById('supportSubject').value;
        const message = document.getElementById('supportMessage').value;
        if (!subject && !message) {
            Utils.showToast('Error', 'Please enter both subject and message', Constants.TOAST.fail);
            return;
        }

        Utils.requestHandler('/scf/api/applicant/send-support-email', Constants.REQUEST.post, { subject, body: message })
            .then((response) => {
                if (response.message == 'OK') {
                    Utils.showToast(response.message, 'Mail sent to support-team', Constants.TOAST.success);
                    document.getElementById('supportSubject').value = "";
                    document.getElementById('supportMessage').value = "";
                } else {
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            });
    }

    goToDashBoardHandler() {
        window.location.href = '/scf/application';
    }
}
