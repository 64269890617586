import {DocumentUtils, Utils} from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from 'mustache';
import {TOAST} from "../../core/constants";
import {SERVICE_BASE_BACKOFFICE} from "../../core/constants";
import {ScfPermission} from "../scf_auth_handler";
export default class ScfAppPanelInvoice {
  
  constructor() {
    let that = this;
    let fetched = false;
    this.application_id = document.getElementById('scf_application_id').value;
    this.transcationinput = document.getElementById('transcationinputvalue').value;
    this.elem_btn_invoice_modal = document.getElementById('btn_upload_invoice');
    this.elem_invoice_modal = new bootstrap.Modal(document.getElementById('invoice_modal'), {});
    this.elem_invoice_modal_header = document.getElementById('inv_modal_header');
    this.elem_btn_submit_invoice = document.getElementById('btn-submit-invoice');
    this.elem_div_inv_upload = document.getElementById('div_inv_upload');
    this.elem_select_buyer = document.getElementById('inv_buyer_options');
    this.elem_inv_preview = document.getElementById('inv_preview');
    this.elem_inv_purpose = document.getElementById('inv_purpose');
    this.elem_due_date = document.getElementById('inv_due_date');
    this.elem_tbody = document.getElementById('tb-invoices-tbody');
    document.getElementById('nav-invoice').addEventListener('click', function (){
      if (fetched){
        return;
      }
      that.uploadInvoice();
      that.fetchCounterParties();
      that.submitInvoice();
      that.fetchInvoices();
      that.requestForFinance();
      that.sendToLenderForFinance();
      that.handler();
      fetched = true;
    })
    this.submitDrawDownRequest();
    document.getElementById('btn-invoice-modal-close').addEventListener('click', function(){
      that.resetForm();
    })
  }
  handler(){
    //hiding request for finance button from lender
    let that=this;
    that.userWisePermissoin() 
  }
  
  submitDrawDownRequest(){
    let that = this;
    document.getElementById('btn-drawdown-submit').addEventListener('click', function(){
      let body = {
        invoice_ids: document.getElementById('transcationinputvalue').value, 
        url: SERVICE_BASE_BACKOFFICE + '/v2/draw-down-request',
        scf_application_id: that.application_id
      }
      Utils.requestHandler('/scf/api/invoice/draw-down-request', Constants.REQUEST.post, body)
      .then(function (response){
        if(response.message === 'OK'){
          Utils.showToast('DDR', 'DDR sent duccesfully', Constants.TOAST.success);
          document.getElementById('btn-invoice-submit').disabled=true;
        }else{
          Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
        }
      })
    })
  }
  
  userWisePermissoin() {
    if (ScfPermission.isScfLender()){
      document.getElementById("btn_request_financing").classList.add("no-display");
      document.getElementById("btn_upload_invoice").classList.add("no-display");
    }
    if(ScfPermission.isScfAdmin() || ScfPermission.isScfSuperAdmin()){
      document.getElementById("invoice-consent-upload").classList.remove("no-display");
    }
  }
  
  
  validateValues(){
    let that = this;
    that.pass=true;
    document.querySelectorAll('.invoice-question').forEach(function (elem){
      let label=elem.getAttribute('data-label');
      Utils.reInput(elem);
      function toggleMessage(){
        let value=elem.value;
        if(!value){
          Utils.showInputError(elem, label + ' is mandatory');
          that.pass=false;
        }else{
          Utils.hideInputError(elem); 
        }
      }
      toggleMessage();
      elem.addEventListener('input', toggleMessage);
      
    })
    return that.pass;
  }
  
  uploadInvoice(){
    let that = this;
    DocumentUtils.bindInputElement(that.elem_div_inv_upload, null);
    DocumentUtils.bindInputElement(document.getElementById('invoice-consent-upload'), null)
    DocumentUtils.showOfflinePreview(that.elem_div_inv_upload, that.elem_inv_preview);
    DocumentUtils.showOfflinePreview(document.getElementById('invoice-consent-upload'), document.getElementById('inv_consent_preview'));
    document.getElementById('btn_upload_invoice').addEventListener('click', function (){
      document.querySelectorAll('.invoice-stage').forEach(function (elem){
        elem.classList.add('no-display')
      })
      document.getElementById('inv_stage_upload').classList.remove('no-display');
      that.elem_invoice_modal_header.innerHTML = 'Upload Invoice';
      that.elem_invoice_modal.show();
    })
  }
  
  static bindInputElement(action_element){
    action_element.addEventListener('click', function(){
      let inp_elem = action_element.parentElement.querySelector('.file-input');
      if (!inp_elem.files || inp_elem.files.length === 0) {
        inp_elem.dispatchEvent(new MouseEvent('click'));
      }
    })
  }
  
  static getFileData(inp_elem, more_params = null){
    let file_data = [];
    Array.from(inp_elem.files).forEach(function (fls){
      let body = new FormData();
      body.set('file_data', fls)
      body.set('file_name', fls.name);
      if(more_params){
        for (const m in more_params) {
          body.set(m, more_params[m]);
        }
      }
      file_data.push(body);
    })
    return file_data;
  }
  
  submitInvoice(){
    let that = this;
    let invoiceInput = document.getElementById('pdf-upload');
    let consentInput = document.getElementById('image-upload');
    this.elem_btn_submit_invoice.addEventListener('click', function () {
      if (that.validateValues()) {
        that.elem_btn_submit_invoice.disabled = true;
        let formData = new FormData();
        formData.append('scf_application_id', that.application_id);
        formData.append('buyer_id', that.elem_select_buyer.value);
        formData.append('invoice_date', that.elem_due_date.value);
        formData.append('purpose', that.elem_inv_purpose.value);
        if (invoiceInput.files.length > 0) {
          formData.append('invoice', invoiceInput.files[0]);
          formData.append('invoice_file_name', invoiceInput.files[0].name);
        } else {
          Utils.showToast('Error', 'Invoice file is missing', Constants.TOAST.fail);
          that.elem_btn_submit_invoice.disabled = false;
          return;
        }
        if(ScfPermission.isScfAdmin() || ScfPermission.isScfSuperAdmin()){
          if (consentInput.files.length > 0) {
            formData.append('invoice_consent', consentInput.files[0]);
            formData.append('invoice_consent_file_name', consentInput.files[0].name);
          } else {
            Utils.showToast('Error', 'Invoice consent file is missing', Constants.TOAST.fail);
            that.elem_btn_submit_invoice.disabled = false;
            return;
          }
          if (consentInput.files.length > 0) {
            formData.append('invoice_consent', consentInput.files[0]);
            formData.append('invoice_consent_file_name', consentInput.files[0].name);
          } else {
            Utils.showToast('Error', 'Invoice consent file is missing', Constants.TOAST.fail);
            that.elem_btn_submit_invoice.disabled = false;
            return;
          }
          Utils.requestHandler('/scf/api/invoice/invoice-discounting/', Constants.REQUEST.post, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(function (response) {
            if (response.message === 'OK') {
              Utils.showToast('Uploaded', 'Document uploaded', Constants.TOAST.success);
              that.resetForm();
              that.elem_invoice_modal.hide();
              that.elem_btn_submit_invoice.style.display = 'none';
              setTimeout(function () {
                that.fetchInvoices();
              }, 5000);
            } else {
              Utils.showToast('Error', response.errors.invoice_date, Constants.TOAST.fail);
            }
          })
          .catch(function (error) {
            Utils.showToast('Error', 'An error occurred during upload', Constants.TOAST.fail);
            that.elem_btn_submit_invoice.disabled = false;
          });
        }
      }
    });
  }
  
  resetForm() {
    document.getElementById('pdf-upload').value = ''; 
    document.getElementById('image-upload').value = '';
    this.elem_select_buyer.value = '';
    this.elem_due_date.value = '';
    this.elem_inv_purpose.value = '';
    document.getElementById('inv_preview').innerHTML = '';
    document.getElementById('inv_consent_preview').innerHTML = '';
  }
  
  closeModalForRequestForFinance(){
    document.getElementById('btn-lender-modal-close').addEventListener('click', function(){
      document.getElementById('inv_stage_req_fin_4').classList.add('no-display');
    })
  }
  
  fetchCounterParties(){
    let that = this;
    Utils.requestHandler('/scf/api/application/'+that.application_id+'/lender-approved-cps', Constants.REQUEST.get)
    .then(function (response){
      if(response.message === 'OK'){
        that.elem_select_buyer.textContent = "";
        let option = document.createElement('option');
        option.value = "";
        option.text = "Select buyer";
        that.elem_select_buyer.add(option);
        response.data.approved_counter_parties.forEach(function (dd){
          let option = document.createElement( 'option' );
          option.value = dd.cp_id;
          option.text = dd.name;
          that.elem_select_buyer.add(option);
        })
      }
    })
    .then(function (){
      
    })
  }
  
  transformInvoiceData(data){
    if(data.invoice_no === null){
      data.invoice_no = data.id;
    }
    if(data.total_invoice_value === null){
      data.formatted_invoice_amount = ' -'
    }else{
      data.formatted_invoice_amount = data.total_invoice_value.toLocaleString('en-IN');
    }
    data.formatted_created_at = Utils.formatTime(data.created_at);
    data.formatted_updated_at = Utils.formatTimeRelative(data.updated_at);
    data.formatted_due_date = Utils.formatDate(data.due_date);
    data.formatted_due_date_rel = Utils.formatTimeRelative(data.due_date);
    data.status_human = Utils.capitalizeFirstLetter(data.status.split('_').join(' '));
    return data;
  }
  
  fetchInvoices(){
    let that = this;
    Utils.requestHandler('/scf/api/application/'+that.application_id+'/invoices', Constants.REQUEST.get)
    .then(function (response){
      if(response.message === 'OK'){
        that.renderInvoice(response.data.invoices);
      }
    })
    .then(function (){
      
    })
  }
  renderInvoice(data){
    let that = this;
    let p_tmpl = document.getElementById('invoice-row-tmpl');
    that.elem_tbody.innerHTML = null;
    data.forEach(function (inv){
      let d1 = that.transformInvoiceData(inv)
      let rend = Mustache.render(p_tmpl.innerHTML, d1);
      let renderedRow = $.parseHTML(rend)[1];            
      let invoiceLinks = renderedRow.querySelectorAll('a#invoice-id');
      if(ScfPermission.isScfBorrower()){
        invoiceLinks.forEach(function(element){
          let href = element.getAttribute('href');
          if (href.includes('/scf/invoice/')) {
            element.removeAttribute('href');
            element.classList.add('disabled');
            element.setAttribute('tabindex', '-1');
          }
        });
      }  
      that.elem_tbody.append(renderedRow);
    });
  }
  
  
  requestForFinance(){
    let that = this;
    document.getElementById('btn_request_financing').addEventListener('click', function (){
      let re_inv = document.querySelectorAll('.inv-req-fin:checked');
      if(re_inv.length > 0){
        document.getElementById('inv_selected').value = re_inv[0].value;
                document.querySelectorAll('.invoice-stage').forEach(function (elem){
                    elem.classList.add('no-display')
                })
                document.getElementById('inv_stage_req_fin_4').classList.remove('no-display');
                that.elem_invoice_modal_header.innerHTML = 'Request for Finance';
                that.elem_invoice_modal.show();
      }else{
        Utils.showToast('Error', 'Select an invoice first', Constants.TOAST.fail);
      }
    })
  }
  
  sendToLenderForFinance(){
    let that = this;
    document.getElementById('btn-conf-req-finance-modal').addEventListener('click', function (){
      let finding_amount = document.getElementById('inv_funding_amount').value;
      let inv_id = document.getElementById('inv_selected').value;
      if(finding_amount<=0){
        Utils.showToast('Error',"Invalid Funding Amount", Constants.TOAST.fail);
        return;
      }
      Utils.requestHandler("/scf/api/invoice/" + inv_id + '/invoice_discounting/request-finance', Constants.REQUEST.post, {scf_funding_amount: finding_amount, scf_application_id: that.application_id})
      .then(function (response){
        debugger;
        if(response.message === 'OK'){
          debugger;
          Utils.showToast('Success', 'Invoice requested for finance', Constants.TOAST.success);
          document.querySelectorAll('.invoice-stage').forEach(function (elem){
            elem.classList.add('no-display')
          })
          that.elem_invoice_modal.hide();
        }else{
          Utils.showToast('Error', response.errors.funding_amount || response.errors.invoice.invoice_date);
        }
      })
    })
  }
  
}