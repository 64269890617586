import { DocumentUtils, Utils } from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from 'mustache';

export class ScfUserDashboard {
  constructor() {
    Utils.pageSanityHandler();

    this.user_first_name = null;
    this.user_last_name = null;
    this.user_email = null;
    this.user_mobile = null;
    this.user_password = null;
    this.user_password_confirmation = null;
    this.user_permission = null;
    this.user_address = null;
    this.user_city = null;
    this.user_state = null;
    this.user_zipcode = null;

    this.elem_user_first_name = document.getElementById('user_new_first_name');
    this.elem_user_last_name = document.getElementById('user_new_last_name');
    this.elem_user_email = document.getElementById('user_new_email');
    this.elem_user_mobile = document.getElementById('user_new_mobile');
    this.elem_user_password = document.getElementById('user_new_password');
    this.elem_user_password_confirmation = document.getElementById('user_new_password_confirmation');
    this.elem_user_permission =  document.getElementById('user_new_permission');
    this.elem_user_address = document.getElementById('user_new_address');
    this.elem_user_city = document.getElementById('user_new_city');
    this.elem_user_state = document.getElementById('user_new_state');
    this.elem_user_zipcode = document.getElementById('user_new_zipcode');
 
    this.elem_user_address.addEventListener('input', function () {
        Utils.reInput(this);
    })
    this.elem_user_city.addEventListener('input', function () {
        Utils.reInput(this);
    })
    this.elem_user_state.addEventListener('input', function () {
        Utils.reInput(this);
    })
    this.elem_user_zipcode.addEventListener('input', function () {
        Utils.reInput(this);
    })
    this.elem_user_first_name.addEventListener('input', function () {
        Utils.reInput(this);
    })
    this.elem_user_last_name.addEventListener('input', function () {
        Utils.reInput(this);
    })
    this.elem_user_email.addEventListener('input', function () {
        Utils.reInput(this);
    })
    this.elem_user_mobile.addEventListener('input', function () {
        Utils.reInput(this);
    })
    this.elem_user_password.addEventListener('input', function () {
        Utils.reInput(this);
    })
    this.elem_user_password_confirmation.addEventListener('input', function () {
        Utils.reInput(this);
    })
    this.elem_user_permission.addEventListener('input', function () {
        Utils.reInput(this);
    })

  }

  setValues(){
  }

  getValues(){
    this.user_first_name = Utils.getter(this.elem_user_first_name);
    this.user_last_name = Utils.getter(this.elem_user_last_name);
    this.user_email = Utils.getter(this.elem_user_email);
    this.user_mobile = Utils.getter(this.elem_user_mobile);
    this.user_password = Utils.getter(this.elem_user_password);
    this.user_password_confirmation = Utils.getter(this.elem_user_password_confirmation);
    this.user_permission = Utils.getter(this.elem_user_permission);
    this.user_address = Utils.getter(this.elem_user_address);
    this.user_city = Utils.getter(this.elem_user_city);
    this.user_state = Utils.getter(this.elem_user_state);
    this.user_zipcode = Utils.getter(this.elem_user_zipcode);
  }

  validateValues() {
    let pass = true;
    if (!Utils.validatorEmail(this.user_email)) {
        Utils.showInputError(this.elem_user_email, 'Incorrect email address');
        pass = false;
    }
    if (!this.user_first_name) {
        Utils.showInputError(this.elem_user_first_name, 'First Name is mandatory');
        pass = false;
    }
    if (!this.user_last_name) {
        Utils.showInputError(this.elem_user_last_name, 'Last Name is mandatory');
        pass = false;
    }
    if (!this.user_email) {
        Utils.showInputError(this.elem_user_email, 'Email Name is mandatory');
        pass = false;
    }
    if (!this.user_mobile) {
        Utils.showInputError(this.elem_user_mobile, 'Mobile is mandatory');
        pass = false;
    }
    if (!this.user_password) {
        Utils.showInputError(this.elem_user_password, 'Password is mandatory');
        pass = false;
    }
    if (!this.user_password_confirmation) {
        Utils.showInputError(this.elem_user_password_confirmation, 'Password Confirmation is mandatory');
        pass = false;
    }
    if (!this.user_permission) {
        Utils.showInputError(this.elem_user_permission, 'Permission is mandatory');
        pass = false;
    }

    if (this.user_password != this.user_password_confirmation) {
        Utils.showInputError(this.elem_user_password_confirmation, 'Password does not match');
        pass = false;
    }

    if (!Utils.validatorMobile(this.user_mobile)) {
        Utils.showInputError(this.elem_user_mobile, 'Incorrect Mobile Format');
        pass = false;
    }
    if (this.elem_user_permission.value == 'scf_lender') {
        if (!this.user_address) {
            Utils.showInputError(this.elem_user_address, 'Address is mandatory');
            pass = false;
        }
        if (!this.user_city) {
            Utils.showInputError(this.elem_user_city, 'City is mandatory');
            pass = false;
        }
        if (!this.user_state) {
            Utils.showInputError(this.elem_user_state, 'State is mandatory');
            pass = false;
        }
        if (!this.user_zipcode) {
            Utils.showInputError(this.elem_user_zipcode, 'Zipcode is mandatory');
            pass = false;
        }
        if (!Utils.validatorZipcode(this.user_zipcode)) {
            Utils.showInputError(this.elem_user_zipcode, 'Incorrect Zipcode Format');
            pass = false;
        }
    }

    return pass;
}


  handler() {
    this.submitUserDetails();
    this.toggleExtraInputFields();
  }

  toggleExtraInputFields(){
    this.elem_user_permission.addEventListener('change', function (event) {
        const elements = document.querySelectorAll(".lender-extra-field");
        if (event.target.value == 'scf_lender') {
            elements.forEach(element => {
                element.classList.remove("no-display");
            });
        } else {
            elements.forEach(element => {
                element.classList.add("no-display");
            });
        }
    });
  }


  submitUserDetails(){
    let that = this;
    document.getElementById('btn-user-new-submit').addEventListener('click', function(){
      that.getValues();
      if( that.validateValues()){
        let body = {
            first_name: that.user_first_name,
            last_name: that.user_last_name,
            email: that.user_email,
            mobile: that.user_mobile,
            password: that.user_password,
            password_confirmation: that.user_password_confirmation,
            permission: that.user_permission,
            address: that.user_address,
            city: that.user_city,
            state: that.user_state,
            zipcode: that.user_zipcode
        }
        Utils.requestHandler("/scf/api/user/new-user-admin",  Constants.REQUEST.post, body)
        .then(function (response){
            if(response.message === 'OK'){
            Utils.showToast(response.message, 'User details updated', Constants.TOAST.success);
            }else{
            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
        
        });
      }
    })
  }


}