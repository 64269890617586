import {DocumentUtils, Utils} from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from 'mustache';
import { end } from "@popperjs/core";

class Limit  {

    constructor(scf_application_id){
        let that = this;
        this.scf_application_id = scf_application_id;
        this.limit_type = null;
        this.limit_expiry = null;
        this.limit_amount = null;
        this.limit_cp = null;
        this.scf_lender_application_id = null;

        this.elem_limit_type = document.getElementById('limit_type');
        this.elem_limit_expiry = document.getElementById('limit_expiry');
        this.elem_limit_amount = document.getElementById('limit_amount');
        this.elem_limit_cp = document.getElementById('limit_cp');
        this.elem_scf_lender_app_id = document.getElementById('scf_lender_application_id');
        document.getElementById('nav-panel-lender-limits').addEventListener('click', function (){
            that.createLimit();
            that.getAllLimits();
            that.fetchCounterParties();
            that.toggleLimitData();
        })
    }

    getValues(){
        this.limit_type = Utils.getter(this.elem_limit_type);
        this.limit_expiry = Utils.getter(this.elem_limit_expiry);
        this.limit_amount = Utils.getter(this.elem_limit_amount);
        this.limit_cp = Utils.getter(this.elem_limit_cp);
        this.scf_lender_application_id = Utils.getter(this.elem_scf_lender_app_id);
    }

    setValues(){
        Utils.setter(this.elem_limit_type, this.limit_type);
        Utils.setter(this.elem_limit_expiry, this.limit_expiry);
        Utils.setter(this.elem_limit_amount, this.limit_amount);
        Utils.setter(this.elem_limit_cp, this.limit_cp);
        Utils.setter(this.elem_scf_lender_app_id, this.scf_lender_application_id);
    }

    validateValues(){
        let pass = true;
        if(!this.limit_type){
            Utils.showInputError(this.elem_limit_type, 'Type is mandatory');
            pass = false;
        }
        if(!this.limit_expiry){
            Utils.showInputError(this.elem_limit_expiry, 'Expiry is mandatory');
            pass = false;
        }
        if(!this.limit_amount){
            Utils.showInputError(this.elem_limit_amount, 'Amount is mandatory');
            pass = false;
        }
        if(this.limit_type && this.limit_type === 'lender_counter_party_limit'  && !this.limit_cp){
            Utils.showInputError(this.elem_limit_cp, 'Counterparty is mandatory');
            pass = false;
        }
        return pass;
    }

    createLimit(){
        let that = this;
        document.getElementById('btn-submit-limit').addEventListener('click', function(){
            that.getValues();
            if(that.validateValues()){
                let body = {
                    // id: that.limit_id,
                    counter_party_id: that.limit_cp,
                    limit_type: that.limit_type,
                    amount: that.limit_amount,
                    expiry: that.limit_expiry
                }
                Utils.requestHandler('/scf/api/limit/' + that.scf_lender_application_id +'/create', Constants.REQUEST.post, body)
                    .then(function (response){
                        if(response.message === 'OK'){
                            Utils.showToast('Created', 'Limit created', Constants.TOAST.success);
                        }else{
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    })
            }
        })
    }

    getAllLimits(){
        let that = this;
        document.getElementById('nav-panel-lender-limits').addEventListener('click', function(){
            that.getValues();
            Utils.requestHandler('/scf/api/limit/' + that.scf_lender_application_id +'/all', Constants.REQUEST.get, {})
                .then(function (response){
                    if(response.message === 'OK'){
                        document.getElementById('tbody-lender-limits').innerHTML = null;
                        that.renderLimits(response.data.limits);
                        that.editLimits();
                        Utils.showToast('Fetched', 'Lender Limits', Constants.TOAST.success);
                    }else{
                        // Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                })
        })
    }

    transformLimitData(data){
        data.limit_type = data.limit_type.split("_").join(" ");
        return data;
    }

    renderLimits(data){
        let that = this;
        let tmpl = document.getElementById('lender-limit-row-tmpl');
        let dx = document.getElementById('tbody-lender-limits')
        data.forEach(limit => {
            let d = that.transformLimitData(limit);
            let rendered = Mustache.render(tmpl.innerHTML, d);
            dx.append($.parseHTML(rendered)[1]);
        })
    }

    fetchCounterParties(){
        let that = this;
        Utils.requestHandler('/scf/api/application/' + that.scf_application_id + '/approved-counter-parties', Constants.REQUEST.get)
            .then(function (response){
                if(response.message === 'OK'){
                    let cp_dropdown = document.getElementById('limit_cp');
                    cp_dropdown.innerHTML = '';
                    response.data.counter_parties.forEach(cp => {
                        let option = document.createElement("option");
                        option.setAttribute('value', cp.business_applicant.id);
                        let optionText = document.createTextNode(cp.business_applicant.name);
                        option.appendChild(optionText);
                        cp_dropdown.appendChild(option);
                    })                    
                    // Utils.showToast('Fetched', 'Counterparties fetched', Constants.TOAST.success);
                }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
            .then(function (){

            })
    }

    editLimits(){
        let that = this;
        document.querySelectorAll('.edit-limit').forEach(pelem => {
            pelem.addEventListener('click', function(){
                that.limit_id = pelem.getAttribute('data-limit-id');
                let limit_type = pelem.getAttribute('data-limit-type');
                document.getElementById('limit_type').value = limit_type.split(" ").join("_");
                document.getElementById('limit_expiry').value = pelem.getAttribute('data-limit-expiry');
                document.getElementById('limit_amount').value = pelem.getAttribute('data-limit-amount');
                document.getElementById('limit_cp').value = pelem.getAttribute('data-df-id');
            })
        })
    }

    toggleLimitData(){
        let that = this;
        this.elem_limit_type.addEventListener('change', function (){
            if(that.elem_limit_type.value === 'lender_counterparty_limit'){
                that.elem_limit_cp.classList.remove('no-display');
            }else{
                that.elem_limit_cp.classList.add('no-display');
            }
        })
    }

}

class Decision {

    constructor(scf_application_id){
        this.scf_application_id = scf_application_id;
        this.scf_lender_application_id = null;
        this.lapplicant_id = null;
        this.lapplication_id = null;
        this.decision = null;
        this.remarks = null;

        this.elem_decision = document.getElementById('app_lender_desc');
        this.elem_lapplicant_id = document.getElementById('lapplicant_id'); 
        this.elem_lapplication_id = document.getElementById('lapplication_id');
        this.elem_remarks = document.getElementById('app_lender_desc_remarks');
        this.elem_scf_lender_app_id = document.getElementById('scf_lender_application_id');

        this.submitDecision();
    }


    setValues(){
        Utils.setter(this.elem_decision, this.decision);
        Utils.setter(this.elem_lapplicant_id, this.lapplicant_id);
        Utils.setter(this.elem_lapplication_id, this.lapplication_id);
        Utils.setter(this.elem_remarks, this.remarks);
        Utils.setter(this.elem_scf_lender_app_id, this.scf_lender_application_id);
    }   

    getValues(){
        this.decision = Utils.getter(this.elem_decision);
        this.lapplicant_id = Utils.getter(this.elem_lapplicant_id);
        this.lapplication_id = Utils.getter(this.elem_lapplication_id);
        this.remarks = Utils.getter(this.elem_remarks);
        this.scf_lender_application_id = Utils.getter(this.elem_scf_lender_app_id);
    }

    validateValues(){
        let pass = true;
        if(!this.decision){
            Utils.showInputError(this.elem_decision, 'Decision is mandatory');
            pass = false;
        }

        if(!this.lapplicant_id){
            Utils.showInputError(this.elem_lapplicant_id, 'Lender applicant ID is mandatory');
            pass = false;
        }

        if(!this.lapplication_id){
            Utils.showInputError(this.elem_lapplication_id, 'Lender application ID is mandatory');
            pass = false;
        }
        if(!this.remarks){
            Utils.showInputError(this.elem_remarks, 'Remarks is mandatory');
            pass = false;
        }
        return pass;
    }

    submitDecision(){
        let that = this;
        document.getElementById('btn-desc-submit').addEventListener('click', function(){
            that.getValues();
            if(that.validateValues()){
                let body = {
                    lender_applicant_id: that.lapplicant_id,
                    lender_application_id: that.lapplication_id,
                    remarks: that.remarks
                }
                Utils.requestHandler('/scf/api/lender_application/' + that.scf_lender_application_id + '/decision/' + that.decision, Constants.REQUEST.post, body)
                    .then(function (response){
                        if(response.message === 'OK'){
                            Utils.showToast('Submitted', 'Decision', Constants.TOAST.success);
                        }else{
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    })
            }
        })
    }

    // toggleLenderApplicationColumn(){
    //     let that = this;
    //     let app_col = document.getElementById('app-' + that.scf_lender_application_id);
    //     app_col.innerHTML =  that.lapplication_id + '<br/>' +
    //                         '<small class="text-grey">' +
    //                          that.lapplicant_id +
    //                         '</small>';
    //
    //     let decision_col = document.getElementById('decision-' + that.scf_lender_application_id);
    //     decision_col.innerHTML = '<span class="badge badge-green">' + that.decision + '</span>';
    // }
}

class Documents {

    constructor(){
        this.scf_application_id = '80a07aec-13db-4661-a60b-789b69a2675d'
        this.elem_document_details = document.getElementById('app_lender_docs')
        this.renderDocuments();
    }

    renderDocuments(){
        let that = this;
        Utils.requestHandler("/scf/api/onboarding/application/" + that.scf_application_id + '/documents', Constants.REQUEST.get, {})
            .then(function (response){
                if(response.message === 'OK'){
                    that.renderAllDocuments(response.data);
                    Utils.showToast(response.message, 'Document list fetched', Constants.TOAST.success);
                }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
    }

    transformDocumentData(ref, last_doc = null){
        let drop_ship = {}
        drop_ship.doc_title = ref.set_value.title;
        if(last_doc != null){
            drop_ship.doc_subtitle = "Last updated " + Utils.formatTimeRelative(last_doc.updated_at);
        } else {
            drop_ship.doc_subtitle = ref.set_value.title;
        }
        drop_ship.dtype = ref.set_key;
        let ext = ref.set_value.ext[0];
        if(ext && ['jpeg', 'jpg', 'png'].includes(ext.toLowerCase())) {
            drop_ship.logo = "bi-file-earmark-image";
        }else if(ext && ['xls', 'xlsx'].includes(ext.toLowerCase())) {
            drop_ship.logo = "bi-filetype-xls";
        }else if(ext && ['pdf'].includes(ext.toLowerCase())){
            drop_ship.logo = "bi-file-earmark-pdf";
        }else{
            drop_ship.logo = "bi-file-earmark-text";
        }
        return drop_ship;
    }

    transformPreviewData(preview_data){
        preview_data.formatted_ts = Utils.formatTime(preview_data.created_at);
        preview_data.formatted_ts_ago = Utils.formatTimeRelative(preview_data.created_at);
        preview_data.name = preview_data.id;
        return preview_data;
    }

    renderAllDocuments(data){
        let that = this;
        let tmpl = document.getElementById('doc-tmpl');
        this.elem_document_details.innerHTML = '';
        for(let dtype in data.ref){
            if(['loan_agreement', 'loan_sanction_letter', 'loan_p_nach'].includes(dtype)) {
                let d = that.transformDocumentData(data.ref[dtype]);
                let rendered = Mustache.render(tmpl.innerHTML, d);
                that.elem_document_details.append($.parseHTML(rendered)[1]);
                let url = '/scf/api/application/' + that.scf_application_id + '/document/upload/' + dtype;
                let action_element = document.getElementById('btn_upload_' + dtype);
                DocumentUtils.bindInputElement(action_element);
                DocumentUtils.uploadFiles(action_element, url);
                that.showUploadPreview(action_element);
            }
        }
        this.renderUploadedDocuments(data);
    }

    renderUploadedDocuments(data){
        let that = this;
        let p_tmpl = document.getElementById('doc-preview-tmpl');
        data.documents.forEach(function (doc){
            let dtype = doc.file_code;
            if(['loan_agreement', 'loan_sanction_letter', 'loan_p_nach'].includes(dtype)) {
                let prev = document.getElementById('prev-' + dtype);
                let d1 = that.transformPreviewData(doc);
                let rend = Mustache.render(p_tmpl.innerHTML, d1);
                prev.append($.parseHTML(rend)[1]);
            }
        })
    }

    showUploadPreview(action_element){
        let that = this;
        let p_tmpl = document.getElementById('doc-preview-tmpl');
        action_element.addEventListener('ev_document_uploaded', function (){
            let response = JSON.parse(action_element.getAttribute('data-upload-response'));
            let prev = document.getElementById('prev-'+response.file_code);
            let d1 = that.transformPreviewData(response);
            let rend = Mustache.render(p_tmpl.innerHTML, d1);
            prev.prepend($.parseHTML(rend)[1]);
        })
    }

}

// class EditLenderApplication {

//     constructor(scf_application_id){
//         new Decision(scf_application_id);
//         new Limit(scf_application_id);
//         // new Documents();
//     }
// }




class CreateLenderApplication {

    constructor(scf_application_id){
        this.lender = null;
        this.amount = null;
        this.product = null;
        this.program = null;
        this.facility_validity = null;
        this.roi = null;
        this.processing_fee = null;
        this.cp_limits = [];
        this.interest_mode=null;

        this.elem_lender = document.getElementById('app_lender');
        this.elem_amount = document.getElementById('app_amount');
        this.elem_product = document.getElementById('app_product');
        this.elem_program = document.getElementById('app_program');
        this.elem_roi = document.getElementById('app_roi');
        this.elem_processing_fee = document.getElementById('app_processing_fee');
        this.elem_interest_mode = document.getElementById('interest_mode');
        this.elem_facility_validity = document.getElementById('app_facility_validity');
        this.elem_fldg_percentage = document.getElementById('fldg_anchor_fee');

        this.elem_lender.addEventListener('input', function (){
            Utils.reInput(this);
        })

        this.elem_roi.addEventListener('input', function (){
            Utils.reInput(this);
        })

        this.elem_amount.addEventListener('input', function (){
            Utils.reInput(this);
        })

        this.elem_processing_fee.addEventListener('input', function (){
            Utils.reInput(this);
        })

        this.elem_facility_validity.addEventListener('input', function (){
            Utils.reInput(this);
        })

        this.scf_application_id = scf_application_id;
        this.fetchCounterParties();
        this.fetchLenders();
        this.createApplication();
        this.fetchSelectedLender();
    }

    setValues(){
        Utils.setter(this.elem_lender, this.lender);
        Utils.setter(this.elem_amount, this.amount);
        Utils.setter(this.elem_roi, this.roi);
        Utils.setter(this.elem_processing_fee, this.processing_fee);
        Utils.setter(this.fldg_percentage, this.fldg_percentage);
        Utils.setter(this.elem_interest_mode, this.interest_mode);
    }

    getValues(){
        let that = this;
        this.lender = Utils.getter(this.elem_lender);
        this.amount = Utils.getter(this.elem_amount);
        this.roi = Utils.getter(this.elem_roi);
        this.processing_fee = Utils.getter(this.elem_processing_fee);
        this.facility_validity = Utils.getter(this.elem_facility_validity);
        that.cp_limits = [];
        this.fldg_percentage = Utils.getter(this.elem_fldg_percentage);
        this.interest_mode = Utils.getter(this.elem_interest_mode);
    }

    validateValues(){
        let pass = true;
        if(!this.lender){
            Utils.showInputError(this.elem_lender, 'Lender is mandatory');
            pass = false;
        }

        if(!this.amount){
            Utils.showInputError(this.elem_amount, 'Amount is mandatory');
            pass = false;
        }

        if(!this.roi){
            Utils.showInputError(this.elem_roi, 'ROI is mandatory');
            pass = false;
        }
        else{
            if(this.roi <= 0){
                Utils.showInputError(this.elem_roi, 'Roi value should be greater than 0');
                pass = false;
            }
        }
        if(!this.processing_fee){
            Utils.showInputError(this.elem_processing_fee, 'Processing value fee is mandatory');
            pass = false;
        }else{
            if(this.processing_fee<=0){
                Utils.showInputError(this.elem_processing_fee, 'Processing value should be greater than 0');
                pass = false;
            }
        }
        if(!this.facility_validity){
            Utils.showInputError(this.elem_facility_validity, 'Facility Validity is mandatory');
            pass = false;
        }else{
            if (!Number.isInteger(parseFloat(this.facility_validity))) {
                Utils.showInputError(this.elem_facility_validity, 'Facility Validity must be a whole number');
                pass = false;
            } 
        }
        return pass;
    }


    fetchCounterParties(){
        let that = this;
        Utils.requestHandler('/scf/api/application/' + this.scf_application_id + '/approved-counter-parties', Constants.REQUEST.get)
            .then(function (response){
                if(response.message === 'OK'){
                    let tmpl = document.getElementById('lapp-cp-row-tmpl');
                    let dx = document.getElementById('tb-lapp-cp-tbody');
                    dx.innerHTML = null;
                    document.getElementById('app_product').value = response.data.counter_parties[0].product
                    response.data.counter_parties.forEach(function (dd){
                        let rendered = Mustache.render(tmpl.innerHTML, dd);
                        dx.append($.parseHTML(rendered)[1]);
                        that.populateSelectedCpIds();
                    })
                    // Utils.showToast('Fetched', 'Counterparties fetched', Constants.TOAST.success);
                }else{
                    // Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
            .then(function (){
                if(document.getElementById('app_product').value === "Short Term Loan"){
                    document.getElementById('anchor_fldg_lebel_id').classList.add("no-display");
                    document.getElementById('fldg_anchor_fee').classList.add("no-display");
                    document.getElementById('select-counter-parties').classList.add("no-display");
                    document.getElementById('label_app_facility_validity').textContent = "Facility Validity (in Months)"
                    document.getElementById('app_facility_validity').placeholder = "Enter Months"
                }
            })
    }

    fetchSelectedLender(){
        let that = this;
        Utils.requestHandler('/scf/api/application/'+ that.scf_application_id + '/program-lender', Constants.REQUEST.get)
            .then(function (response){
                document.getElementById('app_lender').value = response.data.tagged_lender.name;
                document.getElementById('lender_id').value = response.data.tagged_lender.id;
            });
    }

    fetchLenders(){
        let that = this;
        Utils.requestHandler('/scf/api/lender_application/lenders/all', Constants.REQUEST.get)
            .then(function (response){
                if(response.message === 'OK'){
                    let lender_dropdown = document.getElementById('app_lender');
                    lender_dropdown.innerHTML = '<option value="">Select a lender</option>';
                    response.data.lenders.forEach(lender => {
                        let option = document.createElement("option");
                        option.setAttribute('value', lender.id);
                        let optionText = document.createTextNode(lender.name);
                        option.appendChild(optionText);

                        lender_dropdown.appendChild(option);
                    })
                    // Utils.showToast('Fetched', 'Lenders', Constants.TOAST.success);    
                }else{
                    // Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
            .then(function (){

            })
    }

    createApplication() {
        let that = this;
        document.getElementById('btn-app-submit').addEventListener('click', function() {
            const requiredFields = document.querySelectorAll('#from1, #to1, #stale1');
            let allFilled = true;

            requiredFields.forEach(function(field) {
                if (!field.value) {
                    allFilled = false;
                    field.classList.add('is-invalid');
                } else {
                    field.classList.remove('is-invalid');
                }
            });

            if (!allFilled) {
                Utils.showToast('Validation Error', 'Please fill all mandatory fields.', Constants.TOAST.fail);
                return;
            }

            that.getValues();
            const idContainer = document.getElementById('lender_app_cp_ids');
            const dataCpIds = idContainer.getAttribute('data-cp-ids');
            const isEdit = document.getElementById('is_edit_lender_application').value;
            const lender_application_id = document.getElementById('lender_application_id').value;
            let facility_validity_data = that.facility_validity;
            const stalePeriods = [];

            function getFieldValues(i) {
                const from = document.getElementById(`from${i}`).value;
                const to = document.getElementById(`to${i}`).value;
                const stale = document.getElementById(`stale${i}`).value;
                return { from, to, stale };
            }

            for (let i = 1; i <= 3; i++) {
                const values = getFieldValues(i);
                if (values.from || values.to || values.stale) {
                    stalePeriods.push(values);
                }
            }

            function validateStalePeriods(periods) {
                for (let i = 0; i < periods.length; i++) {
                    if (new Date(periods[i].from) > new Date(periods[i].to)) {
                        return false;
                    }
                    if (i > 0 && (new Date(periods[i].from) <= new Date(periods[i - 1].to))) {
                        return false;
                    }
                    if (i > 0 && (Number(periods[i].stale) <= Number(periods[i - 1].stale))) {
                        return false;
                    }
                }
                return true;
            }

            function validateCustomConditions() {
                const fields = ['from', 'to'];
                const values = fields.reduce((acc, field) => {
                    for (let i = 1; i <= 3; i++) {
                        acc[`${field}${i}`] = Number(document.getElementById(`${field}${i}`).value);
                    }
                    return acc;
                }, {});

                return (values.to1 > values.from1) &&
                    (values.from2 ? values.to2 > values.to1 && values.from2 > values.to1 : true) &&
                    (values.from2 ? values.to2 > values.from2 : true) &&
                    (values.from3 ? values.to3 > values.to2 && values.from3 > values.to2 : true) &&
                    (values.from3 ? values.to3 > values.from3 : true);
            }

            if (!validateStalePeriods(stalePeriods)) {
                Utils.showToast('Validation Error', 'Please ensure the stale periods are valid.', Constants.TOAST.fail);
                return;
            }

            if (!validateCustomConditions()) {
                Utils.showToast('Validation Error', 'Please ensure the stale period conditions are valid.', Constants.TOAST.fail);
                return;
            }

            if (document.getElementById('app_product').value === "Short Term Loan") {
                var currentDate = new Date();
                var nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
                var nextValidMonth = new Date(currentDate.getFullYear(), parseInt(currentDate.getMonth()) + 1 + parseInt(that.facility_validity), 1);
                var timeDifference = nextValidMonth - nextMonth;
                var daysDifference = timeDifference / (1000 * 3600 * 24);
                facility_validity_data = daysDifference;
            }

            const body = {
                approved_limit: that.amount,
                roi: that.roi,
                processing_fee: that.processing_fee,
                counter_parties: dataCpIds,
                facility_validity: String(facility_validity_data),
                fldg_percentage: that.fldg_percentage,
                interest_mode_data: that.interest_mode,
                stale_periods: stalePeriods
            };

            const url = isEdit === "true"
                ? `/scf/api/lender_application/${lender_application_id}/edit-application/` 
                : `/scf/api/lender_application/${that.scf_application_id}/${document.getElementById('lender_id').value}`;

            Utils.requestHandler(url, Constants.REQUEST.post, body)
                .then(function(response) {
                    if (response.message === 'OK') {
                        var modalElement = document.getElementById('modal_lender_app');
                        var modalInstance = bootstrap.Modal.getInstance(modalElement);
                        modalInstance.hide();
                        var button = document.getElementById('nav-panel-lenders');
                        if (button) {
                            button.click();
                        } else {
                            console.error("Button not found!");
                        }
                        Utils.showToast(isEdit === "true" ? 'Lender Application Updated' : 'Lender application created', 'Success', Constants.TOAST.success);
                        document.getElementById('btn-lender-modal-close').click();
                    } else {
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                });
        });
    }

    populateSelectedCpIds() {
        const checkboxes = document.querySelectorAll('.lender_app_cp_checkbox');
        const idContainer = document.getElementById('lender_app_cp_ids');
  
        // Function to update the data-cp-ids attribute
        const updateIds = () => {
            const selectedIds = Array.from(checkboxes) // Convert NodeList to Array
                                    .filter(checkbox => checkbox.checked) // Filter out unchecked checkboxes
                                    .map(checkbox => checkbox.dataset.id); // Map to data-id values
    
            idContainer.setAttribute('data-cp-ids', selectedIds.join(',')); // Join IDs with comma and update attribute
        };
    
        // Attach event listeners to checkboxes
        checkboxes.forEach(checkbox => {
            checkbox.addEventListener('change', updateIds);
        });
    
        // Initial update in case some checkboxes are pre-checked (optional)
        updateIds();
    }
}

export default class ScfPanelLenderApplication{

    constructor() {
        let that = this;
        let clicked = false;
        this.elem_lender_modal = new bootstrap.Modal(document.getElementById('modal_lender_app'), {});
        Utils.initializePagers('lender-app-pager');
        document.getElementById('nav-panel-lenders').addEventListener('click', function (){

            that.scf_application_id = document.getElementById('scf_application_id').value;
            // new EditLenderApplication(that.scf_application_id);
            that.fetchLenderApplications();
            if(!clicked) {     
              clicked = true;
              new CreateLenderApplication(that.scf_application_id);
              that.initializeAddLenderApplication();
            }
        })
        document.getElementById('nav-limits').addEventListener('click', function (){
            that.scf_application_id = document.getElementById('scf_application_id').value;
            that.getApplicationLimit();
        })
    }

    transformApplicationLimit(data){
        data.limit_type_formatted = Utils.capitalizeFirstLetter(data.limit_type.split("_").join(" "))
        data.updated_at_fromatted = Utils.formatTime(data.updated_at);
        data.relative_expiry = Utils.formatTimeRelative(data.expiry);
        data.formatted_expiry = Utils.formatTime(data.expiry);
        data.f_amount = data.amount.toLocaleString('en-IN');
        data.f_utilized_amount =  data.utilized_amount ? data.utilized_amount.toLocaleString('en-IN') : 0;
        return data;
    }

    getApplicationLimit(){
        let that = this;
        Utils.requestHandler("/scf/api/application/" + that.scf_application_id + '/app-limits', Constants.REQUEST.get, {})
            .then(function (response){
                if(response.message === 'OK'){
                    if(response.data.limits.length > 0){
                        let tmpl = document.getElementById('app-limits-tmpl-row');
                        let dx = document.getElementById('app-limits-tbody');
                        dx.innerHTML = null;
                        response.data.limits.forEach(function (dd){
                            that.transformApplicationLimit(dd);
                            let rendered = Mustache.render(tmpl.innerHTML, dd);
                            dx.append($.parseHTML(rendered)[1]);
                        })
                    }
                }
            })
    }

    initializeAddLenderApplication(){
        let that = this;
        document.getElementById('btn-lender-modal').addEventListener('click', function (){
            that.clearAddLenderApplicationModal();
            document.getElementById('nav-panel-lender-application').click();
            that.elem_lender_modal.show();
            document.querySelectorAll('.m1').forEach(function (elem){
                elem.classList.remove('no-display');
            })
            document.querySelectorAll('.m2').forEach(function (elem){
                elem.classList.add('no-display');
            })
        })
    }

    editLenderApplication(){
        let that = this;
        const buttons = document.querySelectorAll('.edit-lender-application-btn');

        // Loop through each button and add an event listener
        buttons.forEach(button => {
            button.addEventListener('click', function(event) {
                event.preventDefault();  // Prevent the default action
                
                document.getElementById('lender_application_id').value = button.getAttribute('data-id-lender');
                document.getElementById('is_edit_lender_application').value = "true";
                document.getElementById('app_lender').classList.add('no-display');
                document.getElementById('app_amount').value = button.getAttribute('data-approved-limit');
                document.getElementById('app_roi').value = button.getAttribute('data-roi');
                document.getElementById('app_processing_fee').value = button.getAttribute('data-processing-fee');
                document.getElementById('app_facility_validity').value = button.getAttribute('data-facility-validity');
                document.getElementById('lender_application_id').value = button.getAttribute('data-id');
                var ids = button.getAttribute('data-lender-app-cp-ids').split(',');
                ids.forEach(function(item, index) {
                    let selectBox = document.querySelector(`[data-id='${item.trim()}']`);
                    if (selectBox && selectBox.type === 'checkbox') {
                        selectBox.checked = true;  
                    }
                });
                const idContainer = document.getElementById('lender_app_cp_ids');
                idContainer.setAttribute('data-cp-ids', button.getAttribute('data-lender-app-cp-ids'));
                that.elem_lender_modal.show();
            });
        });
    }

    cancelButtonLenderApplication() {
        const anchors = document.querySelectorAll('a[id^="cancel_lender_application_"]');
    
        function handleClick(event) {
            event.preventDefault();
            if (confirm('Are you sure you want to cancel?')) {
                const fullId = event.target.id;
                const lenderApplicationId = fullId.substring('cancel_lender_application_'.length);
                Utils.requestHandler('/scf/api/lender_application/' + lenderApplicationId + '/cancel-lender-application/', Constants.REQUEST.post, {})
                .then(function (response){
                    if(response.message === 'OK'){
                        Utils.showToast('Success', 'Lender Application Cacelled', Constants.TOAST.success);
                        document.getElementById('nav-panel-lenders').click();
                    }else{
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                })
            }
        }

        anchors.forEach(function(anchor) {
            anchor.addEventListener('click', handleClick);
        });
    }

    programValidator(data) {
        document.getElementById('app_program').value = data['tagged_program'].program_name
        document.getElementById('app_product').value = data['tagged_program'].product
        if (data['tagged_program'].program_rules === 'fixed') {
            document.getElementById('processing_fee_lebel_id').innerHTML = "Processing Fee In Fixed";
            document.getElementById('app_processing_fee').placeholder = "In fixed";
        }
        if (data['tagged_program'].fldg_applied != 'Yes') {
            document.getElementById('anchor_fldg_lebel_id').classList.add('no-display');
            document.getElementById('fldg_anchor_fee').classList.add('no-display');
        }
        if (data['tagged_program'].interest_mode != 'all') {
            document.getElementById('inerest_mode_lebel_id').classList.add('no-display');
            document.getElementById('inerest_mode').classList.add('no-display');
        }
        if(document.getElementById('app_product').value === "Short Term Loan"){
            document.getElementById('anchor_fldg_lebel_id').classList.add("no-display");
            document.getElementById('fldg_anchor_fee').classList.add("no-display");
            document.getElementById('select-counter-parties').classList.add("no-display");
            document.getElementById('label_app_facility_validity').textContent = "Facility Validity (in Months)"
            document.getElementById('app_facility_validity').placeholder = "Enter Months"
        }
    }

    SendApplicationToLender() {
        // Select all anchor tags with ids starting with 'submit_to_lender_application_'
        const anchors = document.querySelectorAll('a[id^="submit_to_lender_application_"]');
    
        function handleClick(event) {
            event.preventDefault(); // Prevent the default link behavior
            if (confirm('Are you sure you want to submit this application to the lender?')) {
                const fullId = event.target.id;
                const lenderApplicationId = fullId.substring('submit_to_lender_application_'.length);
    
                Utils.requestHandler('/scf/api/lender_application/' + lenderApplicationId + '/submit-to-lender/', Constants.REQUEST.post, {})
                .then(function (response) {
                    if (response.message === 'OK') {
                        Utils.showToast('Success', 'Lender Application Submitted', Constants.TOAST.success);
                        // Assuming you might want to redirect or perform some UI update
                        document.getElementById('nav-panel-lenders').click();
                    } else {
                        Utils.showToast('Error', Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                })
            }
        }
    
        anchors.forEach(function(anchor) {
            anchor.addEventListener('click', handleClick);
        });
    }



    clearAddLenderApplicationModal(){
        document.getElementById('app_amount').value = '';
        document.getElementById('app_roi').value = '';
        document.getElementById('app_processing_fee').value = '';
        document.getElementById('interest_mode').value="";
        document.getElementById('app_facility_validity').value = '';
        document.getElementById('app_lender').classList.remove('no-display');
        document.getElementById('is_edit_lender_application').value = "false";
        document.getElementById('lender_application_id').value = "";
        document.getElementById('fldg_anchor_fee').value = '';
        document.getElementById('from1').value = '';
        document.getElementById('from2').value = '';
        document.getElementById('from3').value = '';
        document.getElementById('to1').value = '';
        document.getElementById('to2').value = '';
        document.getElementById('to3').value = '';
        document.getElementById('stale1').value = '';
        document.getElementById('stale2').value = '';
        document.getElementById('stale3').value = '';
        const checkboxes = document.querySelectorAll('.lender_app_cp_checkbox');

        checkboxes.forEach(function(checkbox) {
            checkbox.checked = false;
        });
    }

    transformLenderApplicationData(data){
        data.status = Utils.snakeToTitleCase(data.status)
        data.counter_parties = data.counter_party_names
        data.lender_application_id = data.lender_application_id ? data.lender_application_id : '-';
        data.approved_limit = data.approved_limit
        data.formatted_approved_limit =  Utils.indianRepresentation(data.approved_limit);
        data.cp_count = data.cp_count
        data.lender_name = data.lender
        data.processing_fee = data.processing_fee
        data.roi = data.roi
        data.lender_app_cps = data.lender_app_cps
        data.facility_validity = data.facility_validity
        data.product = data.product
        data.interest_mode = data.interest_mode
        return data;
    }

    fetchLenderApplications(){
        let that = this;
        Utils.requestHandler('/scf/api/application/' + that.scf_application_id + '/lender-applications', Constants.REQUEST.get)
            .then(function (response){
                if(response.message === 'OK'){
                    let tmpl = document.getElementById('lender-app-tmpl');
                    let tbody = document.getElementById('tbody-lender-app')
                    tbody.innerHTML = null;
                    if (response.data.lender_applications){
                        response.data.lender_applications.forEach(function (app){
                            that.transformLenderApplicationData(app);
                            let rend = Mustache.render(tmpl.innerHTML, app);
                            tbody.append($.parseHTML(rend)[1]);
                            if (app.status == 'Lender Approved'){
                                document.getElementById("submit_to_lender_application_" + app.id).classList.add('no-display')
                                document.getElementById("lender_application_action_" + app.id).classList.add('no-display')
                            } else if(app.status == 'Lender Rejected') {
                                document.getElementById("submit_to_lender_application_" + app.id).classList.add('no-display')
                                document.getElementById("lender_application_action_" + app.id).classList.add('no-display')
                            } else if(app.status == 'Cancelled') {
                                document.getElementById("submit_to_lender_application_" + app.id).classList.add('no-display')
                                document.getElementById("lender_application_action_" + app.id).classList.add('no-display')
                            }  else if(app.status == 'Awaiting Lender Decision') {
                                document.getElementById("submit_to_lender_application_" + app.id).classList.add('no-display')
                                document.getElementById("edit_lender_application_" + app.id).classList.add('no-display')
                            }
                            that.detailsButtonLenderApplicantion();
                        })
                    }
                    that.editLenderApplication();
                    that.cancelButtonLenderApplication();
                    that.SendApplicationToLender();
                    that.programValidator(response.data);
                    // Utils.showToast('Fetched', 'Lender Applications', Constants.TOAST.success);
                }else{
                    // Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
    }

    detailsButtonLenderApplicantion(){
        let that = this;
        document.querySelectorAll('.lender-applicantion-details-button').forEach(function(button) {
            button.addEventListener('click', function(event) {
                const lenderName = this.getAttribute('data-lender-name');
                const approvedLimit = this.getAttribute('data-approved-limit');
                const roi = this.getAttribute('data-roi');
                const processingFee = this.getAttribute('data-processing-fee');
                const status = this.getAttribute('data-status');
                const counterParties = this.getAttribute('data-counter-parties');
                const remarks = this.getAttribute('data-remarks');
        
                document.getElementById('modal-details-lender-name').textContent = lenderName;
                document.getElementById('modal-details-approved-limit').textContent = approvedLimit;
                document.getElementById('modal-details-roi').textContent = roi;
                document.getElementById('modal-details-processing-fee').textContent = processingFee;
                document.getElementById('modal-details-status').textContent = status;
                document.getElementById('modal-details-counter-parties').textContent = counterParties;
                document.getElementById('modal-details-lender-remarks').textContent = remarks;
    
                var modal = new bootstrap.Modal(document.getElementById('detailsModal'), {});
                modal.show();
            });
          });
    }

    initializeLenderEditModal(){
        let that = this;
        document.querySelectorAll('.btn-edit-lender-modal').forEach(function (pelem){
            pelem.addEventListener('click', function (){
                document.getElementById('nav-panel-lender-decision').click();
                that.setUpLenderEditModalData(pelem);
                that.elem_lender_modal.show();
                document.querySelectorAll('.m1').forEach(function (elem){
                    elem.classList.add('no-display');
                })
                document.querySelectorAll('.m2').forEach(function (elem){
                    elem.classList.remove('no-display');
                })
            })
        })
    }

    setUpLenderEditModalData(pelem){
        document.getElementById('lapplicant_id').value = null;
        document.getElementById('lapplication_id').value = null;
        document.getElementById('app_lender_desc_remarks').value = null;

        document.getElementById('scf_lender_application_id').value = pelem.getAttribute('data-scf-lapp-id');
        document.getElementById('lapplicant_id').value = pelem.getAttribute('data-lappn-id');
        document.getElementById('lapplication_id').value = pelem.getAttribute('data-lapp-id');

        let decision = pelem.getAttribute('data-desc');
        if (decision === "lender approved"){
            decision = "approved";
        }else{
            decision = "rejected";
        }

        document.getElementById('app_lender_desc').value = decision;
        document.getElementById('tbody-lender-limits').innerHTML = null;
    }

}


