import { DocumentUtils, Utils } from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from "mustache";
import Moment from "moment";

import ScfAppPanelDocument from "./scf_app_panel_document";
import ScfAppPanelInvoice from "./scf_app_panel_invoice";
import ScfAppApplicationPanel from "./scf_app_application_panel";
import ScfAppAnalysisCrimeCheck from "./scf_app_analysis_crime_check";
import ScfAppAnalysisBanking from "./scf_app_analysis_banking";
import ScfPanelLenderApplication from "./scf_app_panel_lender";
import ScfAppPanelLenderDecision from "./scf_app_panel_decision";
import ScfAppPanelDedupe from "./scf_app_panel_dedupe";
import { ScfPermission } from "../scf_auth_handler";



export class ScfApplicationDashboard {
  constructor() {
    Utils.pageSanityHandler();
  }

  handler() {
    this.dtHandler();
  }

  mainTableTransformer(data) {
    data.application_details_link = "/scf/application/details/" + data.id;
    data.required_amount = data.required_amount.toLocaleString("en-IN");
    data.formatted_created_at = Utils.formatTime(data.created_at);
    data.created_at_ago = Utils.formatTimeRelative(data.created_at);
    data.formatted_updated_at = Utils.formatTimeRelative(data.updated_at);
    data.status_human = Utils.capitalizeFirstLetter(
      data.status.split("_").join(" ")
    );
    return data;
  }

  dtHandler() {
    let table_container_id = "#dt-table-application";
    let dt_instance = Utils.renderDataTable(table_container_id + "> table");
    Utils.initializeDataTable(
      table_container_id,
      dt_instance,
      "/scf/api/applications",
      Constants.REQUEST.get,
      {},
      "dt-main-tmpl",
      this.mainTableTransformer,
      "data",
      {
        business_application_id: "Application ID",
        business_applicant_name: "Applicant",
      }
    );
  }
}

export class ScfApplicationDetails {
  constructor() {
    Utils.pageSanityHandler();
    Utils.initializePagers("app-pager-parent");
  }

  handler() {
    this.userWisePanelHandler();
    new ScfAppApplicationPanel();
    new ScfAppPanelDocument();
    new ScfAppPanelInvoice();
    new ScfAppPanelDedupe();
    new PanelAnalysis();
    new ScfPanelLenderApplication();
    if  (ScfPermission.isScfLender()){
      new ScfAppPanelLenderDecision();
    }
    new PanelCounterParties();
    new PanelPd();
    new PanelGstin();
    new PanelDomainEmail();
    new PanelDecision();
    new UdhyamAadhar();
    new Eligibility();
    this.attachProfileButtonHandler();
}

  userWisePanelHandler() {
    if (ScfPermission.isScfBorrower()) {
      document
        .getElementById("app-pager-parent")
        .querySelectorAll(".pager-items")
        .forEach((element) => {
          element.classList.add("no-display");
        });
      document.getElementById("nav-application").classList.remove("no-display");
      document.getElementById("nav-applicant").classList.remove("no-display");
      document.getElementById("nav-counterparty").classList.remove("no-display");
    }
    // hiding fulfiilment for counterparty and borrower and lender
    if (ScfPermission.isScfLender() || ScfPermission.isScfCounterparty() || ScfPermission.isScfBorrower() || ScfPermission.isScfSales()) {
      document.getElementById("nav-panel-lenders").classList.add("no-display");
      document.getElementById("balance_sheet_file").classList.add("no-display");
      document.getElementById("btn-submit-app-decision").classList.add("no-display");
      document.getElementById("udyamSearchField").classList.add("no-display");
      document.getElementById("syncButton").classList.add("no-display");
      document.getElementById("btn_generate_gst_report_pdf").classList.add("no-display");
      document.getElementById("upload_balance_sheet").classList.add("no-display");
      document.getElementById("upload_financial_appraisal").classList.add("no-display");
      document.getElementById("download_button_hide").classList.add("no-display");
      document.getElementById("btn_generate_pd_pdf").classList.add("no-display");
      document.getElementById("btn-quest-submit").classList.add("no-display");
      document.getElementById("profil_button").classList.add("no-display");
    } 
    // hiding counterparty add button for lender and counteryparty
    if (ScfPermission.isScfLender() || ScfPermission.isScfCounterparty()) {
      document.getElementById("btn_cp_add_modal").classList.add("no-display");
    }
    // hiding dedupe for lender and counterparty and borrower
    if(ScfPermission.isScfLender() || ScfPermission.isScfCounterparty() || ScfPermission.isScfBorrower() || ScfPermission.isScfSales()){
      document.getElementById("nav-dedupe").classList.add("no-display");
    }
    //hiding for document for counterparty,borrrwer
    if(ScfPermission.isScfCounterparty() || ScfPermission.isScfBorrower() || ScfPermission.isScfSales()){
      document.getElementById("nav-documents").classList.add("no-display");
      document.getElementById("nav-kyc").classList.add("no-display");
    }
    //hiding profile and application and de-duplication and kyc 
    if(ScfPermission.isScfAdmin() || ScfPermission.isScfCredit()){
      document.getElementById("nav-applicant").classList.add("no-display");
      document.getElementById("nav-application").classList.add("no-display");
      document.getElementById("nav-documents").classList.add("no-display");
      document.getElementById("nav-dedupe").classList.add("no-display");
      document.getElementById("nav-kyc").classList.add("no-display");
    }
    //hiding anyalysis for counterparty
    if(ScfPermission.isScfCounterparty()  || ScfPermission.isScfSales()){
      document.getElementById("nav-analysis-tab").classList.add("no-display");
    }
    else if (ScfPermission.isScfCounterparty()) {
      document
        .getElementById("app-pager-parent")
        .querySelectorAll(".pager-items")
        .forEach((element) => {
          element.classList.add("no-display");
        });
      document.getElementById("nav-application").classList.remove("no-display");
      document.getElementById("nav-applicant").classList.remove("no-display");
    } else if (ScfPermission.isScfAdmin()) {
      localStorage.setItem("pan_name", "");
      localStorage.setItem("pan", "");
    } else if (ScfPermission.isScfCredit()) {
      localStorage.setItem("business_applicant_id", "");
      localStorage.setItem("pan_name", "");
      localStorage.setItem("pan", "");
    }
  }
  attachProfileButtonHandler() {
    const profileButton = document.getElementById('profil_button');
    if (profileButton) {
      profileButton.addEventListener('click', this.reactProfileUrl);
    }
  }
  reactProfileUrl() {
      window.location.replace("/R/application/detail/" + document.getElementById('scf_application_id').value
      ) 
    // window.location.replace("http://localhost:3000/R/application/detail") 
  }

}

class PanelDomainEmail {
  constructor() {
    this.scf_application_id =
      document.getElementById("scf_application_id").value;
    let that = this;
    document
      .getElementById("nav-domain-email")
      .addEventListener("click", function () {
        that.fetchDomainEmailData();
      });
    this.refreshDomainEmail();
  }

  fetchDomainEmailData() {
    let that = this;
    Utils.requestHandler(
      "/scf/api/application/" + that.scf_application_id + "/email-fraud-check",
      Constants.REQUEST.get
    )
      .then(function (response) {
        if (response.message === "OK") {
          let data = response.data.result[0];
          let container = document.getElementById("email_domain_container"); // Assuming 'container' is a div where you want to append the table
          that.createNestedTable(data, container);
          // const dataEmailDomainMustache = that.populateEmailData(response);
          // const templateDomainEmail = document.getElementById('templateDomainEmail').innerHTML;
          // const rendered = Mustache.render(templateDomainEmail, dataEmailDomainMustache);
          // document.getElementById('targetDomainEmail').innerHTML = rendered;
          Utils.showToast(
            "Fetched",
            "Domain & Email fetched",
            Constants.TOAST.success
          );
        } else {
          Utils.showToast(
            response.message,
            Utils.formatErrorMessage(response.errors),
            Constants.TOAST.info
          );
        }
      })
      .then(function () {});
  }

  populateEmailData(json) {
    let data = {
      company: `${json["data"]["result"][0]["emailOwnerDetails"]?.company}<br/>`,
      email: `${json["data"]["result"][0]["emailOwnerDetails"]?.email}<br/>`,
      location: `${json["data"]["result"][0]["emailOwnerDetails"]?.location}<br/>`,
      name: `${json["data"]["result"][0]["emailOwnerDetails"]?.name}<br/>`,
      domainName: `${json["data"]["result"][0]["domainDetails"]?.domainName}<br/>`,
      domainCountry: `${json["data"]["result"][0]["domainDetails"]?.country}<br/>`,
      emailRiskAdvice: `<label>${json["data"]["result"][0]["emailAndDomainRiskDetails"]?.advice}</label>`,
      emailRiskReason: `${json["data"]["result"][0]["emailAndDomainRiskDetails"]?.reason}`,
      ipAddress: `${json["data"]["result"][0]["ipDetails"]?.ipAddress}<br/>`,
      ipReputation: `${json["data"]["result"][0]["ipDetails"]?.ipReputation}`,
      ipCity: `${json["data"]["result"][0]["ipLocationDetails"]?.ipCity}`,
      ipRegion: `${json["data"]["result"][0]["ipLocationDetails"]?.ipRegion}`,
      ipNetSpeedCell: `${json["data"]["result"][0]["ipDetails"]?.ipNetSpeedCell}`,
      domainAge: `${json["data"]["result"][0]["emailAndDomainValidationDetails"]?.domainAge}`,
      gravatar: `${json["data"]["result"][0]["socialMediaDetails"]["smlinks"][0]?.link}`,
      googleSearch: `${json["data"]["result"][0]["socialMediaDetails"]["smlinks"]?.link}`,
      twitter: `${json["data"]["result"][0]["socialMediaDetails"]["smlinks"][3]?.link}`,
    };
    return data;
  }

  createNestedTable(jsonObj, parentElement) {
    let that = this;
    let table = document.createElement("table");
    table.style.borderCollapse = "collapse";
    table.style.width = "100%";
    table.style.border = "1px solid #ddd";
    table.style.marginTop = "20px";
    table.style.fontFamily = "Arial, sans-serif";
    table.style.fontSize = "14px";

    for (let key in jsonObj) {
      if (jsonObj.hasOwnProperty(key)) {
        let tr = document.createElement("tr");
        let capitalizedKey = key.replace(/([A-Z])/g, " $1");
        capitalizedKey =capitalizedKey.charAt(0).toUpperCase() + capitalizedKey.slice(1);
        let tdKey = document.createElement("td");
        tdKey.style.border = "1px solid #ddd";
        tdKey.style.padding = "8px";
        tdKey.style.textAlign = "left";
        tdKey.style.backgroundColor = "#f2f2f2";
        tdKey.style.width = "30%";
        tdKey.textContent = capitalizedKey;
        let tdValue = document.createElement("td");
        tdValue.style.border = "1px solid #ddd";
        tdValue.style.padding = "8px";
        tdValue.style.textAlign = "left";
        tdValue.style.width = "70%";
        if(jsonObj[key] === null || jsonObj[key] === ""){
          tdValue.textContent = "Not Found";
          tr.appendChild(tdKey);
          tr.appendChild(tdValue);
          table.appendChild(tr);
          continue;
        }
        if (typeof jsonObj[key] === "object") {
          if (Array.isArray(jsonObj[key])) {
            let ul = document.createElement("ul");
            ul.style.padding = "0";
            ul.style.margin = "0";
            ul.style.listStyleType = "none";
            jsonObj[key].forEach((item) => {
              let li = document.createElement("li");
              li.style.marginBottom = "5px";
              if (typeof item === "object") {
                that.createNestedTable(item, li);
              } else {
                li.textContent = item;
              }
              ul.appendChild(li);
            });
            tdValue.appendChild(ul);
          } else {
            that.createNestedTable(jsonObj[key], tdValue);
          }
        } else {
          tdValue.textContent = jsonObj[key];
        }

        tr.appendChild(tdKey);
        tr.appendChild(tdValue);
        table.appendChild(tr);
      }
    }
    parentElement.appendChild(table);
  }

  refreshDomainEmail() {
    let that = this;
    document
      .getElementById("btn_refresh_email_domain")
      .addEventListener("click", function () {
        Utils.loaderToast(Constants.ELEMENT_STATE.show);
        let body = {};
        Utils.requestHandler(
          "/scf/api/application/" +
            that.scf_application_id +
            "/email-fraud-check",
          Constants.REQUEST.post,
          body
        )
          .then(function (response) {
            if (response.message === "OK") {
              let data = response.data.result[0];
              let container = document.getElementById("email_domain_container"); // Assuming 'container' is a div where you want to append the table
              that.createNestedTable(data, container);

              // const dataEmailDomainMustache = that.populateEmailData(response)
              // const templateDomainEmail = document.getElementById('templateDomainEmail').innerHTML;
              // const rendered = Mustache.render(templateDomainEmail, dataEmailDomainMustache);
              // document.getElementById('targetDomainEmail').innerHTML = rendered;
              Utils.showToast(
                "Fetched",
                "Domain & Email fetched successfully",
                Constants.TOAST.success
              );
            } else {
              Utils.showToast(
                response.message,
                Utils.formatErrorMessage(response.errors),
                Constants.TOAST.fail
              );
            }
          })
          .then(function () {
            Utils.loaderToast(Constants.ELEMENT_STATE.hide);
          });
      });
  }
}

class PanelAnalysis {
  constructor() {
    this.scf_application_id = document.getElementById("scf_application_id");
    Utils.navPanelHandler(".raw-panel-link", ".raw-panel");
    new SubPanelAnalysisBanking();
    this.uploadBalanceSheet();
    this.renderBalanceSheet();
    new ScfAppAnalysisCrimeCheck();
    new ScfAppAnalysisBanking();
    this.elem_document_details = document.getElementById(
      "div_document_details_balance_sheet"
    );
    this.elem_password_modal = new bootstrap.Modal(document.getElementById('modal_doc_pass'), {});
    this.elem_password = document.getElementById('doc_password_financials');
    this.elem_btn_password = document.getElementById('btn-update-password-financials');
    this.fetchFinancialTrendline();
    this.bureauSubPanelHandler();
    this.renderUploadedBureauReport();
    this.downloadCamReport()
  }

  renderBalanceSheet() {
    let that = this;
    let data = new FormData();
    data.set("file_code[]", "balance_sheet");
    Utils.requestHandler(
      "/scf/api/application/" + that.scf_application_id.value + "/documents",
      Constants.REQUEST.get,
      data
    ).then(function (response) {
      that.renderDocument(response.data);
    });
  }

  bureauSubPanelHandler(){
      let that = this;
      document.getElementById("upload_bureau_report")
    .addEventListener("click", function () {
      let elem_file = document.getElementById("upload_bureau_file");
        let file = elem_file.files[0];
        if (!file) {
          Utils.showToast(
            "Not Found",
            "File is mandatory",
            Constants.TOAST.fail
          );
          return;
        }
        Utils.loaderToast(Constants.ELEMENT_STATE.show);
        let data = new FormData();
        data.set("file_data", file);
        data.set("file_name", file.name);
        data.set("file_password", null);
        data.set("file_code", "bureau_document");
        Utils.requestHandler(
          "/scf/api/application/" +
            that.scf_application_id.value +
            "/document/upload/" +
            data.get("file_code"),
          Constants.REQUEST.post,
          data
        )
          .then(function (response) {
            if (response.message === "OK") {
              Utils.showToast(
                response.message,
                "Document uploaded",
                Constants.TOAST.success
              );
              setTimeout(function () {
                that.renderUploadedBureauReport();
              }, 5000);
            } else {
              Utils.showToast(
                response.message,
                Utils.formatErrorMessage(response.errors),
                Constants.TOAST.fail
              );
            }
          })
          .then(function () {
            Utils.loaderToast(Constants.ELEMENT_STATE.hide);
          });
      });
  }

  truncateText(text, wordLimit, charLimit) {
    let firstWords = text.slice(0, wordLimit);
    let lastChars = text.slice(-charLimit); 
    if (firstWords.length + lastChars.length < text.length) {
      return `${firstWords}...${lastChars}`;
    }
    return text;
  }

  renderUploadedBureauReport() {
    let that = this;
    Utils.requestHandler(
      "/scf/api/application/" + that.scf_application_id.value + "/bureau-data",
      Constants.REQUEST.get
    )
    .then(response => {
      let bureauSubTabs = document.getElementById('bureau-sub-tabs');
      document.getElementById('bureaudoc').srcdoc = '';
      document.getElementById('waitMessage').style.display = 'none';
      bureauSubTabs.textContent = '';
      bureauSubTabs.innerHTML = ''; 
      if (response.data == null) {
        document.getElementById('btn_bureau_delete').classList.add('no-display');
      } else {
        document.getElementById('btn_bureau_delete').classList.remove('no-display');
      }
      response.data.forEach((item, index) => {
        let subTabContainer = document.createElement('div');
        subTabContainer.className = 'sub-tab-container';
  
        let subTabButton = document.createElement('button');
        subTabButton.className = 'btn btn-secondary sub-tab';
        subTabButton.textContent = that.truncateText(item.name, 10, 10);
        subTabButton.title = item.name;
        subTabButton.addEventListener('click', () => {
          document.querySelectorAll('.sub-tab').forEach(tab => tab.classList.remove('active'));
          subTabButton.classList.add('active');
          document.getElementById('bureaudoc').srcdoc = item.document;
          document.getElementById('bureau_file_id').value = item.document_id; 
          document.getElementById('btn_bureau_delete').setAttribute('data-id', item.document_id);
        });
        if (index === 0) {
          subTabButton.classList.add('active');
          document.getElementById('bureaudoc').srcdoc = item.document;
          document.getElementById('bureau_file_id').value = item.document_id;
        }
        subTabContainer.appendChild(subTabButton);
        bureauSubTabs.appendChild(subTabContainer);
      });
    })
    .finally(() => {
      that.deleteBureauFile();
    });
  }
  
  downloadCamReport(){
    let that = this;
    document.getElementById("cam_report_download").addEventListener("click", function () {
      Utils.requestHandlerDocument(
          "/scf/api/application/" + that.scf_application_id.value + "/download_cam_report",
          Constants.REQUEST.get,
          {},
          { responseType: 'blob' }  // Set response type to 'blob'
      ).then(function (response) {
          if (response && response instanceof Blob && response.type=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
              const url = window.URL.createObjectURL(response);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'cam_report_' + that.scf_application_id.value + '.xlsx'; // Set the file name
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
          } else {
              Utils.showToast(
                  "Error",
                  "Error found while generating CAM Report",
                  Constants.TOAST.fail
              );
          }
      }).catch(function (error) {
          Utils.showToast(
              "Error",
              "An error occurred while generating the report.",
              Constants.TOAST.fail
          );
          console.error("Error during GST file generation:", error);
      });
    });
  }

  deleteBureauFile() {
    let that = this;
    let deleteButton = document.getElementById('btn_bureau_delete');
    let newDeleteButton = deleteButton.cloneNode(true);
    deleteButton.parentNode.replaceChild(newDeleteButton, deleteButton);
    deleteButton = newDeleteButton;
    
    deleteButton.addEventListener('click', function() {
      let confirmed = window.confirm("Are you sure you want to delete this document?");
      if (confirmed) {
        let doc_id = document.getElementById('bureau_file_id').value;
        Utils.requestHandler('/scf/api/application/'+that.scf_application_id.value+'/document/destroy/'+ doc_id,
          Constants.REQUEST.get)
          .then(function(response){
            if(response.message === 'OK'){
              document.getElementById('waitMessage').style.display = 'block';
              Utils.showToast(response.message, 'Deleted', Constants.TOAST.success);
              setTimeout(function () {
                  that.renderUploadedBureauReport();
              }, 5000);
            } else {
              Utils.showToast(response.message, 'Not Found', Constants.TOAST.fail);
            }
        });
      }
    });
  }
  

  renderUploadedDocuments() {
    let that = this;
    document
      .getElementById("nav-documents")
      .addEventListener("click", function () {
        Utils.requestHandler(
          "/scf/api/application/" +
            that.scf_application_id.value +
            "/documents",
          Constants.REQUEST.get,
          {}
        ).then(function (response) {
          if (response.message === "OK") {
            that.renderDocument(response.data);
          } else {
            Utils.showToast(
              response.message,
              Utils.formatErrorMessage(response.errors),
              Constants.TOAST.fail
            );
          }
        });
      });
  }

  transformDocumentData(ref, last_doc) {
    let drop_ship = {};
    drop_ship.doc_title = ref.set_value.title;
    drop_ship.doc_subtitle =
      "Last updated " + Utils.formatTimeRelative(last_doc.updated_at);
    drop_ship.dtype = ref.set_key;
    drop_ship.ext = "";
    if (ref.set_value.ext) {
      drop_ship.ext = ref.set_value.ext.map(function (x) {
        return x.toUpperCase();
      });
    }
    let ext = ref.set_value.ext[0];
    if (ext && ["jpeg", "jpg", "png"].includes(ext.toLowerCase())) {
      drop_ship.logo = "bi-file-earmark-image";
    } else if (ext && ["xls", "xlsx"].includes(ext.toLowerCase())) {
      drop_ship.logo = "bi-filetype-xls";
    } else if (ext && ["pdf"].includes(ext.toLowerCase())) {
      drop_ship.logo = "bi-file-earmark-pdf";
    } else {
      drop_ship.logo = "bi-file-earmark-text";
    }
    return drop_ship;
  }

  transformPreviewData(preview_data) {
    preview_data.formatted_ts = Utils.formatTime(preview_data.created_at);
    preview_data.formatted_ts_ago = Utils.formatTimeRelative(
      preview_data.created_at
    );
    preview_data.name = preview_data.id;
    return preview_data;
  }

  passwordHandler(elem){
    if(elem.getAttribute('data-password') === 'null'){
      this.elem_password.value = '';
    }else{
      this.elem_password.value = elem.getAttribute('data-password');
    }
    this.elem_btn_password.setAttribute('data-id',  elem.getAttribute('data-id'));
    this.elem_password_modal.show();
  }

  transformDocumentData(ref, last_doc){
    let drop_ship = {}
    drop_ship.doc_title = ref.set_value.title;
    drop_ship.doc_subtitle = "Last updated " + Utils.formatTimeRelative(last_doc.updated_at);
    drop_ship.dtype = ref.set_key;
    drop_ship.ext = "";
    if(ref.set_value.ext){
        drop_ship.ext = ref.set_value.ext.map(function(x) {return x.toUpperCase()});
    }
    let ext = ref.set_value.ext[0];
    if(ext && ['jpeg', 'jpg', 'png'].includes(ext.toLowerCase())) {
        drop_ship.logo = "bi-file-earmark-image";
    }else if(ext && ['xls', 'xlsx'].includes(ext.toLowerCase())) {
        drop_ship.logo = "bi-filetype-xls";
    }else if(ext && ['pdf'].includes(ext.toLowerCase())){
        drop_ship.logo = "bi-file-earmark-pdf";
    }else{
        drop_ship.logo = "bi-file-earmark-text";
    }
    return drop_ship;
  }

  transformPreviewData(preview_data){
    preview_data.formatted_ts = Utils.formatTime(preview_data.created_at);
    preview_data.formatted_ts_ago = Utils.formatTimeRelative(preview_data.created_at);
    preview_data.name = preview_data.id;
    return preview_data;
  }

  renderDocument(data){
    if(data.documents.length < 1){
        return;
    }
    let that = this;
    let tmpl = document.getElementById('doc-tmpl');
    let p_tmpl = document.getElementById('doc-preview-tmpl');
    this.elem_document_details.innerHTML = '';
    data.documents = data.documents.sort(function (row){
        return row.dtype;
    })
    let grped_data = Utils.groupBy(data.documents, 'file_code');
    for(let dtype in grped_data){
        let d = that.transformDocumentData(data.ref[dtype], grped_data[dtype][0]);
        let rendered = Mustache.render(tmpl.innerHTML, d);
        that.elem_document_details.append($.parseHTML(rendered)[1]);
        let prev = document.getElementById('prev-'+dtype);
        let url =  "/scf/api/application/" + that.scf_application_id.value + "/document/upload/" + dtype;
        let action_element = document.getElementById('btn_upload_'+dtype);
        DocumentUtils.bindInputElement(action_element);
        DocumentUtils.uploadFiles(action_element, url);
        that.showUploadPreview(action_element);
        grped_data[dtype].forEach(function (doc_data){
            let d1 = that.transformPreviewData(doc_data);
            let rend = Mustache.render(p_tmpl.innerHTML, d1);
            prev.append($.parseHTML(rend)[1]);
        })
    }
    document.querySelectorAll('.btn-doc-password').forEach(function (elem){
        elem.addEventListener('click', function (){
            that.passwordHandler(elem);
        })
    })
    that.updatePasswordHandler();
    that.deleteDocument();
    let elements = document.querySelectorAll('#delete-button-document');
    if(ScfPermission.isScfLender()){
        elements.forEach(function(element){
          element.classList.add("no-display");
        });
    }
  }

  deleteDocument() {
    let that = this;
    let elements = document.querySelectorAll('#delete-button-document');
    elements.forEach(function(element) {
        element.addEventListener('click', function (event) {
            let id = event.target.getAttribute("data-id");
            let confirmed=window.confirm("Are you sure you want to delete this document?")
            if(confirmed){
                Utils.requestHandler('/scf/api/application/'+that.scf_application_id.value+'/document/destroy/'+id,
                Constants.REQUEST.get)
                .then(function(response){
                    if(response.message==='OK'){
                        element.disabled = true;
                        Utils.showToast(response.message, 'Deleted', Constants.TOAST.success);
                    }
                    else{
                        Utils.showToast(response.message, 'Not Found', Constants.TOAST.fail);
                    }
                })
            }
        });
    });
  }

  updatePasswordHandler(){
    let that = this;
    this.elem_btn_password.addEventListener('click', function (){
        Utils.requestHandler('/core/api/document/'+ that.elem_btn_password.getAttribute('data-id'), Constants.REQUEST.post, {document_id: that.elem_btn_password.getAttribute('data-id'), file_password: that.elem_password.value})
            .then(function (response){
                if(response.message === 'OK'){
                    document.getElementById('btn_password_'+response.data.id).setAttribute('data-password', response.data.decrypted_digest);
                    that.elem_password_modal.hide();
                    Utils.showToast(response.message, 'Password updated', Constants.TOAST.success);
                }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
    })
  }

  showUploadPreview(action_element){
    let that = this;
    let p_tmpl = document.getElementById('doc-preview-tmpl');
    action_element.addEventListener('ev_document_uploaded', function (){
        let response = JSON.parse(action_element.getAttribute('data-upload-response'));
        let prev = document.getElementById('prev-'+response.file_code);
        let d1 = that.transformPreviewData(response);
        let rend = Mustache.render(p_tmpl.innerHTML, d1);
        prev.prepend($.parseHTML(rend)[1]);
        let pass_elem = document.getElementById('btn_password_'+response.id);
        pass_elem.addEventListener('click', function (){
            that.passwordHandler(pass_elem);
        })
    })
  }
  
  uploadBalanceSheet() {
    let that = this;
    document
      .getElementById("upload_balance_sheet")
      .addEventListener("click", function () {
        let elem_file = document.getElementById("balance_sheet_file");
        let file = elem_file.files[0];
        if (!file) {
          Utils.showToast(
            "Not Found",
            "File is mandatory",
            Constants.TOAST.fail
          );
          return;
        }
        Utils.loaderToast(Constants.ELEMENT_STATE.show);
        let data = new FormData();
        data.set("file_data", file);
        data.set("file_name", file.name);
        data.set("file_password", null);
        data.set("file_code", "balance_sheet");
        Utils.requestHandler(
          "/scf/api/application/" +
            that.scf_application_id.value +
            "/document/upload/" +
            data.get("file_code"),
          Constants.REQUEST.post,
          data
        )
          .then(function (response) {
            if (response.message === "OK") {
              Utils.showToast(
                response.message,
                "Document uploaded",
                Constants.TOAST.success
              );
              setTimeout(function () {
                that.renderBalanceSheet();
              }, 5000);
            } else {
              Utils.showToast(
                response.message,
                Utils.formatErrorMessage(response.errors),
                Constants.TOAST.fail
              );
            }
          })
          .then(function () {
            Utils.loaderToast(Constants.ELEMENT_STATE.hide);
          });
      });
  }

  fetchFinancialTrendline() {
    let that = this;
    let body = {
      url: Constants.SERVICE_BASE_BACKOFFICE + "/v2/financial-trendline",
      req_type: "post",
    };
    Utils.requestHandler(
      "/scf/api/application/" +
        that.scf_application_id.value +
        "/financial-trendline",
      Constants.REQUEST.post,
      body
    ).then(function (response) {
      if (response.data && response.data.data && response.data.data.data) {
        let data = response.data.data.data;
        if (response.message === "OK") {
          if (data.trim() === "No files Uploaded for financial trendline") {
            document.getElementById("financial-trendline").innerHTML = '';
          } 
          else{
            document.getElementById("financial-trendline").innerHTML = data;
          }      
        }
      }
    });
  }
}

class SubPanelAnalysisBanking {
  constructor() {
    document
      .querySelectorAll(".btn-banking-analysis-trigger")
      .forEach(function (item) {
        item.addEventListener("click", function () {
          Utils.showToast(
            "Trigger",
            "Triggered banking analysis, will be avaliable in some time",
            Constants.TOAST.success
          );
        });
      });
  }
}

class PanelCounterParties{

  constructor(){
      this.application_id = document.getElementById('scf_application_id').value;
      this.elem_pan = document.getElementById('cp_pan');
      this.elem_mobile =  document.getElementById('cp_mobile');
      this.elem_email = document.getElementById('cp_email');
      this.elem_name = document.getElementById('cp_name');
      this.elem_cp_extra_input_fields = document.getElementById('cp_extra_input_fields');
      this.elem_cp_vintage_month = document.getElementById('cp_vintage_month_select');
      this.elem_cp_payment_term = document.getElementById('counter_party_payment_terms');
      this.elem_cp_vintage_year = document.getElementById('cp_vintage_year_select');
      this.elem_cp_contact_person_name = document.getElementById('cp_contact_person_name');
      this.elem_cp_contact_person_designation =  document.getElementById('cp_contact_person_designation');
      this.elem_btn_cp_submit =  document.getElementById('btn-cp-submit');
      this.elem_btn_cp_validate =  document.getElementById('btn-cp-validate');
      this.elem_pan.addEventListener('input', function (){
          Utils.reInput(this);
      })
      this.elem_mobile.addEventListener('input', function (){
          Utils.reInput(this);
      })
      this.elem_email.addEventListener('input', function (){
          Utils.reInput(this);
      })
      this.elem_cp_vintage_month.addEventListener('input', function (){
        Utils.reInput(this);
      })
      this.elem_cp_payment_term.addEventListener('input', function (){
        Utils.reInput(this);
      })
      this.elem_cp_vintage_year.addEventListener('input', function (){
          Utils.reInput(this);
      })
      this.elem_cp_contact_person_designation.addEventListener('input', function (){
        Utils.reInput(this);
      })
      this.elem_cp_contact_person_name.addEventListener('input', function (){
        Utils.reInput(this);
      })
      this.cp_modal = new bootstrap.Modal(document.getElementById('modal_cp_add'), {});
      let that = this;
      that.fetchCounterParties();
      that.openModalAddCounterParty();
      that.createCp();
      that.getNameFromPan();
      that.approveCounterParty();
      that.fillCpVintageField();
  }

fillCpVintageField() {
  let yearSelect = document.getElementById("cp_vintage_year_select");
  let currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= currentYear - 30; i--) {
    let option = document.createElement("option");
    option.text = i;
    option.value = i;
    yearSelect.add(option);
  }
}

approveCounterParty() {
  let that = this;
  // document.getElementById('btn_cp_approve').addEventListener('click',function(){
  //     let cpIds =[];
  //     let body={
  //         cp_ids:cpIds
  //     }
  //     let selectedCheckbox=document.querySelectorAll('.approve-cp:checked');
  //     selectedCheckbox.forEach(function(checkbox){
  //         cpIds.push(checkbox.value);
  //     })
  //     Utils.requestHandler('/scf/api/counter_party/approve-cp',Constants.REQUEST.post,body)
  //     .then(function(response){
  //         if(response.message==='OK'){
  //             Utils.showToast('Approved','Counterparty approved successfully', Constants.TOAST.success);
  //         }else{
  //             Utils.showToast('Error', 'Select at least one counterparty to approve', Constants.TOAST.fail);
  //         }
  //     })
  // })
}

  openModalAddCounterParty(){
      this.cp_modal = new bootstrap.Modal(document.getElementById('modal_cp_add'), {});    
      document.getElementById('btn_cp_add_modal').addEventListener('click', () => {
          this.cp_modal.show();
          this.elem_pan.removeAttribute('readonly');
          this.elem_mobile.removeAttribute('readonly');
          this.elem_email.removeAttribute('readonly');
          this.elem_cp_extra_input_fields.classList.add('d-none');
          this.elem_btn_cp_submit.classList.add('no-display');
          this.elem_btn_cp_validate.classList.remove('no-display');
          this.elem_name.innerHTML = '-'
          this.elem_pan.value = '';
          this.elem_mobile.value = '';
          this.elem_email.value = '';
          this.elem_cp_vintage_year.value = '';
          this.elem_cp_vintage_month.value = '';
          this.elem_cp_payment_term.value = '';
          this.elem_cp_contact_person_name.value = '';
          this.elem_cp_contact_person_designation.value = '';
          this.elem_pan.classList.remove('back-grey');
          this.elem_mobile.classList.remove('back-grey');
      });
  }


  getNameFromPan(){
      let that = this;
      document.getElementById('btn-cp-validate').addEventListener('click', function (){
          let elem_btn = this; 
          Utils.buttonWithLoader(elem_btn, Constants.ELEMENT_STATE.show);
          let body = {
              pan: that.elem_pan.value,
              scf_application_id: document.getElementById("scf_application_id").value
          }
          Utils.requestHandler('/scf/api/counter_party/name', Constants.REQUEST.post, body)
              .then(function (response){
                  if(response.message =='OK'){
                      that.elem_name.setAttribute('data-name', response.data.full_name);
                      that.elem_name.innerHTML = response.data.full_name;
                      that.elem_cp_extra_input_fields.classList.remove('d-none');  
                      if(that.elem_pan.value){
                          that.elem_pan.setAttribute('readonly',true);
                          that.elem_pan.classList.add('readonly-input');  
                      }
                      Utils.buttonWithLoader(elem_btn,  Constants.ELEMENT_STATE.hide);
                      elem_btn.classList.add('no-display');
                      that.elem_btn_cp_submit.classList.remove('no-display');
                      if (response.data.applicant_present == true){
                          that.elem_mobile.value = response.data.mobile;
                          that.elem_email.value = response.data.email;
                          that.elem_mobile.setAttribute('readonly',true);
                          that.elem_email.setAttribute('readonly',true);
                          that.elem_email.classList.add('back-grey');
                          that.elem_mobile.classList.add('back-grey');
                      } 
                      Utils.showToast('Fetched', 'Pan fetched successfully', Constants.TOAST.success);
                  }else{
                      Utils.buttonWithLoader(elem_btn,  Constants.ELEMENT_STATE.hide);
                      Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                  }
              })
              .then(function (){
                 
              })
   
      })
  }


  createCp() {
    let that = this;
    this.elem_btn_cp_submit.addEventListener("click", function () {
      let elem_btn = this;
      let elem_btn_cp_validate = document.getElementById("btn-cp-validate");
      if (that.validateValues()) {
        Utils.buttonWithLoader(elem_btn, Constants.ELEMENT_STATE.show);
        let body = {
          pan: that.elem_pan.value,
          pan_name: that.elem_name.getAttribute("data-name"),
          scf_application_id: that.application_id,
          email: that.elem_email.value,
          mobile: that.elem_mobile.value,
          vintage_month: that.elem_cp_vintage_month.value,
          payment_term: that.elem_cp_payment_term.value,
          vintage_year: that.elem_cp_vintage_year.value,
          contact_person_name: that.elem_cp_contact_person_name.value,
          contact_person_designation: that.elem_cp_contact_person_designation.value
        };
        Utils.requestHandler(
          "/scf/api/counter_party",
          Constants.REQUEST.post,
          body
        )
          .then(function (response) {
            if (response.message === "OK") {
              that.elem_pan.removeAttribute("readonly");
              that.elem_name.innerHTML = "-";
              that.elem_pan.value = "";
              that.elem_mobile.value = "";
              that.elem_email.value = "";
              that.elem_cp_contact_person_designation.value = "";
              that.elem_cp_contact_person_name.value = "";
              Utils.showToast(
                "Created",
                "Counterparty created",
                Constants.TOAST.success
              );
              that.cp_modal.hide();
              return Utils.requestHandler(
                "/scf/api/application/" +
                  that.application_id +
                  "/counter-parties",
                Constants.REQUEST.get
              );
            } else {
              Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
              throw new Error(response.message);
            }
          })
          .then(function (response) {
            if (response.message === "OK") {
              that.renderCounterParties(response);
            }
          })
          .catch(function (error) {
            console.error("Error occurred:", error);
          })
          .finally(function () {
            Utils.buttonWithLoader(
              that.elem_btn_cp_submit,
              Constants.ELEMENT_STATE.hide
            );
            that.elem_btn_cp_submit.classList.add("no-display");
            elem_btn_cp_validate.classList.remove("no-display");
          });
      }
    });
  }
  
  destroyCp() { 
    let that = this;
    document.querySelectorAll('[id^="destroy_cp_"]').forEach(element => {
      element.addEventListener('click', function(event) {
        event.preventDefault();
        let confirmed=window.confirm("Are you sure you want to delete CounterParty?")
        if(confirmed){
          let cp_id = this.id.split('_').slice(2).join('_');
          Utils.requestHandler('/scf/api/counter_party/'+ cp_id + '/destroy-cp' , Constants.REQUEST.post, {})
          .then(function(response){
            if(response.message === 'OK'){
              Utils.showToast(response.message, ' CounterParty Deleted', Constants.TOAST.success);
              that.fetchCounterParties();
            }
            else{
              Utils.showToast(response.message, 'Not Found', Constants.TOAST.fail);
            }
          })        
        }
      });
  });
  }

  
  

  validateValues() {
    let pass = true;
    if (!this.elem_email.value) {
      Utils.showInputError(this.elem_email, "Email is mandatory");
      pass = false;
    } else if (!Utils.validatorEmail(this.elem_email.value)) {
      Utils.showInputError(this.elem_email, "Incorrect email");
      pass = false;
    }
    if (!this.elem_mobile.value) {
      Utils.showInputError(this.elem_mobile, "Mobile is mandatory");
      pass = false;
    } else if (!Utils.validatorMobile(this.elem_mobile.value)) {
      Utils.showInputError(this.elem_mobile, "Incorrect mobile number");
      pass = false;
    }
    if (!this.elem_pan.value) {
      Utils.showInputError(this.elem_pan, "PAN is mandatory");
      pass = false;
    } else if (!Utils.validatorPan(this.elem_pan.value)) {
      Utils.showInputError(this.elem_pan, "Incorrect PAN");
      pass = false;
    }
    if (this.elem_cp_vintage_month.value == "") {
      Utils.showInputError(
        this.elem_cp_vintage_month,
        "Vintage Month is mandatory"
      );
      pass = false;
    }
    if (this.elem_cp_payment_term.value == "") {
      Utils.showInputError(
        this.elem_cp_payment_term,
        "Payment term is manadatory"
      );
      pass = false;
    }
    if (this.elem_cp_vintage_year.value == "") {
      Utils.showInputError(
        this.elem_cp_vintage_year,
        "Vintage Year is mandatory"
      );
      pass = false;
    }
    if (this.elem_cp_contact_person_designation.value == "") {
      Utils.showInputError(
        this.elem_cp_contact_person_designation,
        "Contact person designation is Mandatory"
      );
      pass = false;
    }
    if (this.elem_cp_contact_person_name.value == "") {
      Utils.showInputError(
        this.elem_cp_contact_person_name,
        "Contact person name is mandatory"
      );
      pass = false;
    }
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    var currentMonth = currentDate.getMonth() + 1; // Month is 0-based
    if (
      this.elem_cp_vintage_year.value > currentYear ||
      (this.elem_cp_vintage_year.value >= currentYear &&
        this.elem_cp_vintage_month.value > currentMonth)
    ) {
      Utils.showInputError(
        this.elem_cp_vintage_year,
        "Date Cannot be in future"
      );
      pass = false;
    }
    return pass;
  }

  renderCounterParties(response) {
    let that = this
    if (response.message === "OK") {
      let tmpl = document.getElementById("cp-row-tmpl");
      let dx = document.getElementById("tb-cp-tbody");
      dx.innerHTML = null;
      response.data.counter_parties.forEach(function (dd) {
        that.generateScore(dd);
        dd.status = Utils.snakeToTitleCase(dd.status);
        dd.vintage = Utils.formatTimeRelative(dd.vintage);
        let rendered = Mustache.render(tmpl.innerHTML, dd);
        var parsedHTML = $.parseHTML(rendered);
        if (
          ScfPermission.isScfBorrower() ||
          ScfPermission.isScfCounterparty() || 
          ScfPermission.isScfSales()
        ) {
          var cpDetailsLink =
            parsedHTML[1].querySelector("#cp_details_link");
          cpDetailsLink.setAttribute("href", "javascript:void(0)");
          dx.append(parsedHTML[1]);
        } else {
          dx.append(parsedHTML[1]);
        }
      });
      if (ScfPermission.isScfBorrower() || ScfPermission.isScfCounterparty() || ScfPermission.isScfLender()) {
        document.querySelectorAll('[id^="destroy_cp_"]').forEach(element => {
          element.classList.add("no-display");
        });
      }  
    } else {
      let tmpl = document.getElementById("cp-row-blank-tmpl");
      let dx = document.getElementById("tb-cp-tbody");
      dx.innerHTML = null;
      let rendered = Mustache.render(tmpl.innerHTML);
      dx.innerHTML = rendered; 
    }
    that.destroyCp();
  }

  fetchCounterParties() {
    let that = this;
    Utils.requestHandler(
      "/scf/api/application/" + that.application_id + "/counter-parties",
      Constants.REQUEST.get
    )
    .then(function (response) {
      that.renderCounterParties(response);
    })
  }

  generateScore(row) {
    row.score = row.business_applicant.name.charCodeAt(2);
    if (row.score < 71) {
      row.badge_score = "back-red";
    } else if (row.score >= 71 && row.score < 95) {
      row.badge_score = "back-yellow";
    } else {
      row.badge_score = "back-green";
    }
  }
}

class PanelPd {
  constructor() {
    this.scf_application_id = document.getElementById("scf_application_id").value;
    this.pass = true;
    this.getQuestions();
    this.submitCPVQuestions();
    this.submitPDQuestions();
    this.generatePdQuestionsPdf();
    this.toggleCPVSections();
  }

  getValues() {
    let data = {};
    document.querySelectorAll(".questions").forEach((question) => {
      let id = question.getAttribute("data-label");
      data[id] = question.value;
    });
    return data;
  }

  clearForm() {
    document.querySelectorAll(".questions").forEach((question) => {
      question.value = "";
    });
  }

  validatePDValues(section) {
    let that = this;
    that.pass = true;
    document.querySelectorAll(`.questions.${section}`).forEach((question) => {
      let mandatory = question.getAttribute("data-mandatory");
      let label = question.getAttribute("data-label");
      let label_title =  Utils.snakeToTitleCase(label);
      Utils.reInput(question);
      function toggleErrorMessage() {
        let value = question.value;
        if (mandatory === "true" && !value) {
          Utils.showInputError(question, label_title + " is mandatory");
          that.pass = false;
        } else {
          Utils.hideInputError(question);
        }
      }
      toggleErrorMessage();
      question.addEventListener("input", toggleErrorMessage);
    });
    document
      .querySelectorAll(`.questions[data-code="photo_of_shop"]`)
      .forEach((element) => {
        if (section.includes('Section-1') || section.includes('Section-2-CPV')) {
          if (element.files.length === 0) {
            
            Utils.showToast(
              "Error",
              "Photo of the shop is mandatory",
              Constants.TOAST.fail
            );
            that.pass = false;
          }
        }
      });
      return that.pass;
  }

  getQuestions() {
    let that = this;
    document.getElementById("nav-pd").addEventListener("click", function () {
      // Existing code remains unchanged
      Utils.requestHandler(
        "/scf/api/application/" + that.scf_application_id + "/pd-questions",
        Constants.REQUEST.get,
        {}
      ).then(function (response) {
        if (response.message === "OK") {
          let all_quest = document.getElementById("all_questions");
          all_quest.innerHTML = null;
          let questions = {};
          response.data.forEach(function (question) {
            if (question.name == "pd_done_by") {
              if (document.getElementById("username-dashboard").textContent != "" || document.getElementById("username-dashboard").textContent == null) {
                question.value = '';
              } else {
                question.value = document.getElementById("username-dashboard").textContent;
              }
            }
            if (questions[question.section]) {
              questions[question.section].push(question);
            } else {
              questions[question.section] = [question];
            }
          });
          let tmpl = document.getElementById("question-tmpl");
          for (const section in questions) {
            all_quest.append(
              $.parseHTML(
                Mustache.render(
                  document.getElementById("question-header-tmpl").innerHTML,
                  { name: section.split("_").join(" ") }
                )
              )[1]
            );
            questions[section].forEach(function (question) {
              switch (question.type) {
                case "text":
                  question["text"] = true;
                  break;
                case "select":
                  question["select"] = true;
                  break;
                case "date":
                  question["date"] = true;
                  break;
                case "textbox":
                  question["textbox"] = true;
                  break;
                case "upload":
                  question["upload"] = true;
                  break;
                case "number":
                  question["number"] = true;
                  break;
              }
              let q_data = Mustache.render(tmpl.innerHTML, question);
              all_quest.append($.parseHTML(q_data)[1]);
            });
          }
          
        const sectionHeaders = document.querySelectorAll('.section-header.pd');
        sectionHeaders.forEach(header => {
          header.style.fontWeight = 'bold';
          header.style.textAlign = 'center';
          header.style.fontSize = '2.5em'; 

        });
          // document.getElementById("pd_done_by").disabled = true;
          that.hideAllSections();
          that.uploadDocuments();
          that.fetchDropDownValuesIndustry();
          that.fetchDropDownLevel2();
          // document.getElementById('borrower_name').value = document.getElementById('inp_application_id').text();
          let maxDate = new Date().toISOString().split('T')[0];
          document.getElementById('date_of_meeting').setAttribute('max', maxDate);
        } else {
          Utils.showToast(
            response.message,
            Utils.formatErrorMessage(response.errors),
            Constants.TOAST.fail
          );
        }
      });
    
      // Adding event listener for cpv click
      document.getElementById("btn-cpv").addEventListener("click", function () {
        document.getElementById("btn-cpv").classList.add("active");
        document.getElementById("btn-pd").classList.remove("active");
        document.getElementById("btn-quest-submit").classList.remove("no-display");
        that.toggleCPVSections();
        that.toggleSubmitButtons("cpv");
      });

      document.getElementById("btn-pd").addEventListener("click", function () {
        document.getElementById("btn-cpv").classList.remove("active");
        document.getElementById("btn-pd").classList.add("active");
        document.getElementById("btn-pd-quest-submit").classList.remove("no-display");
        that.togglePDSections();
        that.toggleSubmitButtons("pd");
      });
    
    });
    
  }

  hideAllSections() {
    let allQuestions = document.getElementById("all_questions").children;
    for (let i = 0; i < allQuestions.length; i++) {
      allQuestions[i].classList.add('no-display');
    }
  }

  toggleCPVSections() {
    let allQuestions = document.getElementById("all_questions").children;
    for (let i = 0; i < allQuestions.length; i++) {
      let section = allQuestions[i].className;
      if (section.includes('Section-1') || section.includes('Section-2-CPV')) {
        allQuestions[i].classList.remove('no-display');
      } else {
        allQuestions[i].classList.add('no-display');
      }
    }
  }

  togglePDSections() {
    let allQuestions = document.getElementById("all_questions").children;
    for (let i = 0; i < allQuestions.length; i++) {
      let section = allQuestions[i].className;
      if (section.includes('Section-3-PD') || section.includes('Section-4-Audit Trails')) {
        allQuestions[i].classList.remove('no-display');
      } else {
        allQuestions[i].classList.add('no-display');
      }
    }
  }

  toggleSubmitButtons(tab) {
    if (tab === "pd") {
        document.getElementById("btn-quest-submit").style.display = "none";
        document.getElementById("btn-pd-quest-submit").style.display = "block";
    } else {
        document.getElementById("btn-quest-submit").style.display = "block";
        document.getElementById("btn-pd-quest-submit").style.display = "none";
    }
}

  submitCPVQuestions() {
    let that = this;
    document
      .getElementById("btn-quest-submit")
      .addEventListener("click", function () {
        let pass1 = that.validatePDValues('Section-1');
        let pass2 = that.validatePDValues('Section-2-CPV');
        that.uploadDocuments();
        if (pass1 && pass2) {
          let values = that.getValues();
          Utils.requestHandler(
            "/scf/api/application/" + that.scf_application_id + "/pd-questions",
            Constants.REQUEST.post,
            values
          ).then(function (response) {
            if (response.message === "OK") {
              Utils.showToast(
                response.message,
                "CPV responses submitted",
                Constants.TOAST.success
              );
              // that.clearForm();
            } else {
              Utils.showToast(
                response.message,
                Utils.formatErrorMessage(response.errors),
                Constants.TOAST.fail
              );
            }
          });
        } else {
          Utils.showToast(
            "Error",
            "Please Enter Required field",
            Constants.TOAST.fail
          );
        }
      });
  }

  submitPDQuestions() {
    let that = this;
    document
      .getElementById("btn-pd-quest-submit")
      .addEventListener("click", function () {
        let pass1 = that.validatePDValues('Section-3-PD');
        let pass2 = that.validatePDValues('Section-4-Audit Trails');
        if (pass1 && pass2) {
          let values = that.getValues();
          Utils.requestHandler(
            "/scf/api/application/" + that.scf_application_id + "/pd-questions",
            Constants.REQUEST.post,
            values
          ).then(function (response) {
            if (response.message === "OK") {
              Utils.showToast(
                response.message,
                "PD responses submitted",
                Constants.TOAST.success
              );
              // that.clearForm();
            } else {
              Utils.showToast(
                response.message,
                Utils.formatErrorMessage(response.errors),
                Constants.TOAST.fail
              );
            }
          });
        } else {
          Utils.showToast(
            "Error",
            "Please Enter Required field",
            Constants.TOAST.fail
          );
        }
      });
  }

  uploadDocuments() {
    let that = this;
    document.querySelectorAll(".pd-upload").forEach((element) => {
      element.addEventListener("click", function () {
        let elem_file = element.parentElement.parentElement.firstElementChild;
        let file = elem_file.files[0];
        if (!file) {
          Utils.showToast(
            "Not Found",
            "File is mandatory",
            Constants.TOAST.fail
          );
          return;
        }
        let data = new FormData();
        let file_code = elem_file.getAttribute("data-code");
        data.set("file_data", file);
        data.set("file_name", file.name);
        data.set("file_password", null);
        // Utils.requestHandler("/scf/api/application/document/upload/" + file_code + "/" + that.scf_application_id, Constants.REQUEST.post, data)
        Utils.requestHandler(
          "/scf/api/application/" +
            that.scf_application_id +
            "/document/upload/" +
            file_code,
          Constants.REQUEST.post,
          data
        ).then(function (response) {
          if (response.message === "OK") {
            Utils.showToast(
              response.message,
              "Document uploaded",
              Constants.TOAST.success
            );
          } else {
            Utils.showToast(
              response.message,
              Utils.formatErrorMessage(response.errors),
              Constants.TOAST.fail
            );
          }
        });
      });
    });
  }

  fetchDropDownValuesIndustry() {
    let that = this;
    document
      .getElementById("business_segment")
      .addEventListener("change", function () {
        document.getElementById("industry_classification").innerHTML = "";
        document.getElementById("industry_classification_2").innerHTML = "";
        let segment = "";
        if (
          document.getElementById("business_segment").value === "Manufacturing / Trader"
        ) {
          segment = "HSN";
        } else if (
          document.getElementById("business_segment").value === "Services"
        ) {
          segment = "SAC";
        }
        let body = {
          level: 1,
          segment: segment,
        };
        Utils.requestHandler(
          "/scf/api/application/pd-questions/industry-codes",
          Constants.REQUEST.get,
          body
        ).then(function (response) {
          if (response.message === "OK") {
            var dropdown = document.getElementById("industry_classification");
            document.getElementById("industry_classification").innerHTML = "";
            var option = document.createElement("option");
            option.value = "";
            option.text = "";
            dropdown.appendChild(option);
            var values = response.data.data;
            for (var i = 0; i < values.length; i++) {
              var option = document.createElement("option");
              option.value = values[i].code;
              option.text = values[i].name;
              dropdown.appendChild(option);
            }
          } else {
            Utils.showToast(
              response.message,
              Utils.formatErrorMessage(response.errors),
              Constants.TOAST.fail
            );
          }
        });
      });
  }

  fetchDropDownLevel2() {
    let that = this;
    document
      .getElementById("industry_classification")
      .addEventListener("change", function () {
        let segment = "";
        if (
          document.getElementById("business_segment").value === "Manufacturing / Trader"
        ) {
          segment = "HSN";
        } else if (
          document.getElementById("business_segment").value === "Services"
        ) {
          segment = "SAC";
        }
        let body = {
          level: 2,
          segment: segment,
          parent_code: document.getElementById("industry_classification").value,
        };
        Utils.requestHandler(
          "/scf/api/application/pd-questions/industry-codes",
          Constants.REQUEST.get,
          body
        ).then(function (response) {
          if (response.message === "OK") {
            var dropdown = document.getElementById("industry_classification_2");
            document.getElementById("industry_classification_2").innerHTML = "";
            var option = document.createElement("option");
            option.value = "";
            option.text = "";
            dropdown.appendChild(option);
            var values = response.data.data;
            for (var i = 0; i < values.length; i++) {
              var option = document.createElement("option");
              option.value = values[i].code;
              option.text = values[i].name;
              dropdown.appendChild(option);
            }
          } else {
            Utils.showToast(
              response.message,
              Utils.formatErrorMessage(response.errors),
              Constants.TOAST.fail
            );
          }
        });
      });
  }

  generatePdQuestionsPdf() {
    let that = this;
    document
      .getElementById("btn_generate_pd_pdf")
      .addEventListener("click", function () {
        Utils.requestHandler(
          "/scf/api/application/" +
            that.scf_application_id +
            "/generate-pd-questions-pdf",
          Constants.REQUEST.get
        ).then(function (response) {
          if (response.message === "OK") {
            Utils.showToast(
              response.message,
              "PDF Generation Started.",
              Constants.TOAST.success
            );
          } else {
            Utils.showToast(
              response.message,
              Utils.formatErrorMessage(response.errors),
              Constants.TOAST.fail
            );
          }
        });
      });
  }
}

class PanelGstin {
  constructor() {
    this.application_id = document.getElementById("scf_application_id").value;
    let that = this;
    document
      .getElementById("nav-scoring-gst")
      .addEventListener("click", function () {
        that.getAllGstin();
        // that.renderTrendLines();
      });
    this.generateGstReportPdf();
    this.fetchGstFilings();
  }

  generateGstReportPdf() {
    let that = this;
    document
      .getElementById("btn_generate_gst_report_pdf")
      .addEventListener("click", function () {
        Utils.requestHandler(
          "/scf/api/application/" +
            that.application_id +
            "/generate-gst-report-pdf",
          Constants.REQUEST.get
        ).then(function (response) {
          if (response.message === "OK") {
            Utils.showToast(
              response.message,
              "Gst Report Generation Started.",
              Constants.TOAST.success
            );
            document.getElementById("btn_generate_gst_report_pdf").disabled = true;
          } else {
            Utils.showToast(
              "Error",
              "Not found Gst Filings for this applicant",
              Constants.TOAST.fail
            );
            document.getElementById('btn_fetch_gst_filings').classList.remove('no-display')
          }
        });
      });
  }

  fetchGstFilings() {
    let that = this;
    document
      .getElementById("btn_fetch_gst_filings")
      .addEventListener("click", function () {
        Utils.requestHandler(
          "/scf/api/application/" +
            that.application_id +
            "/fetch-gst-filings",
          Constants.REQUEST.get
        ).then(function (response) {
          if (response.message === "OK") {
            Utils.showToast(
              response.message,
              "Fetchings filings started",
              Constants.TOAST.success
            );
          } else {
            Utils.showToast(
              "Error",
              "Unable to fetch Filings",
              Constants.TOAST.fail
            );
          }
        });
      });
  }

  getAllGstin() {
    let that = this;
    Utils.requestHandler(
      "/scf/api/application/" + that.application_id + "/gst-all",
      Constants.REQUEST.get
    ).then(function (response) {
      if (response.message === "OK") {
        let tmpl = document.getElementById("gstin-tr-tmpl");
        let dx = document.getElementById("tb_gstins");
        let gstinNumbers = [];
        dx.innerHTML = null;
        response.data.gstins.forEach(function (dd) {
          dd.formatted_registration_date = Utils.formatDate(
            dd.registration_date
          );
          let rendered = Mustache.render(tmpl.innerHTML, dd);
          dx.append($.parseHTML(rendered)[1]);
          if (dd.filings_fetched == true){
            document.getElementById('fetch_gst_filings_' + dd.gstin).classList.add('no-display');
           } 
        });
        that.fetchGstFilingsLink();
        that.generateGstReportLink();
        that.generateGSTFile();
        if (response.data.documents) {
          let gstServiceUrls = {};
          response.data.documents.forEach(function (doc) {
            if (!gstServiceUrls[doc.business_gst_number]) {
              gstServiceUrls[doc.business_gst_number] = doc.service_url;
            }
          });

          let anchors = document.querySelectorAll(
            'a[id$="_gst_pdf_report_link"]'
          );
          anchors.forEach(function (anchor) {
            let idPrefix = anchor.id.split("_gst_pdf_report_link")[0];
            if (gstServiceUrls[idPrefix]) {
              anchor.href = gstServiceUrls[idPrefix];
            }
          });
        }
        else{
          let anchors = document.querySelectorAll(
            'a[id$="_gst_pdf_report_link"]'
          );
          anchors.forEach(function (anchor) {
            anchor.classList.add("no-display");
          });
        }
        Utils.showToast("Fetched", "GSTINs fetched", Constants.TOAST.success);
      }
    });
  }

  fetchGstFilingsLink(){
    let links = document.querySelectorAll('.fetch_gst_filings_link');
    let that = this;
    links.forEach(link => {
      link.addEventListener('click', function(event) {
        event.preventDefault(); 
        let gstin = this.getAttribute('data-gstin');
        Utils.requestHandler('/scf/api/application/'+ that.application_id + '/fetch-gst-filings/' + gstin , Constants.REQUEST.post, {})
        .then(function(response){
          if(response.message === 'OK'){
            Utils.showToast(response.message, 'Fetching Filings started', Constants.TOAST.success);
          }
          else{
            Utils.showToast(
              response.message,
              Utils.formatErrorMessage(response.errors),
              Constants.TOAST.fail
            );
          }
        })  
      })  
    })
  }

  generateGstReportLink(){
    let links = document.querySelectorAll('.generate_gst_report_link');
    let that = this;
    links.forEach(link => {
      link.addEventListener('click', function(event) {
        event.preventDefault(); 
        let gstin = this.getAttribute('data-gstin');
        Utils.requestHandler('/scf/api/application/'+ that.application_id + '/generate-gst-report-pdf/' + gstin , Constants.REQUEST.post, {})
        .then(function(response){
          if(response.message === 'OK'){
            Utils.showToast(response.message, 'Gst Report Generation Started', Constants.TOAST.success);
          }
          else{
            Utils.showToast(
              response.message,
              Utils.formatErrorMessage(response.errors),
              Constants.TOAST.fail
            );
          }
        })  
      })  
    })
  }

  renderHTML() {
    let that = this;
    let last_n_months_keys = that.jsondata.data.month_range;
    if(last_n_months_keys==undefined  || last_n_months_keys==null){
      return;
    }
    let templateObject = {
      c1: null,
    };
    let nth = "<th></th>";
    for (let i = 0; i < last_n_months_keys.length; i++) {
      nth += "<th>" + last_n_months_keys[i] + "</th>";
    }
    let htmlElem = $(nth);
    $("#th-gst-trendlines").append(htmlElem);

    let trend_lines = that.jsondata.data;
    let tbody = "";
    for (var key in trend_lines) {
      if (key == "month_range") {
        continue;
      }
      if (trend_lines.hasOwnProperty(key)) {
        var trends = trend_lines[key];
        tbody += "<tr>";
        tbody += "<td>" + key.split("--", 2)[1] + "</td>";
        for (var month_idx in last_n_months_keys) {
          let month_key = last_n_months_keys[month_idx];
          let trend = trends[month_key];
          tbody += "<td>" + trend.toFixed(2) + "</td>";
        }
        tbody += "</tr>";
      }
    }
    let tdElem = $(tbody);
    $("#tb-gst-trendlines").append(tdElem);
  }

  renderTrendLines() {
    let tmpl_th = document.getElementById("gstin-trendlines-th-tmpl");
    let tmpl = document.getElementById("gstin-trendlines-tmpl");
    let dx = document.getElementById("th-gst-trendlines");
    dx.innerHTML = null;
    let dy = document.getElementById("tb-gst-trendlines");
    dy.innerHTML = null;
    let that = this;
    let body = {
      url:
        Constants.SERVICE_BASE_BACKOFFICE + "/v2/gst/aggregated-gst-trendline",
      req_type: "post",
    };
    let jsondata = [];
    Utils.requestHandler(
      "/scf/api/application/" + that.application_id + "/gst-trendline",
      Constants.REQUEST.post,
      body
    ).then(function (response) {
      if (response.message === "OK") {
        that.jsondata = response.data.data;
        that.renderHTML();
      } else {
        Utils.showToast(
          response.message,
          Utils.formatErrorMessage(response.errors),
          Constants.TOAST.fail
        );
      }
    });
  }

  generateGSTFile() {
    let that = this;
    document.getElementById("btn_generate_gst_report_v2").addEventListener("click", function () {
        Utils.requestHandlerDocument(
            "/scf/api/application/" + that.application_id + "/gst/generate-report-v2",
            Constants.REQUEST.get,
            {},
            { responseType: 'blob' }  // Set response type to 'blob'
        ).then(function (response) {
            if (response && response instanceof Blob && response.type=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const url = window.URL.createObjectURL(response);
                const a = document.createElement('a');
                a.href = url;
                a.download = "gst_report.xlsx"; // Set the file name
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            } else {
                Utils.showToast(
                    "Error",
                    "GST json file not found",
                    Constants.TOAST.fail
                );
            }
        }).catch(function (error) {
            Utils.showToast(
                "Error",
                "An error occurred while generating the report.",
                Constants.TOAST.fail
            );
            console.error("Error during GST file generation:", error);
        });
    });
}

  
}

class PanelDecision {
  constructor() {
    this.scf_application_id =
      document.getElementById("scf_application_id").value;
    this.submitApplicationDecision();
    this.fetchApplicationDeviation();
    this.submitApplicationDeviation();
    this.clearDeviationForm();
    this.submitLoanData();
    // this.fetchbankDetails();
    this.fetchLoanDetails();
    this.fetchGatingDetails();
    this.clearLoanDetailsForm();
    let that = this;
    document
      .getElementById("nav-credit-decision")
      .addEventListener("click", function () {
        that.getApplicationDecision();
        that.initializeScorecardEventListeners();
      });
    // that.amountField = document.getElementById("amount_field");
    const applicationDecision = document.getElementById("app_decision");
    // applicationDecision.addEventListener("change", function () {
    //   that.toggleAmountField();
    // });
  }

  // toggleAmountField() {
  //   const applicationDecision = document.getElementById("app_decision");
  //   this.amountField.disabled =
  //     applicationDecision.value === "approved" ? false : true;
  //   if (this.amountField.disabled === true) {
  //     this.amountField.value = "";
  //   }
  // }

  submitApplicationDecision() {
    let that = this;
    document
      .getElementById("btn-submit-app-decision")
      .addEventListener("click", function () {
        Utils.loaderToast(Constants.ELEMENT_STATE.show);

        // const applicationScore = document.getElementById("app_score");
        const applicationDecision = document.getElementById("app_decision");
        const applicationRemarks = document.getElementById("app_remarks");

        const borrowerScorecard = {
          borrower_rating: document.getElementById('borrower_scorecard_borrower_rating').value,
          management_rating: document.getElementById('borrower_scorecard_management_rating').value,
          financials_rating: document.getElementById('borrower_scorecard_financials_rating').value,
          banking_rating: document.getElementById('borrower_scorecard_banking_rating').value,
          bureau_p_rating: document.getElementById('borrower_scorecard_bureau_p_rating').value,
          bureau_c_rating: document.getElementById('borrower_scorecard_bureau_c_rating').value,
          gst_rating: document.getElementById('borrower_scorecard_gst_rating').value,
          total_rating: document.getElementById('borrower_scorecard_total_rating').value
        };

        let body = {
          // score: applicationScore.value,
          decision: applicationDecision.value,
          remarks: applicationRemarks.value,
          borrower_scorecard: borrowerScorecard

        };

        // let scoreValue = parseInt(applicationScore.value, 10);
        // if (isNaN(scoreValue) || scoreValue < 0 || scoreValue > 1000) {
        //   Utils.showToast(
        //     "Error",
        //     "Please enter a valid score between 0 and 1000.",
        //     Constants.TOAST.fail
        //   );
        //   Utils.loaderToast(Constants.ELEMENT_STATE.hide);
        //   return;
        // }
        // if (applicationDecision.value === "approved") {
        //   const amountValue = that.amountField.value;
        //   if (!amountValue) {
        //     Utils.showToast(
        //       "Error",
        //       "Please enter the amount for the approved decision.",
        //       Constants.TOAST.fail
        //     );
        //     Utils.loaderToast(Constants.ELEMENT_STATE.hide);
        //     return;
        //   }

        //   body.approved_amount = amountValue;
        // }
        Utils.requestHandler(
          "/scf/api/application/" +
            that.scf_application_id +
            "/credit-decision",
          Constants.REQUEST.post,
          body
        )
          .then(function (response) {
            if (response.message === "OK") {
              Utils.showToast(
                "Success",
                "Decision submitted successfully.",
                Constants.TOAST.success
              );
            } else {
              Utils.showToast(
                response.message,
                Utils.formatErrorMessage(response.errors),
                Constants.TOAST.fail
              );
            }
          })
          .then(function () {
            Utils.loaderToast(Constants.ELEMENT_STATE.hide);
          });
      });
  }

  validateValues() {
    let that = this;
    that.pass = true;
    document.querySelectorAll(".loan-questions").forEach((question) => {
      let label = question.getAttribute("data-label");
      let field_id=question.getAttribute("id");
      Utils.reInput(question);
      function toggleErrorMessage() {
        let value = question.value;
        if (!value) {
          Utils.showInputError(question, label + " is mandatory");
          that.pass = false;
        } else {
          Utils.hideInputError(question);
        }

        if (field_id === "app_sanction_amt_input" || field_id === "app_current_outstanding_input" || field_id === "app_emi_interest_input") {
          if (!/^\d+(\.\d{1,2})?$/.test(value)) {
            Utils.showInputError(question, label + " should be a valid number with up to 2 decimal places");
            that.pass = false;
          } else if (parseFloat(value) > 1000000000) {
            Utils.showInputError(question, label + " should not exceed 1,00,00,00,000");
            that.pass = false;
          } else {
            Utils.hideInputError(question);
          }
        }  

        if (field_id === "app_tenure_input") {
            if (!/^\d+$/.test(value)) {
                Utils.showInputError(question, label + " should be a valid whole number");
                that.pass = false;
            } else {
                Utils.hideInputError(question);
            }
        }
      }
      toggleErrorMessage();
      question.addEventListener("input", toggleErrorMessage);
    });
    return that.pass;
  }

  submitLoanData(){
    let that = this;
    document.getElementById("btn-submit-app-loan-data")
      .addEventListener("click", function () {
        if(!that.validateValues()){
          return;
        }
        const nameOfBanker = document.getElementById("app_name_of_banker_input");
        const nameOfFacility = document.getElementById("app_name_of_facility_input");
        const borrowingRelationship = document.getElementById("app_borrowing_relationship_input");
        const typeOfLoan = document.getElementById("app_type_of_loan_input");
        const sanctionDate = document.getElementById("app_sanction_date_input");
        const sanctionAmt = document.getElementById("app_sanction_amt_input");
        const currentOutstanding = document.getElementById("app_current_outstanding_input");
        const emiInterest = document.getElementById("app_emi_interest_input");
        const tenure = document.getElementById("app_tenure_input");
        const emiDate = document.getElementById("app_emi_date_input");
        const emiTracked = document.getElementById("app_emi_tracked_input");
        const loanId = document.getElementById("loan_data_id");
        let body = {
          "params": {
            nature_of_facility: nameOfFacility.value,
            borrowing_relationship: borrowingRelationship.value,
            bank_name: nameOfBanker.value,
            type_of_loan: typeOfLoan.value,
            sanction_date: sanctionDate.value,
            sanction_amount: sanctionAmt.value,
            current_outstanding: currentOutstanding.value,
            emi_interest: emiInterest.value,
            loan_tenure: tenure.value,
            emi_date: emiDate.value,
            emi_tracked_and_bank: emiTracked.value,
            loan_data_id: loanId.value,
          },
          "url": Constants.SERVICE_BASE_BACKOFFICE + "/v2/borrower-loan",
          "req_type": "post"
        }
        Utils.requestHandler(
          "/scf/api/application/" + that.scf_application_id + "/borrower-loan",
          Constants.REQUEST.post,
          body
        ) .then(function (response) {
          if (response.message === "OK") {
            Utils.showToast(
              "Success",
              "Deviation submitted successfully.",
              Constants.TOAST.success
            );
            nameOfBanker.value = "";
            nameOfFacility.value = "";
            borrowingRelationship.value = "";
            typeOfLoan.value = "";
            sanctionDate.value = "";
            sanctionAmt.value = "";
            currentOutstanding.value = "";
            emiInterest.value = "";
            tenure.value = "";
            emiDate.value = "";
            emiTracked.value = "";
            loanId.value = "";
            document.getElementById("btn-app-loan-data-modal-close").click();
            setTimeout(function () {
              that.fetchLoanDetails();
            }, 2000);
          } else {
            Utils.showToast(
              response.message,
              Utils.formatErrorMessage(response.errors.errors.error),
              Constants.TOAST.fail
            );
          }
        })
        .then(function () {
          Utils.loaderToast(Constants.ELEMENT_STATE.hide);
        });

      })
  }

  fetchbankDetails() {
    let that = this;
    let body = {
      "params": {},
      "url": Constants.SERVICE_BASE_BACKOFFICE + "/v2/fetch-banks",
      "req_type": "get"
    }
    Utils.requestHandler(
      "/scf/api/application/" + that.scf_application_id + "/fetch-banks",
      Constants.REQUEST.get,
      body
    ).then(function (response) {
      if (response.message === "OK") {
        let elem_bankers = document.getElementById('app_name_of_banker_input');
        elem_bankers.textContent = "";
        let option = document.createElement('option');
        option.value = "";
        option.text = "Select Bank";
        elem_bankers.add(option);
        response.data.forEach(function (dd){
            let option = document.createElement( 'option' );
            option.value = dd.id;
            option.text = dd.name;
            elem_bankers.add(option);
        })
      } 
    })
  }

  formatDate(date) {
    const d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1; // Months are zero-based
    const year = d.getFullYear();
  
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
  
    return `${day}-${month}-${year}`;
  }

  renderAppLoanDetailsTable(response) {
    let that = this;
    if (response.message === "OK") {
      let tmpl = document.getElementById("app-loan-data-row-tmpl");
      let dx = document.getElementById("tb_loan_data");
      dx.innerHTML = null;
      response.data.forEach(function (dd) {
        dd.emi_date = that.formatDate(dd.emi_date);
        dd.sanction_date = that.formatDate(dd.sanction_date);
        let rendered = Mustache.render(tmpl.innerHTML, dd);
        dx.append($.parseHTML(rendered)[1]);
      });
      that.destroyLoanDetails();
      that.editLoanDetails();
    }
  }

  fetchLoanDetails(){
    let that = this;
    let body = {
      "params": {},
      "url": Constants.SERVICE_BASE_BACKOFFICE + "/v2/get-borrower-loan",
      "req_type": "get"
    }
    Utils.requestHandler(
      "/scf/api/application/" + that.scf_application_id + "/borrower-loan",
      Constants.REQUEST.post,
      body
    ).then(function (response) {
      if (response.message === "OK") {
        that.renderAppLoanDetailsTable(response);
      }
      else{
        let tmpl = document.getElementById("app-loan-data-row-blank-tmpl");
        let dx = document.getElementById("tb_loan_data");
        dx.innerHTML = null;
        let rendered = Mustache.render(tmpl.innerHTML);
        dx.innerHTML = rendered;
      }
    });
  }

  renderGatingDetailsTable(response) {
    let that = this;
    if (response.message === "OK") {
      let tmpl = document.getElementById("app-gating-data-row-tmpl");
        let dx = document.getElementById("tb_gating_criteria");
        dx.innerHTML = null;
        response.data.forEach(function (dd) {
            if (dd.emi_date) {
                dd.emi_date = that.formatDate(dd.emi_date);
            }
            if (dd.sanction_date) {
                dd.sanction_date = that.formatDate(dd.sanction_date);
            }
            dd.is_applicable_true = dd.is_applicable === true;
            dd.is_applicable_false = dd.is_applicable === false;
            dd.is_applicable_none = dd.is_applicable === null;

            let rendered = Mustache.render(tmpl.innerHTML, dd);
            dx.append($.parseHTML(rendered)[1]);
        });
    }
  }

  fetchGatingDetails(){
    let that = this;
    let body = {
      "params": {},
      "url": Constants.SERVICE_BASE_BACKOFFICE + "/v2/program/get-gating-details",
      "req_type": "get"
    }
    Utils.requestHandler(
      "/scf/api/application/" + that.scf_application_id + "/gating-data",
      Constants.REQUEST.post,
      body
    ).then(function (response) {
      if (response.message === "OK") {
        that.renderGatingDetailsTable(response);
        that.submitGatingData();
      }
      else{
        let tmpl = document.getElementById("app-gating-data-row-tmpl");
        let dx = document.getElementById("tb_gating_criteria");
        dx.innerHTML = null;
        let rendered = Mustache.render(tmpl.innerHTML);
        dx.innerHTML = rendered;
      }
    });
  }

  submitGatingData(){
    let that = this;
    document.getElementById('gate-keeper-submit').addEventListener('click', function(){
      const rows = document.querySelectorAll('#tb_gating_criteria tr');
      const data = [];
    
      rows.forEach(row => {
        const title = row.querySelector('td:first-child').innerText.trim();
        const applicable = row.querySelector('select').value;
        const remark = row.querySelector('input').value;
    
        if (title) {
          data.push({
            title: title,
            is_applicable: applicable,
            remark: remark,
            scf_application_id: that.scf_application_id
          });
        }
      });
      const body = {
        "params": {
          gate_keeper_data: data
        },
        "url": Constants.SERVICE_BASE_BACKOFFICE + "/v2/program/save-gating-details",
        "req_type": "post"
      };
      Utils.requestHandler(
        "/scf/api/application/" + that.scf_application_id + "/gating-data",
        Constants.REQUEST.post,
        body
      ).then(function (response) {
        if (response.message === "OK") {
          Utils.showToast(
            "Success",
            "Gate Keeper Data submitted successfully.",
            Constants.TOAST.success
          );
          setTimeout(function () {
            that.fetchApplicationDeviation();
          }, 1000);
        }
      });
    });
  }

  convertDateToDDMMYYYY(dateString) {
    const parts = dateString.split('-');
    if (parts.length !== 3) {
      return dateString;
    }
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  }

  editLoanDetails() {
    let that = this;
    document.querySelectorAll('[id^="edit_app_loan_details_"]').forEach(element => {
      element.addEventListener('click', function(event) {
        event.preventDefault();
        document.getElementById('btn_show_add_new_loan_data').click();
        let loanData = {
          loan_id: this.dataset.loan_id,
          bank_name: this.dataset.bankName,
          nature_of_facility: this.dataset.natureOfFacility,
          borrowing_relationship: this.dataset.borrowingRelationship,
          type_of_loan: this.dataset.typeOfLoan,
          sanction_date: that.convertDateToDDMMYYYY(this.dataset.sanctionDate),
          sanction_amount: this.dataset.sanctionAmount,
          current_outstanding: this.dataset.currentOutstanding,
          emi_interest: this.dataset.emiInterest,
          loan_tenure: this.dataset.loanTenure,
          emi_date: that.convertDateToDDMMYYYY(this.dataset.emiDate),
          emi_tracked_and_bank: this.dataset.emiTrackedAndBank,
          loan_id: this.id.split('_').slice(4).join('_')
        };
        document.getElementById('app_name_of_banker_input').value = loanData.bank_name;
        document.getElementById('app_name_of_facility_input').value = loanData.nature_of_facility;
        document.getElementById('app_borrowing_relationship_input').value = loanData.borrowing_relationship;
        document.getElementById('app_type_of_loan_input').value = loanData.type_of_loan;
        document.getElementById('app_sanction_date_input').value = loanData.sanction_date;
        document.getElementById('app_sanction_amt_input').value = loanData.sanction_amount;
        document.getElementById('app_current_outstanding_input').value = loanData.current_outstanding;
        document.getElementById('app_emi_interest_input').value = loanData.emi_interest;
        document.getElementById('app_tenure_input').value = loanData.loan_tenure;
        document.getElementById('app_emi_date_input').value = loanData.emi_date;
        document.getElementById('app_emi_tracked_input').value = loanData.emi_tracked_and_bank;
        document.getElementById('loan_data_id').value = loanData.loan_id;
      });
    });
  }

  destroyLoanDetails() { 
    let that = this;
    document.querySelectorAll('[id^="destroy_app_loan_details_"]').forEach(element => {
      element.addEventListener('click', function(event) {
        event.preventDefault();
        let confirmed=window.confirm("Are you sure you want to delete Loan Data?")
        if(confirmed){
          let loan_data_id = this.id.split('_').slice(4).join('_');
          let body = {
            "params": {
              loan_data_id: loan_data_id
            },
            "url": Constants.SERVICE_BASE_BACKOFFICE + "/v2/delete-borrower-loan",
            "req_type": "delete"
          }
          Utils.requestHandler(
            "/scf/api/application/" + that.scf_application_id + "/borrower-loan",
            Constants.REQUEST.post,
            body
          ).then(function(response){
            if(response.message === 'OK'){
              Utils.showToast(response.message, ' Loan Data Deleted', Constants.TOAST.success);
              setTimeout(function () {
                that.fetchLoanDetails();
              }, 2000);
            }
            else{
              Utils.showToast(response.message, 'Not Found', Constants.TOAST.fail);
            }
          })        
        }
      });
    });
  }

  clearLoanDetailsForm() {  
    document.getElementById('btn-app-loan-data-modal-close').addEventListener('click', function() {
      document.getElementById("app_name_of_banker_input").value = '';
      document.getElementById("app_justification_input").value = '';
      document.getElementById("app_name_of_facility_input").value = '';
      document.getElementById("app_borrowing_relationship_input").value = '';
      document.getElementById("app_type_of_loan_input").value = '';
      document.getElementById("app_sanction_date_input").value = '';
      document.getElementById("app_sanction_amt_input").value = '';
      document.getElementById("app_current_outstanding_input").value = '';
      document.getElementById("app_emi_interest_input").value = '';
      document.getElementById("app_tenure_input").value = '';
      document.getElementById("app_emi_date_input").value = '';
      document.getElementById("app_emi_tracked_input").value = '';
    });
  }

  submitApplicationDeviation() {
    let that = this;
    document.getElementById("btn-submit-app-deviation").addEventListener("click", function () {
      const applicationDeviation = document.getElementById("app_deviation_input");
      const applicationJustification = document.getElementById("app_justification_input");

      let body = {
        deviation: applicationDeviation.value,
        justification: applicationJustification.value,
        deviation_id: document.getElementById("app_deviation_id").value
      };

      Utils.requestHandler(
        "/scf/api/application/" +
          that.scf_application_id +
          "/app-deviations",
        Constants.REQUEST.post,
        body
      )
        .then(function (response) {
          if (response.message === "OK") {
            Utils.showToast(
              "Success",
              "Deviation submitted successfully.",
              Constants.TOAST.success
            );
            document.getElementById("app_deviation_input").value = "";
            document.getElementById("app_justification_input").value = "";
            document.getElementById("btn-app-deviation-modal-close").click();
            that.fetchApplicationDeviation();
          } else {
            Utils.showToast(
              response.message,
              Utils.formatErrorMessage(response.errors),
              Constants.TOAST.fail
            );
          }
        })
        .then(function () {
          Utils.loaderToast(Constants.ELEMENT_STATE.hide);
        });


    });
  }

  fetchApplicationDeviation() {
    let that = this;
    Utils.requestHandler(
      "/scf/api/application/" + that.scf_application_id + "/app-deviations",
      Constants.REQUEST.get
    )
    .then(function (response) {
      if (response.message === "OK") {
      that.renderAppDeviationTable(response);
      } else {
        let tmpl = document.getElementById("app-deviation-row-blank-tmpl");
        let dx = document.getElementById("verified_deviations");
        dx.innerHTML = null;
        let rendered = Mustache.render(tmpl.innerHTML);
        dx.innerHTML = rendered;
        // Utils.showToast(response.message, 'Not Found', Constants.TOAST.fail);
      }
    })
      
  }

  renderAppDeviationTable(response) {
    let that = this;
    if (response.message === "OK") {
      let tmpl = document.getElementById("app-deviation-row-tmpl");
      let dx = document.getElementById("verified_deviations");
      dx.innerHTML = null;
      response.data.deviations.forEach(function (dd) {
        let rendered = Mustache.render(tmpl.innerHTML, dd);
        dx.append($.parseHTML(rendered)[1]);
      });
      that.destroyAppDeviation();
      that.editAppDeviation();
    }
  }

  destroyAppDeviation() { 
    let that = this;
    document.querySelectorAll('[id^="destroy_app_deviation_"]').forEach(element => {
      element.addEventListener('click', function(event) {
        event.preventDefault();
        let confirmed=window.confirm("Are you sure you want to delete Deviation?")
        if(confirmed){
          let deviation_id = this.id.split('_').slice(3).join('_');
          Utils.requestHandler('/scf/api/application/'+ deviation_id + '/delete-app-deviations' , Constants.REQUEST.post, {})
          .then(function(response){
            if(response.message === 'OK'){
              Utils.showToast(response.message, ' Deviation Deleted', Constants.TOAST.success);
              that.fetchApplicationDeviation();
            }
            else{
              Utils.showToast(response.message, 'Not Found', Constants.TOAST.fail);
            }
          })        
        }
      });
  });
  }

  clearDeviationForm() {  
    document.getElementById('btn-app-deviation-modal-close').addEventListener('click', function() {
    document.getElementById('app_deviation_input').value = '';
    document.getElementById('app_justification_input').value = '';
    document.getElementById('app_deviation_id').value = '';
    });
  }

  editAppDeviation() { 
    let that = this;
    document.querySelectorAll('[id^="edit_app_deviation_"]').forEach(element => {
      element.addEventListener('click', function(event) {
        event.preventDefault();
        document.getElementById('btn_show_add_new_app_deviation').click();
        let deviation_id = this.id.split('_').slice(3).join('_');   
        document.getElementById('app_deviation_input').value = this.dataset.deviation; 
        document.getElementById('app_justification_input').value = this.dataset.justification; 
        document.getElementById('app_deviation_id').value = deviation_id;
      });
  });
  }

  getApplicationDecision() {
    let that = this;
    Utils.requestHandler(
      "/scf/api/application/" + that.scf_application_id + "/credit-decision",
      Constants.REQUEST.get
    )
      .then(function (response) {
        if (response.message === "OK" && response.data) {
 
          const borrowerScorecard = {
            borrower_rating: response.data.borrower_scorecard.borrower_rating,
            management_rating: response.data.borrower_scorecard.management_rating,
            financials_rating: response.data.borrower_scorecard.financials_rating,
            banking_rating: response.data.borrower_scorecard.banking_rating,
            bureau_p_rating: response.data.borrower_scorecard.bureau_p_rating,
            bureau_c_rating: response.data.borrower_scorecard.bureau_c_rating,
            gst_rating: response.data.borrower_scorecard.gst_rating,
            total_rating: response.data.borrower_scorecard.total_rating
          };
          // Set the values in the HTML inputs
          document.getElementById('borrower_scorecard_borrower_rating').value = borrowerScorecard.borrower_rating || '';
          document.getElementById('borrower_scorecard_management_rating').value = borrowerScorecard.management_rating || '';
          document.getElementById('borrower_scorecard_financials_rating').value = borrowerScorecard.financials_rating || '';
          document.getElementById('borrower_scorecard_banking_rating').value = borrowerScorecard.banking_rating || '';
          document.getElementById('borrower_scorecard_bureau_p_rating').value = borrowerScorecard.bureau_p_rating || '';
          document.getElementById('borrower_scorecard_bureau_c_rating').value = borrowerScorecard.bureau_c_rating || '';
          document.getElementById('borrower_scorecard_gst_rating').value = borrowerScorecard.gst_rating || '';
          document.getElementById('borrower_scorecard_total_rating').value = borrowerScorecard.total_rating || '';

          // let applicationScore = document.getElementById("app_score");
          let applicationDecision = document.getElementById("app_decision");
          let applicationRemarks = document.getElementById("app_remarks");
          // let applicationApprovedAmout =
          //   document.getElementById("amount_field");
          // applicationScore.value = response["data"]["score"];
          applicationDecision.value = response["data"]["decision"];
          applicationRemarks.value = response["data"]["remarks"];
          // applicationApprovedAmout.value = response["data"]["approved_amount"];
          Utils.showToast(
            "Fetched",
            "Decision Fetched",
            Constants.TOAST.success
          );
        } else {
          Utils.showToast(
            response.message,
            Utils.formatErrorMessage(response.errors),
            Constants.TOAST.fail
          );
        }
      })
      .then(function () {});
  }

  initializeScorecardEventListeners() {
    const elements = document.querySelectorAll('.borrower_scorecard_element');

    // Function to calculate the total
    function calculateTotal() {
        let total = 0;
        elements.forEach(element => {
            // Add the numeric value of each field to the total. Handle empty fields as 0.
            total += Number(element.value) || 0;
        });

        // Update the total field
        document.getElementById('borrower_scorecard_total_rating').value = total;
    }

    // Attach the calculateTotal function as an event listener to each input element
    elements.forEach(element => {
        element.addEventListener('input', calculateTotal);
    });
  }
}

class UdhyamAadhar {
  constructor(
    scf_application_id,
    inputValue = null,
    business_applicant_id = null,
    udyam_reg_no = null
  ) {
    this.scf_application_id =
      document.getElementById("scf_application_id").value;
    this.elem_applicant_details = document.getElementById(
      "div_applicant_details_gst"
    );
    this.elem_application_details = document.getElementById(
      "applicant-details-tmpl-gst"
    );
    Utils.navPanelHandler(".kyc-panel-link", ".kyc-panel");
    this.inputValue = inputValue;
    this.business_applicant_id = localStorage.getItem("business_applicant_id");
    this.udyam_reg_no = udyam_reg_no;
    this.fetchUdyamDetails();
    this.Gstindetails();
    this.tempUdyamSearch();
    this.mcaDetails();
  }

  fetchUdyamDetails() {
    let that = this;
    return Utils.requestHandler(
      "/scf/api/application/" + this.scf_application_id + "/udyam",
      Constants.REQUEST.get
    ).then(function(response) {
      that.renderGstDetails(response.data);
      if (response.message === "OK") {
        let container = document.getElementById("div_application_details_udyam");
  
        // Clear previous content
        container.innerHTML = '';
  
        if (!response.data.udyam_details || !response.data.udyam_details.tsp_data) {
          let notFoundMsg = document.createElement("h1");
          notFoundMsg.textContent = "Not Found";
          container.appendChild(notFoundMsg);
        }
  
        that.business_applicant_name = document.getElementById('business_applicant_name').value;
        that.business_applicant_pan = document.getElementById('business_applicant_pan').value;
        let profile_name = response.data.udyam_details ? response.data.udyam_details.name : '';
        let trade_name = document.getElementById('scf_application_trade_name').value;
  
        // Create combinations with labels and format them
        let combinations = [
          `Udyam Name: ${profile_name} Trade Name: ${trade_name}`,
          // `Business Applicant Name: ${that.business_applicant_name} Udyam Name: ${profile_name}`, // Removed second pair
          `Business Applicant Name: ${that.business_applicant_name} Trade Name: ${trade_name}`,
          `Business Applicant Name: ${that.business_applicant_name} Udyam Name: ${profile_name} Trade Name: ${trade_name}`
        ];
  
        // Display combinations with specified formatting
        combinations.forEach(combination => {
          container.innerHTML += `<div style="text-align: center; color: red; font-weight: bold;">${combination}</div><br>`;
        });
  
        // Hide error message if conditions are met
        if (profile_name === that.business_applicant_name || profile_name === trade_name) {
          document.getElementById('error_message').classList.add('no-display');
        }
  
        // Call function to create nested table based on tsp_data
        if (response.data.udyam_details && response.data.udyam_details.tsp_data) {
          that.createNestedTableUydam(response.data.udyam_details.tsp_data, container);
        }
      }
    }).catch(function(error) {
      console.error('Error fetching Udyam details:', error);
      // Handle error display or logging as needed
    });
  }
  
  
  tempUdyamSearch() {
    let inputbox = document.getElementById("udyamSearchField");
    let button = document.getElementById("syncButton");
    let that = this;
    button.addEventListener("click", function () {
      Utils.requestHandler(
        "/scf/api/application/" + that.scf_application_id + "/udyam",
        Constants.REQUEST.post,
        { udhyam_aadhaar: inputbox.value }
      ).then(function (response) {
        if (response.message === "OK") {
          Utils.showToast(
            "Success",
            "Udyam Fetched successfully.",
            Constants.TOAST.success
          );
        } else {
          Utils.showToast(
            response.message,
            Utils.formatErrorMessage(response.errors),
            Constants.TOAST.fail
          );
        }
      });
    });
  }

  Gstindetails() {
    let that = this;
    Utils.requestHandler(
      "/scf/api/application/" + this.scf_application_id,
      Constants.REQUEST.get,
      {}
    ).then(function (response) {
      if (response.message === "OK") {
        document.getElementById('eligibility_stage').value = response.data.eligibility_stage;
        that.renderGstDetails(response.data);
      } else {
        Utils.showToast(
          response.message,
          Utils.formatErrorMessage(response.errors),
          Constants.TOAST.fail
        );
      }
    });
  }
 
  mcaDetails() {
    let that = this;
    Utils.requestHandler(
      "/scf/api/application/" + this.scf_application_id,
      Constants.REQUEST.get,
      {}
    ).then(function (response) {
      if (response.message === "OK") {
        let container = document.getElementById("div_application_details_mca");
        let mcaData = response.data.mca_data;
  
        if (mcaData === null || mcaData.length === 0) {
          container.textContent = null;
          let notFoundMsg = document.createElement("h1");
          notFoundMsg.textContent = "Not Found";
          container.appendChild(notFoundMsg);
        } else {
          // Clear the container
          container.innerHTML = "";
  
          // Create a table element with styles
          let table = document.createElement("table");
          table.style.width = "100%";
          table.style.borderCollapse = "collapse";
          table.style.border = "1px solid #ddd";
          table.style.marginTop = "20px";
  
          // Recursive function to handle nested objects
          function createTableRow(key, value) {
            let row = document.createElement("tr");
  
            let keyCell = document.createElement("td");
            keyCell.textContent = key.replace(/_/g, " ").toUpperCase();
            keyCell.style.border = "1px solid #ddd";
            keyCell.style.padding = "8px";
            keyCell.style.backgroundColor = "#f2f2f2";
            keyCell.style.fontWeight = "bold";
            keyCell.style.textTransform = "uppercase";
  
            let valueCell = document.createElement("td");
            valueCell.style.border = "1px solid #ddd";
            valueCell.style.padding = "8px";
            if (typeof value === "object" && value !== null) {
              if (Array.isArray(value)) {
                let list = document.createElement("ul");
                value.forEach(item => {
                  let listItem = document.createElement("li");
                  if (typeof item === "object" && item !== null) {
                    let nestedTable = document.createElement("table");
                    nestedTable.style.width = "100%";
                    nestedTable.style.borderCollapse = "collapse";
                    for (let nestedKey in item) {
                      nestedTable.appendChild(createTableRow(nestedKey, item[nestedKey]));
                    }
                    listItem.appendChild(nestedTable);
                  } else {
                    listItem.textContent = item.toString().toUpperCase();
                  }
                  list.appendChild(listItem);
                });
                valueCell.appendChild(list);
              } else {
                let nestedTable = document.createElement("table");
                nestedTable.style.width = "100%";
                nestedTable.style.borderCollapse = "collapse";
                for (let nestedKey in value) {
                  nestedTable.appendChild(createTableRow(nestedKey, value[nestedKey]));
                }
                valueCell.appendChild(nestedTable);
              }
            } else {
              valueCell.textContent = value.toString().toUpperCase();
            }
  
            row.appendChild(keyCell);
            row.appendChild(valueCell);
            return row;
          }
  
          // Populate the table with rows
          for (let key in mcaData) {
            table.appendChild(createTableRow(key, mcaData[key]));
          }
  
          // Append the new table to the container
          container.appendChild(table);
        }
      } else {
        Utils.showToast(
          response.message,
          Utils.formatErrorMessage(response.errors),
          Constants.TOAST.fail
        );
      }
    });
  }
  
  
  
  
   

  transformGstData(data) {
    if (data!=null && data.gstins) {
      let idxgst = 1;
      data.gstins.forEach(function (row) {
        row.registration_date = Utils.formatDate(row.registration_date);
        row.formatted_registration_date = Utils.formatTimeRelative(
          row.registration_date
        );
        row.last_updated = Utils.formatDate(row.last_updated);
        if (row.last_updated) {
          row.formatted_last_updated = Utils.formatTimeRelative(
            row.last_updated
          );
        }
        row.idx = idxgst++;
      });
    }
    return data;
  }
  renderGstDetails(data) {
    if (data == null) {
      return;
    }
    const that = this;
    let tmpl = document.getElementById("applicant-details-tmpl-gst");
    let d = this.transformGstData(data);
    let rendered = Mustache.render(tmpl.innerHTML, d);
    this.elem_application_details.append($.parseHTML(rendered)[1]);
    tmpl = document.getElementById("applicant-details-tmpl-gst");
    rendered = Mustache.render(tmpl.innerHTML, d);
    this.elem_applicant_details.append($.parseHTML(rendered)[1]);
  }

  createNestedTableUydam(jsonObj, parentElement, parentKey = null) {
    let that = this;
    let table = document.createElement("table");
    table.style.borderCollapse = "collapse";
    table.style.width = "100%";
    table.style.border = "1px solid #ddd";
    table.style.marginTop = "20px";
    table.style.fontFamily = "Arial, sans-serif";
    table.style.fontSize = "14px";
    for (let key in jsonObj) {
      if (jsonObj.hasOwnProperty(key)) {
        let tr = document.createElement("tr");
        let capitalizedKey = key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1');
        capitalizedKey = capitalizedKey.charAt(0).toUpperCase() + capitalizedKey.slice(1);        
        let tdKey = document.createElement("td");
        tdKey.style.border = "1px solid #ddd";
        tdKey.style.padding = "8px";
        tdKey.style.textAlign = "left";
        tdKey.style.backgroundColor = "#f2f2f2";
        tdKey.style.fontWeight = "bold";
        tdKey.style.width = "30%";
        tdKey.textContent = capitalizedKey;
        let tdValue = document.createElement("td");
        tdValue.style.border = "1px solid #ddd";
        tdValue.style.padding = "8px";
        tdValue.style.textAlign = "left";
        tdValue.style.width = "70%";
        if(jsonObj[key] === null){
          tdValue.textContent = "Not Found";
          tr.appendChild(tdKey);
          tr.appendChild(tdValue);
          table.appendChild(tr);
          continue;
        }
        if (parentKey == "profile" && key == "name") {
          let ud_name = jsonObj['name'];
          let bs_name = that.business_applicant_name;
          if(ud_name != bs_name){
            tdValue.style.color = "red";
            tdValue.style.fontWeight = "bold";
          }
        }

        if (typeof jsonObj[key] === "object") {
          if (Array.isArray(jsonObj[key])) {
            let ul = document.createElement("ul");
            ul.style.padding = "0";
            ul.style.margin = "0";
            ul.style.listStyleType = "none";
            jsonObj[key].forEach((item) => {
              let li = document.createElement("li");
              li.style.marginBottom = "5px";
              if (typeof item === "object") {
                that.createNestedTableUydam(item, li, key);
              } else {
                li.textContent = item;
              }
              ul.appendChild(li);
            });
            tdValue.appendChild(ul);
          } else {
            that.createNestedTableUydam(jsonObj[key], tdValue, key);
          }
        } else {
          tdValue.textContent = jsonObj[key];
        }

        tr.appendChild(tdKey);
        tr.appendChild(tdValue);
        table.appendChild(tr);
      }
    }
    parentElement.appendChild(table);
  }
}


class Eligibility {
  constructor() {
    this.scf_application_id = document.getElementById("scf_application_id").value;
    this.fetchCounterParties();
    this.getBankingDetails();
    this.submitForm();
    Utils.requestHandler(
      "/scf/api/application/" + this.scf_application_id,
      Constants.REQUEST.get,
      {}
    ).then(function (response) {
      if (response.message === "OK") {
        document.getElementById('eligibility_stage').value = response.data.eligibility_stage;
        if(document.getElementById('eligibility_stage').value == "ABB Method"){
          document.getElementById('eligibility_basis_abb').classList.remove('no-display');
        }
        else if(document.getElementById('eligibility_stage').value == "GST Method"){
          document.getElementById('eligibility_basis_gst').classList.remove('no-display');
        }
        else if(document.getElementById('eligibility_stage').value == "Financials"){
          document.getElementById('financial_ratios').classList.remove('no-display');
        }
      }
    });
    this.fetchEligibilityData();
  }

  fetchCounterParties() {
    let that = this;
    that.counter_parties = [];
    Utils.requestHandler(
      "/scf/api/application/" + that.scf_application_id + "/counter-parties",
      Constants.REQUEST.get
    )
    .then(function (response) {
      if (response.message === "OK") {
        that.counter_parties = response.data.counter_parties;
        that.renderFormSections();
      }
    });
  }

  formatDateForInput(dateString) {
    const [year, month, day] = dateString.split('-');
    return `${year}-${month}-${day}`;
  }

  renderFormSections() {
    const container = document.getElementById('eligibility_basis_counterparty_form');
    container.innerHTML = '';
    const updateTotals = () => {
        let totalYearlyPurchase = 0;

        this.counter_parties.forEach(counterParty => {
            const yearlyPurchaseValue = parseFloat(document.getElementById(`eligibility_${counterParty.id}`).value) || 0;
            totalYearlyPurchase += yearlyPurchaseValue;
        });

        document.getElementById('total_eligibility').value = totalYearlyPurchase.toFixed(2);
    };

    container.innerHTML = `
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Counterparty Name</th>
                    <th>Dealing Since When</th>
                    <th>Yearly Purchase</th>
                    <th>Credit Period (Days)</th>
                    <th>Eligibility</th>
                    <th>Proposed Exposure</th>
                </tr>
            </thead>
            <tbody>
                ${this.counter_parties.map((counterParty, index) => {
                    const formattedDate = this.formatDateForInput(counterParty.vintage);
                    
                    return `
                        <tr>
                            <td>
                                <input type="text" class="form-control form-control-lg" id="counterparty_name_${counterParty.id}" value="${counterParty.business_applicant.name}" disabled data-label="Counterparty Name">
                                <small class="hint error no-display">This is an error</small>
                            </td>
                            <td>
                                <input type="date" class="form-control form-control-lg" id="dealing_since_${counterParty.id}" value="${formattedDate}" disabled data-label="Dealing Since When">
                                <small class="hint error no-display">This is an error</small>
                            </td>
                            <td>
                                <input type="number" class="form-control form-control-lg yearly-purchase" id="yearly_purchase_${counterParty.id}" data-label="Yearly Purchase" oninput="updateTotals()">
                                <small class="hint error no-display">This is an error</small>
                            </td>
                            <td>
                                <input type="number" class="form-control form-control-lg" id="credit_period_${counterParty.id}" data-label="Credit Period (Days)">
                                <small class="hint error no-display">This is an error</small>
                            </td>
                            <td>
                                <input type="number" class="form-control form-control-lg" id="eligibility_${counterParty.id}" step="0.01" value="${counterParty.proposed_exposure}" data-label="Eligibility">
                                <small class="hint error no-display">This is an error</small>
                            </td>
                            <td>
                            </td>
                        </tr>
                    `;
                }).join('')}
                <tr>
                    <td colspan="2"><strong>Total</strong></td>
                    <td></td>
                    <td></td>
                    <td>
                        <input type="number" class="form-control form-control-lg" id="total_eligibility" data-label="Total Eligibility" disabled>
                        <small class="hint error no-display">This is an error</small>
                    </td>
                    <td>
                        <input type="number" class="form-control form-control-lg proposed-exposure" id="proposed_exposure_${this.counter_parties[this.counter_parties.length - 1].id}" step="0.01" value="${this.counter_parties[this.counter_parties.length - 1].proposed_exposure}" data-label="Proposed Exposure">
                        <small class="hint error no-display">This is an error</small>
                    </td>
                </tr>
            </tbody>
        </table>
    `;
    this.counter_parties.forEach(counterParty => {
        document.getElementById(`eligibility_${counterParty.id}`).addEventListener('input', updateTotals);
    });
    updateTotals();
}

  getBankingDetails() {
    let that = this;
    that.bank_accounts = [];
    Utils.requestHandler('/scf/api/application/' + that.scf_application_id + '/bank-accounts', Constants.REQUEST.get).then(function (response) {
        if (response.message === 'OK') {
            that.bank_accounts = response.data.bank_accounts;
            const bankingDetails = response.data.bank_accounts;
            that.renderBankingDetails(bankingDetails);
        }
    });
  }

  renderBankingDetails(bankAccounts) {
    const container = document.getElementById('eligibility_basis_abb');
    container.innerHTML = `
        <h4>Eligibility Basis: ABB</h4>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Bank Name</th>
                    <th>Type of Account</th>
                    <th>Daily ABB (Last 12 Months)</th>
                    <th>Less: Non-Business Transactions Proportion</th>
                    <th>% of Non-Business Transactions</th>
                    <th>Eligible ABB</th>
                    <th>Proposed Exposure (FOIR @100%; ROI 20%; 36mth TL)</th>
                </tr>
            </thead>
            <tbody>
                ${bankAccounts.map((bank, index) => {
                    return `
                        <tr>
                            <td>
                                <input type="text" class="form-control form-control-lg" id="bank_name_${bank.id}" value="${bank.bank_name}" disabled data-label="Bank Name">
                                <small class="hint error no-display">This is an error</small>
                            </td>
                            <td>
                                <input type="text" class="form-control form-control-lg" id="account_type_${bank.id}" value="${bank.account_type}" disabled data-label="Type of Account">
                                <small class="hint error no-display">This is an error</small>
                            </td>
                            <td>
                                <input type="number" class="form-control form-control-lg" id="daily_abb_${bank.id}" value="${bank.daily_abb || ''}" step="0.01" data-label="Daily ABB">
                                <small class="hint error no-display">This is an error</small>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    `;
                }).join('')}
                <tr>
                    <td colspan="2"><strong>Total</strong></td>
                    <td>
                        <input type="number" class="form-control form-control-lg" id="total_daily_abb" step="0.01" data-label="Daily ABB" disabled>
                        <small class="hint error no-display">This is an error</small>
                    </td>
                    <td>
                        <input type="number" class="form-control form-control-lg" id="non_business_transactions_${bankAccounts[bankAccounts.length - 1].id}" step="0.01" data-label="Non Business Transactions">
                        <small class="hint error no-display">This is an error</small>
                    </td>
                    <td>
                        <input type="number" class="form-control form-control-lg" id="non_business_percentage_${bankAccounts[bankAccounts.length - 1].id}" step="0.01" data-label="Non Business Percentage">
                        <small class="hint error no-display">This is an error</small>
                    </td>
                    <td>
                        <input type="number" class="form-control form-control-lg" id="eligible_abb_${bankAccounts[bankAccounts.length - 1].id}" step="0.01" data-label="Eligible ABB">
                        <small class="hint error no-display">This is an error</small>
                    </td>
                    <td>
                        <input type="number" class="form-control form-control-lg" id="proposed_exposure_abb_${bankAccounts[bankAccounts.length - 1].id}" step="0.01" data-label="Proposed Exposure ABB">
                        <small class="hint error no-display">This is an error</small>
                    </td>
                </tr>
            </tbody>
        </table>
    `;
    const updateTotals = () => {
        let totalDailyABB = 0;

        bankAccounts.forEach(bank => {
          const dailyabb = parseFloat(document.getElementById(`daily_abb_${bank.id}`).value) || 0;
          totalDailyABB += dailyabb;
        });

        document.getElementById('total_daily_abb').value = totalDailyABB.toFixed(2);
    };

    bankAccounts.forEach(bank => {
        document.getElementById(`daily_abb_${bank.id}`).addEventListener('input', updateTotals);
    });

    updateTotals();
  }

  fetchEligibilityData() {
    let payload = {};
    let that = this;
    payload["url"] = Constants.SERVICE_BASE_BACKOFFICE + "/v2/eligibility/fetch-eligibility-details/";
    payload["req_type"] = "get";

    Utils.requestHandler(
        "/scf/api/application/" + that.scf_application_id + "/eligibility-data",
        Constants.REQUEST.post,
        payload
    ).then(function(response) {
      if(response.message==='OK'){
        that.populateFormFields(response.data);
      } 
    }).catch(function(error) {
        console.error("Error fetching eligibility data:", error);
    });
  }

  

  populateFormFields(data) {
    // Populate Counterparty fields and calculate totals
    if (data.counterparty && data.counterparty.length > 0) {
      let total_eligiblity = 0;

      data.counterparty.forEach(counterparty => {
          const yearlyPurchaseElement = document.getElementById(`yearly_purchase_${counterparty.counter_party_id}`);
          const creditPeriodElement = document.getElementById(`credit_period_${counterparty.counter_party_id}`);
          const eligibilityElement = document.getElementById(`eligibility_${counterparty.counter_party_id}`);
          const proposedExposureElement = document.getElementById(`proposed_exposure_${counterparty.counter_party_id}`);

          if (yearlyPurchaseElement) {
              yearlyPurchaseElement.value = counterparty.yearly_purchase || '';
          }
          if (creditPeriodElement) creditPeriodElement.value = counterparty.credit_period || '';
          if (eligibilityElement){ 
            total_eligiblity += parseFloat(counterparty.eligibility) || 0;
            eligibilityElement.value = counterparty.eligibility || '';
        }

          if (proposedExposureElement) proposedExposureElement.value = counterparty.proposed_exposure || '';
      });

      // Update total fields
      document.getElementById('total_eligibility').value = total_eligiblity.toFixed(2);
    }

    // Populate ABB fields and calculate totals
    if (data.abb && data.abb.length > 0) {
    let totalDailyABB = 0;

    data.abb.forEach(abb => {
        const dailyAbElement = document.getElementById(`daily_abb_${abb.bank_account_id}`);
        const nonBusinessTransactionsElement = document.getElementById(`non_business_transactions_${abb.bank_account_id}`);
        const nonBusinessPercentageElement = document.getElementById(`non_business_percentage_${abb.bank_account_id}`);
        const eligibleAbElement = document.getElementById(`eligible_abb_${abb.bank_account_id}`);
        const proposedExposureAbbElement = document.getElementById(`proposed_exposure_abb_${abb.bank_account_id}`);

        if (dailyAbElement) {
            dailyAbElement.value = abb.daily_abb || '';
            totalDailyABB += parseFloat(abb.daily_abb) || 0;
        }
        if (nonBusinessTransactionsElement) {
            nonBusinessTransactionsElement.value = abb.non_business_transactions || '';
        }
        if (nonBusinessPercentageElement) nonBusinessPercentageElement.value = abb.non_business_percentage || '';
        if (eligibleAbElement) eligibleAbElement.value = abb.eligible_abb || '';
        console.log(proposedExposureAbbElement);
        if (proposedExposureAbbElement) {
            proposedExposureAbbElement.value = abb.proposed_exposure_abb || '';
        }
    });

    // Update total fields
    document.getElementById('total_daily_abb').value = totalDailyABB.toFixed(2);
    }

    // Populate GST fields
    if (data.gst && data.gst.length > 0) {
      const gst = data.gst[0];
      document.getElementById('avg_monthly_sales').value = gst.avg_monthly_sales || '';
      document.getElementById('industry').value = gst.industry || '';
      document.getElementById('margin').value = gst.margin || '';
      document.getElementById('total_income_foir').value = gst.total_income_foir || '';
      document.getElementById('existing_obligations').value = gst.existing_obligations || '';
      document.getElementById('proposed_exposure_gst').value = gst.proposed_exposure_gst || '';
    }

    // Populate Financial Ratios fields
    if (data.financial_ratios && data.financial_ratios.length > 0) {
      const ratios = data.financial_ratios[0];
      document.getElementById('drop_in_sales').value = ratios.drop_in_sales || '';
      document.getElementById('positive_atnw').value = ratios.positive_atnw || '';
      document.getElementById('positive_cash_profit').value = ratios.positive_cash_profit || '';
      document.getElementById('dpd_debtors_vs_atnw').value = ratios.dpd_debtors_vs_atnw || '';
      document.getElementById('debtor_ageing_credit_ageing').value = ratios.debtor_ageing_credit_ageing || '';
      document.getElementById('tca_debtor_stock').value = ratios.tca_debtor_stock || '';
      document.getElementById('dscr').value = ratios.dscr || '';
      document.getElementById('tol_atnw').value = ratios.tol_atnw || '';
      document.getElementById('de_ratio').value = ratios.de_ratio || '';
      document.getElementById('debt_turnover').value = ratios.debt_turnover || '';
      document.getElementById('bto').value = ratios.bto || '';
  }
  }


  submitForm() {
    let that = this;
    const submitButton = document.getElementById("btn-eligibility-submit");

    submitButton.addEventListener("click", (event) => {
        event.preventDefault();

        if (that.validateForm()) {
            const eligibilityStage = document.getElementById('eligibility_stage').value;
            let payload = {
                scf_application_id: document.getElementById("scf_application_id").value || '',
                counterparty: [],
                abb: [],
                gst: {},
                financial_ratios: {}
            };
            if (eligibilityStage === "ABB Method") {
                // Gather ABB data
                that.bank_accounts.forEach((bankAccount) => {
                    let bankId = bankAccount.id; // Assuming `bankAccount.id` represents the unique ID of the bank account
                    let bankAccountElement = document.getElementById(`bank_account_${bankId}`);
                    let dailyAbbElement = document.getElementById(`daily_abb_${bankId}`);
                    let nonBusinessTransactionsElement = document.getElementById(`non_business_transactions_${bankId}`);
                    let nonBusinessPercentageElement = document.getElementById(`non_business_percentage_${bankId}`);
                    let proposedExposureAbbElement = document.getElementById(`proposed_exposure_abb_${bankId}`);
                    let eligibleElement = document.getElementById(`eligible_abb_${bankId}`);

                    payload.abb.push({
                        id: bankId,
                        bank_account: bankAccountElement ? bankAccountElement.value : '',
                        daily_abb: parseFloat(dailyAbbElement ? dailyAbbElement.value : 0),
                        non_business_transactions: parseFloat(nonBusinessTransactionsElement ? nonBusinessTransactionsElement.value : null),
                        non_business_percentage: parseFloat(nonBusinessPercentageElement ? nonBusinessPercentageElement.value : null),
                        proposed_exposure_abb: parseFloat(proposedExposureAbbElement ? proposedExposureAbbElement.value : null),
                        eligible_abb: parseFloat(eligibleElement ? eligibleElement.value : null),
                    });
                });
            } else if (eligibilityStage === "GST Method") {
                // Gather GST data
                payload.gst = {
                    avg_monthly_sales: parseFloat(document.getElementById("avg_monthly_sales").value || 0),
                    industry: document.getElementById("industry").value || '',
                    margin: parseFloat(document.getElementById("margin").value || 0),
                    total_income_foir: parseFloat(document.getElementById("total_income_foir").value || 0),
                    existing_obligations: parseFloat(document.getElementById("existing_obligations").value || 0),
                    proposed_exposure_gst: parseFloat(document.getElementById("proposed_exposure_gst").value || 0)
                };
            } else if (eligibilityStage === "Financials") {
                // Gather Financial ratios data
                payload.financial_ratios = {
                    drop_in_sales: parseFloat(document.getElementById("drop_in_sales").value || 0),
                    positive_atnw: document.getElementById("positive_atnw").value || '',
                    positive_cash_profit: document.getElementById("positive_cash_profit").value || '',
                    dpd_debtors_vs_atnw: parseFloat(document.getElementById("dpd_debtors_vs_atnw").value || 0),
                    debtor_ageing_credit_ageing: parseFloat(document.getElementById("debtor_ageing_credit_ageing").value || 0),
                    tca_debtor_stock: parseFloat(document.getElementById("tca_debtor_stock").value || 0),
                    dscr: parseFloat(document.getElementById("dscr").value || 0),
                    tol_atnw: parseFloat(document.getElementById("tol_atnw").value || 0),
                    de_ratio: parseFloat(document.getElementById("de_ratio").value || 0),
                    debt_turnover: parseFloat(document.getElementById("debt_turnover").value || 0),
                    bto: parseFloat(document.getElementById("bto").value || 0)
                };
            }

            // Gather Counterparty data (common for all stages)
            that.counter_parties.forEach((counterParty) => {
                let counterpartyElement = document.getElementById(`counterparty_name_${counterParty.id}`);
                let yearlyPurchaseElement = document.getElementById(`yearly_purchase_${counterParty.id}`);
                let creditPeriodElement = document.getElementById(`credit_period_${counterParty.id}`);
                let proposedExposureElement = document.getElementById(`proposed_exposure_${counterParty.id}`);
                let eligibility = document.getElementById(`eligibility_${counterParty.id}`);

                payload.counterparty.push({
                    id: counterParty.id,
                    counter_party: counterpartyElement ? counterpartyElement.value : '',
                    yearly_purchase: parseFloat(yearlyPurchaseElement ? yearlyPurchaseElement.value : 0),
                    credit_period: parseInt(creditPeriodElement ? creditPeriodElement.value : 0),
                    proposed_exposure: parseFloat(proposedExposureElement ? proposedExposureElement.value : null),
                    eligibility: parseFloat(eligibility ? eligibility.value : null)
                });
            });

            payload["url"]= Constants.SERVICE_BASE_BACKOFFICE + "/v2/eligibility/eligibility-submit/";
            payload["req_type"]= "post";
            Utils.requestHandler(
              "/scf/api/application/" + that.scf_application_id + "/eligibility-data",
              Constants.REQUEST.post,
              payload
            ).then(function (response) {
              if (response.message === "OK") {
                Utils.showToast(
                  "Success",
                  "Eligibility Submitted Successfully.",
                  Constants.TOAST.success
                );
              }
              else{
                  Utils.showToast(
                    response.message,
                    "Invalid Input",
                    Constants.TOAST.info
                  );
                }
            });
        }
    });
}

validateForm() {
  let that = this;
  that.pass = true;

  const eligibilityStage = document.getElementById('eligibility_stage').value;

  // Define sections and fields to validate based on the eligibility stage
  const sectionsToValidate = {
      "ABB Method": [
          "eligibility_basis_counterparty_form",
          "eligibility_basis_abb"
      ],
      "GST Method": [
        "eligibility_basis_counterparty_form",
        "eligibility_basis_gst"
      ],
      "Financials": [
        "eligibility_basis_counterparty_form",
        "financial_ratios"
      ]
  };

  // Validate fields for the selected eligibility stage
  (sectionsToValidate[eligibilityStage] || []).forEach(sectionId => {
      document.querySelectorAll(`#${sectionId} input, #${sectionId} select`).forEach((field) => {
        console.log(field);
          let label = field.getAttribute("data-label");
          let label_title = label;

          if (!field.value.trim()) {
              Utils.showInputError(field, `${label_title} is mandatory`);
              that.pass = false;
          } else {
              Utils.hideInputError(field);
          }
          if (field.type === "number" && field.value) {
              if (isNaN(field.value) || field.value <= 0) {
                  Utils.showInputError(field, `${label_title} must be a positive number`);
                  that.pass = false;
              } else {
                  Utils.hideInputError(field);
              }
          }
          if (field.type === "date" && field.value) {
              let date = new Date(field.value);
              if (isNaN(date.getTime())) {
                  Utils.showInputError(field, "Invalid date format");
                  that.pass = false;
              } else {
                  Utils.hideInputError(field);
              }
          }
          if (field.tagName === "SELECT") {
              if (!field.value.trim()) {
                  Utils.showInputError(field, `${label_title} is mandatory`);
                  that.pass = false;
              } else {
                  Utils.hideInputError(field);
              }
          }

          // Add event listener for input validation
          field.addEventListener("input", () => {
              if (!field.value.trim()) {
                  Utils.showInputError(field, `${label_title} is mandatory`);
                  that.pass = false;
              } else {
                  Utils.hideInputError(field);
              }

              if (field.type === "number" && field.value) {
                  if (isNaN(field.value) || field.value <= 0) {
                      Utils.showInputError(field, `${label_title} must be a positive number`);
                      that.pass = false;
                  } else {
                      Utils.hideInputError(field);
                  }
              }
              if (field.type === "date" && field.value) {
                  let date = new Date(field.value);
                  if (isNaN(date.getTime())) {
                      Utils.showInputError(field, "Invalid date format");
                      that.pass = false;
                  } else {
                      Utils.hideInputError(field);
                  }
              }
          });
      });
  });

  return that.pass;
}

}
