import { DocumentUtils, Utils } from "../core/utils";
import * as Constants from "../core/constants";
import Mustache from 'mustache';

export class AnchorBorrower {
    constructor() {
        Utils.pageSanityHandler();
        this.elem_anchor_id = document.getElementById('select_anchor_borrower');
        this.elem_anchor_id.addEventListener('input', function (){
            Utils.reInput(this);
        })
    }

    handler() {
        this.fetchAllAnchorsList();
        this.renderAnchorTable();
    }

    fetchAllAnchorsList(){
        let that = this;
        Utils.requestHandler('/anchor/api/applicant/get-anchors-list', Constants.REQUEST.get, {})
        .then(function (response){
          if(response.message === 'OK' ){
            let selectField = that.elem_anchor_id;
            
            while (selectField.options.length > 1) {
              selectField.remove(1);
            }
        
            response.data.anchors.forEach(anchor => {
              let option = document.createElement('option');
              option.value = anchor.id;
              option.text = anchor.name;
              selectField.add(option);
            });
          }
        }) 
    }

    renderAnchorTable() {
        document.getElementById('select_anchor_borrower').addEventListener('change', function(){
            let body = {
                anchor_applicant_id: document.getElementById('select_anchor_borrower').value
            }
            Utils.requestHandler('/anchor/api/anchor_leads_lot/'+ document.getElementById('select_anchor_borrower').value +'/lead-lot-applicants', Constants.REQUEST.get, body)
            .then(function (response){
                if(response.message === 'OK' ){
                    let tmpl = document.getElementById("app-anchor-business-applicant-row-tmpl");
                    let dx = document.getElementById("tb_anchor_business_applicant_details");
                    dx.innerHTML = null;
                    response.data.applicants.forEach(function (dd) {
                        let rendered = Mustache.render(tmpl.innerHTML, dd);
                        dx.append($.parseHTML(rendered)[1]);
                    });
                }
                else{
                    let tmpl = document.getElementById("app-anchor-business-applicant-row-tmpl");
                    let dx = document.getElementById("tb_anchor_business_applicant_details");
                    dx.innerHTML = `
                        <tr>
                            <td colspan="4" class="text-center">
                                No Anchor Data Found.
                            </td>
                        </tr>
                    `;
                }
            });
        })
    }
}