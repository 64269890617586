import { Utils } from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from "mustache";
import { ScfPermission } from "../scf_auth_handler";

export default class ScfAppApplicationPanel {

    constructor() {
        this.scf_application_id = document.getElementById('scf_application_id');
        this.business_applicant_id = document.getElementById('business_applicant_id');
        this.business_applicant_name = document.getElementById('business_applicant_name');
        this.elem_application_details = document.getElementById('div_application_details');
        this.elem_applicant_details = document.getElementById('div_applicant_details');
        this.business_application_pan = document.getElementById('business_applicant_pan');
        this.applicationDetailsHandler();
        this.fcWidgetMethod();
    }

    programList(selected) {
        let that = this;
        Utils.requestHandler('/scf/api/program/all-programs', Constants.REQUEST.get, {})
            .then(function (response) {
                if (response.message === 'OK') {
                    that.addProgram(response.data, selected);
                } else {
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
    }

    addProgram(data, selected) {
        let that = this;
        let select_program = document.getElementById('program_program_rules');
        select_program.innerHTML = "";
        let defaultOpt = document.createElement('option');
        defaultOpt.value = '';
        defaultOpt.innerHTML = 'Select a Program';
        select_program.append(defaultOpt);
        data.program.forEach(program => {
            let opt = document.createElement('option');
            opt.value = program.id;
            opt.innerHTML = program.name;
            if (opt.value == selected) {
                opt.selected = true;
                select_program.disabled = true;
            }
            select_program.append(opt);
        });
        document.getElementById('btn-save-program').addEventListener('click', function(){
            let programId = document.getElementById('program_program_rules').value;
            let scfApplicationId = that.scf_application_id.value;

            if (!programId || !scfApplicationId) {
                Utils.showToast('Validation Error', 'Please select a program and an application.', Constants.TOAST.fail);
                return;
            }

            let payload = {
                program_id: programId,
                scf_application_id: scfApplicationId
            };

            Utils.requestHandler('/scf/api/program/tag-program', Constants.REQUEST.post, payload)
            .then(function (response){
                if(response.message === 'OK'){
                    Utils.showToast(response.data.program, 'Program tagged', Constants.TOAST.success);
                    document.getElementById('btn-save-program').classList.add('d-none');
                    document.getElementById('program_program_rules').disabled = true
                } else {
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            });
        });
    }

    fetchTagProgram(programId) {
        let that = this
        let payload = {
            program_id: programId,
            scf_application_id: that.scf_application_id.value
        };

        Utils.requestHandler('/scf/api/program/tag-program', Constants.REQUEST.post, payload)
            .then(function (response) {
                if (response.message === 'OK') {
                    that.addProgram(response.data);
                } else {
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
    }

    fcWidgetMethod() {
        let that = this;
        let id = "";
        let email = "";
        let firstname = "";
        let lastname = "";
        let mobile_no = "";
        let usertype = "Borrower";
        if (ScfPermission.isScfLender()) {
            usertype = "Lender";
        }
        Utils.requestHandler("/scf/api/user/" + localStorage.getItem('user_id'), Constants.REQUEST.get, {})
            .then(function (response) {
                id = response.data.user.id;
                email = response.data.user.email;
                firstname = response.data.user.first_name;
                lastname = response.data.user.last_name;
                mobile_no = response.data.user.mobile;
            }).then(function () {
                window.fcWidget.user.clear();
                window.fcWidget.setExternalId(firstname + "." + lastname);
                // window.fcWidget.user.setRestoreId(id); 
                window.fcWidget.user.setFirstName(firstname);
                window.fcWidget.user.setLastName(lastname);
                window.fcWidget.user.setEmail(email);
                window.fcWidget.user.setPhone(mobile_no);
                window.fcWidget.user.setProperties({
                    first_medium: that.scf_application_id.value,
                    country: usertype
                });
            });
    }

    applicationDetailsHandler() {
        let that = this;
        Utils.requestHandler('/scf/api/application/' + that.scf_application_id.value, Constants.REQUEST.get, {})
            .then(function (response) {
                if (response.message === 'OK') {
                    that.business_applicant_id.value = response.data.scf_business_applicant.id;
                    that.business_applicant_name.value = response.data.scf_business_applicant.pan_name;
                    that.business_application_pan.value = response.data.scf_business_applicant.pan;
                    if (response.data.scf_application.status == "approved" && !(ScfPermission.isScfSales() || ScfPermission.isScfLender())) {
                        document.getElementById("nav-panel-lenders").classList.remove('no-display');
                    }
                    if (response.data.application_lender_approved && !(ScfPermission.isScfSales())) {
                        // const navLimits = document.getElementById("nav-limits");
                        // const navInvoice = document.getElementById("nav-invoice");

                        // navLimits.classList.remove('no-display');
                        // navInvoice.classList.remove('no-display');
                    }
                    that.renderApplicationDetails(response.data);
                    that.programList(response.data.scf_application.scf_program_id);
                    that.copyApplication();
                } else {
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
    }


    transformApplicationData(data) {
        let that = this;

        if (data.sales_agent_name === null || data.sales_agent_name === undefined) {
            data.sales_agent_name = 'Not Available';
        }

        // Transform scf_business_applicant
        if (data.scf_business_applicant) {
            let idx = 1;
            for (let key in data.scf_business_applicant) {
                if (data.scf_business_applicant[key] === null || data.scf_business_applicant[key] === undefined) {
                    data.scf_business_applicant[key] = 'Not Available';
                }
            }
            data.scf_business_applicant.constitution = Utils.snakeToTitleCase(data.scf_business_applicant.constitution);
            data.scf_business_applicant.idx = function () {
                return idx++;
            };
        }

        // Transform scf_application
        if (data.scf_application) {
            let idx = 1;
            for (let key in data.scf_application) {
                if (data.scf_application[key] === null || data.scf_application[key] === undefined) {
                    data.scf_application[key] = 'Not Available';
                }
            }
            data.scf_application.product = Utils.snakeToTitleCase(data.scf_application.product);
            data.scf_application.created_at = Utils.formatTime(data.scf_application.created_at);
            data.scf_application.relative_created_at = Utils.formatTimeRelative(data.scf_application.created_at);
            data.scf_application.required_amount_formatted = Utils.indianRepresentation(data.scf_application.required_amount);
            data.scf_application.idx = function () {
                return idx++;
            };
        }

        // Transform consents
        if (data.consents) {
            let idx = 1;
            data.consents.forEach(function (row) {
                row.ctype = that.consentToTitleCase(row.ctype);
                row.updated_at = Utils.formatTime(row.updated_at);
                row.relative_updated_at = Utils.formatTimeRelative(row.updated_at);
                row.idx = function () {
                    return idx++;
                };
            });
        }

        // Transform business_directors
        if (data.business_directors) {
            let idx = 1;
            data.business_directors.forEach(function (row) {
                for (let key in row) {
                    if (row[key] === null || row[key] === undefined) {
                        row[key] = 'Not Available';
                    }
                }
                row.date_of_birth = Utils.formatDate(row.date_of_birth);
                if (row.date_of_birth && row.date_of_birth !== 'Not Available') {
                    row.age = Utils.formatTimeRelative(row.date_of_birth);
                }
                row.idx = function () {
                    return idx++;
                };
            });
        }

        // Transform gstins
        if (data.gstins) {
            let idx = 1;
            data.gstins.forEach(function (row) {
                row.registration_date = Utils.formatDate(row.registration_date);
                row.formatted_registration_date = Utils.formatTimeRelative(row.registration_date);
                row.last_updated = Utils.formatDate(row.last_updated);
                if (row.last_updated) {
                    row.formatted_last_updated = Utils.formatTimeRelative(row.last_updated);
                }
                row.idx = function () {
                    return idx++;
                };
            });
        }

        return data;
    }


    consentToTitleCase(str) {
        return str
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    copyApplication(data, selected) {
        let that = this;
        let payload = { scf_application_id: that.scf_application_id.value }
        document.getElementById('btn-duplicate-application').addEventListener('click', function () {
            Utils.requestHandler('/scf/api/application/' + that.scf_application_id.value + '/duplicate', Constants.REQUEST.post, payload)
                .then(function (response) {
                    if (response.message === 'OK') {
                        Utils.showToast(response.message, response.data.scf_applicantion, Constants.TOAST.success);
                    } else {
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                })
        })

    }

    renderApplicationDetails(data){
        if(data['scf_application']['product'] === "short_term_loan"){
            document.getElementById('nav-counterparty').classList.add("no-display");
            document.getElementById('nav-invoice').classList.add("no-display");
        }
        if (data.bank_accounts) {
            let idx = 1;
            data.bank_accounts.forEach(function (row) {
                for (let key in row) {
                    if (row[key] === null || row[key] === undefined) {
                        row[key] = 'Not Available';
                    }
                }
                row.created_at = Utils.formatTime(row.created_at);
                row.idx = function () {
                    return idx++;
                };
            });
        }

        data.business_directors.forEach(function (director) {
            director.state = director.state || "NA";
            director.pan_number = director.pan_number || "NA";
            director.date_of_birth = (director.date_of_birth === "Invalid date") ? "NA" : director.date_of_birth;
            director.gender = director.gender || "NA";

        });
        if (data.scf_business_applicant.constitution === "partnership") {
            data.scf_business_applicant.corporate_id = "NA";
            data.scf_business_applicant.din = "";
            data.scf_business_applicant.llp_name = "";
        }
        else {
            data.scf_business_applicant.llp_name = data.scf_business_applicant.name;
        }
        const that = this;
        let tmpl = document.getElementById('application-details-tmpl');
        data.bank_accounts.forEach(function (row) {
            row.created_at = Utils.formatTime(row.created_at);
        });
        let d = this.transformApplicationData(data);
        document.getElementById('scf_application_trade_name').value = data.scf_application.trade_name;
        let rendered = Mustache.render(tmpl.innerHTML, d);
        this.elem_application_details.append($.parseHTML(rendered)[1]);
        tmpl = document.getElementById('applicant-details-tmpl');
        rendered = Mustache.render(tmpl.innerHTML, d);
        this.elem_applicant_details.append($.parseHTML(rendered)[1]);
        if ((ScfPermission.isScfAdmin() || ScfPermission.isScfCredit() || ( ScfPermission.isScfSales() && data.scf_application.status == 'application_drafted')) && data.scf_application.status != "rejected" && data.lender_decision_submitted != true) {
            document.getElementById('application_edit_section').classList.remove('d-none');
        }
        if (data.scf_application.anchor_leads_lot_id != 'Not Available'){
            document.getElementById('btn_cp_add_modal').classList.add('no-display');
        }
        if (data.scf_application.status == "rejected") {
            document.getElementById('btn-duplicate-application').classList.add('d-none');
            document.getElementById('btn-save-program').classList.add('d-none');
            document.getElementById('syncButton').classList.add('d-none');
            document.getElementById('btn_cp_add_modal').classList.add('d-none');
            document.getElementById('btn_refresh_email_domain').classList.add('d-none');
            document.getElementById('upload_balance_sheet').classList.add('d-none');
            document.getElementById('btn-quest-submit').classList.add('d-none');
            document.getElementById('btn_show_add_new_app_deviation').classList.add('d-none');
            document.getElementById('btn_generate_gst_report_v2').classList.add('d-none');
            document.getElementById('btn-submit-app-decision').classList.add('d-none');
            document.getElementById('cam_report_download').classList.add('d-none');
            document.getElementById('cam_report_download').classList.add('d-none');
            const bankingButtons = document.querySelectorAll('.btn-banking-analysis-trigger');
            const gstButtons = document.querySelectorAll('.generate_gst_report_link');
            bankingButtons.forEach(button => {
                button.classList.add('d-none')
            });
            gstButtons.forEach(button => {
                button.classList.add('d-none')
            });

        }
        if (ScfPermission.isScfAdmin() || ScfPermission.isScfCredit()) {
            document.getElementById('btn-duplicate-application').classList.remove('d-none');
            document.getElementById('tag_program_to_application_list').classList.remove('d-none');
            document.getElementById('btn-copy-application').classList.remove('d-none');
            document.getElementById('btn-save-program').classList.remove('d-none');
            document.getElementById('program_id').classList.remove('d-none');
            document.getElementById('application_copy_section').classList.remove('d-none');
            if (data.scf_application.parent_id != null && data.scf_application.parent_id != "Not Available") {
                document.getElementById('btn-duplicate-application').classList.add('d-none');
            }
            if (data.scf_application.scf_program_id != null && data.scf_application.scf_program_id != 'Not Available') {
                document.getElementById('btn-save-program').classList.add('d-none');
            }
        }

        if(ScfPermission.isScfLender()){
            document.getElementById('tag_program_to_application_list').parentNode.classList.add('d-none');
            document.getElementById('program_id').classList.add('d-none');
            document.getElementById('application_copy_section').classList.add('d-none');
        }
    }

}
