import {Utils} from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from "mustache";

export default class ScfOnbPanelCounterParty{

    constructor() {
        let that = this;
        this.application_id = document.getElementById('scf_application_id').value;
        that.fetchCounterParties();
        that.submitCounterParties();
    }

    fetchCounterParties(){
        let that = this;
        Utils.requestHandler('/scf/api/application/'+that.application_id+'/counter-parties', Constants.REQUEST.get)
            .then(function (response){
                if(response.message === 'OK'){
                    let tmpl = document.getElementById('cp-row-tmpl');
                    let dx = document.getElementById('tb-cp-tbody');
                    dx.innerHTML = null;
                    response.data.counter_parties.forEach(function (dd){
                        dd.status = Utils.snakeToTitleCase(dd.status);
                        dd.vintage = Utils.formatTimeRelative(dd.vintage);
                        let rendered = Mustache.render(tmpl.innerHTML, dd);
                        dx.append($.parseHTML(rendered)[1]);
                    })
                    Utils.showToast('Fetched', 'Counterparties fetched', Constants.TOAST.success);
                }
            })
            .then(function (){

            })
    }

    submitCounterParties(){
        document.getElementById('btn-submit-cps').addEventListener('click', function (){
            let s_cps = [];
            let pass = true;
            document.querySelectorAll('.approve-cp:checked').forEach(function (elem){
                let id = elem.value;
                let mob = document.getElementById('cp-mobile-'+id);
                let email = document.getElementById('cp-email-'+id);
                if(!Utils.validatorMobile(mob.value)){
                    pass = false;
                }
                if(!Utils.validatorEmail(email.value)){
                    pass = false;
                }
                s_cps.push({
                    id: id,
                    mobile: mob.value,
                    email: email.value,
                    status: 'active'
                })
            })
            if(pass){
                Utils.requestHandler('/scf/api/counter_party/update-multiple', Constants.REQUEST.post, {counter_parties: s_cps})
                    .then(function (response) {
                        if (response.message === 'OK') {
                            Utils.showToast('Updated', 'Counterparties updated', Constants.TOAST.success);
                        }
                    })
                    .then(function () {

                    })
            }else{
                Utils.showToast('Input error', 'Incorrect/Empty Email or Mobile', Constants.TOAST.fail);
            }
        })
    }

}