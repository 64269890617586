import {Utils} from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from 'mustache';

export class ScfInvoiceConfirmation {

    constructor() {
        this.obj_banking = [];
        this.invoice_id = document.getElementById('invoice_id').value;
    }

    handler(){
        this.verify_token();
    }
    validateValues(){
        let that = this;
        that.pass=true;
        document.querySelectorAll('.select_cp_confirmation').forEach(function (elem){
            let label=elem.getAttribute('data-label');
            Utils.reInput(elem);
            function toggleMessage(){
                let value=elem.value;
                if(!value){
                    Utils.showInputError(elem, label + ' is mandatory');
                    that.pass=false;
                }else{
                    Utils.hideInputError(elem); 
                }
            }
            toggleMessage();
            elem.addEventListener('input', toggleMessage);

        })
        return that.pass;
    }

    setInvoiceDocument(elem_invoice_document, data){
        elem_invoice_document.setAttribute('data', data.invoice_document.service_url);
        elem_invoice_document.setAttribute('type', data.invoice_document.content_type);
        elem_invoice_document.setAttribute('src', data.invoice_document.service_url);
    }

    verify_token(){
        let that = this;
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const application_id = urlParams.get('scf_application_id');
        let inv_type = 'invoice_discounting'
        let tmpl = document.getElementById('counter-party-invoice-confirmation-tmpl');
        let rejectTmpl = document.getElementById('counter-party-invoice-confirmation-reject-tmpl');
        let successTmpl = document.getElementById('counter-party-invoice-confirmation-success-tmpl');
        let tokenNull = document.getElementById('invocie-token-null-templ')
        let target = document.getElementById('cpInvoiceConfirmationPage');
        if (token){
                Utils.requestHandler('/scf/api/invoice/' + that.invoice_id + "/verify-invoice-token", Constants.REQUEST.post, {token: token, inv_type: inv_type, scf_application_id: application_id})
                .then(function (response){
                    if(response.data.valid_token==false){
                        let rendered =  Mustache.render(tokenNull.innerHTML, target);
                        target.innerHTML = rendered
                    }
                    else if(response.data.valid_token == true){
                        if (response.data.cp_invoice_confirmation_done == true && response.data.allowed == false){
                            let rendered =  Mustache.render(successTmpl.innerHTML, target);
                            target.innerHTML = rendered;
                        }else{
                            if (response.data.is_admin == true){
                                    let rendered =  Mustache.render(tmpl.innerHTML, target);
                                    target.innerHTML = rendered;
                            } else {
                                let rendered =  Mustache.render(tmpl.innerHTML, target);
                                target.innerHTML = rendered;
                            }
                            let elem_invoice_document = document.getElementById('inv_verification_document');
                            that.setInvoiceDocument(elem_invoice_document, response.data);
                            that.submitInvoiceConfirmation(); 
                        }
                    }
                    else {
                        let rendered =  Mustache.render(rejectTmpl.innerHTML, target);
                        target.innerHTML = rendered
                    }
            })
        }
    }

    submitInvoiceConfirmation(){
        let that = this;
        document.getElementById('btn-buyer-invoice-decision').addEventListener('click', function (){
            if (!that.validateValues()) {
                Utils.showToast('Validation Error', 'All fields are mandatory', Constants.TOAST.fail);
                return;
            }
            let decisionInvoice = document.getElementById('invoice_buyer_decision').value;
            let invoiceComment = document.getElementById('invoice_buyer_comment').value;
            const urlParams = new URLSearchParams(window.location.search);
            const application_id = urlParams.get('scf_application_id');
            let body = {
                decision_invoice: decisionInvoice,
                remarks: invoiceComment,
                inv_type: 'invoice_discounting',
                scf_application_id: application_id
            }
            let successTmpl = document.getElementById('counter-party-invoice-confirmation-success-tmpl');
            let target = document.getElementById('cpInvoiceConfirmationPage');
            Utils.requestHandler("/scf/api/invoice/" + that.invoice_id + '/buyer-verified', Constants.REQUEST.post, body)
                .then(function (response){
                    if(response.message === 'OK'){
                        let rendered =  Mustache.render(successTmpl.innerHTML, target);
                        target.innerHTML = rendered
                        Utils.showToast(response.message, 'Invoice Verified Sucessfully', Constants.TOAST.success);
                    }else{
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                })
        })
    }

}