import { Utils} from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from 'mustache';
import { ScfPermission } from "../scf_auth_handler";

export default class ScfAppAnalysisCrimeCheck {

    constructor() {
        this.application_id = document.getElementById('scf_application_id').value;
        // this.getCrimeCheckCases();
        // this.requestCrimeCheck();
        this.userWisePermissoin();
    }
    userWisePermissoin() {
        if (ScfPermission.isScfLender() || ScfPermission.isScfCounterparty() || ScfPermission.isScfBorrower()){
            document.getElementById("nav-crime-check").classList.add("no-display");
        }
    }

    getCrimeCheckCases() {
        let that = this;
        document.getElementById('nav-crime-check').addEventListener('click', function () {
            Utils.requestHandler('/scf/api/application/analysis/' + that.application_id + '/crime-cases', Constants.REQUEST.get).then(function (response) {
                if (response.message === 'OK') {
                    let tmpl = document.getElementById('cases-table-tmpl');
                    let dx = document.getElementById('all_cases');
                    let aml_tmpl = document.getElementById('aml-table-tmpl');
                    let aml_dx = document.getElementById('aml_cases');
                    aml_dx.innerHTML = null;
                    dx.innerHTML = null;
                    response.data.crime_check.forEach(obj => {
                        let rendered = Mustache.render(tmpl.innerHTML, obj);
                        dx.append($.parseHTML(rendered)[1]);
                        rendered = Mustache.render(aml_tmpl.innerHTML, obj.aml);
                        aml_dx.append($.parseHTML(rendered)[1]);
                    })
                    Utils.showToast('Fetched', 'Cases fetched', Constants.TOAST.success);
                } else {
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
        })
    }

    requestCrimeCheck() {
        let that = this;
        document.getElementById('req-crime-check').addEventListener('click', function () {
            Utils.requestHandler('/scf/api/application/analysis/' + that.application_id + '/crime-cases', Constants.REQUEST.post).then(function (response) {
                if (response.message === 'OK') {
                    Utils.showToast('Requested', 'Crime Check data request initiated', Constants.TOAST.success);
                } else {
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
        })
    }
}