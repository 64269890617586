import { Utils} from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from 'mustache';
import { ScfPermission } from "../scf_auth_handler";
export default class ScfAppAnalysisBanking {

    constructor() {
        this.application_id = document.getElementById('scf_application_id').value;
        this.getBankingDetails();
    }

    userWisePermission() {
        if (ScfPermission.isScfLender() || ScfPermission.isScfCounterparty() || ScfPermission.isScfBorrower()){
            document.querySelectorAll('.btn-banking-analysis-trigger-upload').forEach(element => {
                element.classList.add('no-display');
            });
        }
    }

    getBankingDetails(){
        let that = this;
        Utils.requestHandler('/scf/api/application/' + that.application_id + '/bank-accounts', Constants.REQUEST.get).then(function (response) {
                if (response.message === 'OK') {
                    let tmpl = document.getElementById('banking-tmpl');
                    let dx = document.getElementById('banking-tbody');
                    dx.innerHTML = null;
                    response.data.bank_accounts.forEach(obj => {
                        let rendered = Mustache.render(tmpl.innerHTML, obj);
                        dx.append($.parseHTML(rendered)[1]);
                    })
                }
                that.userWisePermission();
                that.uploadBankStatement();
                that.downloadBankStatement();
                that.getReports();
            })
    }

    getAnalysisUrl(){
        let that = this;
        let all_quest = document.getElementsByClassName('btn-banking-analysis-trigger');
        [...all_quest].forEach(element => {
            element.addEventListener('click', function(){
               Utils.requestHandler('/scf/api/application/' + that.application_id + '/finbox-analyzer', Constants.REQUEST.post, {bank_account_id: element.getAttribute('data-id')}).then(function (response) {
                if (response.message === 'OK') {
                   window.open(response.data.url.redirect_url);
                }else{
                   Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            }) 
            })
        });
    }

    disableEnableButtons(){
        let elements = document.querySelectorAll('.btn-banking-analysis-trigger-upload')
        elements.forEach(function(element) {
            let id = element.getAttribute("data-id");
            var currentTime = new Date();
            var updatedAt = new Date(element.getAttribute("updated-at"));
            var timeDifferenceInMinutes = Math.abs(currentTime - updatedAt)/60000;
            if(timeDifferenceInMinutes < 3 && id!==null){
                document.querySelectorAll("[data-id='" + id + "']").forEach(function(button) {
                    button.disabled = true;
                    document.getElementById('file-upload-message').textContent = "Your File is currently in processing";
                });
                setTimeout(() => {
                    document.querySelectorAll("[data-id='" + id + "']").forEach(function(button) {
                        button.disabled = false;
                        document.getElementById('file-upload-message').textContent = "";
                    });
                }, timeDifferenceInMinutes*60000);
            }
        })
    }

    uploadBankStatement(){
        let that = this;
        let elements = document.querySelectorAll('.btn-banking-analysis-trigger-upload')
        
        elements.forEach(function(element) {
            element.addEventListener('click', function (event){
                let id = event.target.getAttribute("data-id");
                let entity_id = event.target.getAttribute("entity-id");
                let bank_account_number = event.target.getAttribute("bank-account-number");
                let params = "";
                if(entity_id!=undefined || entity_id!=''){
                    params = {
                        "entity_id": event.target.getAttribute("entity-id"),
                        "account_id": id
                    }
                }
                else{
                    params = {
                        "account_id": id,
                    }
                }
                let body = {
                    "params": params,
                    "url": Constants.SERVICE_BASE_BACKOFFICE + "/v2/bank/statement-upload?identity=true",
                    "req_type": "post",
                    "bank_account_number": bank_account_number
                }
                Utils.requestHandler('/scf/api/application/' + that.application_id + '/finbox/upload', Constants.REQUEST.post, body)
                .then(function (response){
                    if(response.message === 'OK'){
                        document.querySelectorAll("[data-id='" + id + "']").forEach(function(button) {
                            button.disabled = true;
                        });
                        // setTimeout(() => {
                            // document.querySelectorAll("[data-id='" + id + "']").forEach(function(button) {
                            //     button.disabled = false;
                            //     document.getElementById('file-upload-message').textContent = "";
                            // });
                        // }, 180000);
                        Utils.showToast(response.message, response.data, Constants.TOAST.success);
                    }else{
                        Utils.showToast(response.message, response.errors.error, Constants.TOAST.fail);
                    }
                })
            })
        })
        
        
    }

    downloadBankStatement(){
        let that = this;
        let elements = document.querySelectorAll('.btn-banking-analysis-trigger-download');
        elements.forEach(function(element) {
            element.addEventListener('click', function (event){
                let id = event.target.getAttribute("entity-id");
                let body = {
                    "params": {
                        "entity_id":event.target.getAttribute("entity-id")
                    },
                    "url": Constants.SERVICE_BASE_BACKOFFICE + "/v2/bank/generate-detailed-report",
                    "req_type": "get"
                }
                Utils.requestHandler('/scf/api/application/' + that.application_id + '/finbox/download', Constants.REQUEST.post, body)
                .then(function (response){
                    if(response.message === 'OK'){
                        document.querySelectorAll(".btn-banking-analysis-trigger-download[entity-id='" + id + "']").forEach(function(button) {
                            button.disabled = true;
                        });
                        if(response.data["reports"].length > 0) {
                            for (let i = 0; i < response.data["reports"].length; i++) {
                                let pdfUrl = response.data["reports"][i].link;
                                window.open(pdfUrl, '_blank');
                            }
                        }
                        else{
                            Utils.showToast('Error','Your file is currently in processing', Constants.TOAST.fail);
                        }
                    }else{
                        Utils.showToast(response.message, "Bank Statments not uploaded", Constants.TOAST.fail);
                    }
                })
            })
        })
    }

    getReports(){
        let that = this;
        let all_quest = document.getElementsByClassName('btn-banking-analysis-download');
        [...all_quest].forEach(element => {
            element.addEventListener('click', function(){
               Utils.requestHandler('/scf/api/application/' + that.application_id + '/finbox-analyzer', Constants.REQUEST.get, {bank_account_id: element.getAttribute('data-id')}).then(function (response) {
                if (response.message === 'OK') {
                   window.open(response.data.url);
                }else{
                   Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            }) 
            })
        });
    }

}
