import 'datatables'
import 'daterangepicker'
import * as Constants from './constants';
import Mustache from 'mustache';
import Moment from 'moment';
import {ScfPermission, ScfSignIn} from '../scf/scf_auth_handler'

export class Utils{

    static renderDataTable(dt_selector) {
        return $(dt_selector).DataTable({
                    scrollY:        '38rem',
                    scrollCollapse: false,
                    searching: true,
                    lengthChange: false,
                    retrieve : true,
                    paging: false,
                    info: false,
                    processing: true,
                    language: {
                        info: "Showing _TOTAL_ entries",
                        emptyTable: "No data found in this date range"
                    },
                    order: [],
                });
    }

    static requestHandlerDocument(url, req_type, body, options = {}) {
        let stringify = false;
        let headers = {
            'x-session-id': this.readCookie('_alf-session-id'),
            'x-org-id': Constants.ORG_ID
        };
    
        if (!(body instanceof FormData)) {
            headers['Content-Type'] = 'application/json';
            stringify = true;
        }
    
        if (req_type === Constants.REQUEST.get) {
            url = Constants.SERVICE_BASE.concat(url);
            if (options.limit && options.skip) {
                body.limit = parseInt(options.limit);
                body.skip = parseInt(options.skip);
            }
            url = url.concat('?', new URLSearchParams(body));
            return fetch(url, {
                method: 'GET',
                headers: headers
            }).then(function (response) {
                if (response.status === 403) {
                    window.location = '/scf';
                }
                if (options.responseType === 'blob') {
                    return response.blob();
                }
                return response.json();
            }).catch(function (error) {
                return {
                    message: "Error",
                    errors: {
                        "Server error": ["Please contact the administrator"]
                    }
                };
            });
        } else {
            return fetch(Constants.SERVICE_BASE.concat(url), {
                method: 'POST',
                headers: headers,
                body: stringify === true ? JSON.stringify(body) : body
            }).then(function (response) {
                if (response.status === 403) {
                    window.location = '/scf';
                }
                if (options.responseType === 'blob') {
                    return response.blob();
                }
                return response.json();
            }).catch(function (error) {
                return {
                    message: "Error",
                    errors: {
                        "Server error": ["Please contact the administrator"]
                    }
                };
            });
        }
    }    

    static requestHandler(url, req_type, body, limit = null, skip = null){
        let stringify = false;
        let headers = {
            'x-session-id': this.readCookie('_alf-session-id'),
            'x-org-id': Constants.ORG_ID
        };

        if( !(body instanceof FormData) ){
            headers['Content-Type'] = 'application/json';
            stringify = true;
        }

        let that = this;
        if(req_type === Constants.REQUEST.get){
            url = Constants.SERVICE_BASE.concat(url);
            if(limit && skip){
                body.limit = parseInt(limit);
                body.skip = parseInt(skip);
            }
            url = url.concat('?', new URLSearchParams(body));
            return fetch(url, {
                method: 'GET',
                headers: headers
            }).then(function (response) {
                if(response.status === 403){
                    window.location = '/scf'
                }
                return response.json();
            }).catch(function (error){
                return {
                    message: "Error",
                    errors: {
                        "Server error" : ["Please contact the administrator"]
                    }
                }
            })
        }else{
            return fetch(Constants.SERVICE_BASE.concat(url), {
                method: 'POST',
                headers: headers,
                body: stringify === true ? JSON.stringify(body) : body
            }).then(function (response) {
                if(response.status === 403){
                    window.location = '/scf'
                }
                return response.json();
            }).catch(function (error){
                return {
                    message: "Error",
                    errors: {
                        "Server error" : ["Please contact the administrator"]
                    }
                }
            })
        }
    }

    static refreshDataTable(table_container_id, dt_instance, url, req_type, body, mu_tmpl, transformer, list_key, limit = Constants.API_LIMIT.limit, offset = Constants.API_LIMIT.offset){
        let that = this;
        $(table_container_id).find('#dt-table-loader').removeClass('invisible')
        let load_more = document.querySelector(table_container_id +' .dt-footer #dt-table-load-more #dt-load-more');
        load_more.disabled = true;
        this.requestHandler(url, req_type, body, limit, offset)
            .then(function (response){
                if(response.message === 'OK' && response.data[list_key].length > 0){
                    let template = document.getElementById(mu_tmpl).innerHTML;
                    let rendered = response.data[list_key].map(function (row){
                        let trans = row;
                        if(transformer != null){
                            trans = transformer.call(this, row);
                        }
                        return $.parseHTML(Mustache.render(template, trans))[1];
                    })
                    dt_instance.rows.add(rendered).draw();
                    $(table_container_id).find('#dt-table-info').html('Showing ' + dt_instance.page.info().recordsTotal + ' entries');
                }else{
                    if(response.errors){
                        that.showToast(response.message, that.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                }
                $(table_container_id).find('#dt-table-loader').addClass('invisible');
                load_more.disabled = false;
                that.copyToClipboard(); // Fix multi event attachment
            })
    }

    static loaderToast(state){
        if(state === 1){
            let template = document.getElementById('loader-toast-tmpl').innerHTML;
            document.getElementById('toast-holder').append($.parseHTML(Mustache.render(template, {}))[1])
            document.getElementById('loading-box').classList.add('show', 'slide', 'slide-in');
        }else{
            if(document.getElementById('loading-box')){
                document.getElementById('loading-box').classList.add('slide-out');
                setTimeout(function (){
                    document.getElementById('loading-box').remove();
                }, 1000)
            }
        }
    }

    static placeholderHandler(element, state){
        if(state === 1){
            element.parentElement.parentElement.querySelector('.placeholder-content').classList.add('no-display');
            element.parentElement.parentElement.querySelector('.placeholder-glow').classList.remove('no-display');
        }else{
            element.parentElement.parentElement.querySelector('.placeholder-content').classList.remove('no-display');
            element.parentElement.parentElement.querySelector('.placeholder-glow').classList.add('no-display');
        }

    }

    static buttonWithLoader(element, state){
        if(state === 1){
            element.parentElement.querySelector('.badge').classList.remove('no-display');
            element.classList.add('no-display');
        }else{
            element.parentElement.querySelector('.badge').classList.add('no-display');
            element.classList.remove('no-display');
        }
    }

    static showToast(title, sub_text, ttype = Constants.TOAST.info){
        if(title === 'OK'){
            title = 'Success';
        }
        let tclass;
        switch(ttype) {
            case Constants.TOAST.success:
                sub_text = [sub_text];
                tclass = 'success';
                break;
            case Constants.TOAST.fail:
                tclass = 'fail';
                break;
            default:
                tclass = 'info';
        }
        let toast_id = Math.random().toString(36).replace('0.','toast_box_' || '');
        let template = document.getElementById('toast-tmpl').innerHTML;
        document.getElementById('toast-holder').append($.parseHTML(Mustache.render(template, {title: title, sub_text: sub_text, tid: toast_id}))[1])
        document.getElementById(toast_id).classList.add('show', tclass, 'slide', 'slide-in');
        setTimeout(function(){
            // document.getElementById(toast_id).classList.remove( 'slide-in');
            // document.getElementById(toast_id).classList.add('slide-out');
            setTimeout(function (){
                document.getElementById(toast_id).remove();
            }, 1000)
        }, 5000);
    }

    static capitalizeFirstLetter(string) {
        if(string == null){
            return;
        }
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    static formatTime(string){
        if(string == null){
            return;
        }
        return Moment(string).format('LLL');
    }

    static formatDate(string){
        if(string == null){
            return;
        }
        return Moment(string).format('DD MMMM YYYY');
    }

    static formatTimeRelative(string){
        if(string == null){
            return;
        }
        return Moment(string).fromNow();
    }

    static formatErrorMessage(errors){
        let result = [];
        for (let [key, val] of Object.entries(errors)) {
            if(val instanceof Object && val instanceof Array){
                key = Utils.capitalizeFirstLetter(key).split("_").join(" ");
                result.push(key + ": " + val);  
                
            } else{
                for(let [sub_key, sub_val] of Object.entries(val)){
                    sub_key = Utils.capitalizeFirstLetter(sub_key).split("_").join(" ");
                    result.push(sub_key + ": " + sub_val);
                }  
            }
        }
        return result;
    }

    static assembleParameters(){
        let result = {};
        let params = document.location.href.split('?')
        if(params.length > 1){
            params = params.pop();
            params.split('&').forEach(function (element, index){
               result[element.split('=')[0]] = decodeURIComponent(element.split('=')[1]).trim();
            });
        }
        return result;
    }

    static navPanelHandler(link_class, panel_class){
        document.querySelectorAll(link_class).forEach((element) => {
            element.addEventListener('click', event => {
                if (element === event.target || element.contains(event.target)) {
                    document.querySelectorAll(link_class).forEach(element => {
                        element.classList.remove('active')
                    });
                    element.classList.add('active');
                    document.querySelectorAll(panel_class).forEach(element => {
                        element.classList.add('no-display')
                    });
                    document.getElementById(element.getAttribute('data-panel')).classList.remove('no-display');
                }
            })
        })
    }


    static setupNavPanel(title, sub_title, panel_id, indx){
        document.getElementById('custom-nav-panel-tabs').append($.parseHTML(Mustache.render(document.getElementById('nav_panel_tmpl').innerHTML, {
            title: title,
            sub_title: sub_title,
            panel_id: panel_id,
            indx: indx
        }))[1])
    }

    static snakeToTitleCase(snakeStr) {
        return snakeStr.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '); 
    }

    static validatorEmail(email){
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    static showInputError(input_elem, error_msg){
        input_elem.classList.add('input-error');
        input_elem.parentElement.querySelector('.hint').innerHTML = error_msg;
        input_elem.parentElement.querySelector('.hint').classList.remove('no-display');
    }

    static hideInputError(element){
        element.classList.remove('input-error');
        element.nextElementSibling.classList.add('no-display');
    }

    static removeInputError(input_elem){
        input_elem.parentElement.querySelector('.hint').classList.add('no-display');
    }

    static reInput(input_elem){
        input_elem.classList.remove('input-error');
        this.removeInputError(input_elem)
    }

    static paiseToRupee(amount, shorten = false, add_sign = true){
        if(amount == null){
            amount = 0;
        }
        let a = parseInt(amount/100);
        if(shorten){
            a = this.numberShortener(a);
        }
        if(add_sign){
            a = "<span class='rupee'></span>".concat(a);
        }
        return a;
    }

    static numberShortener(amount){
        let result = String(amount.toFixed(amount === 0 || amount == null ? 0: 1));
        if(amount >= 1e3 && amount < 1e5){
            result = (amount/1000).toFixed(1).concat(' K')
        }else if(amount >= 1e5 && amount < 1e7){
            result = (amount/100000).toFixed(1).concat(' L')
        }else if(amount >= 1e7){
            result = (amount/10000000).toFixed(1).concat(' Cr')
        }
        return result;
    }

    static indianRepresentation(amount){
        if(amount == null){
            return 0;
        }
        return parseInt(amount).toLocaleString('en-IN');
    }

    static initializePagers(parent_panel_id){
        let prp = document.getElementById(parent_panel_id);
        prp.querySelectorAll('.pager-items').forEach((element) => {
            element.addEventListener('click', event => {
                prp.querySelectorAll('.pager-items').forEach(element => {element.classList.remove('active')});
                element.classList.add('active');
                prp.querySelectorAll('.pager-pane').forEach(
                    element => {
                        element.classList.add('no-display')
                    });
                document.getElementById(element.getAttribute('data-id')).classList.remove('no-display');
            })
        })
    }

    static formatPickerDate(string){
        if(string == null){
            return;
        }
        return Moment(string).format('YYYY-MM-DD');
    }

    static saveCookie(key,value){
        document.cookie = key + "=" + value + ";path=/";
    }

    static deleteCookie(key) {
        document.cookie = key + "=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }

    static readCookie(key) {
        let result;
        let web_cookie = document.cookie;
        web_cookie = web_cookie.split(";");
        for (let c in web_cookie) {
            let wc = web_cookie[c].split("=");
            if (wc[0].trim() === key) {
                result = wc[1];
                break;
            }
        }
        return result;
    };

    static dataTableLoadMoreHandler(table_container_id, picker, dt_instance, url, req_type, mu_tmpl, transformer, list_key){
       let element = document.querySelector(table_container_id +' .dt-footer #dt-table-load-more #dt-load-more')
        element.addEventListener('click', event => {
            if (element === event.target || element.contains(event.target)) {
                let body = {
                    start_date: picker.attr('data-start'),
                    end_date: picker.attr('data-end')
                }
                let skip = parseInt(element.getAttribute('data-skip')) || Constants.API_LIMIT.limit
                if(dt_instance.page.info().recordsTotal < skip){
                    return;
                }
                Utils.refreshDataTable(
                    table_container_id,
                    dt_instance,
                    url,
                    req_type,
                    body,
                    mu_tmpl,
                    transformer,
                    list_key,
                    Constants.API_LIMIT.limit,
                    skip);
                element.setAttribute('data-skip', skip + Constants.API_LIMIT.limit)
            }
        })
    }

    static dateRangeSelectHandler(table_container_id, picker, start, end, dt_instance, url, req_type, mu_tmpl, transformer, list_key){
        picker.html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
        picker.attr('data-start', start.format('YYYY-MM-DD'));
        picker.attr('data-end', end.format('YYYY-MM-DD'))
        let body = {
            start_date: start.format('YYYY-MM-DD'),
            end_date: end.format('YYYY-MM-DD')
        }
        dt_instance.clear().draw();
        Utils.refreshDataTable(
            table_container_id,
            dt_instance,
            url,
            req_type,
            body,
            mu_tmpl,
            transformer,
            list_key);
        return true;
    }

    static initializeDataTable(table_container_id, dt_instance, url, req_type, body, mu_tmpl, transformer, list_key, filters) {
        let start = Moment().subtract(100, 'days');
        let end = Moment();
        let that = this;
        let dt_range_picker = $(table_container_id + '> .table-filters').find('#dt-date-filter');
        let dt_search_element = document.querySelector(table_container_id + ' .table-filters #dt-search-dropdown');
    
        dt_range_picker.daterangepicker({
            startDate: start,
            endDate: end,
            maxDate: Moment().add(1, 'days'),
            locale: { cancelLabel: 'Clear' },
            ranges: {
                'Today': [Moment(), Moment()],
                'Yesterday': [Moment().subtract(1, 'days'), Moment().subtract(1, 'days')],
                'Last 7 Days': [Moment().subtract(6, 'days'), Moment()],
                'Last 30 Days': [Moment().subtract(29, 'days'), Moment()],
                'This Month': [Moment().startOf('month'), Moment().endOf('month')],
                'Last Month': [Moment().subtract(1, 'month').startOf('month'), Moment().subtract(1, 'month').endOf('month')]
            }
        }, function (st, en) {
            that.dateRangeSelectHandler(table_container_id, dt_range_picker, st, en, dt_instance, url, req_type, mu_tmpl, transformer, list_key);
        });
    
        that.dateRangeSelectHandler(table_container_id, dt_range_picker, start, end, dt_instance, url, req_type, mu_tmpl, transformer, list_key);
        that.dataTableLoadMoreHandler(table_container_id, dt_range_picker, dt_instance, url, req_type, mu_tmpl, transformer, list_key);
        that.initializeDataTableSearch(table_container_id, dt_instance, url, req_type, mu_tmpl, transformer, list_key, dt_range_picker);
        that.initializeDataTableFilters(dt_search_element, filters);
        that.initializeDropdown(dt_search_element);
    }
    

    static initializeDataTableSearch(table_container_id, dt_instance, url, req_type, mu_tmpl, transformer, list_key, picker) {
        const dt_search_button = document.querySelector(table_container_id + ' .table-filters #dt-search-button');
        const dt_search_inp = document.querySelector(table_container_id + ' .table-filters #dt-search');
        
        dt_search_button.addEventListener('click', event => {
            let body = {
                start_date: picker.attr('data-start'),
                end_date: picker.attr('data-end'),
                search_term: dt_search_inp.value
            }
            dt_instance.clear().draw();
            Utils.refreshDataTable(
                table_container_id,
                dt_instance,
                url,
                req_type,
                body,
                mu_tmpl,
                transformer,
                list_key
            );
        });
    }
    
    

    static initializeDataTableFilters(search_elem, search_obj){
        if(Object.keys(search_obj).length > 0){
            Object.entries(search_obj).forEach(entry => {
                const [key, value] = entry;
                search_elem.querySelector('.dropdown-menu').insertAdjacentHTML('beforeend', '<div class="dropdown-item" data-search="'+key+'">'+value+'</div>');
            })
        }else{
            search_elem.classList.add('no-display');
        }

    }

    static initializeDropdown(element, change_content = true){
        const bDropdown = new bootstrap.Dropdown(element, {
            offset: [0, 10]
        })
        element.addEventListener('click', event => {
            bDropdown.show();
        })
        element.querySelectorAll('.dropdown-item').forEach(item => {
            item.addEventListener('click', event => {
                if(change_content){
                    element.querySelector('#dropdownMenuButton').textContent = item.textContent;
                }
            })
        })
    }

    static getUsername() {
        Utils.requestHandler("/scf/api/user/" + localStorage.getItem('user_id'), Constants.REQUEST.get, {})
        .then(function (response) {
            let user_name = document.getElementById('username-dashboard');
            let user_type = document.getElementById('user-type-dashboard');
            let user_icon = document.getElementById('dropdownMenuButton');
            let user_name_full = "";
    
            if (response.data.user.first_name && response.data.user.first_name.trim() !== "") {
                user_name_full = response.data.user.first_name;
            }
            if (response.data.user.last_name && response.data.user.last_name.trim() !== "") {
                if (user_name_full !== "") {
                    user_name_full += " ";
                }
                user_name_full += response.data.user.last_name;
            }
            let user_permission = response.data.user_permission;
            if (Array.isArray(user_permission) && user_permission.length > 0) {
                let user_type_permission = user_permission[0].replace('scf_', '');
                user_type_permission = user_type_permission.charAt(0).toUpperCase() + user_type_permission.slice(1);
                user_type.innerHTML = `&nbsp;${user_type_permission}`;
            } else {
                console.error('user_permission is not a valid array:', user_permission);
                user_type.innerHTML = '';
            }
            user_name.innerHTML = user_name_full;
            user_icon.innerHTML = user_name_full.charAt(0);
        })
        .catch(function (error) {
            console.error('Error fetching user data:', error);
        });
    }

    static copyToClipboard(){
        const that = this;
        document.querySelectorAll('.copy').forEach(item => {
            if(!item.getAttribute('copy-event')) {
                item.addEventListener('click', event => {
                    const txt = item.getAttribute('data-copy')
                    navigator.clipboard.writeText(txt);
                    that.showToast('Copied to clipboard', txt, Constants.TOAST.info);
                    item.setAttribute('copy-event', 'true');
                })
            }
        })
    }
    
    static sidebarHandler(active_element){
        let dp_user_settings = document.getElementById('dp-user-settings');
        if(dp_user_settings){
            this.initializeDropdown(document.getElementById('dp-user-settings'), false);
            this.getUsername();
        }
        ScfSignIn.logoutHandler();
        if(ScfPermission.isScfBorrower() || ScfPermission.isScfCounterparty()){
            document.getElementById('sidebar-transactions').classList.add('no-display');
            // document.getElementById('sidebar-settings').classList.add('no-display');
        }
        if(ScfPermission.isScfAdmin() || ScfPermission.isScfCounterparty() || ScfPermission.isScfCredit() || ScfPermission.isScfSuperAdmin() || ScfPermission.isScfLender()){
            document.getElementById('sidebar-support').classList.add('no-display');
            document.getElementById('sidebar-contact').classList.add('no-display');
            document.getElementById('sidebar-create-application').classList.add('no-display');
            document.getElementById('onboarding-hide').classList.add('no-display');
        }
        // Show analytic dashboard for both Admin and Credit permissions
        if(ScfPermission.isScfAdmin() || ScfPermission.isScfCredit()){
            document.getElementById('analytic-dashboard').classList.remove('no-display');
        } else {
            document.getElementById('analytic-dashboard').classList.add('no-display');
        }
        if(ScfPermission.isScfAdmin()){
            document.getElementById('sidebar-settings').classList.remove('no-display');
        }
        if(ScfPermission.isScfAdmin() || ScfPermission.isScfCredit()){
            document.getElementById('sidebar-anchor-section').classList.remove('no-display');
        }
        if (ScfPermission.isScfAdmin() || ScfPermission.isScfSuperAdmin() || ScfPermission.isScfBorrower()) {
            document.getElementById('sidebar-transactions').classList.remove('no-display');
        }
        if(ScfPermission.isScfCounterparty() || ScfPermission.isScfCredit() || ScfPermission.isScfLender() || ScfPermission.isScfSuperAdmin() || ScfPermission.isScfAdmin() || ScfPermission.isScfBorrower()){
            document.getElementById('sidebar-collections').classList.add('no-display');
        }
        if (ScfPermission.isScfSales()) {
            document.getElementById("sidebar-sales").classList.remove("no-display");
            document.getElementById("sidebar-applicant-new-application").classList.add("no-display");
            document.getElementById("sidebar-transactions").classList.add("no-display");
            document.getElementById("sidebar-collections").classList.add("no-display");
        }

        document.getElementById('user_settings').addEventListener('click', function(){
            window.location = '/scf/user/settings'
        })

    }

    static pageSanityHandler(){
        this.sidebarHandler();
        this.hideDropdowns();
    }

    static hideDropdowns(){
        document.addEventListener('click', event => {
            document.querySelectorAll('.dropdown').forEach((element) => {
                if(!element.contains(event.target)){
                    element.querySelector('#dropdown-menu').classList.remove('show');
                }
            })
        })
    }

    static validatorText(text){
        return String(text)
            .match(
                /^[A-Za-z\s]*$/
            );
    }

    static validatorNumber(text){
      return String(text)
          .match(
              /^[1-9]\d*$/
          );
    }

    static validatorZipcode(zipcode){
        return String(zipcode)
            .match(
                /^[1-9][0-9]{5}$/
            );
    }

    static validatorAadhaar(aadhar){
        return String(aadhar)
            .match(
                /^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)/
            );
    }

    static validatorMobile(mobile){
        return String(mobile)
            .match(
                /^[6-9]\d{9}$/gi
            );
    }

    static validatorPan(pan){
        return String(pan)
            .match(
                /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
            );
    }

    static setter(elem, value){
        switch(elem.tagName.toLowerCase()) {
            case 'input':
               elem.value = value
                break;
            case 'select':
                elem.value = value
                elem.dispatchEvent(new Event('change'));
                break;
            default:
                elem.setAttribute('data-value', value)
                elem.innerHTML = value;
                break;
        }
    }

    static getter(elem){
        let result = null;
        switch(elem.tagName.toLowerCase()) {
            case 'input':
                result = elem.value
                break;
            case 'select':
                result = elem.value
                break;
            default:
                break;
        }
        if (result && result.length > 0){
            return result.trim();
        }
    }

    static getZipcodeDetails(zipcode){
        if(String(zipcode).length === 6){
            return Utils.requestHandler('/core/api/utility/zipcode-details/'+zipcode, Constants.REQUEST.get);
        }else{
            Utils.showToast('Error', 'Invalid Zipcode', Constants.TOAST.fail);
            return Promise.resolve();
        }
    }

    static groupBy(data, param){
        let result = {}
        data.forEach(function (row){
            if(result[row[param]]){
                result[row[param]].push(row)
            }else{
                result[row[param]] = [row]
            }
        })
        return result;
    }

    static toggleInput(elem, enable = true){
       elem.disabled = !enable;
    }

    static clean(element){
        var ch_event = new Event('change');
        element.querySelectorAll('input').forEach(function (elem){
            elem.removeAttribute('disabled')
            elem.value = null;
            elem.dispatchEvent(ch_event);
        })
        element.querySelectorAll('select').forEach(function (elem){
            elem.value = null;
            elem.dispatchEvent(ch_event);
        })
        element.querySelectorAll('button').forEach(function (elem){
            elem.removeAttribute('disabled');
        })
    }

}

export class DocumentUtils{

    static bindInputElement(action_element){
        action_element.addEventListener('click', function(){
            let inp_elem = action_element.parentElement.querySelector('.file-input');
            // Check if the file input is empty before dispatching the click event
            if (!inp_elem.files || inp_elem.files.length === 0) {
                inp_elem.dispatchEvent(new MouseEvent('click'));
            }
        })
    }

    static getFileData(inp_elem, more_params = null){
        let file_data = [];
        Array.from(inp_elem.files).forEach(function (fls){
            let body = new FormData();
            body.set('file_data', fls)
            body.set('file_name', fls.name);
            if(more_params){
                for (const m in more_params) {
                    body.set(m, more_params[m]);
                }
            }
            file_data.push(body);
        })
        return file_data;
    }

    static uploadFiles(action_element, url, more_params = null){
        let that = this;
        let inp_elem = action_element.parentElement.querySelector('.file-input');
        const event = new Event("ev_document_uploaded");
        inp_elem.addEventListener('change', function(){
            let file_data = that.getFileData(inp_elem, more_params);
            inp_elem.value = '';
            file_data.forEach(function (body){
                Utils.buttonWithLoader(action_element, Constants.ELEMENT_STATE.show);
                Utils.requestHandler(url, Constants.REQUEST.post, body)
                    .then(function (response){
                        if(response.message === 'OK'){
                            action_element.setAttribute('data-upload-response', JSON.stringify(response.data));
                            action_element.dispatchEvent(event);
                            Utils.showToast(response.message, 'Document uploaded', Constants.TOAST.success);
                        }else{
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    }).then(function (){
                        Utils.buttonWithLoader(action_element, Constants.ELEMENT_STATE.hide);
                    })
            })
        })
    }

    static uploadOnElementTrigger(action_element, inp_element, url, more_params = null){
        let that = this;
        const event = new Event("ev_document_uploaded");
        let file_data = that.getFileData(inp_element, more_params);
        file_data.forEach(function (body){
            // Utils.buttonWithLoader(action_element, Constants.ELEMENT_STATE.show);
            Utils.requestHandler(url, Constants.REQUEST.post, body)
                .then(function (response){
                    if(response.message === 'OK'){
                        action_element.setAttribute('data-upload-response', JSON.stringify(response.data));
                        action_element.dispatchEvent(event);
                        Utils.showToast(response.message, 'Document uploaded', Constants.TOAST.success);
                    }else{
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                }).then(function (){
                    // Utils.buttonWithLoader(action_element, Constants.ELEMENT_STATE.hide);
            })
        })
    }

    static showOfflinePreview(action_element, preview_element){
        let inp_elem = action_element.parentElement.querySelector('.file-input');
        inp_elem.addEventListener('change', function(){
            if(inp_elem.files.length > 0){
                preview_element.innerHTML = null;
            }
            Array.from(inp_elem.files).forEach(function (fls){
                if (fls) {
                    let ah = document.createElement('a');
                    ah.innerHTML = fls.name;
                    ah.setAttribute('src', URL.createObjectURL(fls));
                    preview_element.append(ah);
                }
            })
        })
    }
}