import { DocumentUtils, Utils } from "../core/utils";
import * as Constants from "../core/constants";
import Mustache from 'mustache';

export class LeadsLotDetails {
    constructor() {
      Utils.pageSanityHandler();
      this.anchor_leads_lot_id = document.getElementById('anchor_leads_lot_id').value
      console.log("Hi")
    }

    handler() {
      this.fetchLeadsLotDetails();
      this.dtHandler();
    }


  mainTableTransformer(data) {
      data.application_details_link = "/scf/application/details/" + data.id;
      data.product = Utils.capitalizeFirstLetter(
          data.product.split("_").join(" ")
          );
      data.required_amount = data.required_amount.toLocaleString("en-IN");
      data.formatted_created_at = Utils.formatTime(data.created_at);
      data.created_at_ago = Utils.formatTimeRelative(data.created_at);
      data.formatted_updated_at = Utils.formatTimeRelative(data.updated_at);
      data.formatted_created_at = Utils.formatTimeRelative(data.created_at);
      data.status_human = Utils.capitalizeFirstLetter(
          data.status.split("_").join(" ")
      );
      if(data.trade_name != null){
          data.business_applicant_name = data.trade_name;
      }
      return data;
  }

  dtHandler() {
      let table_container_id = "#dt-table-anchor-applications";
      let dt_instance = Utils.renderDataTable(table_container_id + "> table");

      Utils.initializeDataTable(
          table_container_id,
          dt_instance,
          "/anchor/api/anchor_leads_lot/" + this.anchor_leads_lot_id + "/all-applications",
          Constants.REQUEST.get,
          {},
          "dt-main-tmpl",
          this.mainTableTransformer,
          "data",
          {
          business_application_id: "Application ID",
          business_applicant_name: "Applicant",
          }
      );
    }  

    fetchLeadsLotDetails(){
      let that = this;
      Utils.requestHandler('/anchor/api/anchor_leads_lot/' + that.anchor_leads_lot_id, Constants.REQUEST.get, {})
      .then(function (response){
        if(response.message === 'OK' ){
          that.renderBusinessApplicantTable(response);
          that.renderUsersTable(response);
        }
      }) 
    }

    renderUsersTable(response) {
      let tmpl = document.getElementById("app-anchor-user-row-tmpl");
      let dx = document.getElementById("tb_anchor_users");
      dx.innerHTML = null;
      response.data.users.forEach(function (dd) {
        let rendered = Mustache.render(tmpl.innerHTML, dd);
        dx.append($.parseHTML(rendered)[1]);
      });
    }

    renderBusinessApplicantTable(response) {
      let tmpl = document.getElementById("app-anchor-business-applicant-row-tmpl");
      let dx = document.getElementById("tb_anchor_business_applicant_details");
      dx.innerHTML = null;
      response.data.business_applicants.forEach(function (dd) {
        let rendered = Mustache.render(tmpl.innerHTML, dd);
        dx.append($.parseHTML(rendered)[1]);
      });
    }
}