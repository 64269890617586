import {Utils} from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from 'mustache';

export class ScfCounterPartyConfirmation {

    constructor() {
        this.obj_banking = [];
        this.counter_party_id = document.getElementById('counter_party_id').value;
        this.cp_name = document.getElementById('cp_name');
    }

    handler(){
        this.verify_token();
    }

    toggleConsentValue(){
        const checkbox = document.getElementById('cp_escrow_consent');
    
        checkbox.addEventListener('change', (event) => {
          if (event.target.checked) {
            event.target.value = "CP_ESCROW_CONSENT";
          } else {
            event.target.value = "";
          }
        });
    }

    verify_token(){
        let that = this;
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        let tmpl = document.getElementById('counter-party-bank-confirmation-tmpl');
        let rejectTmpl = document.getElementById('counter-party-bank-confirmation-reject-tmpl');
        let successTmpl = document.getElementById('counter-party-bank-confirmation-success-tmpl');
        let target = document.getElementById('cpBankDetailsConfirmationPage');
        if (token){
            Utils.requestHandler('/scf/api/counter_party/' + that.counter_party_id + "/verify-banking-token", Constants.REQUEST.post, {token: token})
            .then(function (response){
                if(response.data.valid_token == true){
                    if (response.data.cp_confirmation_done == true && response.data.allowed == false){
                        //Render Temp Already Submitted
                        let rendered =  Mustache.render(successTmpl.innerHTML, target);
                        target.innerHTML = rendered
                    }else{
                        if (response.data.is_admin == true){
                            //Render Temp Form
                            if (confirm("Confimation done already, Still want to proceed?")) {
                                //Render Temp Form
                                let rendered =  Mustache.render(tmpl.innerHTML, target);
                                target.innerHTML = rendered
                                that.applicationDetailsHandler();
                                that.BankingPanelHandler();
                                that.toggleConsentValue();
                            } else {
                                window.close();
                            }
                        } else {
                            //Render Temp Form
                            let rendered =  Mustache.render(tmpl.innerHTML, target);
                            target.innerHTML = rendered
                            that.applicationDetailsHandler();
                            that.BankingPanelHandler();
                            that.toggleConsentValue();
                        }  
                    }
                }else {
                    //Render Temp Form Invalid Token
                    let rendered =  Mustache.render(rejectTmpl.innerHTML, target);
                    target.innerHTML = rendered
                }
            })
        } else {
            //Render Temp Form Invalid Token
            let rendered =  Mustache.render(rejectTmpl.innerHTML, target);
            target.innerHTML = rendered
        }


    }


    BankingPanelHandler(){
        let that = this;
        that.editBankAccountsPanel();
        that.showBankingModal();
        that.bankingAddBankAccount();
        that.bankingValidateBankAccount();
        that.bankGetIfscDetails();
        that.bankAddBankAccount();
        that.submitCpConfirmation();
      }
    
    editBankAccountsPanel(){
        let that = this;
        Utils.requestHandler("/scf/api/counter_party/" + that.counter_party_id + '/get-bank-accounts', Constants.REQUEST.get, {})
            .then(function (response){
            if(response.message === 'OK'){
                response.data.bank_accounts.forEach(bank_account => {
                    let abs_banking = new AbstractBanking(bank_account.id);
                    abs_banking.account_number = bank_account.account_number;
                    abs_banking.account_type = bank_account.account_type;
                    abs_banking.account_holder = bank_account.primary_account_holder_name;
                    abs_banking.verified_account_holder = bank_account.primary_account_holder_name;
                    abs_banking.ifsc_code = bank_account.ifsc_code;
                    abs_banking.bank_branch = bank_account.branch;
                    abs_banking.bank_name = bank_account.bank_name;
                    abs_banking.bank_micr = bank_account.micr_code;
                    that.bankingSetupButtonIds(abs_banking.id);
                    that.obj_banking.push(abs_banking);
                })
                that.bankRenderVerifiedAccounts();
                that.bankSelectFieldsHandler();
                //   Utils.showToast('Fetched', 'Bank Accounts', Constants.TOAST.success);
                }else{
                //   Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
    }

    showBankingModal(){
        let that = this;
        document.getElementById('btn_show_add_new_bank_account').addEventListener('click', function () {
        var modal = new bootstrap.Modal(document.getElementById('bankingList'), {});
        modal.show();
        document.getElementById('add_bank_ac').classList.remove('no-display');
        document.getElementById('add_bank_ac_pane').classList.add('no-display');
        });
    }

    bankingAddBankAccount(){
        let that = this;
        document.getElementById('add_bank_ac').addEventListener('click', function (){
            let abs_banking = new AbstractBanking(Math.random().toString(36));
            abs_banking.setValues();
            that.bankingSetupButtonIds(abs_banking.id);
            document.querySelectorAll('.btn-banking').forEach(element => {
                element.classList.add('no-display');
            });
            document.getElementById('btn-validate-ifsc').classList.remove('no-display');
            document.getElementById('add_bank_ac_pane').classList.remove('no-display');

            that.obj_banking.push(abs_banking);
            this.classList.add('no-display');
        })
    }

    bankingSetupButtonIds(btn_id){
        let bt1 = document.getElementById('btn-validate-account');
        bt1.setAttribute('data-id', btn_id);
        bt1.classList.add('no-display');
        let bt2 = document.getElementById('btn-submit-account');
        bt2.setAttribute('data-id', btn_id);
        bt1.classList.add('no-display');
        let bt3 = document.getElementById('btn-validate-ifsc')
        bt3.setAttribute('data-id', btn_id);
        bt3.classList.remove('no-display');
    }

    bankingValidateBankAccount(){
        let that = this;
        document.getElementById('btn-validate-account').addEventListener('click', function(){
            let abs_banking = null;
            let elm_btn = this;
            let active_id = this.getAttribute('data-id');
            if(!active_id){
                Utils.showToast("Search", "Error finding bank account", Constants.TOAST.fail);
            }
            that.obj_banking.forEach(function (abs_bank){
                if(abs_bank.id === active_id){
                    abs_banking = abs_bank;
                    return false;
                }
            })
            abs_banking.getValues();
            if(abs_banking.validateValues()){
                let body = {
                    bank_ac_no: abs_banking.account_number,
                    bank_ifsc: abs_banking.ifsc_code
                }
                Utils.loaderToast(Constants.ELEMENT_STATE.show);
                Utils.requestHandler("/scf/api/counter_party/" + that.counter_party_id + "/validate/bank-account", Constants.REQUEST.post, body)
                        .then(function (response){
                            if(response.message === 'OK'){
                                if(response.data.status === 'active'){
                                    abs_banking.verified_account_holder = response.data.bank_ac_holder_name;
                                    abs_banking.setValues();
                                    document.querySelectorAll('.btn-banking').forEach(element => {
                                        element.classList.add('no-display');
                                    });
                                    document.getElementById('btn-submit-account').classList.remove('no-display');
                                    Utils.showToast(response.message, 'Bank account validated', Constants.TOAST.success);
                                }else{
                                    abs_banking.verified_account_holder = abs_banking.account_holder;
                                    abs_banking.setValues();
                                //   elm_btn.classList.add('no-display');
                                //   document.getElementById('btn-submit-account').classList.remove('no-display');
                                    Utils.showToast("Inactive", "Bank account is inactive", Constants.TOAST.fail);
                                }
                            } else{
                                Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                            }
                        }).then(function (){
                            Utils.loaderToast(elm_btn, Constants.ELEMENT_STATE.hide);
                        })
            }            
        })
    }

    bankGetIfscDetails(){
        let that = this;
        document.getElementById('btn-validate-ifsc').addEventListener('click', function (){
            let abs_banking = null;
            let btn_elem = this;
            let active_id = this.getAttribute('data-id');
            if(!active_id){
                Utils.showToast("Search", "Error finding bank account", Constants.TOAST.fail);
            }
            that.obj_banking.forEach(function (abs_bank){
                if(abs_bank.id === active_id){
                    abs_banking = abs_bank;
                    return false;
                }
            })
            abs_banking.getValues();
            Utils.loaderToast(Constants.ELEMENT_STATE.show);
            Utils.requestHandler("/scf/api/counter_party/validate/bank-ifsc", Constants.REQUEST.get, {bank_ifsc: abs_banking.ifsc_code})
                .then(function (response){
                    if(response.message === 'OK'){
                        abs_banking.ifsc_code = response.data.ifsc_data.ifsc;
                        abs_banking.bank_micr = response.data.ifsc_data.micr;
                        abs_banking.bank_name = response.data.ifsc_data.bank_name;
                        abs_banking.bank_branch = response.data.ifsc_data.branch + ", " + response.data.ifsc_data.city + ", " + response.data.ifsc_data.state;
                        Utils.setter(abs_banking.elem_ifsc_code, abs_banking.ifsc_code);
                        Utils.setter(abs_banking.elem_bank_micr, abs_banking.bank_micr);
                        Utils.setter(abs_banking.elem_bank_name, abs_banking.bank_name);
                        Utils.setter(abs_banking.elem_bank_branch, abs_banking.bank_branch);
                        Utils.setter(abs_banking.elem_bank_branch_full, abs_banking.bank_branch);
                        document.querySelectorAll('.btn-banking').forEach(element => {
                            element.classList.add('no-display');
                        });
                        document.getElementById('btn-validate-account').classList.remove('no-display');                        
                        Utils.showToast(response.message, 'IFSC Details fetched', Constants.TOAST.success);
                    }else{
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                }).then(function (){
                    Utils.loaderToast(Constants.ELEMENT_STATE.hide);
            })
        })
    }

    bankAddBankAccount(){
        let that = this;
        document.getElementById('btn-submit-account').addEventListener('click', function (){
            let abs_banking = null;
            let active_id = this.getAttribute('data-id');
            if(!active_id){
                Utils.showToast("Search", "Error finding bank account", Constants.TOAST.fail);
            }
            that.obj_banking.forEach(function (abs_bank){
                if(abs_bank.id === active_id){
                    abs_banking = abs_bank;
                    return false;
                }
            })
            let body = {
                "bank_ifsc": abs_banking.ifsc_code,
                "bank_name": abs_banking.bank_name,
                "holder_name": abs_banking.verified_account_holder,
                "bank_branch": abs_banking.bank_branch,
                "bank_ac_type": abs_banking.account_type,
                "bank_ac_no": abs_banking.account_number
            }

            Utils.requestHandler("/scf/api/counter_party/" + that.counter_party_id + "/bank-account", Constants.REQUEST.post, body)
                .then(function (response){
                    if(response.message === 'OK'){
                    document.getElementById('add_bank_ac_pane').classList.add('no-display');
                    document.getElementById('add_bank_ac').classList.remove('no-display');
                    that.obj_banking = [];
                    that.editBankAccountsPanel();
                    let closeModalButton = document.getElementById("btn-banking-modal-close"); 
                    closeModalButton.click();
                    Utils.showToast(response.message, 'Bank Details added', Constants.TOAST.success);
                    }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                })
        })
    }
    
    bankRenderVerifiedAccounts(){
        let that = this;
        let temp = document.getElementById('banking-tr-tmpl-business-details');
        let ac_table = document.getElementById('verified_accounts_business_details');
        ac_table.innerHTML = null;
        this.obj_banking.forEach(function (abs_banking){
            if(abs_banking.account_holder != null && abs_banking.account_number != null){
                let rend_row = Mustache.render(temp.innerHTML, abs_banking);
                ac_table.append($.parseHTML(rend_row)[1]);
            }
        })
    
    }

    applicationDetailsHandler(){
        let that = this;
        Utils.requestHandler('/scf/api/counter_party/' + that.counter_party_id + "/cp-details-confirmation", Constants.REQUEST.get, {})
            .then(function (response){
                if(response.message === 'OK'){
                    if (response.data.product != "purchase_invoice_discounting"){
                        document.getElementById('invitation_message_cp_banking').classList.remove('no-display');
                        document.getElementById('cp_escrow_consent_section').classList.remove('no-display');
                    }
                    // that.cp_name.innerHTML = response.data.scf_business_applicant.name;
                }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
    }

    bankSelectFieldsHandler(){
        let selectedCheckboxId = '';
        let that = this;
        document.querySelectorAll('.cp_bank_select_fields').forEach(function(checkbox) {
            checkbox.addEventListener('change', function() {
            if (this.checked) {
                selectedCheckboxId = this.getAttribute('data-id');
                that.updateHiddenField(selectedCheckboxId);
                that.unselectAllCheckboxes(this.getAttribute('id'));
            } else {
                selectedCheckboxId = '';
                that.updateHiddenField(selectedCheckboxId);
            }
            });
        });
    }

    unselectAllCheckboxes(currentId) {
        document.querySelectorAll('.cp_bank_select_fields').forEach(function(checkbox) {
        if (checkbox.getAttribute('id') !== currentId) {
        checkbox.checked = false;
        }
    });
    }

    updateHiddenField(id) {
    document.getElementById('cp_bank_account_id').value = id;
    }

    validateValues(){
        // Select all checkboxes with id starting with "cp_bank_select_"
        var checkboxes = document.querySelectorAll('.cp_bank_select_fields')
        // Select the checkbox with id "cp_escrow_consent"
        var escrowCheckbox = document.getElementById('cp_escrow_consent');
        
        // Check if at least one checkbox is checked
        var atLeastOneSelected = Array.from(checkboxes).some(function(checkbox) {
            return checkbox.checked;
        });
        
        // Check if the "cp_escrow_consent" checkbox is checked
        var escrowSelected = escrowCheckbox.checked;
        
        // Return true if at least one checkbox is selected and the "cp_escrow_consent" checkbox is also selected
        return atLeastOneSelected ;
    }


    submitCpConfirmation(){
        let that = this;
        document.getElementById('btn-submit-cp-confirmation').addEventListener('click', function (){
            if (!that.validateValues()) {
                Utils.showToast('Validation Error', 'Please select Bank Account and give Consent', Constants.TOAST.fail);
                return;
            }

            let selectedCheckboxId = document.getElementById('cp_bank_account_id').value;
            let consentCheckboxValue = document.getElementById('cp_escrow_consent').value;
            let body = {
                cp_bank_account_id: selectedCheckboxId,
                ctype: consentCheckboxValue,
                user_agent: window.navigator.userAgent
            }
            let successTmpl = document.getElementById('counter-party-bank-confirmation-success-tmpl');
            let target = document.getElementById('cpBankDetailsConfirmationPage');
            Utils.requestHandler('/scf/api/counter_party/' + that.counter_party_id + '/submit-cp-confirmation', Constants.REQUEST.post, body)
                .then(function (response){
                    if(response.message === 'OK'){
                        //Render Temp Already Submitted
                        let rendered =  Mustache.render(successTmpl.innerHTML, target);
                        target.innerHTML = rendered
                        Utils.showToast(response.message, 'Counter Party Confirmation submitted', Constants.TOAST.success);
                    }else{
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                })
        })


    }

}

class AbstractBanking {

    constructor(id){
        this.id = id;
        this.account_number = null;
        this.account_type = null;
        this.account_holder = null;
        this.ifsc_code = null;
        this.bank_branch = null;
        this.bank_name = null;
        this.bank_micr = null;
        this.verified_account_holder = null;
        this.elem_account_number = document.getElementById('account_number');
        this.elem_account_holder = document.getElementById('account_holder');
        this.elem_account_type = document.getElementById('account_type');
        this.elem_ifsc_code = document.getElementById('ifsc_code');
        this.elem_bank_branch = document.getElementById('bank_branch');
        this.elem_bank_name = document.getElementById('bank_name');
        this.elem_bank_micr = document.getElementById('bank_micr');
        this.elem_verified_account_holder = document.getElementById('verified_account_holder');
        this.elem_verified_account_holder_name = document.getElementById('account_holder');
        this.elem_bank_branch_full = document.getElementById('bank_branch_full');
    }
  
    getValues(){
        this.account_number = Utils.getter(this.elem_account_number);
        this.account_type = Utils.getter(this.elem_account_type);
        this.account_holder = Utils.getter(this.elem_account_holder);
        this.ifsc_code = Utils.getter(this.elem_ifsc_code);
        this.bank_branch = Utils.getter(this.elem_bank_branch);
        this.bank_name = Utils.getter(this.elem_bank_name);
        this.bank_micr = Utils.getter(this.elem_bank_micr);
        this.account_type = Utils.getter(this.elem_account_type);
        this.verified_account_holder = Utils.getter(this.elem_verified_account_holder);
    }
  
    setValues() {
        Utils.setter(this.elem_account_number, this.account_number);
        Utils.setter(this.elem_account_type, this.account_type);
        Utils.setter(this.elem_account_holder, this.account_holder);
        Utils.setter(this.elem_ifsc_code, this.ifsc_code);
        Utils.setter(this.elem_bank_branch, this.bank_branch);
        Utils.setter(this.elem_bank_name, this.bank_name);
        Utils.setter(this.elem_bank_micr, this.bank_micr);
        Utils.setter(this.elem_account_type, this.account_type);
        Utils.setter(this.elem_verified_account_holder, this.verified_account_holder);
        Utils.setter(this.elem_verified_account_holder_name, this.verified_account_holder);
        Utils.setter(this.elem_bank_branch_full, this.bank_branch);
    }
  
    validateValues(){
        return true;
    }
  }