import {Utils, DocumentUtils} from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from 'mustache';
import ScfOnbPanelDocument from './scf_onb_panel_document';
import ScfUdyamPanel from "./scf_udyam_panel";
import ScfBankingModule from "./scf_onb_panel_banking";
import ScfPromoterModule from "./scf_onb_panel_promoter";
import ScfPreApproval from "./scf_pre_approval_panel";
import ScfOnbPanelCounterParty from "./scf_onb_panel_counterparty";
import Moment from "moment";
import { ScfPermission } from "../scf_auth_handler";


class AbstractUser{

    constructor() {
        this.user_id = null;
        this.email = null;
        this.mobile = null;
        this.first_name = null;
        this.last_name = null;
        this.elem_email = document.getElementById('user_email');
        this.elem_mobile = document.getElementById('user_mobile');
        this.elem_mobile_otp = document.getElementById('user_mobile_otp');
        this.elem_email_otp = document.getElementById('user_email_otp');
        this.elem_pasword = document.getElementById('user_password');
    /*  this.elem_pasword.addEventListener('input', function (){
            Utils.reInput(this);
        })
        this.elem_mobile.addEventListener('input', function (){
            Utils.reInput(this);
        })
        this.elem_mobile_otp.addEventListener('input', function (){
            Utils.reInput(this);
        })
        this.elem_email.addEventListener('input', function (){
            Utils.reInput(this);
        })
        this.elem_email_otp.addEventListener('input', function (){
            Utils.reInput(this);
        })
    */    
    }

    getValues(){
        this.email = Utils.getter(this.elem_email) == null ? "" : Utils.getter(this.elem_email).toLowerCase();
        this.mobile = Utils.getter(this.elem_mobile);
        this.first_name = Utils.capitalizeFirstLetter(Utils.getter(this.elem_mobile_otp));
        this.last_name = Utils.capitalizeFirstLetter(Utils.getter(this.elem_email_otp));
        this.name_of_org = Utils.getter(this.elem_pasword);
    }

    setValues(){
        Utils.setter(this.elem_email, this.email);
        Utils.setter(this.elem_mobile, this.mobile);
        Utils.setter(this.elem_mobile_otp, this.first_name);
        Utils.setter(this.elem_email_otp, this.last_name);
        Utils.setter(this.elem_pasword, this.name_of_org);
        return true;
    }

    validateValues() {
        let pass = true;

        if(!this.email){
            Utils.showInputError(this.elem_email, 'Email is mandatory');
            pass = false;
        }else if(!Utils.validatorEmail(this.email)){
            Utils.showInputError(this.elem_email, 'Incorrect email');
            pass = false;
        }
        if(!this.mobile){
            Utils.showInputError(this.elem_mobile, 'Mobile is mandatory');
            pass = false;
        }else if(!Utils.validatorMobile(this.mobile)){
            Utils.showInputError(this.elem_mobile, 'Incorrect mobile number');
            pass = false;
        }
        if(!this.first_name){
            Utils.showInputError(this.elem_mobile_otp, 'First name is mandatory');
            pass = false;
        }else if(!Utils.validatorText(this.first_name)){
            Utils.showInputError(this.elem_mobile_otp, 'Incorrect first name');
            pass = false;
        }
        if(!this.last_name){
            Utils.showInputError(this.elem_email_otp, 'Last name is mandatory');
            pass = false;
        }else if(!Utils.validatorText(this.last_name)){
            Utils.showInputError(this.elem_email_otp, 'Incorrect last name');
            pass = false;
        }
        if(!this.name_of_org){
            Utils.showInputError(this.elem_pasword, 'Organization name is mandatory');
            pass = false;
        }
        return pass;
    }

}

class AbstractPan{

    constructor() {
        this.company_pan = null;
        this.company_pan_type = null;
        this.company_name = null;
        this.elem_company_pan = document.getElementById('company_pan');
        this.elem_company_pan_type = document.getElementById('company_pan_type');
        this.elem_company_name = document.getElementById('company_name');
        this.elem_company_pan.addEventListener('input', function (){
            Utils.reInput(this);
        })
        this.elem_company_pan_type.addEventListener('change', function (){
            Utils.reInput(this);
        })
    }

    getValues(){
        this.company_pan = Utils.getter(this.elem_company_pan).toUpperCase();
        this.company_pan_type = Utils.getter(this.elem_company_pan_type);
        this.company_name = Utils.getter(this.elem_company_name);
    }

    setValues(){
        Utils.setter(this.elem_company_pan, this.company_pan);
        Utils.setter(this.elem_company_pan_type, this.company_pan_type);
        Utils.setter(this.elem_company_name, this.company_name);
        return true;
    }

    validateValues() {
        let pass = true;
        if(!this.company_pan_type){
            Utils.showInputError(this.elem_company_pan_type, 'Constitution is mandatory');
            pass = false;
        }
        if(!this.company_pan){
            Utils.showInputError(this.elem_company_pan, 'PAN is mandatory');
            pass = false;
        }

        if(this.company_pan && this.company_pan_type){
            if(this.company_pan_type === 'sole_proprietorship' && this.company_pan[3] !== 'P'){
                Utils.showInputError(this.elem_company_pan, 'Not a valid PAN for this constitution');
                pass = false;
            }else if(['private_ltd', 'public'].includes(this.company_pan_type) && this.company_pan[3] !== 'C'){
                Utils.showInputError(this.elem_company_pan, 'Not a valid PAN for this constitution');
                pass = false;
            }else if(['llp', 'partnership'].includes(this.company_pan_type) && this.company_pan[3] !== 'F'){
                Utils.showInputError(this.elem_company_pan, 'Not a valid PAN for this constitution');
                pass = false;
            }
        }
        return pass;
    }    
}

class AbstractMCA {
    constructor() {
        this.company_name = null;
        this.cin_or_llp_id = null;
        this.company_type = null;
        this.date_of_inc = null;
        this.company_address = null;
        this.company_city = null;
        this.company_state = null;
        this.company_zipcode = null;
        this.roc_code = null;
        this.company_email = null;
        this.elem_srch_company_cin = document.getElementById('srch_company_cin')
        this.elem_srch_company_name = document.getElementById('srch_company_name')
        this.elem_srch_company_list = document.getElementById('srch_company_list')
        this.elem_company_name = document.getElementById('mca_company_name')
        this.elem_cin_or_llp_id = document.getElementById('cin_or_llp_id')
        this.elem_company_type =  document.getElementById('company_type')
        this.elem_date_of_inc =  document.getElementById('date_of_inc')
        this.elem_company_address = document.getElementById('company_address')
        this.elem_company_city = document.getElementById('company_city')
        this.elem_company_state = document.getElementById('company_state')
        this.elem_roc_code = document.getElementById('roc_code')
        this.elem_company_zipcode = document.getElementById('company_zipcode')
        this.elem_company_email = document.getElementById('company_email');
        this.elem_select_company_modal = new bootstrap.Modal(document.getElementById('select_company_modal'), {});
    }

    getValues(){
        this.cin_or_llp_id = Utils.getter(this.elem_cin_or_llp_id).toUpperCase();
        this.company_name = Utils.getter(this.elem_company_name);
        this.company_type = Utils.getter(this.elem_company_type);
        this.date_of_inc = Utils.getter(this.elem_date_of_inc);
        this.company_address = Utils.getter(this.elem_company_address);
        this.company_city = Utils.getter(this.elem_company_city);
        this.company_state = Utils.getter(this.elem_company_state);
        this.company_zipcode = Utils.getter(this.elem_company_zipcode);
        this.roc_code = Utils.getter(this.elem_roc_code);
        this.company_email = Utils.getter(this.elem_company_email);
    }

    setValues(){
        Utils.setter(this.elem_company_name, this.company_name);
        Utils.setter(this.elem_cin_or_llp_id, this.cin_or_llp_id);
        Utils.setter(this.elem_company_type, this.company_type);
        Utils.setter(this.elem_date_of_inc, this.date_of_inc);
        Utils.setter(this.elem_company_address, this.company_address);
        Utils.setter(this.elem_company_city, this.company_city);
        Utils.setter(this.elem_company_state, this.company_state);
        Utils.setter(this.elem_company_zipcode, this.company_zipcode);
        Utils.setter(this.elem_roc_code, this.roc_code);
        Utils.setter(this.elem_company_email, this.company_email);
        return true;
    }

}

class AbstractQuestion {

    constructor(){
        this.quest_values = {};
    }

    getValues(){
       let that = this;
       let all_quest = document.getElementsByClassName('questions-onb');
       [...all_quest].forEach(element => {
           that.quest_values[element.getAttribute('data-label')] = element.value
       });
       return true;
    }

    setValues(){
        let that = this;
        let all_quest = document.getElementsByClassName('questions-onb');
        [...all_quest].forEach(element => {
            Utils.setter(element, that.quest_values[element.getAttribute('data-label')]);
        });
        return true;
    }

    validateValues(){
        let pass = true;
        let that = this;
        let pan = localStorage.getItem('pan');
        let all_quest = null;
        if (pan[3] == 'C') {
          all_quest = document.querySelectorAll('#mca-tab .questions-onb');
        }else{
          all_quest = document.querySelectorAll('#gst-tab .questions-onb');
        }
        // let all_quest = document.getElementsByClassName('questions-onb');
        [...all_quest].forEach(element => {
            if(!element.value){
                function toggleErrorMessage() {
                    let value = element.value;
                    if (!value) {
                        Utils.showInputError(element, 'Field is mandatory');
                    } else {
                        Utils.hideInputError(element);
                    }
                }
                toggleErrorMessage();
                element.addEventListener('input', toggleErrorMessage);
                pass = false;
            }else if(element.getAttribute('data-label') == 'avg_monthly_sales' && !Utils.validatorNumber(element.value)) {
              Utils.showInputError(element, 'Invalid number');
              pass = false;
            }
        });
        return pass
    }
}

class AbstractApplication{
    constructor() {
        this.required_amount = 0;
        this.required_tenure = null;
        this.product = null;
        this.required_amount_head = 0;
        this.elem_required_tenure =  document.getElementById('application_tenure_required');
        this.elem_required_amount_range = document.getElementById('required_amount');
        // this.elem_required_amount_head = document.getElementById('required_amount_head');
        this.elem_application_product = document.getElementById('application_product');
        let that = this;
        // this.elem_required_amount_range.addEventListener('input', function (){
        //     that.elem_required_amount_head.innerText = Utils.indianRepresentation(this.value);
        // })
        this.elem_application_product.addEventListener('input', function (){
            Utils.reInput(this);
        })
        this.elem_required_tenure.addEventListener('input', function (){
            Utils.reInput(this);
        });
        that.productSelectHandler()
    }

    productSelectHandler() {
        document.getElementById('application_product').addEventListener('change', function() {
            let tenureField = document.getElementById('application_tenure_required');
            const selectedProduct = this.value;
            const errorMessage = tenureField.nextElementSibling;
            tenureField.innerHTML = '';
            if (errorMessage && errorMessage.classList.contains('error')) {
                errorMessage.classList.add('no-display');
            }
        
            if (selectedProduct === 'short_term_loan') {
                const inputField = document.createElement('input');
                inputField.type = 'number';
                inputField.className = 'form-control form-control-lg';
                inputField.id = 'application_tenure_required';
                inputField.placeholder = 'Enter tenure';
                inputField.max = 120;
                if (inputField.value === '') {
                    errorMessage.textContent = 'Please enter a value between 1 and 120';
                    errorMessage.classList.remove('no-display');
                }
                // Add event listener for input validation
                inputField.addEventListener('input', function() {
                    const value = parseInt(this.value, 10);
                    if (value > 120 || value <= 0) {
                        errorMessage.textContent = 'Please enter a value between 1 and 120';
                        errorMessage.classList.remove('no-display');
                    } else {
                        errorMessage.classList.add('no-display');
                    }
                });
        
                tenureField.parentNode.replaceChild(inputField, tenureField);
            } else {
                const selectField = document.createElement('select');
                selectField.className = 'form-select form-control-lg';
                selectField.ariaLabel = 'vintage';
                selectField.id = 'application_tenure_required';
                selectField.dataset.label = 'vintage';
        
                const options = [
                    { value: '', text: 'Select an option', disabled: true, selected: true },
                    { value: '0-30 days', text: '0-30 days' },
                    { value: '30-60 days', text: '30-60 days' },
                    { value: '60-90 days', text: '60-90 days' },
                    { value: '> 90 days', text: '> 90 days' }
                ];
        
                options.forEach(optionData => {
                    const option = document.createElement('option');
                    option.value = optionData.value;
                    option.textContent = optionData.text;
                    if (optionData.disabled) option.disabled = true;
                    if (optionData.selected) option.selected = true;
                    selectField.appendChild(option);
                });
                if (selectField.value === '') {
                    errorMessage.textContent = 'Please select an option';
                    errorMessage.classList.remove('no-display');
                }        
                selectField.addEventListener('change', function() {
                    if (this.value === '') {
                        errorMessage.textContent = 'Please select an option';
                        errorMessage.classList.remove('no-display');
                    } else {
                        errorMessage.classList.add('no-display');
                    }
                }, { once: true });
        
                tenureField.parentNode.replaceChild(selectField, tenureField);
            }
        });
    }
    
    getValues(){
        // this.required_amount = Utils.getter(this.elem_required_amount_range);
        this.required_tenure =  document.getElementById('application_tenure_required').value;
        this.required_amount = document.getElementById('required_amount').value;
        this.product = Utils.getter(this.elem_application_product);
    }

    setValues(){
        Utils.setter(this.elem_required_amount_range, this.required_amount);
        Utils.setter(this.elem_application_product, this.product);
        Utils.setter(this.elem_required_tenure, this.required_tenure);
        // this.elem_required_amount_head.innerText = Utils.indianRepresentation(this.required_amount)
        return true;
    }

    validateValues() {
        let pass = true;
        let that = this;
        let requiredAmountInput = document.getElementById('required_amount');
    
        function toggleErrorMessage() {
            let value = parseInt(requiredAmountInput.value, 10);
            if (isNaN(value) || value < 10000 || value > 100000000) {
                document.getElementById('error-required-amount').classList.remove('no-display');
                pass = false;
            } else {
                document.getElementById('error-required-amount').classList.add('no-display');
            }
        }
        requiredAmountInput.addEventListener('input', toggleErrorMessage);
        toggleErrorMessage();
        if (!this.product) {
            Utils.showInputError(this.elem_application_product, 'Product selection is mandatory');
            pass = false;
        }
        if (!this.required_tenure) {
            Utils.showInputError(this.elem_required_tenure, 'Tenure selection is mandatory');
            pass = false;
        }
        return pass;
    }
}

class AbstractOwner {

    constructor(director_id) {
        this.director_id = director_id;
        this.first_name = null;
        this.last_name = null;
        this.email = null;
        this.mobile = null;
        this.city = null;
        this.state = null;
        this.address = null;
        this.gender = null;
        this.dob = null;
        this.pan = null;
        this.aadhaar = null;
        this.zipcode = null;
        this.elem_mobile_otp = document.getElementById('owner_first_name');
        this.elem_email_otp = document.getElementById('owner_last_name');
        this.elem_email = document.getElementById('owner_email');
        this.elem_mobile = document.getElementById('owner_mobile');
        this.elem_city = document.getElementById('owner_city');
        this.elem_state = document.getElementById('owner_state');
        this.elem_address = document.getElementById('owner_address');
        this.elem_gender = document.getElementById('owner_gender');
        this.elem_dob = document.getElementById('owner_dob');
        this.elem_pan = document.getElementById('owner_pan');
        this.elem_aadhaar = document.getElementById('owner_aadhaar');
        this.elem_zipcode = document.getElementById('owner_zipcode');

        this.elem_mobile_otp.addEventListener('input', function(){
            Utils.reInput(this);
        })

        this.elem_email_otp.addEventListener('input', function(){
            Utils.reInput(this);
        })

        this.elem_email.addEventListener('input', function(){
            Utils.reInput(this);
        })

        this.elem_mobile.addEventListener('input', function(){
            Utils.reInput(this);
        })        

        this.elem_city.addEventListener('input', function(){
            Utils.reInput(this);
        })

        this.elem_state.addEventListener('input', function(){
            Utils.reInput(this);
        })

        this.elem_address.addEventListener('input', function(){
            Utils.reInput(this);
        })

        this.elem_dob.addEventListener('input', function(){
            Utils.reInput(this);
        })

        this.elem_pan.addEventListener('input', function(){
            Utils.reInput(this);
        })

        this.elem_aadhaar.addEventListener('input', function(){
            Utils.reInput(this);
        })        

        this.elem_zipcode.addEventListener('input', function(){
            Utils.reInput(this);
        })
    }

    getValues(){
        this.first_name = Utils.getter(this.elem_mobile_otp);
        this.last_name = Utils.getter(this.elem_email_otp);
        this.email = Utils.getter(this.elem_email);
        this.mobile = Utils.getter(this.elem_mobile);
        this.city =  Utils.getter(this.elem_city);
        this.state =  Utils.getter(this.elem_state);
        this.address = Utils.getter(this.elem_address);
        this.gender =  Utils.getter(this.elem_gender);
        this.dob =  Utils.getter(this.elem_dob);
        this.pan =  Utils.getter(this.elem_pan);
        this.aadhaar = Utils.getter(this.elem_aadhaar);
        this.zipcode = Utils.getter(this.elem_zipcode);
    }

    setValues(){
        Utils.setter(this.elem_mobile_otp, this.first_name);
        Utils.setter(this.elem_email_otp, this.last_name);
        Utils.setter(this.elem_email, this.email);
        Utils.setter(this.elem_mobile, this.mobile);
        Utils.setter(this.elem_city, this.city);
        Utils.setter(this.elem_state, this.state);
        Utils.setter(this.elem_address, this.address);
        Utils.setter(this.elem_gender, this.gender);
        Utils.setter(this.elem_dob, this.dob);
        Utils.setter(this.elem_pan, this.pan);
        Utils.setter(this.elem_aadhaar, this.aadhaar);
        Utils.setter(this.elem_zipcode, this.zipcode);
    }

    validateValues(){
        let result = true;
        if(!this.dob){
            Utils.showInputError(this.elem_dob, 'DOB is mandatory');
            result = false;    
        }
        if(this.dob && (Moment(this.dob) - Moment()) > 0){
            Utils.showInputError(this.elem_dob, 'Date of Birth cannot be in future');
            result = false;
        }
        if(!this.mobile){
            Utils.showInputError(this.elem_mobile, 'Mobile is mandatory');
            result = false;
        }
        if(this.mobile && !Utils.validatorMobile(this.mobile)){
            Utils.showInputError(this.elem_mobile, 'Incorrect mobile number');
            result = false;    
        }
        if(!this.zipcode){
            Utils.showInputError(this.elem_zipcode, 'Zipcode is mandatory');
            result = false;   
        }
        if(!this.city){
            Utils.showInputError(this.elem_city, 'City is mandatory');
            result = false;       
        }
        if(!this.state){
            Utils.showInputError(this.elem_state, 'State is mandatory');
            result = false;           
        }
        if(!this.gender){
            Utils.showInputError(this.elem_gender, 'Gender is mandatory');
            result = false;       
        }
        return result;
    }
}

class AbstractBanking {

    constructor(id){
        this.id = id;
        this.account_number = null;
        this.account_type = null;
        this.account_holder = null;
        this.ifsc_code = null;
        this.bank_branch = null;
        this.bank_name = null;
        this.bank_micr = null;
        this.verified_account_holder = null;
        this.elem_account_number = document.getElementById('account_number');
        this.elem_account_holder = document.getElementById('account_holder');
        this.elem_account_type = document.getElementById('account_type');
        this.elem_ifsc_code = document.getElementById('ifsc_code');
        this.elem_bank_branch = document.getElementById('bank_branch');
        this.elem_bank_name = document.getElementById('bank_name');
        this.elem_bank_micr = document.getElementById('bank_micr');
        this.elem_verified_account_holder = document.getElementById('verified_account_holder');
        this.elem_bank_branch_full = document.getElementById('bank_branch_full');
    }

    getValues(){
        this.account_number = Utils.getter(this.elem_account_number);
        this.account_type = Utils.getter(this.elem_account_type);
        this.account_holder = Utils.getter(this.elem_account_holder);
        this.ifsc_code = Utils.getter(this.elem_ifsc_code);
        this.bank_branch = Utils.getter(this.elem_bank_branch);
        this.bank_name = Utils.getter(this.elem_bank_name);
        this.bank_micr = Utils.getter(this.elem_bank_micr);
        this.account_type = Utils.getter(this.elem_account_type);
        this.verified_account_holder = Utils.getter(this.elem_verified_account_holder);
    }

    setValues() {
        Utils.setter(this.elem_account_number, this.account_number);
        Utils.setter(this.elem_account_type, this.account_type);
        Utils.setter(this.elem_account_holder, this.account_holder);
        Utils.setter(this.elem_ifsc_code, this.ifsc_code);
        Utils.setter(this.elem_bank_branch, this.bank_branch);
        Utils.setter(this.elem_bank_name, this.bank_name);
        Utils.setter(this.elem_bank_micr, this.bank_micr);
        Utils.setter(this.elem_account_type, this.account_type);
        Utils.setter(this.elem_verified_account_holder, this.verified_account_holder);
        Utils.setter(this.elem_bank_branch_full, this.bank_branch);
    }

    validateValues(){
        return true;
    }
}

export class ScfOnboarding{

    APP_MAX_ALLOWED_PANEL = 11;
    POST_APP_MAX_ALLOWED_PANEL = 13;

    constructor() {
        this.max_panel = 0;
        this.applicant_id = null;
        this.application_id = null;
        this.udyam_reg_no = null;
        this.is_edit = false;

        if(Constants.UAT){
            this.max_panel = this.APP_MAX_ALLOWED_PANEL;
            this.application_id = null;
            this.application_id = null;
        }
        this.obj_user = new AbstractUser();
        this.obj_pan = new AbstractPan();
        this.obj_application = new AbstractApplication();
        this.obj_mca = new AbstractMCA();
        this.obj_gst = [];
        this.obj_question = new AbstractQuestion();
        this.obj_owner = [];
        this.obj_banking = [];
        this.application_id = document.getElementById('scf_application_id').value;
        this.elem_pan = document.getElementById('cp_pan');
        this.elem_mobile =  document.getElementById('cp_mobile');
        this.elem_email = document.getElementById('cp_email');
        this.elem_name = document.getElementById('cp_name');
        this.elem_cp_extra_input_fields = document.getElementById('cp_extra_input_fields');
        this.elem_cp_vintage_month = document.getElementById('cp_vintage_month_select');
        this.elem_cp_vintage_year = document.getElementById('cp_vintage_year_select');
        this.elem_cp_contact_person_name = document.getElementById('cp_contact_person_name');
        this.elem_cp_contact_person_designation =  document.getElementById('cp_contact_person_designation');
        this.elem_btn_cp_submit =  document.getElementById('btn-cp-submit-counterparty');
        this.elem_btn_cp_validate =  document.getElementById('btn-cp-validate-counterparty');
        // Utils.pageSanityHandler();
        this.elem_pan.addEventListener('input', function (){
            Utils.reInput(this);
        })
        this.elem_mobile.addEventListener('input', function (){
            Utils.reInput(this);
        })
        this.elem_email.addEventListener('input', function (){
            Utils.reInput(this);
        })
        this.elem_cp_vintage_month.addEventListener('input', function (){
            Utils.reInput(this);
        })
        this.elem_cp_vintage_year.addEventListener('input', function (){
            Utils.reInput(this);
        })
        this.elem_cp_contact_person_designation.addEventListener('input', function (){
          Utils.reInput(this);
        })
        this.elem_cp_contact_person_name.addEventListener('input', function (){
          Utils.reInput(this);
        })
    }

    handler(){
        this.userWisePanelHandler();
        this.editApplicationHandler();
        this.pagesValidator();
        this.changePageBasedOnEdit();
        // this.preApprovalPanelHandler();
        this.panelNavigationHandler();
        this.userPanelHandler();
        this.panPanelHandler();
        this.productPanelHandler();
        this.mcaPanelHandler();
        this.gstPanelHandler();
        this.completeOnboardingHandler();
        this.consenthandler();
        this.ownersPanelHandler();
        
        this.udyamPanelHandler();
        this.documentPanelHandler();
        this.counterPartyPanelHandler();
        this.consentPanelHandler();
        this.sideNavToggleHandler();
        this.businessPromoterDetailsHandler();
        this.setDefaultNavBar();
        this.formCardsToggle();
        this.indicativeOfferPanelHandler();
        this.openModalAddCounterParty();
        this.fillCpVintageField();
    }

    userWisePanelHandler(){
        if (ScfPermission.isScfSales()) {
            document.getElementById("btn-consent-submit").classList.add("no-display");
            document.getElementById("btn_consent_add_skip").classList.remove("no-display");
            document.getElementById("btn-panel-consent").classList.add("no-display");
            document.getElementById("btn-panel-complete").classList.add("no-display");
        }
    }

    changePageBasedOnEdit(){
        if (this.is_edit == true){
          document.getElementById("btn-panel-complete").classList.add("no-display");
          document.getElementById("btn-panel-consent").classList.add("no-display");
          document.getElementById('Build your Application_onb_module').classList.remove('d-none');
        }
    }
        
    

    fillCpVintageField() {  
        let yearSelect = document.getElementById("cp_vintage_year_select");
        let currentYear = new Date().getFullYear();
        for (let i = currentYear; i >= currentYear - 30; i--) {
            let option = document.createElement("option");
            option.text = i;
            option.value = i;
            yearSelect.add(option);
        }
    }

    formCardsToggle(){
        const toggleIcons = document.querySelectorAll('[id$="_card_toggle_icon"]');

        toggleIcons.forEach(icon => {
          icon.addEventListener('click', () => {

            const dynamicId = icon.id.replace('_toggle_icon', '');

            const dynamicElement = document.getElementById(dynamicId);
        

            if (dynamicElement) {
                dynamicElement.classList.toggle('d-none');
            }
        
            icon.classList.toggle('bi-chevron-down');
            icon.classList.toggle('bi-chevron-right');
            });
        });
    }

    pagesValidator(){
        let that = this;
        const panelLinks = document.querySelectorAll('[id^="btn-panel-"]');

        // Add a click event listener to each element
        panelLinks.forEach(link => {
            link.addEventListener('click', function () {
                // Your logic here when a panel link is clicked
                const panelId = link.getAttribute('data-panel'); // You can retrieve the data-panel attribute if needed
                let body = {};
                if (that.application_id !== null && that.application_id !== undefined) {
                    body = {
                        scf_application_id: that.application_id
                    }
                }
                Utils.requestHandler('/scf/api/onboarding/application'  + '/onboarding_status/' + localStorage.getItem('business_applicant_id'), Constants.REQUEST.post, body)
                .then(function (response){
                    if(response.message === 'OK'){
                        const jsonData = response.data.onboarding_status;
                        let firstFalseEncountered = false;
                        let firstFalseElement;
                        
                        for (const key in jsonData) {
                          if (jsonData.hasOwnProperty(key) && key !== 'latest') {
                            const dataPanelNumberId = parseInt(key);
                            const validationStatus = jsonData[key];
                        
                            const liElement = document.querySelector(`[data-panel-number-id="${dataPanelNumberId}"]`);
                            let elementId = "panel_complete_check_" + dataPanelNumberId;

                            const icon = document.getElementById(elementId);
                        
                            if (liElement) {
                              if (!validationStatus) {
                                if (!firstFalseEncountered) {
                                  firstFalseEncountered = true;
                                  firstFalseElement = liElement;
                                  icon.classList.add('d-none');
                                } else {
                                  liElement.classList.add('disabled-link');
                                  icon.classList.add('d-none');
                                }
                              } else {
                                liElement.classList.remove('disabled-link');
                                icon.classList.remove('d-none');
                              }
                            }
                          }
                        }
                        
                        // Remove the class from the first false element as an exception
                        if (firstFalseElement) {
                          firstFalseElement.classList.remove('disabled-link');
                        }
                    }
                    else{
                        
                        // Utils.showToast(response.message, "Please Add Atleast One Counter Party", Constants.TOAST.fail);
                    }
                })
            });
        });
    }



  businessPromoterDetailsHandler() {
    this.tempFeedData();
    this.tempUdyamSearch();
    this.tempUdyamAddressCopyPaste();
    this.populateNameFromPan();
    this.populateCityStateFromPincode();
    this.promoterDataSubmit();
    this.communicationAddressSubmit();
    this.populateTitleFromPanType();
    this.uploadDocumentsSubmit();
    this.bussinessAndPromotersSubmit();
    this.submitcpOnboarding();
    this.populateTitleRelationShipwith();
    this.populateRelationship();
  }

  populateTitleRelationShipwith() {
    const titleRelationShipwith = document.getElementById("titleRelationShipwith");
    const relationships = ["","Father", "Mother", "Son", "Daughter", "Sister", "Brother", "Husband", "Wife", "Others"];
    
    titleRelationShipwith.innerHTML = ""; 

    relationships.forEach((relationship) => {
        const option = document.createElement("option");
        option.text = relationship;
        option.value = relationship.toLowerCase().replace(' ', '_');

        titleRelationShipwith.add(option);
    });
}

    populateRelationship(){
        document.getElementById('titleOfBorrower').addEventListener('change', function(){
            let title_of_borrower = document.getElementById('titleOfBorrower').value;
            if(title_of_borrower==='CO APPLICANT'){
                document.getElementById('relationshipHide').classList.remove("no-display");
                document.getElementById('labelPromoterPan').classList.add("mt-4");
            }
            else{
                document.getElementById('relationshipHide').classList.add("no-display");
                document.getElementById('labelPromoterPan').classList.remove("mt-4");
            }
        })
    }

  populateTitleFromPanType() {
    Utils.requestHandler("/scf/api/applicant/" + localStorage.getItem('business_applicant_id'), Constants.REQUEST.get, {}).then(function (response) {
        const constitution = response.data.scf_applicant['constitution'];
        const titleOfBorrower = document.getElementById("titleOfBorrower");        const titleOptionsMap = {
            "partnership": ["Partner", "Authorized Signatory","Co applicant"],
            "llp": ["Partner", "Authorized Signatory","Co applicant"],
            "private_limited": ["Director","Authorized Signatory","Co applicant"],
            "public_company": ["Director", "Authorized Signatory","Co applicant"],
            "sole_proprietorship": ["Proprietor","Co applicant"]
        };
        titleOfBorrower.innerHTML = "";
        if (constitution in titleOptionsMap) {
            titleOptionsMap[constitution].forEach((optionText) => {
                const option = document.createElement("option");
                option.text = optionText;
                switch(optionText) {
                    case "Director":
                        option.value = "DIRECTOR";
                        break;
                    case "Co applicant":
                        option.value = "CO APPLICANT";
                        break;
                    case "Proprietor":
                        option.value = "PROPRIETOR";
                        break;
                    case "Partner":
                        option.value = "PARTNER";
                        break;
                    case "Authorized Signatory":
                        option.value = "AUTHORIZED_SIGNATORY";
                        break;
                    default:
                        option.value = "";
                        break;
                }
                titleOfBorrower.add(option);
            });
        }
        if(constitution=="sole_proprietorship"){
            document.getElementById("promotershareholding").classList.add("no-display");
            document.getElementById("label_share_holding").classList.add("no-display");
        }
    });
  }

  setDefaultNavBar() {
    let button = document.getElementById(["Check Your Eligibility_sidenav_toggle_parent"]);
    let button2 = document.getElementById(["Build your Application_sidenav_toggle_parent"]);
    button.click();
    button2.click();
    let button_gst = document.getElementById("btn-panel-gst");
    button_gst.click();
  }

    communicationAddressSubmit(){
        const communicationAddress = document.getElementById("communication-address-save");
        communicationAddress.addEventListener('click', function () {
            const address = document.getElementById("businessContactAddress").value;
            const pincode = document.getElementById("communicationZipcode").value;
            const city = document.getElementById("communicationCity").value;
            const state = document.getElementById("communicationState").value;
            const primaryAddress = document.getElementById("primaryGstAddress").value;
            const primaryCity = document.getElementById("primaryGstCity").value;
            const primaryPincode = document.getElementById("primaryGstZipcode").value;
            const primaryState = document.getElementById("primaryGstState").value;
            const application_gst = document.getElementById("gstNumberUdyamPage").value;
            let bankAccountSection = document.getElementById("communication-address-save");

            // Create a data object to send to the API
            let body = {
                communication_address: address,
                communication_pincode: pincode,
                communication_city: city,
                communication_state: state,
                gst_number: application_gst
            };

            if (primaryAddress !== null && primaryCity !== null && primaryPincode !== null && primaryState !== null) {
                Utils.requestHandler('/scf/api/onboarding/application/' + document.getElementById('scf_application_id').value +'/business-details', Constants.REQUEST.post, body)
                .then(function (response){
                    if(response.message === 'OK'){
                        Utils.showToast('Created', 'Communication Address Created.', Constants.TOAST.success);
                        bankAccountSection.scrollIntoView({ behavior: 'smooth' });
                    }else{
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                })
            } else {
                Utils.showToast('Error', 'Primary Address is not complete.', Constants.TOAST.fail);
            }
        });

    }
    uploadDocumentsSubmit(){
        let that = this;
        const uploadAllDocuments = document.getElementById("upload-all-documents");
        uploadAllDocuments.addEventListener('click', function () {
            Utils.requestHandler('/scf/api/onboarding/application/' + document.getElementById('scf_application_id').value +'/validate_documents', Constants.REQUEST.get)
            .then(function (response){
                if(response.message === 'OK'){
                    if (ScfPermission.isScfSales() || that.is_edit == true) {
                     document.getElementById('btn-panel-onb-complete').click();
                    } else {
                        that.proceedNext();
                    }
                    
                    Utils.showToast('Success', 'All Documents Submitted', Constants.TOAST.success);
                }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
        });

    }
    openModalAddCounterParty(){
        this.cp_modal = new bootstrap.Modal(document.getElementById('modal_cp_add'), {});    
        document.getElementById('btn_cp_add_modal_counterparty').addEventListener('click', () => {
            this.cp_modal.show();
            this.resetCpForm(); 
            this.elem_pan.removeAttribute('readonly');
            this.elem_mobile.removeAttribute('readonly');
            this.elem_email.removeAttribute('readonly');
            this.elem_cp_extra_input_fields.classList.add('d-none');
            this.elem_btn_cp_submit.classList.add('no-display');
            this.elem_btn_cp_validate.classList.remove('no-display');
            this.elem_name.innerHTML = '-'
            this.elem_pan.value = '';
            this.elem_mobile.value = '';
            this.elem_email.value = '';
            this.elem_cp_vintage_year.value = '';
            this.elem_cp_vintage_month.value = '';
            this.elem_cp_contact_person_name.value = '';
            this.elem_cp_contact_person_designation.value = '';
            this.elem_pan.classList.remove('back-grey');
            this.elem_mobile.classList.remove('back-grey');
        });
        this.createCp();
        this.getNameFromPan();
        this.skipMethod();
    }
    resetCpForm() {    
        if (this.elem_pan instanceof HTMLInputElement) this.elem_pan.removeAttribute('readonly');
        if (this.elem_mobile instanceof HTMLInputElement) this.elem_mobile.removeAttribute('readonly');
        if (this.elem_email instanceof HTMLInputElement) this.elem_email.removeAttribute('readonly');
        if (this.elem_cp_extra_input_fields instanceof HTMLElement) this.elem_cp_extra_input_fields.classList.add('d-none');
        if (this.elem_btn_cp_submit instanceof HTMLElement) this.elem_btn_cp_submit.classList.add('no-display');
        if (this.elem_btn_cp_validate instanceof HTMLElement) this.elem_btn_cp_validate.classList.remove('no-display');
        if (this.elem_name instanceof HTMLElement) this.elem_name.innerHTML = '-';
        if (this.elem_pan instanceof HTMLInputElement) this.elem_pan.value = '';
        if (this.elem_mobile instanceof HTMLInputElement) this.elem_mobile.value = '';
        if (this.elem_email instanceof HTMLInputElement) this.elem_email.value = '';
        if (this.elem_cp_vintage_year instanceof HTMLInputElement) this.elem_cp_vintage_year.value = '';
        if (this.elem_cp_vintage_month instanceof HTMLInputElement) this.elem_cp_vintage_month.value = '';
        if (this.elem_cp_contact_person_name instanceof HTMLInputElement) this.elem_cp_contact_person_name.value = '';  // Reset contact person name
        if (this.elem_cp_contact_person_designation instanceof HTMLInputElement) this.elem_cp_contact_person_designation.value = '';  // Reset contact person designation
        if (this.elem_pan instanceof HTMLElement) this.elem_pan.classList.remove('back-grey');
        if (this.elem_mobile instanceof HTMLElement) this.elem_mobile.classList.remove('back-grey');
    }   
    skipMethod(){
        let that = this;
        const skipBtn = document.getElementById("btn_cp_add_skip");
        skipBtn.addEventListener('click', function () {
            that.proceedNext();
        })
    }
    getNameFromPan(){
        let that = this;
        let elem_btn_cp_submit =  document.getElementById('btn-cp-submit-counterparty');
        document.getElementById('btn-cp-validate-counterparty').addEventListener('click', function (){
            let elem_btn = this;
            Utils.buttonWithLoader(elem_btn, Constants.ELEMENT_STATE.show);
            let body = {
                pan: that.elem_pan.value,
                scf_application_id: document.getElementById("scf_application_id").value
            }
            Utils.requestHandler('/scf/api/counter_party/name', Constants.REQUEST.post, body)
                .then(function (response){
                    if(response.message =='OK'){
                        that.elem_name.setAttribute('data-name', response.data.full_name);
                        that.elem_name.innerHTML = response.data.full_name;
                        that.elem_cp_extra_input_fields.classList.remove('d-none');  
                        if(that.elem_pan.value){
                            that.elem_pan.setAttribute('readonly',true);
                            that.elem_pan.classList.add('readonly-input');  
                        }
                        Utils.buttonWithLoader(elem_btn,  Constants.ELEMENT_STATE.hide);
                        elem_btn.classList.add('no-display');
                        that.elem_btn_cp_submit.classList.remove('no-display');
                        if (response.data.applicant_present == true){
                            that.elem_mobile.value = response.data.mobile;
                            that.elem_email.value = response.data.email;
                            that.elem_mobile.setAttribute('readonly',true);
                            that.elem_email.setAttribute('readonly',true);
                            that.elem_email.classList.add('back-grey');
                            that.elem_mobile.classList.add('back-grey');
                        } 
                        Utils.showToast('Fetched', 'Pan fetched successfully', Constants.TOAST.success);
                    }else{
                        Utils.buttonWithLoader(elem_btn,  Constants.ELEMENT_STATE.hide);
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                })
                .then(function (){
                    
                })
   
        })
    }
 
    createCp() {
        let that = this;
        let elem_btn_cp_submit = document.getElementById('btn-cp-submit-counterparty');
        let elem_btn_cp_validate = document.getElementById('btn-cp-validate-counterparty');
    
        elem_btn_cp_submit.addEventListener('click', function() {
            let elem_btn = this;
            if (that.validateValues()) {
                Utils.buttonWithLoader(elem_btn, Constants.ELEMENT_STATE.show);
    
                let body = {
                    pan: that.elem_pan.value,
                    pan_name: that.elem_name.getAttribute('data-name'),
                    scf_application_id: that.application_id,
                    email: that.elem_email.value,
                    mobile: that.elem_mobile.value,
                    vintage_month: that.elem_cp_vintage_month.value,
                    vintage_year: that.elem_cp_vintage_year.value,
                    contact_person_name: that.elem_cp_contact_person_name.value || "",
                    contact_person_designation: that.elem_cp_contact_person_designation.value || ""
                };    
                Utils.requestHandler('/scf/api/counter_party', Constants.REQUEST.post, body)
                    .then(function(response) {
                        if (response.message === 'OK') {
                            if (that.elem_pan instanceof HTMLInputElement) that.elem_pan.removeAttribute('readonly');
                            if (that.elem_name instanceof HTMLElement) that.elem_name.innerHTML = '-';
                            if (that.elem_pan instanceof HTMLInputElement) that.elem_pan.value = '';
                            if (that.elem_mobile instanceof HTMLInputElement) that.elem_mobile.value = '';
                            if (that.elem_email instanceof HTMLInputElement) that.elem_email.value = '';
                            if (that.elem_cp_contact_person_name instanceof HTMLInputElement) that.elem_cp_contact_person_name.value = '';
                            if (that.elem_cp_contact_person_designation instanceof HTMLInputElement) that.elem_cp_contact_person_designation.value = '';
    
                            Utils.showToast('Created', 'Counterparty created', Constants.TOAST.success);
                            that.cp_modal.hide();
                            that.renderCpRowsInTable();
                        } else {
                            console.error("Error creating counterparty:", response);
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    })
                    .then(function() {
                        Utils.buttonWithLoader(elem_btn_cp_submit, Constants.ELEMENT_STATE.hide);
                        elem_btn_cp_submit.classList.add('no-display');
                        elem_btn_cp_validate.classList.remove('no-display');
                    });
            }
        });
    }
    
    

    renderCpRowsInTable(){
        let that = this;
        Utils.requestHandler('/scf/api/application/'+that.application_id+'/counter-parties', Constants.REQUEST.get)
        .then(function (response){
            if(response.message === 'OK'){
                let tmpl = document.getElementById('cp-row-tmpl');
                let dx = document.getElementById('tb-cp-onboarding-tbody');
                dx.innerHTML = null;
                response.data.counter_parties.forEach(function (dd){
                    dd.status = Utils.snakeToTitleCase(dd.status);
                    dd.vintage = Utils.formatTimeRelative(dd.vintage);
                    let rendered = Mustache.render(tmpl.innerHTML, dd);
                    dx.append($.parseHTML(rendered)[1]);
                })
            }else{
                // Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
        })
    }

    submitcpOnboarding(){
        let that = this;
        const submitcps = document.getElementById("btn-submit-cps");
        submitcps.addEventListener('click', function () {
            Utils.requestHandler('/scf/api/application/'+that.application_id+'/validate-cp', Constants.REQUEST.get)
            .then(function (response){
                if(response.message === 'OK'){
                    Utils.showToast('Created', 'Counter Party Added Successfully', Constants.TOAST.success);
                    that.proceedNext();  
                }
                else{
                    Utils.showToast(response.message, "Please Add Atleast One Counter Party", Constants.TOAST.fail);
                }
            })
        });
    }
    
    validateValues(){
        let pass = true;
        if(!this.elem_email.value){
            Utils.showInputError(this.elem_email, 'Email is mandatory');
            pass = false;
        }else if(!Utils.validatorEmail(this.elem_email.value)){
            Utils.showInputError(this.elem_email, 'Incorrect email');
            pass = false;
        }
        if(!this.elem_mobile.value){
            Utils.showInputError(this.elem_mobile, 'Mobile is mandatory');
            pass = false;
        }else if(!Utils.validatorMobile(this.elem_mobile.value)){
            Utils.showInputError(this.elem_mobile, 'Incorrect mobile number');
            pass = false;
        }
        if(!this.elem_pan.value){
            Utils.showInputError(this.elem_pan, 'PAN is mandatory');
            pass = false;
        }else if(!Utils.validatorPan(this.elem_pan.value)){
            Utils.showInputError(this.elem_pan, 'Incorrect PAN');
            pass = false;
        }
        if(this.elem_cp_vintage_month.value == ""){
            Utils.showInputError(this.elem_cp_vintage_month, 'Vintage Month is mandatory');
            pass = false;
        }
        if(this.elem_cp_vintage_year.value == ""){
            Utils.showInputError(this.elem_cp_vintage_year, 'Vintage Year is mandatory');
            pass = false;
        }
        if (this.elem_cp_contact_person_designation.value == "") {
            Utils.showInputError(
              this.elem_cp_contact_person_designation,
              "Contact person designation is Mandatory"
            );
            pass = false;
          }
          if (this.elem_cp_contact_person_name.value == "") {
            Utils.showInputError(
              this.elem_cp_contact_person_name,
              "Contact person name is mandatory"
            );
            pass = false;
        }
        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();
        var currentMonth = currentDate.getMonth() + 1; // Month is 0-based
        if(this.elem_cp_vintage_year.value > currentYear || (this.elem_cp_vintage_year.value >= currentYear && this.elem_cp_vintage_month.value > currentMonth)){
            Utils.showInputError(this.elem_cp_vintage_year, 'Date Cannot be in future');
            pass = false;
        }

        return pass;
    }
  

    bussinessAndPromotersSubmit(){
        let that = this;
        const uploadAllDocuments = document.getElementById("business_promoter_final_submit");
        uploadAllDocuments.addEventListener('click', function () {
            Utils.requestHandler('/scf/api/onboarding/company/validate/business-and-promoter-details/' + document.getElementById('scf_application_id').value , Constants.REQUEST.get)
            .then(function (response){
                if(response.message === 'OK'){
                    that.proceedNext();
                    if(document.getElementById('application_product').value === 'short_term_loan'){
                        that.proceedNext();
                    }
                    Utils.showToast('Success', 'Business & Directors submitted', Constants.TOAST.success);
                }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
        });

    }

promoterDataSubmit(){
        let promoterSave = document.getElementById("promoter-save");

        promoterSave.addEventListener('click', function () {
            let that = this;

            let titleOfBorrower = document.getElementById("titleOfBorrower").value;
            let promoterPan = document.getElementById("promoterPan").value;
            let promoterName = document.getElementById("promoterName").value;
            let promoterFirstName = document.getElementById("promoterFirstName").value;
            let promoterLastName = document.getElementById("promoterLastName").value;
            let promoterBirthDate = document.getElementById("promoterBirthDate").value;
            let promoterGender = document.getElementById("promoterGender").value;
            let promoterEmail = document.getElementById("promoterEmail").value;
            let promoterMobile = document.getElementById("promoterMobile").value;
            let promoterAddress = document.getElementById("promoterAddress").value;
            let promoterPincode = document.getElementById("promoterPincode").value;
            let promoterCity = document.getElementById("promoterCity").value;
            let promoterState = document.getElementById("promoterState").value;
            let promoterYearsAtCurrentAddress = document.getElementById("promoterYearsAtCurrentAddress").value;
            let promotershareholding = document.getElementById('promotershareholding').value;
            let relationship = document.getElementById('titleRelationShipwith').value;
    
            let body = {
                ownership_type: titleOfBorrower,
                pan_number: promoterPan,
                first_name: promoterFirstName,
                last_name: promoterLastName,
                full_name: promoterName,
                date_of_birth: promoterBirthDate,
                gender: promoterGender,
                email: promoterEmail,
                mobile: promoterMobile,
                address: promoterAddress,
                zipcode: promoterPincode,
                city: promoterCity,
                state: promoterState,
                years_at_current_address: promoterYearsAtCurrentAddress,
                relationship: relationship,
                share_holding: promotershareholding
            };

            Utils.requestHandler('/scf/api/onboarding/company/director/' + localStorage.getItem('business_applicant_id'), Constants.REQUEST.post, body)
            .then(function (response){
                if(response.message === 'OK'){
                    document.getElementById("titleOfBorrower").value = "";
                    document.getElementById("promoterName").removeAttribute("readonly");
                    document.getElementById("promoterName").value = "";
                    document.getElementById("promoterName").setAttribute("readonly", "true");
                    document.getElementById("promoterPan").value = "";
                    document.getElementById("promoterFirstName").value = "";
                    document.getElementById("promoterLastName").value = "";
                    document.getElementById("promoterBirthDate").value = "";
                    document.getElementById("promoterGender").value = "";
                    document.getElementById("promoterEmail").value = "";
                    document.getElementById("promoterMobile").value = "";
                    document.getElementById("promoterAddress").value = "";
                    document.getElementById("promoterPincode").value = "";
                    document.getElementById("promoterCity").value = "";
                    document.getElementById("promoterState").value = "";
                    document.getElementById("promoterYearsAtCurrentAddress").value = "";
                    document.getElementById('titleRelationShipwith').value = "";

                    document.getElementById('promotershareholding').value = "";

                    Utils.showToast('Added', 'Business Promoter Added', Constants.TOAST.success);
                    let closeModalButton = document.getElementById("btn-promoter-modal-close"); 
                    closeModalButton.click();
                    new ScfPromoterModule();
                }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
        });

    }


    populateCityStateFromPincode(){
        const communicationZipcode = document.getElementById("communicationZipcode");
        const communicationCity = document.getElementById("communicationCity");
        const communicationState = document.getElementById("communicationState");
        const promoterPincode = document.getElementById("promoterPincode");
        const promoterCity = document.getElementById("promoterCity");
        const promoterState = document.getElementById("promoterState");

        communicationZipcode.addEventListener('input', function () {
            const communicationZipcodeValue = communicationZipcode.value;
            
            if (communicationZipcodeValue.length === 6) {
                Utils.getZipcodeDetails(communicationZipcodeValue).then(function (response){
                    if(response.message === 'OK'){
                        communicationCity.value = response.data.set_value.district
                        communicationState.value = response.data.set_value.state_name
                    } else {
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                });

            } else {
                communicationCity.value = "";
                communicationState.value = "";
            }
        });

        promoterPincode.addEventListener('input', function () {
            const promoterPincodeValue = promoterPincode.value;
            
            if (promoterPincodeValue.length === 6) {
                Utils.getZipcodeDetails(promoterPincodeValue).then(function (response){
                    if(response.message === 'OK'){
                        promoterCity.value = response.data.set_value.district
                        promoterState.value = response.data.set_value.state_name
                    } else {
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                });

            } else {
                promoterCity.value = "";
                promoterState.value = "";
            }
        });
    }

    populateNameFromPan(){
        const promoterPan = document.getElementById("promoterPan");
        const promoterName = document.getElementById("promoterName");
        const promoterFirstName = document.getElementById("promoterFirstName");
        const promoterLastName = document.getElementById("promoterLastName");
        promoterPan.addEventListener('input', function () {
            const inputValue = promoterPan.value;
            
            if (inputValue.length === 10) {
                Utils.requestHandler("/scf/api/onboarding/name/" + inputValue, Constants.REQUEST.get, {})
                .then(function (response){
                    if(response.message === 'OK'){
                        promoterName.value = response.data.full_name
                        promoterFirstName.value = response.data.first_name
                        promoterLastName.value = response.data.last_name
                    }else{
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        
                    }
                })


            } else {
                promoterName.value = "";
                promoterFirstName.value = "";
                promoterLastName.value = "";
            }
        });

    }

    tempUdyamAddressCopyPaste(){
        const primaryGstAddress = document.getElementById("primaryGstAddress");
        const primaryGstCity = document.getElementById("primaryGstCity");
        const primaryGstState = document.getElementById("primaryGstState");
        const businessContactAddress = document.getElementById("businessContactAddress");
        const SameAddressCheckbox = document.getElementById("SameAddressCheckbox");
        const primaryGstZipcode = document.getElementById('primaryGstZipcode');
        const communicationZipcode = document.getElementById('communicationZipcode');
        const communicationCity = document.getElementById('communicationCity');
        const communicationState = document.getElementById('communicationState');


        SameAddressCheckbox.addEventListener('change', function () {
        if (SameAddressCheckbox.checked) {
            businessContactAddress.value = primaryGstAddress.value;
            communicationZipcode.value = primaryGstZipcode.value;
            communicationCity.value = primaryGstCity.value;
            communicationState.value = primaryGstState.value;
        } else {
            businessContactAddress.value = '';
            communicationZipcode.value = '';
            communicationCity.value = '';
            communicationState.value = '';
        }
        });
    }

    tempUdyamSearch(){
        let udyamSearchField = document.getElementById("udyamSearchField");
        const udyamBusinessName = document.getElementById("udyamBusinessName");
        const UdyamFetchedNameInForms = document.getElementById("UdyamFetchedNameInForms");

        udyamSearchField.addEventListener('input', function () {
            const inputValue = udyamSearchField.value;
            
            if (inputValue.length === 19) {

                new ScfUdyamPanel(document.getElementById('scf_application_id').value, inputValue); 
                const scfUdyamPanel = new ScfUdyamPanel(document.getElementById('scf_application_id').value, inputValue);
   
                scfUdyamPanel.fetchUdyamDetails()
                        .then(data => {
                            udyamBusinessName.value =  data['udhyam_aadhaar'].name;
                            UdyamFetchedNameInForms.classList.remove('d-none');
                        });


            } else {
                UdyamFetchedNameInForms.classList.add('d-none');
            }
        });
        
    }
    
    tempFeedData(){
        let that = this;
        let feedData = document.getElementById("temp_feed_data");
        const businessPanUdyamPage = document.getElementById('businessPanUdyamPage');
        const gstNumberUdyamPage = document.getElementById('gstNumberUdyamPage');
        const BusinessNameFromBusinessApplicant = document.getElementById('BusinessNameFromBusinessApplicant');
        let allGstsOnUdyamPage = [];
        const primaryGstNumber = "";
        document.getElementById('btn-panel-udyam').addEventListener('click', function (){
            businessPanUdyamPage.value = localStorage.getItem('pan');
            BusinessNameFromBusinessApplicant.textContent =  localStorage.getItem('pan_name');
            Utils.requestHandler("/scf/api/applicant/" + localStorage.getItem('business_applicant_id'), Constants.REQUEST.get, {})
            .then(function (response){
                if(response.message === 'OK'){
                    while (gstNumberUdyamPage.options.length > 0) {
                        gstNumberUdyamPage.remove(0);
                    }
                    allGstsOnUdyamPage = [];
                    for (const item of response.data.business_gst) {
                        if (item.gstin) {
                            allGstsOnUdyamPage.push(item.gstin);
                        }
                        if(item.primary === true){
                            that.primaryGstNumber = item.gstin;
                        }
                    }
                    for (const optionText of allGstsOnUdyamPage) {
                        const option = document.createElement('option');
                        option.value = optionText;
                        option.text = optionText;
                        gstNumberUdyamPage.appendChild(option);
                    }
                    
                    document.getElementById('primaryGstAddress').value = response.data.scf_applicant.address;
                    document.getElementById('primaryGstZipcode').value = response.data.scf_applicant.zipcode;
                    document.getElementById('primaryGstCity').value = response.data.scf_applicant.city;
                    document.getElementById('primaryGstState').value = response.data.scf_applicant.state;
                    
                    if (that.primaryGstNumber) {
                        gstNumberUdyamPage.value = that.primaryGstNumber;
                    }

                }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })

        });
    }

    sideNavToggleHandler(){
        const categoryParents = document.querySelectorAll('[id$="_sidenav_toggle_parent"]');
        categoryParents.forEach((parent) => {
        parent.addEventListener('click', () => {
            const categoryClass = parent.id.replace('_sidenav_toggle_parent', '');
            
            const childDivs = document.getElementById(`${categoryClass}_sidenav_toggle_child`);
            const childIconRight = document.getElementById(`${categoryClass}_sidenav_toggle_icon_right`);
            const childIconDown = document.getElementById(`${categoryClass}_sidenav_toggle_icon_down`);
            
            if (childDivs.classList.contains('d-none')) {
                childDivs.classList.remove('d-none');
                childDivs.classList.add('d-block');
                childIconRight.classList.add('d-none');
                childIconDown.classList.remove('d-none');
            } else {
                childDivs.classList.remove('d-block');
                childDivs.classList.add('d-none');
                childIconRight.classList.remove('d-none');
                childIconDown.classList.add('d-none');
            }
        });
        });

    }

    isSoleProprietor(){
        return this.obj_pan.company_pan_type && this.obj_pan.company_pan_type === 'sole_proprietorship';
    }

    panelNavigationHandler(){
        let that = this;
        let all_panel_btn = document.querySelectorAll('.nav-panel-link');
        all_panel_btn.forEach((element) => {
            element.addEventListener('click', event => {
                if (element === event.target || element.contains(event.target)) {
                    if(that.isValidPanel(element)){
                        if(that.isSoleProprietor() && element.id === 'btn-panel-mca'){
                           Utils.showToast('Error', 'This step is not applicable', Constants.TOAST.fail);
                           return;
                        }
                        all_panel_btn.forEach(element => {
                            element.classList.remove('active')
                        });
                        element.classList.add('active');
                        document.querySelectorAll('.panel').forEach(element => {
                            element.classList.add('no-display')
                        });
                        document.getElementById(element.getAttribute('data-panel')).classList.remove('no-display');
                    }
                }
            })
        })
        let elem_next = document.getElementById('btn-next');
        elem_next.addEventListener('click', function (){
            let active_panel_number = 0;
            let active_panel_id = null;
            all_panel_btn.forEach(function (panel, indx){
                if(panel.classList.contains('active')){
                    active_panel_number = indx;
                    active_panel_id = panel.id;
                    return false;
                }
            })
            if(that.isSoleProprietor() && active_panel_id === 'btn-panel-quest'){
                that.panelActiveComplete('panel-mca');
                active_panel_number +=1;
            }
            if(all_panel_btn.length > active_panel_number + 1){
                if(active_panel_number +1 > that.max_panel){
                    Utils.showToast('Complete step', 'Please complete this step first', Constants.TOAST.fail);
                    return;
                }
                all_panel_btn[active_panel_number + 1].click();
            }
        })
        document.getElementById('btn-prev').addEventListener('click', function (){
            let active_panel_number = 0;
            let active_panel_id = null;
            all_panel_btn.forEach(function (panel, indx){
                if(panel.classList.contains('active')){
                    active_panel_number = indx;
                    active_panel_id = panel.id;
                    return false;
                }
            })
            if(that.isSoleProprietor() && active_panel_id === 'btn-panel-gst'){
                active_panel_number -=1;
            }
            if(active_panel_number - 1 >= 0){
                all_panel_btn[active_panel_number - 1].click();
            }
        })
        this.onboardingLogoutButton();
    }
    
    onboardingLogoutButton(){
        let elem =  document.getElementById('onb-logout-button');
        if(elem) {
            document.getElementById('onb-logout-button').addEventListener('click', function () {
                Utils.requestHandler('/core/api/authentication/logout', Constants.REQUEST.post, {})
                    .then(function (response) {
                        if (response.message === 'OK') {
                            Utils.deleteCookie('_alf-permissions');
                            Utils.deleteCookie('_alf-session-id');
                            Utils.deleteCookie('_alf-user-id');
                            localStorage.removeItem('user_id');
                            localStorage.removeItem('business_applicant_id');
                            localStorage.removeItem('pan');
                            localStorage.removeItem('pan_name');
                            window.location = '/scf/'
                            Utils.showToast(response.message, 'Logout successful', Constants.TOAST.success);
                        } else {
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    })
            })
        }
    }


    proceedNext(){
        document.getElementById('btn-next').click();
    }

    proceedPrevious(){
        document.getElementById('btn-prev').click();
    }

    panelActiveComplete(nav_panel_link_id, touch_max_panel = true){
        let clt = document.getElementById('btn-'+nav_panel_link_id);
        clt.classList.add('completed');
        if(touch_max_panel) {
            this.max_panel = Math.max(this.max_panel, parseInt(clt.getAttribute('data-panel-number')) + 1);
        }
    }

    isValidPanel(element, msg_show = true){
        let next_penal_number = parseInt(element.getAttribute('data-panel-number'));
        if(next_penal_number > this.max_panel){
            if(msg_show){
                Utils.showToast('Complete step', 'Please complete this step first', Constants.TOAST.fail);
            }
            return false;
        }
        return true;
    }

    udyamPanelHandler(){
        let that = this;
        document.getElementById('btn-panel-udyam').addEventListener('click', function() {
            ImageBitmapRenderingContext.pry
            new ScfBankingModule();
            new ScfPromoterModule();
        })
        
    }

    primaryGst() {
      const tabs = document.querySelectorAll('.gstin-item');

      // Add click event listeners to each tab
      tabs.forEach(tab => {
        tab.addEventListener('click', () => {
          // Remove the "active" class from all tabs
          tabs.forEach(t => {
            t.classList.remove('active');
          });
          
          // Add the "active" class to the clicked tab
          tab.classList.add('active');
        });
      });
    }

    userPanelHandler(){
        let that  = this;
        document.getElementById('btn-user-submit').addEventListener('click', function(){
            that.obj_user.getValues();
            const elem_btn = this;
            if(that.obj_user.validateValues()){
                Utils.buttonWithLoader(elem_btn, Constants.ELEMENT_STATE.show);
                let body = {
                    first_name: that.obj_user.first_name,
                    last_name: that.obj_user.last_name,
                    email: that.obj_user.email,
                    mobile: that.obj_user.mobile,
                    id: that.obj_user.user_id,
                    url: Constants.SERVICE_BASE_BACKOFFICE + "/v2/send-email",
                    req_type: "post"
                }
                Utils.requestHandler('/scf/api/onboarding/user/', Constants.REQUEST.post, body)
                    .then(function (response){
                        if(response.message === 'OK'){
                            that.obj_user.user_id = response.data.id;
                            Utils.showToast('Created', 'User created', Constants.TOAST.success);
                            that.panelActiveComplete('panel-sign-up');
                            that.proceedNext();
                        }else{
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    })
                    .then(function (){
                        Utils.buttonWithLoader(elem_btn,  Constants.ELEMENT_STATE.hide);
                    })
            }
        })
    }

    constitutionBasedChanges(){
        let owner_panel_head = document.getElementById('owner_panel_head');
        let owner_panel_left = document.querySelector('#btn-panel-owners > .content > label');
        let entity_panel_content = document.querySelector('#btn-panel-mca');
        switch (this.obj_pan.company_pan_type){
            case 'private_limited':
                owner_panel_head.innerHTML = "Directors";
                owner_panel_left.innerHTML = "Directors";
                entity_panel_content.classList.remove('no-display');
                break;
            case 'public_company':
                owner_panel_head.innerHTML = "Directors";
                owner_panel_left.innerHTML = "Directors";
                entity_panel_content.classList.remove('no-display');
                break;
            case 'sole_proprietorship':
                owner_panel_head.innerHTML = "Proprietor";
                owner_panel_left.innerHTML = "Proprietor";
                entity_panel_content.classList.add('no-display');
                break;
            case 'partnership':
            case 'llp':
                owner_panel_head.innerHTML = "Partners";
                owner_panel_left.innerHTML = "Partners";
                entity_panel_content.classList.remove('no-display');
                break;
            default:
            // code block
        }
    }

    panPanelHandler(){
        let that = this;
        let elem_submit = document.getElementById('btn-submit-pan');
        document.getElementById('btn-validate-pan').addEventListener('click', function(){
            that.obj_pan.getValues();
            const elem_btn = this;
            if(that.obj_pan.validateValues()){
                Utils.placeholderHandler(that.obj_pan.elem_company_name, Constants.ELEMENT_STATE.show);
                Utils.buttonWithLoader(elem_btn,  Constants.ELEMENT_STATE.show)
                Utils.requestHandler('/scf/api/onboarding/name/' + that.obj_pan.company_pan, Constants.REQUEST.get, {})
                    .then(function (response){
                        if(response.message === 'OK'){
                            if(response.data.full_name){
                                that.obj_pan.company_name = response.data.full_name;
                                that.obj_pan.elem_company_name.parentElement.classList.remove('no-display');
                                that.obj_pan.elem_company_name.parentElement.querySelector('#danger').classList.add('no-display');
                                that.obj_pan.elem_company_name.parentElement.querySelector('#success').classList.remove('no-display');
                                elem_btn.classList.add('no-display');
                                that.obj_mca.elem_srch_company_name.value = response.data.full_name;
                                Utils.buttonWithLoader(elem_submit,  Constants.ELEMENT_STATE.hide);
                                that.panelActiveComplete('panel-pan');
                                that.constitutionBasedChanges();
                            }else{
                                that.obj_pan.company_name = '-';
                                that.obj_pan.elem_company_name.parentElement.querySelector('#danger').classList.remove('no-display');
                                that.obj_pan.elem_company_name.parentElement.querySelector('#success').classList.add('no-display');
                                Utils.showToast(response.message, 'No PAN matches this pattern', Constants.TOAST.fail);
                                Utils.buttonWithLoader(elem_btn,  Constants.ELEMENT_STATE.hide);
                            }
                            that.obj_pan.setValues();
                        }else{
                            Utils.buttonWithLoader(elem_btn,  Constants.ELEMENT_STATE.hide);
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    }).then(function (){
                        Utils.placeholderHandler(that.obj_pan.elem_company_name, Constants.ELEMENT_STATE.hide);
                    })
            }

        })
        elem_submit.addEventListener('click', function(){
            let body = {
                name: that.obj_pan.company_name,
                pan_name: that.obj_pan.company_name,
                pan: that.obj_pan.company_pan,
                constitution: that.obj_pan.company_pan_type,
                user_id: that.obj_user.user_id
            }
            // Utils.requestHandler("/scf/api/onboarding/company/", Constants.REQUEST.post, body)
            //     .then(function (response){
            //         if(response.message === 'OK'){
            //             if(response.data.business_applicant_id){
            //                 that.applicant_id = response.data.business_applicant_id;
            //                 document.getElementById('business_applicant_id').value = that.applicant_id;
            //                 that.proceedNext();
            //             }
            //             Utils.showToast('Created', 'Business applicant created', Constants.TOAST.success);
            //         }else{
            //             Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            //         }
            //     })
        })
    }

    productPanelHandler(){
        this.submitProduct();
    }

    submitProduct(){
        let that = this;
        document.getElementById('btn-product-submit').addEventListener('click', function(){
            const elem_btn = this;
            that.obj_application.getValues();
            if(that.obj_application.validateValues()){
                let application_tenure = parseInt(that.obj_application.required_tenure);
                if(that.obj_application.product==='short_term_loan'){
                    var currentDate = new Date();
                    var nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
                    var nextValidMonth = new Date(currentDate.getFullYear(), parseInt(currentDate.getMonth()) + 1 + parseInt(application_tenure), 1);
                    var timeDifference = nextValidMonth - nextMonth;
                    var daysDifference = timeDifference / (1000 * 3600 * 24);
                    application_tenure = daysDifference + ' days';
                }
                let body = {
                    required_amount: that.obj_application.required_amount,
                    requested_tenure: application_tenure,
                    product: that.obj_application.product,
                    scf_application_id: that.application_id,
                    url: Constants.SERVICE_BASE_BACKOFFICE + "/v2/send-email",
                    req_type: "post"
                }
                Utils.buttonWithLoader(elem_btn,  Constants.ELEMENT_STATE.show);
                Utils.requestHandler("/scf/api/onboarding/application/create/" + localStorage.getItem('business_applicant_id'), Constants.REQUEST.post, body)
                    .then(function (response){
                        if(response.message === 'OK'){
                            that.application_id = response.data.id;
                            document.getElementById('scf_application_id').value = that.application_id;
                            Utils.showToast(response.message, 'SCF Application created', Constants.TOAST.success);
                            that.getPreApprovedOffer();
                            that.panelActiveComplete('panel-product');
                            if(that.obj_application.product==='short_term_loan'){
                                document.getElementById('btn-panel-counterparty').classList.add('no-display');
                            }
                            else{
                                document.getElementById('btn-panel-counterparty').classList.remove('no-display');
                            }
                            if(that.obj_application.product==='short_term_loan'){
                                document.getElementById('btn-panel-complete').classList.add('no-display');
                                document.getElementById('Build your Application_onb_module').classList.remove('d-none');
                                document.getElementById('div_cp_ledger_documents').classList.add('no-display');
                            }
                            if (that.is_edit == true || that.obj_application.product==='short_term_loan') {
                                document.getElementById('btn-panel-udyam').click();
                            } else {
                                that.proceedNext();
                            }
                        }else{
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    }).then(function (){
                    Utils.buttonWithLoader(elem_btn,  Constants.ELEMENT_STATE.hide);
                })

            }
        })
         
    }

    mcaPanelHandler(){
        this.mcaSearchCompany();
        this.mcaSearchCinOrLlp();
        this.mcaCreateBusinessApplicant();
        this.mcaBackToSelection();
    }

    getPreApprovedOffer() {
        let that = this;
        that.obj_question.getValues();
        that.elem_company_pre_amount = document.getElementById('company_pre_amount');
        let offerTenure = 0;
        if(that.obj_question.quest_values['vintage'] === '0-30 days') {
            offerTenure = 15;
        }
        else if (that.obj_question.quest_values['vintage'] === '30-60 days') {
            offerTenure = 45;
        } else {
            offerTenure = 90;
        }
        let offer = parseInt(that.obj_question.quest_values["avg_monthly_sales"]) * offerTenure * 1.25/30;
        that.elem_company_pre_amount.innerText = Utils.indianRepresentation(offer) + "*";
    };

    indicativeOfferPanelHandler(){
        let that = this;
        let isAttached = false;
        document.getElementById('btn-panel-complete').addEventListener('click', function() {
            if(that.isValidPanel(this, false) && !isAttached) {
                that.panelActiveComplete('panel-product');
                that.panelActiveComplete('panel-complete');
                new ScfPreApproval();
                isAttached = true;
            }
        })
    }

    // questPanelHandler(){
    //   let that = this;
    //   document.getElementById('btn-panel-quest').addEventListener('click', function (){
    //       if(that.isValidPanel(this, false)){
    //           that.questGetQuestionnaire();
    //       }
    //   })
    //   this.questSetQuestionnaireAndPrimaryGst();
    // }

    gstPanelHandler(){
        let that = this;
        let pan = localStorage.getItem('pan');
        let pan_name = localStorage.getItem('pan_name');
        const elem_pan_name = document.getElementById('pan-name-gst-tab');
        let rendered = $.parseHTML(Mustache.render(document.getElementById('pan-name-tmpl').innerHTML, {pan_name: pan_name}))[1];
        elem_pan_name.append(rendered);
        if (pan[3] == 'C') {
          document.getElementById('gst-tab').classList.add('no-display')
        }else{
          document.getElementById('mca-tab').classList.add('no-display')
        }
        document.getElementById('btn-panel-gst').addEventListener('click', function (){
            if(that.isValidPanel(this, false)) {
              if (that.obj_gst.length === 0) {
                if (pan[3] !== 'C') {
                  that.gstGetAllGsts();
                }
                that.questGetQuestionnaire(true);
                that.questSetQuestionnaireAndPrimaryGst();
              }
            }
        })
    }

    completeOnboardingHandler(){
      let that = this;
      document.getElementById('btn-complete-submit').addEventListener('click', function (){
        Utils.requestHandler("/scf/api/onboarding/application/indicative_offer_confirmation/" + that.application_id, Constants.REQUEST.post, {})
        .then(function (response){
            if(response.message === 'OK'){
                that.proceedNext();
            }else{
                Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
        })
    })

    }
//adding consent page next on click method
consenthandler(){
    let that = this;
    document.getElementById('consent-btn').addEventListener('click', function (){
      that.proceedNextconsent();
    })
    this.skipConsentButton();
  }
  proceedNextconsent() {
    // Assuming you want to redirect to the '/dashboard' route
    window.location.href = '/scf/application';
  }
  
  skipConsentButton(){
    let that = this;
    const skipBtn = document.getElementById("btn_consent_add_skip");
    skipBtn.addEventListener('click', function () {
        that.proceedNext();
    })
}


    mcaCreateBusinessApplicant(){
        let that = this;
        document.getElementById('btn-create-business-applicant').addEventListener('click', function () {
          let body = {
                id: that.applicant_id,
                name: that.obj_mca.company_name,
                email: that.obj_mca.company_email,
                pan: that.obj_pan.company_pan,
                address: that.obj_mca.company_address,
                city: that.obj_mca.company_city,
                zipcode: that.obj_mca.company_zipcode,
                state: that.obj_mca.company_state,
                corporate_id: that.obj_mca.cin_or_llp_id
              }
              that.obj_question.getValues();
              if(that.obj_question.validateValues()){
              Utils.requestHandler("/scf/api/onboarding/application/questionnaire/" + localStorage.getItem('business_applicant_id'), Constants.REQUEST.post, {questions: that.obj_question.quest_values})
                    .then(function (response){
                        if(response.message === 'OK'){
                          Utils.requestHandler("/scf/api/onboarding/company/" + localStorage.getItem('business_applicant_id'), Constants.REQUEST.post, body)
                            .then(function (response){
                                if(response.message === 'OK'){
                                    if(response.data.business_applicant_id){
                                        that.applicant_id = response.data.business_applicant_id;
                                    }
                                    that.panelActiveComplete('panel-gst');
                                    that.proceedNext();
                                    Utils.showToast('Created', 'Business applicant updated', Constants.TOAST.success);
                                }else{
                                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                                }
                                Utils.loaderToast(Constants.ELEMENT_STATE.hide)
                            })
                        }else{
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    })
              
            };
        })
    }

    mcaSearchCinOrLlp() {
        let that = this;
        document.getElementById('btn-srch-company-id').addEventListener('click', function () {  
          if(that.obj_mca.elem_srch_company_cin.value){
                Utils.loaderToast(Constants.ELEMENT_STATE.show)
                Utils.requestHandler("/scf/api/onboarding/company/get_company_from_id/" + localStorage.getItem('business_applicant_id') + "/" + that.obj_mca.elem_srch_company_cin.value, Constants.REQUEST.get, {})
                    .then(function (response){
                        if(response.message === 'OK'){
                            that.questGetQuestionnaire(false)
                            that.mcaSetCompanyDetails(response.data);
                            document.getElementById('company_selection_div').classList.add('no-display');
                            document.getElementById('company_details_div').classList.remove('no-display');
                        }else{
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                        Utils.loaderToast(Constants.ELEMENT_STATE.hide)
                    })
            }else{
                Utils.showToast('Input error', 'Provide a valid CIN/LLP Pin', Constants.TOAST.fail);
            }
        })
    }

    mcaSearchCompany(){
        let that = this;
        document.getElementById('btn-srch-company-name').addEventListener('click', function(){
            if(that.obj_mca.elem_srch_company_name.value){
                Utils.loaderToast(Constants.ELEMENT_STATE.show)
                Utils.requestHandler("/scf/api/onboarding/companies/" + that.obj_mca.elem_srch_company_name.value, Constants.REQUEST.get, {})
                    .then(function (response){
                        if(response.message === 'OK'){
                            if(response.data && response.data.companies.length > 0){
                                that.questGetQuestionnaire(false)
                                that.mcaFillCompaniesList(response.data);
                            }else{
                                Utils.showToast("No Data found", "No company matched with search query", Constants.TOAST.fail);
                            }
                        }else{
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                        Utils.loaderToast(Constants.ELEMENT_STATE.hide)
                    })
            }else{
                Utils.showToast('Input error', 'Provide a valid company name', Constants.TOAST.fail);
            }
        })
    }

    mcaFillCompaniesList(data) {
        const that = this;
        that.obj_mca.elem_srch_company_list.innerHTML = '';
        data.companies.forEach(company => {
            let li = document.createElement('li');
            li.setAttribute('data-company-id', company.company_id);
            li.innerHTML = "<div>"+company.name+"<br/><small class='text-grey'>"+company.company_id+"</small></div>"
            that.obj_mca.elem_srch_company_list.appendChild(li);
        })
        document.getElementById('srch_company_list_div').classList.remove('no-display');
        that.mcaGetCompanyDetails();
        that.obj_mca.elem_select_company_modal.show();
    }

    mcaGetCompanyDetails(){
        let that = this;
        that.obj_mca.elem_srch_company_list.querySelectorAll('li').forEach(element => {
            element.addEventListener('click', event => {
                that.obj_mca.elem_srch_company_list.querySelectorAll('li')
                    .forEach(elem => {
                        elem.classList.remove('active');
                })
                element.classList.add('active');
                Utils.loaderToast(Constants.ELEMENT_STATE.show);
                Utils.requestHandler("/scf/api/onboarding/company/get_company_from_id/" + localStorage.getItem('business_applicant_id') + "/" + element.getAttribute('data-company-id'), Constants.REQUEST.get, {})
                    .then(function (response){
                        if(response.message === 'OK'){
                            that.mcaSetCompanyDetails(response.data);
                            that.obj_mca.elem_select_company_modal.hide();
                            document.getElementById('company_selection_div').classList.add('no-display');
                            document.getElementById('company_details_div').classList.remove('no-display');
                        }else{
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                        Utils.loaderToast(Constants.ELEMENT_STATE.hide);
                    })
            })
        })
    }

    mcaBackToSelection(){
        document.getElementById('btn-back-selection').addEventListener('click',event => {
            document.getElementById('company_selection_div').classList.remove('no-display');
            document.getElementById('company_details_div').classList.add('no-display');
        })
    }
    mcaSetCompanyDetails(data){
        this.obj_mca.company_name = data.company_data.name;
        this.obj_mca.cin_or_llp_id = data.company_data.company_id;
        this.obj_mca.company_type = data.company_constitution;
        this.obj_mca.date_of_inc = data.company_data.date_of_incorporation;
        this.obj_mca.company_address = data.company_data.registered_address;
        this.obj_mca.company_city = data.company_data.city;
        this.obj_mca.company_state = data.company_data.state;
        this.obj_mca.company_zipcode = data.company_data.zipcode;
        this.obj_mca.roc_code = data.company_data.roc;
        this.obj_mca.company_email = data.company_data.email;
        this.obj_mca.setValues();
    }

    gstGetAllGsts(){
        let that = this;
        let applicant_id = localStorage.getItem('business_applicant_id');
        Utils.requestHandler("/scf/api/onboarding/company/gst-all/" + applicant_id, Constants.REQUEST.get, {})
            .then(function (response){
                if(response.message === 'OK'){
                    const elem_all_gstins = document.getElementById('all_gstins');
                    elem_all_gstins.innerHTML = null;
                    response.data.gstins.forEach(gst_data => {
                        let rendered = $.parseHTML(Mustache.render(document.getElementById('gstin-tmpl').innerHTML, {gstin: gst_data.gstin, state: gst_data.state}))[1];
                        elem_all_gstins.append(rendered);
                        that.obj_gst.push(gst_data.gstin);
                    })
                    that.primaryGst()
                    Utils.showToast(response.message, 'All linked GST fetched', Constants.TOAST.success);
                }else{
                  Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            }).then(function (){
                that.panelActiveComplete('panel-gst');
                that.gstGetGstDetails();
            })
    }

    gstGetGstDetails(){
        const elem_gstin_details = document.getElementById('gst-details');
        const tmpl_gst_details = document.getElementById('gst-details-tmpl');
        let business_applicant_id = localStorage.getItem('business_applicant_id');
        document.querySelectorAll('#all_gstins .gstin-item').forEach((elem) => {
            elem.addEventListener('click', event => {
                Utils.requestHandler("/scf/api/onboarding/company/gst/" + business_applicant_id + "/" + elem.getAttribute('data-gstin'), Constants.REQUEST.get, {})
                    .then(function (response){
                        if(response.message === 'OK'){
                          response.data.registration_date = Utils.formatDate(response.data.registration_date);
                          response.data.reg_vintage = Utils.formatTimeRelative(response.data.registration_date);
                          let rendered = $.parseHTML(Mustache.render(tmpl_gst_details.innerHTML, response.data))[1];
                          elem_gstin_details.innerHTML = null;
                          elem_gstin_details.append(rendered);
                            // document.getElementById('gst_selection_div').classList.add('no-display');
                            document.getElementById('gst_details_div').classList.remove('no-display');
                            Utils.showToast(response.message, 'GST Details fetched', Constants.TOAST.success);
                        }else{
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    })
            })
        })
        // document.getElementById('btn-back-gst-selection').addEventListener('click', event => {
        //     document.getElementById('gst_selection_div').classList.remove('no-display');
        //     document.getElementById('gst_details_div').classList.add('no-display');
        // })
    }

    questGetQuestionnaire(gst){
        let that = this;
        let questionType = 'all_questions-onboarding'
        if(!gst) {
          questionType = 'all_questions-onboarding-cin'
        }
        const quest_heading =  document.getElementById(all_questions_onboarding_heading);
        const all_quest =  document.getElementById(questionType);
        const tmpl = document.getElementById('question-tmpl-onboarding');
        const quest_heading_template = document.getElementById('question-heading-tmpl-onboarding').innerHTML;

        all_quest.innerHTML = null;
        Utils.requestHandler("/scf/api/onboarding/application/questionnaire/" + localStorage.getItem('business_applicant_id'), Constants.REQUEST.get, {})
            .then(function (response){
                if(response.message === 'OK'){
                  let isSelect = true 
                    
                    response.data.forEach(function (question){
                      question.options = [];
                      question.isSelect = null;
                      question.isSelect = question.type === 'select'
                      question.data.forEach(optn => {
                        question.options.push({
                          value: optn[1],
                          text: optn[0],
                          selected: question.response == optn ? "selected" : ""
                        })
                      })
                      let q_data = $.parseHTML(Mustache.render(tmpl.innerHTML, question))[1];
                      all_quest.append(q_data);
                      const rendered = Mustache.render(quest_heading_template);
                      document.getElementById('all_questions_onboarding_heading').innerHTML = rendered;
                    })
                }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
        })
    }

    questSetQuestionnaireAndPrimaryGst(){
        let that = this;
        document.getElementById('btn-quest-onb-submit').addEventListener('click', function (){
            that.obj_question.getValues();
            let primaryGst = document.querySelectorAll('.gstin-item.active')[0].getAttribute("data-gstin")
            if(that.obj_question.validateValues()){
                Utils.requestHandler("/scf/api/onboarding/application/questionnaire/" + localStorage.getItem('business_applicant_id'), Constants.REQUEST.post, {questions: that.obj_question.quest_values, primary_gst: primaryGst})
                    .then(function (response){
                        if(response.message === 'OK'){
                            Utils.showToast(response.message, 'Questionnaire submitted', Constants.TOAST.success);
                            that.panelActiveComplete('panel-gst');
                            that.proceedNext();
                        }else{
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    })
            }
        })
    }
     
    ownersPanelHandler(){
        /*
        let that = this;
        document.getElementById('btn-panel-owners').addEventListener('click', function (){
            if(that.isValidPanel(this, false)){
                if(that.obj_owner.length === 0){
                    that.ownersGetDetails();
                }
            }
        })
        this.ownersZipcodeHandler();
        this.ownersCreateNew();
        this.saveOwnerDetails();
        */
    }

    ownersZipcodeHandler(){
        document.getElementById('owner_zipcode').addEventListener('input', function (){
            let zipcode = this.value;
            if(String(zipcode).length === 6){
                Utils.getZipcodeDetails(zipcode).then(function (response){
                    if(response.data){
                        document.getElementById('owner_city').value = response.data.set_value.district;
                        document.getElementById('owner_state').value = response.data.set_value.state_name;
                    }
                });
            }
        })
    }

    ownersGetDetails(){
        let that = this;
        Utils.requestHandler("/scf/api/onboarding/company/directors/" + that.applicant_id, Constants.REQUEST.get, {})
            .then(function (response){
                if(response.message === 'OK'){
                    let tbody = document.getElementById('owner_rows');
                    let temp = document.getElementById('owner-table-row-tmpl');
                    tbody.innerHTML = null;
                    response.data.forEach(function (row){
                        row.begin_date_formatted = Utils.formatDate(row.begin_date);
                        row.business_director_id = row.id;
                        let rend_row = Mustache.render(temp.innerHTML, row);
                        tbody.append($.parseHTML(rend_row)[1])
                        let director = new AbstractOwner(row.business_director_id);
                        director.first_name = row.first_name;
                        director.last_name = row.last_name;
                        director.email = row.email;
                        director.mobile = row.mobile;
                        director.city = row.city;
                        director.state = row.state;
                        director.address = row.address;
                        director.gender = row.gender;
                        director.dob = row.date_of_birth;
                        director.pan = row.pan_number;
                        director.aadhaar = row.aadhaar_number;
                        director.zipcode = row.zipcode;
                        director.din = row.din;
                        director.relationship=row.relationship;
                        that.obj_owner.push(director);
                    })
                    that.panelActiveComplete('panel-owners');
                    Utils.showToast(response.message, 'All company directors fetched', Constants.TOAST.success);
                }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            }).then(function () {
                that.showOwnerDetails();
            })
    }

    ownersCreateNew(){
        let that = this;
        document.getElementById('create_new_owner').addEventListener('click', function (){
            let owner = new AbstractOwner(Math.random().toString(36));
            that.obj_owner.push(owner);
            that.ownersDetailsContainer(owner);
        })
    }

    ownersDetailsContainer(data){
        document.getElementById('owner_show_name').innerHTML = (data.first_name ? data.first_name : "") + " " + (data.last_name ? data.last_name : "");
        document.getElementById('owner_show_din').innerHTML = "DIN - " + (data.din ? data.din : "");
        document.getElementById('btn_save_owner').setAttribute('data-director-id', data.director_id);
        document.getElementById('director-details').classList.remove('no-display');
        data.setValues();
    }

    showOwnerDetails(){
        let that = this;
        document.querySelectorAll('.btn-owner-update').forEach(function (item) {
            item.addEventListener('click', function (e) {
                let abs_owner = null;
                that.obj_owner.forEach(function (owner) {
                    if (owner.director_id === item.getAttribute('data-director-id')) {
                        abs_owner = owner;
                        return false;
                    }
                })
                that.ownersDetailsContainer(abs_owner);
            })
        })
    }

    saveOwnerDetails(){
        let that = this;
        document.getElementById('btn_save_owner').addEventListener('click', function() {
                let bid = this.getAttribute('data-director-id');
                if(bid){
                    let owner = null;
                    that.obj_owner.forEach(function (abs_owner){
                        if(abs_owner.director_id === bid){
                            owner = abs_owner;
                            return false;
                        }
                    })
                    owner.getValues();
                    if(owner.validateValues()){
                        let body = {
                            "pan_number": owner.pan,
                            "first_name": owner.first_name,
                            "last_name": owner.last_name,
                            "email": owner.email,
                            "mobile": owner.mobile,
                            "address": owner.address,
                            "gender": owner.gender,
                            "aadhaar_number": owner.aadhaar,
                            "date_of_birth": owner.dob,
                            "city": owner.city,
                            "state": owner.state,
                            "zipcode": owner.zipcode,
                            "relationship": relationship
                        }
                        Utils.requestHandler("/scf/api/onboarding/company/director/" +that.applicant_id, Constants.REQUEST.post, body)
                                .then(function (response){
                                    if(response.message === 'OK'){
                                        that.panelActiveComplete('panel-owners');
                                        Utils.showToast(response.message, 'Owner Details saved', Constants.TOAST.success);
                                        that.ownersGetDetails();
                                        document.getElementById('director-details').classList.add('no-display');
                                    }else{
                                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                                    }
                                })
                    }
                } else{
                    Utils.showToast('Bad Request', 'Owner not found', Constants.TOAST.fail);
                }
            })
    }

    consentPanelHandler(){
        this.consentCreate();
    }

    consentCreate() {
        let that = this;
        let all_consents = document.querySelectorAll('.consent-box');
        document.getElementById('btn-consent-submit').addEventListener('click', function () {
            let brk = [];
            let allChecked = true;
            all_consents.forEach(function (inp_consent) {
                if (inp_consent.checked) {
                    brk.push(inp_consent.value);
                } else {
                    allChecked = false;
                }
            });
            if (!allChecked ) {
                Utils.showToast('Error', 'All Consents are mandatory', Constants.TOAST.fail);
                return false;
            }
            const response = confirm("Are you sure you want to submit?");
            let body ={
                url: Constants.SERVICE_BASE_BACKOFFICE + "/v2/send-email",
                req_type: "post",
                user_agent: window.navigator.userAgent,
                type: brk
            }
            if (response) {
                Utils.requestHandler("/scf/api/onboarding/application/consent/" + document.getElementById('scf_application_id').value, Constants.REQUEST.post, body)
                    .then(function (response) {
                        if (response.message === 'OK') {
                            Utils.showToast(response.message, 'Consent submitted', Constants.TOAST.success);
                            that.panelActiveComplete('panel-consent');
                            that.proceedNext();
                        } else {
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    })
                    .catch(function (error) {
                        Utils.showToast('Error', 'Failed to submit consents', Constants.TOAST.fail);
                    });
            } else {
                that.proceedPrevious();
                that.proceedPrevious();
                that.proceedPrevious();
            }
        });
    }
    

    // preApprovalPanelHandler(){
    //     new ScfPreApproval();
    //     this.panelActiveComplete('panel-pre-offer');
    // }

    documentPanelHandler(){
        let that = this;
        let isAttached = false;
        document.getElementById('btn-panel-docs').addEventListener('click', function() {
            if(that.isValidPanel(this, false) && !isAttached) {
                that.panelActiveComplete('panel-docs');
                isAttached = true;
            }
            new ScfOnbPanelDocument();
        })
    }

    counterPartyPanelHandler(){
        let that = this;
        document.getElementById('btn-panel-counterparty').addEventListener('click', function() {
            that.renderCpRowsInTable();
        })
    }

    // documentCreatePlaceholders(data){
    //     let doc_temp = document.getElementById('doc-tmpl');
    //     let div_all_docs = document.getElementById('all_documents')
    //     div_all_docs.innerHTML = null;
    //     data.forEach(function (row){
    //         row.id = Math.random().toString(36);
    //         let rendered_tmpl =  Mustache.render(doc_temp.innerHTML, row);
    //         div_all_docs.append($.parseHTML(rendered_tmpl)[1])
    //     })
    //     this.getAllDocuments();
    // }
    //
    // documentUploadHandler(){
    //     let that = this;
    //     document.querySelectorAll('.upload-document').forEach((item) => {
    //         item.addEventListener('click', function(){
    //             let doc_container = document.getElementById(item.getAttribute('data-id'));
    //             doc_container.querySelector('.file-input').dispatchEvent(new MouseEvent('click'));
    //         })
    //     })
    //     document.querySelectorAll('.file-input').forEach((item) => {
    //         item.addEventListener('change', function(){
    //             that.documentUploadFile(item.getAttribute('data-id'));
    //         })
    //     })
    // }
    //
    // documentUploadFile(id){
    //     let that = this;
    //     let doc_container = document.getElementById(id);
    //     let file_code = doc_container.getAttribute('data-file-code');
    //     let file_input = doc_container.querySelector('.file-input');
    //     let preview_container =  doc_container.querySelector('.doc-preview');
    //     let preview_tmpl = document.getElementById('doc-preview-tmpl');
    //     let upload_btn = document.getElementById('btn-'+id);
    //     Array.from(file_input.files).forEach(function (fls){
    //         Utils.buttonWithLoader(upload_btn, Constants.ELEMENT_STATE.show);
    //         preview_container.append($.parseHTML(Mustache.render(preview_tmpl.innerHTML, {name: fls.name}))[1])
    //         let body = new FormData();
    //         body.set('file_data', fls)
    //         body.set('file_name', fls.name);
    //         body.set('file_password', null);
    //         let url = '/scf/api/application/' + that.application_id + '/document/upload/' + file_code;
    //         Utils.requestHandler(url, Constants.REQUEST.post, body)
    //             .then(function (response){
    //                 if(response.message === 'OK'){
    //                     document.getElementById('prev-'+fls.name).remove();
    //                     preview_container.append($.parseHTML(Mustache.render(preview_tmpl.innerHTML, {name: response.data.document_id, url: response.data.service_url}))[1])
    //                     Utils.showToast(response.message, 'Document uploaded', Constants.TOAST.success);
    //                 }else{
    //                     Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
    //                 }
    //             }).then(function (){
    //                 Utils.buttonWithLoader(upload_btn, Constants.ELEMENT_STATE.hide);
    //             })
    //     })
    // }

    productSelectHandler() {
        document.getElementById('application_product').addEventListener('change', function() {
            let tenureField = document.getElementById('application_tenure_required');
            const selectedProduct = this.value;
            const errorMessage = tenureField.nextElementSibling;
            tenureField.innerHTML = '';
            if (errorMessage && errorMessage.classList.contains('error')) {
                errorMessage.classList.add('no-display');
            }
        
            if (selectedProduct === 'short_term_loan') {
                const inputField = document.createElement('input');
                inputField.type = 'number';
                inputField.className = 'form-control form-control-lg';
                inputField.id = 'application_tenure_required';
                inputField.placeholder = 'Enter tenure';
                inputField.max = 120;
                if (inputField.value === '') {
                    errorMessage.textContent = 'Please enter a value between 1 and 120';
                    errorMessage.classList.remove('no-display');
                }
                // Add event listener for input validation
                inputField.addEventListener('input', function() {
                    const value = parseInt(this.value, 10);
                    if (value > 120 || value <= 0) {
                        errorMessage.textContent = 'Please enter a value between 1 and 120';
                        errorMessage.classList.remove('no-display');
                    } else {
                        errorMessage.classList.add('no-display');
                    }
                });
        
                tenureField.parentNode.replaceChild(inputField, tenureField);
            } else {
                const selectField = document.createElement('select');
                selectField.className = 'form-select form-control-lg';
                selectField.ariaLabel = 'vintage';
                selectField.id = 'application_tenure_required';
                selectField.dataset.label = 'vintage';
        
                const options = [
                    { value: '', text: 'Select an option', disabled: true, selected: true },
                    { value: '0-30 days', text: '0-30 days' },
                    { value: '30-60 days', text: '30-60 days' },
                    { value: '60-90 days', text: '60-90 days' },
                    { value: '> 90 days', text: '> 90 days' }
                ];
        
                options.forEach(optionData => {
                    const option = document.createElement('option');
                    option.value = optionData.value;
                    option.textContent = optionData.text;
                    if (optionData.disabled) option.disabled = true;
                    if (optionData.selected) option.selected = true;
                    selectField.appendChild(option);
                });
                if (selectField.value === '') {
                    errorMessage.textContent = 'Please select an option';
                    errorMessage.classList.remove('no-display');
                }        
                selectField.addEventListener('change', function() {
                    if (this.value === '') {
                        errorMessage.textContent = 'Please select an option';
                        errorMessage.classList.remove('no-display');
                    } else {
                        errorMessage.classList.add('no-display');
                    }
                }, { once: true });
        
                tenureField.parentNode.replaceChild(selectField, tenureField);
            }
        });             
    }

    editApplicationHandler(){
        let that = this;
        let params = Utils.assembleParameters();
        that.productSelectHandler()
        this.application_id = params.application_id;
        this.applicant_id = params.applicant_id;
        this.pan_name = params.pan_name;
        this.pan = params.pan;  
        if(this.application_id !== undefined){
            this.is_edit = true;
            this.max_panel = this.APP_MAX_ALLOWED_PANEL;
            Utils.requestHandler("/scf/api/onboarding/application/" + this.application_id, Constants.REQUEST.get, {})
                .then(function (response){
                    if(response.message === 'OK'){
                        if ((response.data.scf_application.status != 'application_drafted' && ScfPermission.isScfSales()) || response.data.scf_application.status == 'rejected' || response.data.lender_decision_submitted == true) {
                            alert('User cannot edit this application. Redirecting...');
                            window.location.href = '/scf/application';
                            return;
                        }
                        that.application_id = response.data.scf_application.id;
                        that.applicant_id = response.data.business_applicant.id;
                        that.udyam_reg_no = response.data.udyam_reg_no;
                        document.getElementById('scf_application_id').value = that.application_id;
                        document.getElementById('business_applicant_id').value = that.applicant_id;
                        localStorage.setItem('business_applicant_id', that.applicant_id);
                        localStorage.setItem('pan_name', response.data.business_applicant.pan_name);
                        localStorage.setItem('pan', response.data.business_applicant.pan);
                        that.editFillProductPanel(response);
                        that.editFillBusinessPagePanel(response);
                        // that.editSetupUserPanel(response);
                        // that.editSetupPanPanel(response.data.business_applicant);
                        // that.editProductPanel(response.data.scf_application);
                        // that.editQuestionPanel();
                        // that.editSetupBusinessEntity(response.data.business_applicant);
                        // that.editGstPanel();
                        // that.editOwnersPanel();
                        // that.editBankAccountsPanel();
                        // that.editUdyamPanel();
                        // that.editDocumentsPanel();
                        // that.editConsentsPanel();
                        // that.postApplicationPanels();
                    }
                })

                document.getElementById('scf_application_id').value = this.application_id
                document.getElementById('business_applicant_id').value = this.applicant_id
                localStorage.setItem('business_applicant_id', this.applicant_id);
                localStorage.setItem('pan_name', this.pan_name);
                localStorage.setItem('pan', this.pan);;
        }

        if (this.applicant_id && (this.application_id == undefined)){
            this.is_edit = true;
            this.max_panel = this.APP_MAX_ALLOWED_PANEL;

            document.getElementById('business_applicant_id').value = this.applicant_id
            localStorage.setItem('business_applicant_id', this.applicant_id);
            localStorage.setItem('pan_name', this.pan_name);
            localStorage.setItem('pan', this.pan);;
    }

    }

    editFillProductPanel(response){
        // document.getElementById('required_amount_head').innerHTML = response.data.scf_application.required_amount;
        document.getElementById('application_tenure_required').value = response.data.scf_application.requested_tenure;
        document.getElementById('required_amount').value = response.data.scf_application.required_amount;
        document.getElementById('application_product').value = response.data.scf_application.product;
        if( response.data.scf_application.anchor_leads_lot_id != null){
            document.getElementById('application_product').disabled = true
            document.getElementById('div_cp_ledger_documents').classList.add('no-display');
            document.getElementById('btn_cp_add_modal_counterparty').classList.add("no-display");
        }
        if(response.data.scf_application.product === 'short_term_loan'){
            document.getElementById('btn-panel-counterparty').classList.add("no-display");
        }
        else{
            document.getElementById('btn-panel-counterparty').classList.remove("no-display");
        }
    }

    editFillBusinessPagePanel(response){
        document.getElementById('businessContactAddress').value = response.data.scf_application.communication_address;
        document.getElementById('communicationZipcode').value = response.data.scf_application.communication_zipcode;
        document.getElementById('communicationCity').value = response.data.scf_application.communication_city;
        document.getElementById('communicationState').value = response.data.scf_application.communication_state;
        document.getElementById('udyamSearchField').value = response.data.udyam_reg_no;
    }

    editSetupUserPanel(response){
        let that = this;
        that.obj_user.user_id = response.data.user.id;
        that.obj_user.first_name = response.data.user.first_name;
        that.obj_user.last_name = response.data.user.last_name;
        that.obj_user.email = response.data.user.email;
        that.obj_user.mobile = response.data.user.mobile;
        that.obj_user.name_of_org = response.data.organization.name;
        that.obj_user.setValues();
        Utils.toggleInput(that.obj_user.elem_mobile_otp, false);
        Utils.toggleInput(that.obj_user.elem_email_otp, false);
        Utils.toggleInput(that.obj_user.elem_pasword, false);
        that.panelActiveComplete('panel-sign-up', false);
    }

    editSetupPanPanel(business_applicant){
        let that = this;
        that.obj_pan.company_name = business_applicant.name;
        that.obj_pan.company_pan = business_applicant.pan;
        that.obj_pan.company_pan_type = business_applicant.constitution;
        that.obj_pan.setValues();
        that.obj_pan.elem_company_name.parentElement.classList.remove('no-display');
        that.obj_pan.elem_company_name.parentElement.querySelector('#danger').classList.add('no-display');
        that.obj_pan.elem_company_name.parentElement.querySelector('#success').classList.remove('no-display');
        document.getElementById('btn-validate-pan').classList.add('no-display');
        Utils.toggleInput(that.obj_pan.elem_company_pan, false);
        Utils.toggleInput(that.obj_pan.elem_company_pan_type, false);
        Utils.toggleInput(that.obj_pan.elem_company_pan_type, false);
        that.panelActiveComplete('panel-pan', false);
    }

    editProductPanel(scf_application){
        let that = this;
        that.obj_application.product = scf_application.product;
        ImageBitmapRenderingContext.pry
        that.obj_application.requested_tenure = scf_application.required_tenure;
        that.obj_application.required_amount = scf_application.required_amount;
        that.obj_application.setValues();
        Utils.toggleInput(that.obj_application.elem_application_product, false);
        that.panelActiveComplete('panel-product', false);
    }

    editQuestionPanel(){
        let that = this;
        that.panelActiveComplete('panel-quest', false);
    }

    editSetupBusinessEntity(business_applicant){
        let that = this;
        that.obj_mca.cin_or_llp_id = business_applicant.corporate_id;
        that.obj_mca.company_name = business_applicant.name ;
        that.obj_mca.company_type = business_applicant.constitution ;
        that.obj_mca.company_address = business_applicant.address ;
        that.obj_mca.company_city = business_applicant.city ;
        that.obj_mca.company_state = business_applicant.state ;
        that.obj_mca.company_zipcode = business_applicant.zipcode ;
        that.obj_mca.company_email = business_applicant.email ;
        that.obj_mca.setValues();
        document.getElementById('company_selection_div').classList.add('no-display');
        document.getElementById('company_details_div').classList.remove('no-display');
        document.getElementById('btn-back-selection').classList.add('no-display');
        document.getElementById('btn-create-business-applicant').classList.add('no-display');
        that.panelActiveComplete('panel-mca', false);
    }

    editGstPanel(){
        this.panelActiveComplete('panel-gst', false);
    }

    editOwnersPanel(){
        this.panelActiveComplete('panel-owners', false);
    }

    editUdyamPanel(){
        this.panelActiveComplete('panel-udyam', false);
    }

    editBankAccountsPanel(){
        let that = this;
        Utils.requestHandler("/scf/api/application/" + that.application_id + '/bank-accounts', Constants.REQUEST.get, {})
            .then(function (response){
                if(response.message === 'OK'){
                    response.data.bank_accounts.forEach(bank_account => {
                        let abs_banking = new AbstractBanking(bank_account.id);
                        abs_banking.account_number = bank_account.account_number;
                        abs_banking.account_type = bank_account.account_type;
                        abs_banking.account_holder = bank_account.primary_account_holder_name;
                        abs_banking.verified_account_holder = bank_account.primary_account_holder_name;
                        abs_banking.ifsc_code = bank_account.ifsc_code;
                        abs_banking.bank_branch = bank_account.branch;
                        abs_banking.bank_name = bank_account.bank_name;
                        abs_banking.bank_micr = bank_account.micr_code;
                        that.bankingSetupButtonIds(abs_banking.id);
                        that.obj_banking.push(abs_banking);
                        // that.panelActiveComplete('panel-banking', false);
                    })
                    that.bankRenderVerifiedAccounts();
                    Utils.showToast('Fetched', 'Bank Accounts', Constants.TOAST.success);
                }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
    }

    editDocumentsPanel(){
        this.panelActiveComplete('panel-docs', false);
    }

    editConsentsPanel(){
        let all_consents = document.querySelectorAll('.consent-box');
        all_consents.forEach(function (inp_consent){
            inp_consent.checked = true;
            Utils.toggleInput(inp_consent, false);
        })
        this.panelActiveComplete('panel-consent');
    }

    postApplicationPanels(){
        this.max_panel = this.POST_APP_MAX_ALLOWED_PANEL;
        document.querySelectorAll('.post-onb').forEach(function (elem){
            elem.classList.remove('no-display');
        })
        this.counterPartiesPanel();
        this.postSanctionPanel();
    }

    counterPartiesPanel(){
        let that = this;
        document.getElementById('btn-panel-counterparty').addEventListener('click', function (){
            new ScfOnbPanelCounterParty();
            that.panelActiveComplete('panel-counterparty');
        })
    }

    postSanctionPanel(){
        this.uploadSampleInvoice();
    }

    uploadSampleInvoice(){
        let that = this;
        const elem_div_inv_upload = document.getElementById('div_inv_upload');
        const elem_inv_preview = document.getElementById('inv_preview');
        DocumentUtils.bindInputElement(elem_div_inv_upload);
        DocumentUtils.showOfflinePreview(elem_div_inv_upload, elem_inv_preview);
        let inp_elem = elem_div_inv_upload.parentElement.querySelector('.file-input');
        let btn_action = document.getElementById('btn-submit-invoice');
        btn_action.addEventListener('click', function (){
            let url = '/scf/api/application/' + that.application_id + '/document/upload/sample_invoice';
            DocumentUtils.uploadOnElementTrigger(btn_action, inp_elem, url);
        })
        btn_action.addEventListener('ev_document_uploaded', function (){
            Utils.clean(document.getElementById('sample_invoice_div'));
            elem_inv_preview.innerHTML = null;
        })
    }

}
