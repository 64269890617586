import {DocumentUtils, Utils} from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from 'mustache';
import { ScfPermission } from "../scf_auth_handler";

export default class  ScfAppPanelDocument{

    constructor() {
        this.scf_application_id = document.getElementById('scf_application_id');
        this.business_applicant_id = document.getElementById('business_applicant_id');
        this.elem_document_details = document.getElementById('div_document_details');
        this.elem_password_modal = new bootstrap.Modal(document.getElementById('modal_doc_password'), {});
        this.elem_password = document.getElementById('doc_password');
        this.elem_btn_password = document.getElementById('btn-update-password');
        this.renderUploadedDocuments();
        document.getElementById("nav-counterparty").click();
    }

    reRenderuploadedDocuments(){
        let that =this;
        Utils.requestHandler('/scf/api/application/' + that.scf_application_id.value + '/documents', Constants.REQUEST.get, {})
            .then(function (response){
                if(response.message === 'OK'){
                    that.renderDocument(response.data);
                }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
    }

    renderUploadedDocuments(){
        let that = this;
        document.getElementById('nav-documents').addEventListener('click', function(){
            Utils.requestHandler('/scf/api/application/' + that.scf_application_id.value + '/documents', Constants.REQUEST.get, {})
                .then(function (response){
                    if(response.message === 'OK'){
                        that.renderDocument(response.data);
                    }else{
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                })
        });
    }

    transformDocumentData(ref, last_doc){
        let drop_ship = {}
        drop_ship.doc_title = ref.set_value.title;
        drop_ship.doc_subtitle = "Last updated " + Utils.formatTimeRelative(last_doc.updated_at);
        drop_ship.dtype = ref.set_key;
        drop_ship.ext = "";
        if(ref.set_value.ext){
            drop_ship.ext = ref.set_value.ext.map(function(x) {return x.toUpperCase()});
        }
        let ext = ref.set_value.ext[0];
        if(ext && ['jpeg', 'jpg', 'png'].includes(ext.toLowerCase())) {
            drop_ship.logo = "bi-file-earmark-image";
        }else if(ext && ['xls', 'xlsx'].includes(ext.toLowerCase())) {
            drop_ship.logo = "bi-filetype-xls";
        }else if(ext && ['pdf'].includes(ext.toLowerCase())){
            drop_ship.logo = "bi-file-earmark-pdf";
        }else{
            drop_ship.logo = "bi-file-earmark-text";
        }
        return drop_ship;
    }

    transformPreviewData(preview_data){
        preview_data.formatted_ts = Utils.formatTime(preview_data.created_at);
        preview_data.formatted_ts_ago = Utils.formatTimeRelative(preview_data.created_at);
        preview_data.name = preview_data.id;
        return preview_data;
    }

    renderDocument(data){
        if(data.documents.length < 1){
            return;
        }
        let that = this;
        let tmpl = document.getElementById('doc-tmpl');
        let p_tmpl = document.getElementById('doc-preview-tmpl');
        this.elem_document_details.innerHTML = '';
        data.documents = data.documents.sort(function (row){
            return row.dtype;
        })
        let grped_data = Utils.groupBy(data.documents, 'file_code');
        for(let dtype in grped_data){
            let d = that.transformDocumentData(data.ref[dtype], grped_data[dtype][0]);
            let rendered = Mustache.render(tmpl.innerHTML, d);
            that.elem_document_details.append($.parseHTML(rendered)[1]);
            let prev = document.getElementById('prev-'+dtype);
            let url = '/scf/api/application/' + that.scf_application_id.value + '/document/upload/' + dtype;
            let action_element = document.getElementById('btn_upload_'+dtype);
            DocumentUtils.bindInputElement(action_element);
            DocumentUtils.uploadFiles(action_element, url);
            that.showUploadPreview(action_element);
            grped_data[dtype].forEach(function (doc_data){
                let d1 = that.transformPreviewData(doc_data);
                let rend = Mustache.render(p_tmpl.innerHTML, d1);
                prev.append($.parseHTML(rend)[1]);
            })
        }
        document.querySelectorAll('.btn-doc-password').forEach(function (elem){
            elem.addEventListener('click', function (){
                that.passwordHandler(elem);
            })
        })
        that.updatePasswordHandler();
        that.deleteDocument(data);
    }

    passwordHandler(elem){
        this.elem_password.value = elem.getAttribute('data-password');
        this.elem_btn_password.setAttribute('data-id',  elem.getAttribute('data-id'));
        this.elem_password_modal.show();
    }

    deleteDocument(data) {
        let that = this;
        let elements = document.querySelectorAll('#delete-button-document');
        elements.forEach(function(element) {
            if (ScfPermission.isScfLender() || ScfPermission.isScfCounterparty() || data.status == 'rejected') {
                element.classList.add("no-display");
            }
            element.addEventListener('click', function (event) {
                let id = event.target.getAttribute("data-id");
                let confirmed=window.confirm("Are you sure you want to delete this document?")
                if(confirmed){
                    Utils.requestHandler('/scf/api/application/'+that.scf_application_id.value+'/document/destroy/'+id,
                    Constants.REQUEST.get)
                    .then(function(response){
                        if(response.message==='OK'){
                            element.disabled = true;
                            Utils.showToast(response.message, 'Deleted', Constants.TOAST.success);
                            that.reRenderuploadedDocuments();
                        }
                        else{
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    })
                }

            });
        });
    }

    updatePasswordHandler(){
        let that = this;
        this.elem_btn_password.addEventListener('click', function (){
            Utils.requestHandler('/core/api/document/'+ that.elem_btn_password.getAttribute('data-id'), Constants.REQUEST.post, {document_id: that.elem_btn_password.getAttribute('data-id'), file_password: that.elem_password.value})
                .then(function (response){
                    if(response.message === 'OK'){
                        document.getElementById('btn_password_'+response.data.id).setAttribute('data-password', response.data.decrypted_digest);
                        that.elem_password_modal.hide();
                        Utils.showToast(response.message, 'Password updated', Constants.TOAST.success);
                    }else{
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                })
        })
    }

    showUploadPreview(action_element){
        let that = this;
        let p_tmpl = document.getElementById('doc-preview-tmpl');
        action_element.addEventListener('ev_document_uploaded', function (){
            let response = JSON.parse(action_element.getAttribute('data-upload-response'));
            let prev = document.getElementById('prev-'+response.file_code);
            let d1 = that.transformPreviewData(response);
            let rend = Mustache.render(p_tmpl.innerHTML, d1);
            prev.prepend($.parseHTML(rend)[1]);
            let pass_elem = document.getElementById('btn_password_'+response.id);
            pass_elem.addEventListener('click', function (){
                that.passwordHandler(pass_elem);
            })
        })
    }


}



