import {Utils} from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from "mustache";
import {ScfPermission} from "../scf_auth_handler";

export default class ScfAppPanelLenderDecision{

  constructor() {
    this.scf_application_id = document.getElementById('scf_application_id').value
    this.business_applicant_id = document.getElementById('business_applicant_id');
    this.business_applicant_name = document.getElementById('business_applicant_name');
    this.lender_application_id =  document.getElementById('lender_application_id').value;

    this.lender_decision_amount = null;
    this.lender_decision_roi = null;
    this.lender_decision_processing_fee = null;
    this.lender_decision_facility_validity = null;
    this.lender_decision_app_decision = null;
    this.lender_decision_app_remarks = null;

    this.elem_lender_decision_amount = document.getElementById('lender_decision_amount');
    this.elem_lender_decision_roi= document.getElementById('lender_decision_roi');
    this.elem_lender_decision_processing_fee = document.getElementById('lender_decision_processing_fee');
    this.elem_lender_decision_facility_validity = document.getElementById('lender_decision_facility_validity');
    this.elem_lender_decision_app_decision = document.getElementById('lender_decision_app_decision');
    this.elem_lender_decision_app_remarks = document.getElementById('lender_decision_app_remarks');

    this.handler();

    this.elem_lender_decision_amount.addEventListener('input', function (){
      Utils.reInput(this);
    })
    this.elem_lender_decision_roi.addEventListener('input', function (){
      Utils.reInput(this);
    })
    this.elem_lender_decision_processing_fee.addEventListener('input', function (){
      Utils.reInput(this);
    })
    this.elem_lender_decision_facility_validity.addEventListener('input', function (){
      Utils.reInput(this);
    })
    this.elem_lender_decision_app_decision.addEventListener('input', function (){
      Utils.reInput(this);
    })
    this.elem_lender_decision_app_remarks.addEventListener('input', function (){
      Utils.reInput(this);
    })
  }
  handler(){
    this.fetchLenderApplication();
    this.submitLenderDecision();
    // 1) Set Current Lender and appliaction
    // 2) Get Lender Application Data and Populate the Input fields
    // 3) On submit Update the lender Application

  }

  setValues(){
    Utils.setter(this.elem_lender_decision_amount, this.lender_decision_amount);
    Utils.setter(this.elem_lender_decision_roi, this.lender_decision_roi);
    Utils.setter(this.elem_lender_decision_processing_fee, this.ender_decision_processing_fee);
    Utils.setter(this.elem_lender_decision_facility_validity, this.lender_decision_facility_validity);
    Utils.setter(this.elem_lender_decision_app_decision, this.lender_decision_app_decision);
    Utils.setter(this.elem_lender_decision_app_remarks, this.lender_decision_app_remarks);
  }

  getValues(){
    let that = this;
    this.lender_decision_amount = Utils.getter(this.elem_lender_decision_amount);
    this.lender_decision_roi = Utils.getter(this.elem_lender_decision_roi);
    this.lender_decision_processing_fee = Utils.getter(this.elem_lender_decision_processing_fee);
    this.lender_decision_facility_validity = Utils.getter(this.elem_lender_decision_facility_validity);
    this.lender_decision_app_decision = Utils.getter(this.elem_lender_decision_app_decision);
    this.lender_decision_app_remarks = Utils.getter(this.elem_lender_decision_app_remarks);
  }

  validateValues(){
    let pass = true;
    if(!this.lender_decision_amount){
      Utils.showInputError(this.elem_lender_decision_amount, 'Amount is Mandatory');
      pass = false;
    }

    if(!this.lender_decision_roi){
      Utils.showInputError(this.elem_lender_decision_roi, 'ROI is Mandatory');
      pass = false;
    }

    if(!this.lender_decision_processing_fee){
      Utils.showInputError(this.elem_lender_decision_processing_fee, 'Processing fee is Mandatory');
      pass = false;
    }
    if(!this.lender_decision_facility_validity){
      Utils.showInputError(this.elem_lender_decision_facility_validity, 'Facility Validity is Mandatory');
      pass = false;
    }
    if(!this.lender_decision_app_decision){
      Utils.showInputError(this.elem_lender_decision_app_decision, 'Decision is Mandatory');
      pass = false;
    }
    if(!this.lender_decision_app_remarks){
      Utils.showInputError(this.elem_lender_decision_app_remarks, 'Remarks is Mandatory');
      pass = false;
    }
    return pass;
  }

  formatSnakeString(input) {
    const words = input.split('_');
    const formattedString = words.map(word => 
        word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
    return formattedString;
}

  fetchLenderApplication(){
    let that = this;
    Utils.requestHandler(
      "/scf/api/application/" + that.scf_application_id + "/lender-applications",
      Constants.REQUEST.get
    ).then(function (response) {
      if (response.message === "OK") {
        document.getElementById('nav-panel-decision').classList.remove('no-display')
        if (response.data.lender_applications[0].status == 'lender_approved'){
          document.getElementById('lender_decision_submit').classList.add('no-display')
          document.getElementById('lender_decision_app_decision').value = 'approve'
        }
        if(response.data.tagged_program.product === "short_term_loan"){
          document.getElementById('tb-lender-decision').classList.add("no-display");
        }
        document.getElementById('lender_decision_app_remarks').value = response.data.lender_applications[0].remarks
        document.getElementById('lender_decision_amount').value = response.data.lender_applications[0].approved_limit
        document.getElementById('lender_decision_roi').value = response.data.lender_applications[0].roi
        document.getElementById('lender_decision_processing_fee').value = response.data.lender_applications[0].processing_fee
        document.getElementById('lender_decision_facility_validity').value = response.data.lender_applications[0].facility_validity
        document.getElementById('lender_application_id').value = response.data.lender_applications[0].id
        document.getElementById('lender_decision_program').value = response.data.tagged_program.program_name
        document.getElementById('app_product').value = that.formatSnakeString(response.data.tagged_program.product)
        document.getElementById('lender_decision_product').value = that.formatSnakeString(response.data.tagged_program.product)
        that.fetchCounterParties(response);
      } else {

      }
    })
      .then(function () {});
    
  }

  fetchCounterParties(response){
    let that = this;
    if(response.message === 'OK'){
      let tmpl = document.getElementById('lender-decision-cp-row-tmpl');
      let dx = document.getElementById('tb-lender-decision-cp-tbody');
      dx.innerHTML = null;
      response.data.lender_applications[0].proposed_cps.forEach(function (dd){
        let rendered = Mustache.render(tmpl.innerHTML, dd);
        dx.append($.parseHTML(rendered)[1]);
      })
      that.populateSelectedCpIds();
      response.data.lender_applications[0].lender_app_cps.forEach(function(item, index) {
        let selectBox = document.querySelector(`[data-id='${item}']`);
        selectBox.checked = true;  
      });
      const idContainer = document.getElementById('lender_app_decision_cp_ids');
      idContainer.setAttribute('data-cp-ids', response.data.lender_applications[0].lender_app_cps);
      
      Utils.showToast('Fetched', 'Counterparties fetched', Constants.TOAST.success);
    }else{
      // Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
    }
   
  }

  populateSelectedCpIds() {
    const checkboxes = document.querySelectorAll('.lender_app_decision_cp_checkbox');
    const idContainer = document.getElementById('lender_app_decision_cp_ids');

    const updateIds = () => {
        const selectedIds = Array.from(checkboxes) 
                                .filter(checkbox => checkbox.checked)
                                .map(checkbox => checkbox.dataset.id);

        idContainer.setAttribute('data-cp-ids', selectedIds.join(','));
    };

    checkboxes.forEach(checkbox => {
        checkbox.addEventListener('change', updateIds);
    });

    updateIds();
  }

  submitLenderDecision(){
    let that = this;
    document.getElementById('lender_decision_submit').addEventListener('click', function(){
      const idContainer = document.getElementById('lender_app_decision_cp_ids');
      const dataCpIds = idContainer.getAttribute('data-cp-ids');
      that.getValues();
      if(that.validateValues()){
        let body = {
          approved_limit: that.lender_decision_amount,
          roi: that.lender_decision_roi,
          processing_fee: that.lender_decision_processing_fee,
          counter_parties: dataCpIds,
          facility_validity: that.lender_decision_facility_validity,
          decision: that.lender_decision_app_decision,
          remarks: that.lender_decision_app_remarks
        }

        Utils.requestHandler(
          "/scf/api/lender_application/" +  document.getElementById('lender_application_id').value + "/edit-application",
          Constants.REQUEST.post, body
        ).then(function (response) {
          if (response.message === "OK") {
            Utils.showToast('Success', 'Decision Submitted', Constants.TOAST.success);
          } else {
            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
          }
        })
      }  
    });
  }

}