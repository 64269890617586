import { DocumentUtils, Utils } from "../core/utils";
import * as Constants from "../core/constants";
import Mustache from 'mustache';

export class AnchorNewInvoicesLot {
  constructor() {
    Utils.pageSanityHandler();
    this.anchor_id = null;
    this.program = null;
    this.uploaded_file = null;

    this.elem_anchor_id = document.getElementById('select_anchor');
    this.elem_program = document.getElementById('select_program');
    this.elem_uploaded_file = document.getElementById('anchor_lead_lot_upload');

    this.elem_anchor_id.addEventListener('input', function (){
      Utils.reInput(this);
    })

    this.elem_program.addEventListener('input', function (){
      Utils.reInput(this);
    })

    this.elem_uploaded_file.addEventListener('input', function (){
      Utils.reInput(this);
    })
  }

  setValues(){
    Utils.setter(this.elem_anchor_id, this.anchor_id);
    Utils.setter(this.elem_program, this.program);
    Utils.setter(this.elem_uploaded_file, this.uploaded_file);
  }

  getValues(){
    this.anchor_id = Utils.getter(this.elem_anchor_id);
    this.program = Utils.getter(this.elem_program);
    this.uploaded_file = Utils.getter(this.elem_uploaded_file);
  }

  validateValues(){
    let pass = true;
    if(!this.anchor_id){
      Utils.showInputError(this.elem_anchor_id, 'Anchor is mandatory');
      pass = false;
    }
    if(!this.program){
      Utils.showInputError(this.elem_program, 'program is mandatory');
      pass = false;
    }
    if(!this.uploaded_file){
      Utils.showInputError(this.elem_uploaded_file, 'File is mandatory');
      pass = false;
    }
    
    return pass;
  }

  handler() {
    this.fetchAllAnchorsList();
    this.submitAnchorInvoicesLot();
    this.fetchAllPrograms();
  }

  fetchAllPrograms(){
    let that = this;
    Utils.requestHandler('/scf/api/program/anchor-programs', Constants.REQUEST.get, {})
    .then(function (response){
      if(response.message === 'OK' ){
        let selectField = that.elem_program;
        
        while (selectField.options.length > 1) {
          selectField.remove(1);
        }
    
        response.data.program.forEach(program => {
          let option = document.createElement('option');
          option.value = program.id;
          option.text = program.name;
          selectField.add(option);
        });
      }
    }) 
  }

  fetchAllAnchorsList(){
    let that = this;
    Utils.requestHandler('/anchor/api/applicant/get-anchors-list', Constants.REQUEST.get, {})
    .then(function (response){
      if(response.message === 'OK' ){
        let selectField = that.elem_anchor_id;
        
        while (selectField.options.length > 1) {
          selectField.remove(1);
        }
    
        response.data.anchors.forEach(anchor => {
          let option = document.createElement('option');
          option.value = anchor.id;
          option.text = anchor.name;
          selectField.add(option);
        });
      }
    }) 
  }

  submitAnchorInvoicesLot() {
    const submitButton = document.getElementById('btn-submit-details');
    submitButton.addEventListener('click', () => {
      this.getValues();
  
      if (!this.validateValues()) {
        return; 
      }
  
      const files = this.elem_uploaded_file.files;
      if (files.length === 0) {
        Utils.showToast(
          "Not Found",
          "At least one file is mandatory",
          Constants.TOAST.fail
        );
        return;
      }
  
      const fileData = new FormData();
      Array.from(files).forEach(file => {
        fileData.append("files[]", file);
      });
      fileData.append("file_password", null); // Consider removing or securely handling password
      fileData.append("file_code", "anchor_leads_csv");
      fileData.append("anchor_id", this.anchor_id);
      fileData.append("program_id", this.program);
  
      Utils.loaderToast(Constants.ELEMENT_STATE.show);
  
      Utils.requestHandler('/anchor/api/anchor_invoices_lot/new', Constants.REQUEST.post, fileData)
        .then(response => {
          Utils.loaderToast(Constants.ELEMENT_STATE.hide);
          if (response.message === 'OK') {
            Utils.showToast(response.message, response.data.message, Constants.TOAST.success);
            submitButton.disabled = true;
          } else {
            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
          }
        })
        .catch(error => {
          Utils.loaderToast(Constants.ELEMENT_STATE.hide);
          Utils.showToast("Error", "An error occurred while uploading the files.", Constants.TOAST.fail);
          console.error('Upload error:', error);
        });
    });
  }
  

}