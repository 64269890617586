import {Utils} from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from 'mustache';

class AbstractPromoter {

  constructor(id){
    this.id = id;
    this.title = null;
    this.pan = null;
    this.full_name = null;
    this.first_name = null;
    this.last_name = null;
    this.dob = null;
    this.gender = null;
    this.email = null;
    this.mobile = null;
    this.address = null;
    this.pincode = null;
    this.city = null;
    this.state = null;
    this.years_at_current_address = null;
    this.elem_title = document.getElementById('titleOfBorrower');
    this.elem_pan = document.getElementById('promoterPan');
    this.elem_full_name = document.getElementById('promoterName');
    this.elem_first_name = document.getElementById('promoterFirstName');
    this.elem_last_name = document.getElementById('promoterLastName');
    this.elem_dob = document.getElementById('promoterBirthDate');
    this.elem_gender = document.getElementById('promoterGender');
    this.elem_email = document.getElementById('promoterEmail');
    this.elem_mobile = document.getElementById('promoterMobile');
    this.elem_address = document.getElementById('promoterAddress');
    this.elem_pincode = document.getElementById('promoterPincode');
    this.elem_city = document.getElementById('promoterCity');
    this.elem_state = document.getElementById('promoterState');
    this.elem_years_at_current_address = document.getElementById('promoterYearsAtCurrentAddress');
    this.elem_share_holding = document.getElementById('promotershareholding');
    this.elem_relationship = document.getElementById('titleRelationShipwith');
    this.isProperietor = "false";
  }

  getValues(){
    this.title = Utils.getter(this.elem_title);
    this.pan = Utils.getter(this.elem_pan);
    this.full_name = Utils.getter(this.elem_full_name);
    this.first_name = Utils.getter(this.elem_first_name);
    this.last_name = Utils.getter(this.elem_last_name);
    this.dob = Utils.getter(this.elem_dob);
    this.gender = Utils.getter(this.elem_gender);
    this.email = Utils.getter(this.elem_email);
    this.mobile = Utils.getter(this.elem_mobile);
    this.address = Utils.getter(this.elem_address);
    this.pincode = Utils.getter(this.elem_pincode);
    this.city = Utils.getter(this.elem_city);
    this.state = Utils.getter(this.elem_state);
    this.years_at_current_address = Utils.getter(this.elem_years_at_current_address);
    this.promoter_shareholding = Utils.getter(this.elem_share_holding)
    this.relationship = Utils.getter(this.elem_relationship);
  }

  setValues() {
    let dropdown = document.getElementById("titleOfBorrower");
    if (dropdown) {
      let options = dropdown.options;
      let isPresent = Array.from(options).some(option => option.value === "PROPRIETOR");
        if (this.title === "PROPRIETOR") {
            if (!isPresent) {
                let option = document.createElement('option');
                option.value = "PROPRIETOR";
                option.text = "PROPRIETOR";
                dropdown.add(option);
            }
        }
    }
    Utils.setter(this.elem_title, this.title);
    Utils.setter(this.elem_pan, this.pan);
    Utils.setter(this.elem_full_name, this.full_name);
    Utils.setter(this.elem_first_name, this.first_name);
    Utils.setter(this.elem_last_name, this.last_name);
    Utils.setter(this.elem_dob, this.dob);
    Utils.setter(this.elem_gender, this.gender);
    Utils.setter(this.elem_email, this.email);
    Utils.setter(this.elem_mobile, this.mobile);
    Utils.setter(this.elem_address, this.address);
    Utils.setter(this.elem_pincode, this.pincode);
    Utils.setter(this.elem_city, this.city);
    Utils.setter(this.elem_state, this.state);
    Utils.setter(this.elem_years_at_current_address, this.years_at_current_address);
    Utils.setter(this.elem_share_holding, this.promoter_shareholding)
    Utils.setter(this.elem_relationship, this.relationship);
  }

  validateValues(){
    return true;
  }
}


export default class ScfPromoterModule{

  constructor(){
    this.obj_promoter = [];
    let that = this;
    this.scf_application_id = document.getElementById('scf_application_id').value;
    this.business_applicant_id = localStorage.getItem('business_applicant_id');
    that.pomoterPanelHandler();
  }

  pomoterPanelHandler(){
    let that = this;
    that.getAllPromoters();
    that.showNewPromoterModal();
  }

  showNewPromoterModal(){
    let that = this;
    let pan = localStorage.getItem('pan');
    if (pan[3] == 'C') {
      document.getElementById('btn_show_add_new_promoter').classList.add('no-display')
    }
    document.getElementById('btn_show_add_new_promoter').addEventListener('click', function (){
      document.getElementById('promoter_details_modal_edit_status').value = 'false'
      document.getElementById("promoterPan").removeAttribute("disabled");
      let abs_promoter = new AbstractPromoter(Math.random().toString(36));
      abs_promoter.setValues();
    });
    document.getElementById('btn-promoter-modal-close').addEventListener('click', function(){
      that.getAllPromoters();
    })
  }

  getAllPromoters(){
    let that = this;
    that.obj_promoter = [];
    Utils.requestHandler("/scf/api/onboarding/company/directors/" + that.business_applicant_id , Constants.REQUEST.get, {})
        .then(function (response){
            if(response.message === 'OK'){
              response.data.forEach(promoter => {
                  let abs_promoter = new AbstractPromoter(promoter.id);
                  abs_promoter.title = promoter.ownership_type;
                  abs_promoter.pan = promoter.pan_number;
                  abs_promoter.din=promoter.din_number;
                  abs_promoter.full_name = promoter.full_name;
                  abs_promoter.first_name = promoter.first_name;
                  abs_promoter.last_name = promoter.last_name;
                  abs_promoter.dob = promoter.date_of_birth;
                  abs_promoter.gender = promoter.gender;
                  abs_promoter.email = promoter.email;
                  abs_promoter.mobile = promoter.mobile;
                  abs_promoter.address = promoter.address;
                  abs_promoter.pincode = promoter.zipcode;
                  abs_promoter.city = promoter.city;
                  abs_promoter.state = promoter.state;
                  abs_promoter.years_at_current_address = promoter.years_at_current_address;
                  abs_promoter.relationship = promoter.relationship;
                  abs_promoter.promoter_shareholding = promoter.share_holding;
                  that.obj_promoter.push(abs_promoter);
              })
              that.rederVerifiedPromoters();
              // Utils.showToast('Fetched', 'Promoters', Constants.TOAST.success);
            }else{
              // Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
        });
  }

  rederVerifiedPromoters(){
    let that = this;
    let isProperietor = "false"
    let temp = document.getElementById('promoters-tr-tmpl-business-details');
    let ac_table = document.getElementById('verified_promoters_details');
    ac_table.innerHTML = null;
    this.obj_promoter.forEach(function (obj_promoter_ind){
        if(obj_promoter_ind.full_name != null || obj_promoter_ind.pan != null){
          if(obj_promoter_ind.title === "PROPRIETOR"){
            let dropdown = document.getElementById("titleOfBorrower");
            let options = dropdown.options;
            for (let i = 0; i < options.length; i++) {
                if (options[i].value === "PROPRIETOR") {
                    isProperietor = "true";
                    dropdown.remove(i);
                    break; 
                }
            }
          }
          let rend_row = Mustache.render(temp.innerHTML, obj_promoter_ind);
          ac_table.append($.parseHTML(rend_row)[1]);
          that.promoterEditButton(obj_promoter_ind.id);    
        }
    })
    if(isProperietor==="false"){
      let dropdown = document.getElementById("titleOfBorrower");
      if (dropdown) {
        let options = dropdown.options;
        let isPresent = Array.from(options).some(option => option.value === "PROPRIETOR");
        if (this.title === "PROPRIETOR") {
            if (!isPresent) {
                let option = document.createElement('option');
                option.value = "PROPRIETOR";
                option.text = "PROPRIETOR";
                dropdown.add(option);
            }
          }
      }
    }
  }

  promoterEditButton(active_id){
    let that = this;
    document.getElementById('btn-edit-promoter-'+active_id).addEventListener('click', function () {
      document.getElementById('promoter_details_modal_edit_status').value = 'true'
      document.getElementById("promoterPan").disabled = true;
      var modal = new bootstrap.Modal(document.getElementById('promoterList'), {});
      modal.show();
      let abs_promoter = null;
      if(!active_id){
        Utils.showToast("Search", "Error finding Promoter", Constants.TOAST.fail);
      }
      that.obj_promoter.forEach(function (promoter){
        if(promoter.id === active_id){
          abs_promoter = promoter;
          return false;
        }
      })
      abs_promoter.setValues();
    })
  }

}  
