import { Utils } from "../core/utils";
import * as Constants from "../core/constants";
export class ScfSignIn {

    constructor() {

        this.email = null;
        this.password = null;
        this.otp = null;

        this.new_password = null;
        this.confirm_password = null;

        this.forgot_password_modal = new bootstrap.Modal(document.getElementById('forgot_password_modal'), {});
        this.fp_email = null;

        this.elem_email = document.getElementById('user-email');
        this.elem_password = document.getElementById('user-password');
        this.elem_otp = document.getElementById('user-otp');

        this.elem_new_password = document.getElementById('new-user-password');
        this.elem_confirm_password = document.getElementById('confirm-user-password')
        document.getElementById('show-hide-password').addEventListener('change',()=> {
            this.hiddeShowPasswordSignIn();
        });

        this.elem_fp_email = document.getElementById('fp-user-email')

        this.btn_sign_in = document.getElementById('btn-sign-in');

        this.btn_sign_up = document.getElementById('btn-sign-up');
    }

    getValues() {
        this.email = Utils.getter(this.elem_email);
        this.password = Utils.getter(this.elem_password);
        this.otp = Utils.getter(this.elem_otp);
    }

    hiddeShowPasswordSignIn() {
        var x = document.getElementById("user-password");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }

    validateValues(validateOtp = false) {
        let pass = true;
        if (!Utils.validatorEmail(this.email)) {
            Utils.showInputError(this.elem_email, 'Incorrect email address');
            pass = false;
        }
        if (!this.password) {
            Utils.showInputError(this.elem_password, 'Password is mandatory');
            pass = false;
        }

        if (validateOtp) {
            if (!this.otp) {
                Utils.showInputError(this.elem_otp, 'OTP is mandatory');
                pass = false;
            }
        }

        return pass;
    }

    passwordChangeValidateValues() {
        let pass = true;
        if (!this.new_password) {
            Utils.showInputError(this.elem_new_password, 'New password is mandatory');
            pass = false;
        }

        if (!this.confirm_password) {
            Utils.showInputError(this.elem_confirm_password, 'Confirm password is mandatory');
            pass = false;
        }

        if (this.new_password && this.confirm_password && this.new_password != this.confirm_password) {
            Utils.showInputError(this.elem_confirm_password, "Passwords don't not match");
            pass = false;
        }

        return pass;
    }

    passwordChangeGetValues() {
        this.new_password = Utils.getter(this.elem_new_password);
        this.confirm_password = Utils.getter(this.elem_confirm_password);
    }

    forgotPasswordValidateValues() {
        let pass = true;
        if (!Utils.validatorEmail(this.fp_email)) {
            Utils.showInputError(this.elem_fp_email, 'Incorrect email address');
            pass = false;
        }

        return pass;
    }

    forgotPasswordGetValues() {
        this.fp_email = Utils.getter(this.elem_fp_email);
    }
    
    handler() {
        let that = this;
        this.signIn();
        this.changePassword();
        this.validateOtp();
        this.reCaptchaHandler();

        document.getElementById('forgot_password').addEventListener('click', function () {
            that.forgot_password_modal.show();
        })

        this.sendNewPassword();
    }

    static logoutHandler() {
        ScfSignIn.logout();
    }

    static logout() {
        let elem = document.getElementById('logout');
        if (elem) {
            document.getElementById('logout').addEventListener('click', function () {
                Utils.requestHandler('/core/api/authentication/logout', Constants.REQUEST.post, {})
                    .then(function (response) {
                        if (response.message === 'OK') {
                            Utils.deleteCookie('_alf-permissions');
                            Utils.deleteCookie('_alf-session-id');
                            Utils.deleteCookie('_alf-user-id');
                            localStorage.removeItem('user_id');
                            localStorage.removeItem('business_applicant_id');
                            localStorage.removeItem('pan');
                            localStorage.removeItem('pan_name');
                            window.location = '/scf/'
                            Utils.showToast(response.message, 'Logout successful', Constants.TOAST.success);
                        } else {
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    })
            })
        }
    }

    reCaptchaHandler() {

    }

    signIn() {
        let that = this;
        document.getElementById('btn-sign-in').addEventListener('click', function () {
            that.getValues();
            if (that.validateValues) {
                that.signInHandler();
            }
        })

        document.getElementById('btn-resend-otp').addEventListener('click', function () {
            that.getValues();
            if (that.validateValues) {
                that.signInHandler();
            }
        })
    }

    signInHandler() {
        var captchaResponse = grecaptcha.getResponse();
        if (captchaResponse.length <= 0) {
            Utils.showToast("Invalid", "Captcha is mandatory", Constants.TOAST.fail);
            return false
        }

        Utils.loaderToast(Constants.ELEMENT_STATE.show);
        let that = this;
        let body = {
            email: that.email,
            password: that.password,
            url: Constants.SERVICE_BASE_BACKOFFICE + "/v2/send-email",
            req_type: "post"
        }
        Utils.requestHandler('/core/api/authentication/sign-in/email', Constants.REQUEST.post, body)
            .then(function (response) {
                if (response.message === 'OK') {
                    if (response.data.redirect) {
                        that.passwordChangeComponent();
                        Utils.showToast(response.message, 'Please change your password', Constants.TOAST.success);
                    } else if (response.data.otp) {
                        that.otpComponent();
                        Utils.showToast(response.message, 'OTP sent to verified Mail & Mobile', Constants.TOAST.success);
                    }
                } else {
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
                Utils.loaderToast(Constants.ELEMENT_STATE.hide);
            })
        that.elem_email.addEventListener('input', function () {
            Utils.reInput(this);
        })
        that.elem_password.addEventListener('input', function () {
            Utils.reInput(this);
        })
    }

    otpComponent(show = true) {
        if (show) {
            document.querySelectorAll('.pre-sign-in').forEach(elem => {
                elem.disabled = true;
            })

            document.querySelectorAll('.otp-sign-in').forEach(elem => {
                elem.classList.remove('no-display');
            })
        } else {
            document.querySelectorAll('.pre-sign-in').forEach(elem => {
                elem.disabled = false;
            })

            document.querySelectorAll('.otp-sign-in').forEach(elem => {
                elem.classList.add('no-display');
            })
        }

    }

    validateOtp() {
        var current_env = process.env.NODE_ENV || 'development';
        let that = this;

        document.getElementById('btn-validate-otp').addEventListener('click', function () {
            that.getValues();

            if (current_env == "development") {
                if (that.otp != "12345") {
                    alert("Invalid otp for dev environment");
                    return false;
                }
            }

            if (that.validateValues(true)) {
                Utils.loaderToast(Constants.ELEMENT_STATE.show);
                Utils.requestHandler('/core/api/authentication/validate/otp', Constants.REQUEST.post, { email: that.email, password: that.password, otp: that.otp })
                    .then(function (response) {
                        if (response.message === 'OK') {
                            localStorage.setItem('user_id', response.data.user_id)
                            localStorage.setItem('business_applicant_id', response.data.business_applicant_id)
                            localStorage.setItem('pan', response.data.pan)
                            localStorage.setItem('pan_name', response.data.pan_name)
                            that.userLoginRedirection(response);
                            Utils.saveCookie('_alf-permissions', response.data.permissions);
                            Utils.saveCookie('_alf-session-id', response.data.session_id);
                            Utils.saveCookie('_alf-user-id', response.data.user_id);
                            Utils.showToast(response.message, 'Verified successfully', Constants.TOAST.success);
                        } else {
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    })
                Utils.loaderToast(Constants.ELEMENT_STATE.hide);
            }
        })
    }

    userLoginRedirection(response) {
        if (response && response.data.scf_application_present == false) {
            window.location = '/scf/application/onboarding'
        } else {
            window.location = '/scf/application'
        }
    }

    passwordChangeComponent(show = true) {
        if (show) {
            document.querySelectorAll('.div-sign-in').forEach(elem => {
                elem.classList.add('no-display');
            })

            document.querySelectorAll('.password-change').forEach(elem => {
                elem.classList.remove('no-display')
            })
        } else {
            document.querySelectorAll('.div-sign-in').forEach(elem => {
                elem.classList.remove('no-display');
            })

            document.querySelectorAll('.password-change').forEach(elem => {
                elem.classList.add('no-display')
            })
        }
    }

    changePassword() {
        let that = this;
        document.getElementById('btn-change-password').addEventListener('click', function () {

            that.passwordChangeGetValues();
            if (that.passwordChangeValidateValues()) {
                Utils.loaderToast(Constants.ELEMENT_STATE.show);
                let body = {
                    email: that.email, 
                    old_password: that.password, 
                    new_password: that.new_password,
                    url: Constants.SERVICE_BASE_BACKOFFICE + "/v2/send-email",
                    req_type: "post"
                }
                Utils.requestHandler('/core/api/authentication/change/password', Constants.REQUEST.post, body)
                    .then(function (response) {
                        if (response.message === 'OK') {
                            that.passwordChangeComponent(false);
                            that.otpComponent(false);
                            Utils.showToast(response.message, 'Password Changed', Constants.TOAST.success);
                        } else {
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                        Utils.loaderToast(Constants.ELEMENT_STATE.hide);
                    })
            }

        })
    }

    sendNewPassword() {
        let that = this;
        document.getElementById('btn-fp-link').addEventListener('click', function () {
            that.forgotPasswordGetValues();
            if (that.forgotPasswordValidateValues()) {
                Utils.loaderToast(Constants.ELEMENT_STATE.show);
                let body = {
                    email: that.fp_email,
                    url: Constants.SERVICE_BASE_BACKOFFICE + "/v2/send-email",
                    req_type: "post"
                }
                Utils.requestHandler('/core/api/authentication/forgot/password', Constants.REQUEST.post, body)
                    .then(function (response) {
                        if (response.message === 'OK') {
                            that.forgot_password_modal.hide();
                            Utils.showToast(response.message, 'Password sent to registered mail', Constants.TOAST.success);
                        } else {
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                        Utils.loaderToast(Constants.ELEMENT_STATE.hide);
                    })
            }
        })
    }

}

export class ScfPermission {

    static isScfAdmin() {
        let perms = Utils.readCookie('_alf-permissions')
        if (perms) {
            return perms.split(",").includes("scf_admin");
        }
        return false
    }

    static isScfBorrower() {
        let perms = Utils.readCookie('_alf-permissions')
        if (perms) {
            return perms.split(",").includes("scf_borrower");
        }
        return false
    }

    static isScfCounterparty() {
        let perms = Utils.readCookie('_alf-permissions')
        if (perms) {
            return perms.split(",").includes("scf_counterparty");
        }
        return false
    }

    static isScfCredit() {
        let perms = Utils.readCookie('_alf-permissions')
        if (perms) {
            return perms.split(",").includes("scf_credit");
        }
        return false
    }

    static isScfSuperAdmin() {
        let perms = Utils.readCookie('_alf-permissions')
        if (perms) {
            return perms.split(",").includes("scf_super_admin");
        }
        return false
    }

    static isScfLender() {
        let perms = Utils.readCookie('_alf-permissions')
        if (perms) {
            return perms.split(",").includes("scf_lender");
        }
        return false
    }

    static isScfSales() {
        let perms = Utils.readCookie('_alf-permissions')
        if (perms) {
            return perms.split(",").includes("scf_sales");
        }
        return false
    }

}

export class ScfSignUp {

    constructor() {
        this.email = null;
        this.mobile = null;
        this.password = null;
        this.mobile_otp = null;
        this.email_otp = null;
        this.password_conf = null;
        this.tnc = null;
        this.tnc2 = null;
        this.pan = null;
        this.business_name = null;
        this.elem_pan = document.getElementById("company_pan");
        this.elem_email = document.getElementById('user_email');
        this.elem_business_name = document.getElementById('business_name');
        this.elem_mobile = document.getElementById('user_mobile');
        this.elem_mobile_otp = document.getElementById('user_mobile_otp');
        this.elem_email_otp = document.getElementById('user_email_otp');
        this.elem_password = document.getElementById('user_password');
        this.elem_password_confirmation = document.getElementById('user_password_confirmation');
        this.elem_tnc = document.getElementById('tnc');
        this.elem_tnc2 = document.getElementById('tnc2');
        this.elem_pan.addEventListener('input', function () {
            Utils.reInput(this);
        })
        this.elem_password.addEventListener('input', function () {
            Utils.reInput(this);
        })
        this.elem_mobile.addEventListener('input', function () {
            Utils.reInput(this);
        })
        this.elem_mobile_otp.addEventListener('input', function () {
            Utils.reInput(this);
        })
        this.elem_email.addEventListener('input', function () {
            Utils.reInput(this);
        })
        this.elem_email_otp.addEventListener('input', function () {
            Utils.reInput(this);
        })
        this.elem_password.addEventListener('input', function () {
            Utils.reInput(this);
        })
        this.elem_password_confirmation.addEventListener('input', function () {
            Utils.reInput(this);
        })
        this.elem_tnc.addEventListener('input', function () {
            Utils.reInput(this);
        })
        this.elem_tnc2.addEventListener('input', function () {
            Utils.reInput(this);
        })
    }

    getValues() {
        this.pan = Utils.getter(this.elem_pan)
        this.email = Utils.getter(this.elem_email) == null ? "" : Utils.getter(this.elem_email).toLowerCase();
        this.mobile = Utils.getter(this.elem_mobile);
        this.mobile_otp = Utils.capitalizeFirstLetter(Utils.getter(this.elem_mobile_otp));
        this.email_otp = Utils.capitalizeFirstLetter(Utils.getter(this.elem_email_otp));
        this.password = Utils.getter(this.elem_password);
        this.password_conf = Utils.getter(this.elem_password_confirmation);
        this.tnc = this.elem_tnc.checked
        this.tnc2 = this.elem_tnc2.checked
    }

    setValues() {
        Utils.setter(this.elem_email, this.email);
        Utils.setter(this.elem_mobile, this.mobile);
        Utils.setter(this.elem_mobile_otp, this.mobile_otp);
        Utils.setter(this.elem_email_otp, this.email_otp);
        Utils.setter(this.elem_password, this.password);
        Utils.setter(this.elem_password_confirmation, this.password_conf);
        Utils.setter(this.elem_tnc, this.tnc);
        Utils.setter(this.elem_tnc2, this.tnc2);
        return true;
    }

    validateValues() {
        let pass = true;
        if (!this.email) {
            Utils.showInputError(this.elem_email, 'Email is mandatory');
            pass = false;
        } else if (!Utils.validatorEmail(this.email)) {
            Utils.showInputError(this.elem_email, 'Incorrect email');
            pass = false;
        }
        if (!this.pan) {
            Utils.showInputError(this.elem_pan, 'PAN is mandatory');
            pass = false;
        } else if (!Utils.validatorPan(this.pan)) {
            Utils.showInputError(this.elem_pan, 'Incorrect PAN');
            pass = false;
        }
        if (!this.mobile) {
            Utils.showInputError(this.elem_mobile, 'Mobile is mandatory');
            pass = false;
        } else if (!Utils.validatorMobile(this.mobile)) {
            Utils.showInputError(this.elem_mobile, 'Incorrect mobile number');
            pass = false;
        }
        if (!this.mobile_otp) {
            Utils.showInputError(this.elem_mobile_otp, 'Please enter OTP');
            pass = false;
        }
        if (!this.email_otp) {
            Utils.showInputError(this.elem_email_otp, 'Please enter OTP');
            pass = false;
        }
        if (!this.password) {
            Utils.showInputError(this.elem_password, 'Password is mandatory');
            pass = false;
        }
        if (this.password != this.password_conf) {
            Utils.showInputError(this.elem_password_confirmation, 'Password not matching');
            pass = false;
        }
        if (!this.tnc) {
            Utils.showInputError(this.elem_tnc, 'Please aggree to the tnc');
            pass = false;
        }
        return pass;
    }
    hiddeShowPasswordSignup() {
        var x = document.getElementById("user_password");
        var y = document.getElementById("user_password_confirmation");
        if (x.type === "password" && y.type === "password") {
            x.type = "text";
            y.type = "text";
        } else {
            x.type = "password";
            y.type = "password";
        }
    }

    handler() {
        this.userPanelHandler();
    };

    userPanelHandler() {
        let that = this;
        document.getElementById('btn-sign-up').addEventListener('click', function () {
            that.getValues();
            const elem_btn = this;
            if (that.validateValues()) {
                Utils.buttonWithLoader(elem_btn, Constants.ELEMENT_STATE.show);
                let body = {
                    pan: that.pan,
                    pan_name: that.elem_business_name.value,
                    mobile_otp: that.elem_mobile_otp.value,
                    email_otp: that.elem_email_otp.value,
                    email: that.email,
                    mobile: that.mobile,
                    password: that.password,
                    url: Constants.SERVICE_BASE_BACKOFFICE + "/v2/send-email",
                    req_type: "post"
                }
                Utils.requestHandler('/scf/api/onboarding/user/', Constants.REQUEST.post, body)
                    .then(function (response) {
                        if (response.message === 'OK') {
                            that.user_id = response.data.id;
                            window.location = '/scf/'
                            Utils.showToast('Created', 'User created', Constants.TOAST.success);
                            // that.panelActiveComplete('panel-sign-up');
                            // that.proceedNext();
                        } else {
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    })
                    .then(function () {
                        Utils.buttonWithLoader(elem_btn, Constants.ELEMENT_STATE.hide);
                    })
            }
        })
        document.getElementById('show-hide-password').addEventListener('change', function () {
            that.hiddeShowPasswordSignup();
        })
        const inputField = that.elem_pan;
        // Add a focusout event listener to the input field
        inputField.addEventListener('focusout', function () {
            that.getValues();
            if (Utils.validatorPan(that.pan)) {
                Utils.loaderToast(Constants.ELEMENT_STATE.show);
                Utils.requestHandler('/scf/api/onboarding/name/' + that.pan, Constants.REQUEST.get, {})
                    .then(function (response) {
                        if (response.message === 'OK') {
                            if (response.data.full_name) {
                                that.elem_business_name.value = response.data.full_name;
                            } else {
                                Utils.showToast(response.message, 'No PAN matches this pattern', Constants.TOAST.fail);
                            }
                        } else {
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    }).then(function () {
                        Utils.loaderToast(Constants.ELEMENT_STATE.hide);  
                    })
            }
            else{
                Utils.showToast('Error', 'Invalid Pan Number', Constants.TOAST.fail);
            }
        });
        
        inputField.addEventListener('input', function () {
            that.elem_business_name.value = "";
        });

        const mobileOtpInputField = that.elem_mobile_otp;
        const sendMobileOtpButton = document.getElementById('send_mobile_otp_btn');
        const resendMobileOtpButton = document.getElementById('resend_mobile_otp_btn');

        const emailOtpInputField = that.elem_email_otp;
        const sendEmailOtpButton = document.getElementById('send_email_otp_btn');
        const resendEmailOtpButton = document.getElementById('resend_email_otp_btn');

        sendMobileOtpButton.addEventListener('click', function () {
            Utils.loaderToast(Constants.ELEMENT_STATE.show);
            let mobileOtpInputValue = document.getElementById('user_mobile').value;
            let that = this;
            if (Utils.validatorMobile(mobileOtpInputValue)) {
                Utils.requestHandler('/core/api/authentication/sign-up/send-sms-otp', Constants.REQUEST.post, {mobile_number: mobileOtpInputValue})
                .then(function (response) {
                    if (response.message === 'OK') {
                        sendMobileOtpButton.classList.add('no-display');
                        mobileOtpInputField.classList.remove('no-display');
                        resendMobileOtpButton.classList.remove('no-display');
                        Utils.showToast(response.message, 'OTP Sent to Mobile', Constants.TOAST.success);
                    } else {
                        Utils.showToast(response.message, 'Unable to Send Mobile OTP', Constants.TOAST.fail);
                    }
                });
            } else {
                Utils.showToast('Error', 'Incorrect Mobile Number', Constants.TOAST.fail);
            }  
            Utils.loaderToast(Constants.ELEMENT_STATE.hide);  
        })

        resendMobileOtpButton.addEventListener('click', function () {
            Utils.loaderToast(Constants.ELEMENT_STATE.show);
            let mobileOtpInputValue = document.getElementById('user_mobile').value;
            let that = this;
            if (Utils.validatorMobile(mobileOtpInputValue)) {
                Utils.requestHandler('/core/api/authentication/sign-up/send-sms-otp', Constants.REQUEST.post, {mobile_number: mobileOtpInputValue})
                .then(function (response) {
                    if (response.message === 'OK') {
                        Utils.showToast(response.message, 'OTP Sent to Mobile', Constants.TOAST.success);   
                    } else {
                        Utils.showToast(response.message, 'Unable to Send Mobile OTP', Constants.TOAST.fail);
                    }
                });
            } else {
                Utils.showToast('Error', 'Incorrect Mobile Number', Constants.TOAST.fail);
            }  
            Utils.loaderToast(Constants.ELEMENT_STATE.hide);
        })

        sendEmailOtpButton.addEventListener('click', function () {
            Utils.loaderToast(Constants.ELEMENT_STATE.show);
            let emailOtpInputValue = document.getElementById('user_email').value;
            let that = this;
            if (Utils.validatorEmail(emailOtpInputValue)) {
                let body = {
                    "url": Constants.SERVICE_BASE_BACKOFFICE + "/v2/send-email",
                    "req_type": "post",
                    "mail": emailOtpInputValue
                }
                Utils.requestHandler('/core/api/authentication/sign-up/send-email-otp', Constants.REQUEST.post, body)
                .then(function (response) {
                    if (response.message === 'OK') {
                        sendEmailOtpButton.classList.add('no-display');
                        emailOtpInputField.classList.remove('no-display');
                        resendEmailOtpButton.classList.remove('no-display');
                        Utils.showToast(response.message, 'OTP Sent to Email', Constants.TOAST.success);
                    } else {
                        Utils.showToast(response.message, 'Unable to Send Email OTP', Constants.TOAST.fail);
                    }
                });
            } else {
                Utils.showToast('Error', 'Incorrect Email', Constants.TOAST.fail);
            }
            Utils.loaderToast(Constants.ELEMENT_STATE.hide);  
        })

        resendEmailOtpButton.addEventListener('click', function () {
            Utils.loaderToast(Constants.ELEMENT_STATE.show);
            let emailOtpInputValue = document.getElementById('user_email').value;
            let that = this;
            if (Utils.validatorEmail(emailOtpInputValue)) {
                let body = {
                    "url": Constants.SERVICE_BASE_BACKOFFICE + "/v2/send-email",
                    "req_type": "post",
                    "mail": emailOtpInputValue
                }
                Utils.requestHandler('/core/api/authentication/sign-up/send-email-otp', Constants.REQUEST.post, body)
                .then(function (response) {
                    if (response.message === 'OK') {
                        Utils.showToast(response.message, 'OTP Sent to Email', Constants.TOAST.success);
                    } else {
                        Utils.showToast(response.message, 'Unable to Send Email OTP', Constants.TOAST.fail);
                    }
                });
            } else {  
                Utils.showToast('Error', 'Incorrect Email', Constants.TOAST.fail);
            }
            Utils.loaderToast(Constants.ELEMENT_STATE.hide);   
        })

    }
}