import { DocumentUtils, Utils } from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from 'mustache';

export class ScfUserSettings {
  constructor() {
    Utils.pageSanityHandler();

    this.user_first_name = null;
    this.user_last_name = null;
    this.user_email = null;
    this.user_email_otp = null;
    this.user_mobile = null;
    this.user_mobile_otp = null;
    this.user_old_password = null;
    this.user_new_password = null;

    this.elem_user_first_name = document.getElementById('user_edit_first_name');
    this.elem_user_last_name = document.getElementById('user_edit_last_name');
    this.elem_user_email = document.getElementById('user_edit_email');
    this.elem_user_email_otp = document.getElementById('user_email_otp_input');
    this.elem_user_mobile = document.getElementById('user_edit_mobile');
    this.elem_user_mobile_otp = document.getElementById('user_mobile_otp_input');
    this.elem_user_old_password = document.getElementById('user_edit_old_password');
    this.elem_user_new_password = document.getElementById('user_edit_new_password');
  }

  setValues(){
  }

  getValues(){
    this.user_first_name = Utils.getter(this.elem_user_first_name);
    this.user_last_name = Utils.getter(this.elem_user_last_name);
    this.user_email = Utils.getter(this.elem_user_email);
    this.user_email_otp = Utils.getter(this.elem_user_email_otp);
    this.user_mobile = Utils.getter(this.elem_user_mobile);
    this.user_mobile_otp = Utils.getter(this.elem_user_mobile_otp);
    this.user_old_password = Utils.getter(this.elem_user_old_password);
    this.user_new_password = Utils.getter(this.elem_user_new_password);
  }

  handler() {
    this.mobileOtpHandler();
    this.emailOtpHandler();
    this.fetchUserDetails();
    this.submitUserDetails();
  }

  fetchUserDetails(){
    let that = this;
    Utils.requestHandler("/scf/api/user/" + localStorage.getItem('user_id'), Constants.REQUEST.get, {})
    .then(function (response){
      that.populateInputFields(response.data.user);     
    });
  }

  submitUserDetails(){
    let that = this;
    document.getElementById('btn-user-edit-submit').addEventListener('click', function(){
      that.getValues();
      let body = {
        scf_user:  {        
          first_name: that.user_first_name,
          last_name: that.user_last_name,
          email: that.user_email,
          mobile: that.user_mobile,
          email_otp: that.user_email_otp,
          mobile_otp: that.user_mobile_otp,
          old_password: that.user_old_password,
          new_password: that.user_new_password
        }
      }
      Utils.requestHandler("/scf/api/user/update-user",  Constants.REQUEST.post, body)
      .then(function (response){
        if(response.message === 'OK'){
          that.populateInputFields(response.data.user);   
          Utils.showToast(response.message, 'User details updated', Constants.TOAST.success);
        }else{
          Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
        }
       
      });
    })
  }

  populateInputFields(data){
    this.elem_user_first_name.value = data.first_name;
    this.elem_user_last_name.value = data.last_name;
    this.elem_user_email.value = data.email;
    this.elem_user_mobile.value = data.mobile;

  }

  emailOtpHandler(){
    const emailOtpInputField =  document.getElementById('user_email_otp_input');
    const sendEmailOtpButton = document.getElementById('user_edit_email_otp_btn');
    const resendEmailOtpButton = document.getElementById('resend_email_otp_btn');

    sendEmailOtpButton.addEventListener('click', function () {
      Utils.loaderToast(Constants.ELEMENT_STATE.show);
      let emailOtpInputValue = document.getElementById('user_edit_email').value;
      let that = this;
      if (Utils.validatorEmail(emailOtpInputValue)) {
          let body = {
              "url": Constants.SERVICE_BASE_BACKOFFICE + "/v2/send-email",
              "req_type": "post",
              "mail": emailOtpInputValue
          }
          Utils.requestHandler('/core/api/authentication/sign-up/send-email-otp', Constants.REQUEST.post, body)
          .then(function (response) {
              if (response.message === 'OK') {
                  sendEmailOtpButton.classList.add('no-display');
                  emailOtpInputField.classList.remove('no-display');
                  resendEmailOtpButton.classList.remove('no-display');
                  Utils.showToast(response.message, 'OTP Sent to Email', Constants.TOAST.success);
              } else {
                  Utils.showToast(response.message, 'Unable to Send Email OTP', Constants.TOAST.fail);
              }
          });
      } else {
          Utils.showToast('Error', 'Incorrect Email', Constants.TOAST.fail);
      }
      Utils.loaderToast(Constants.ELEMENT_STATE.hide);  
    })

    resendEmailOtpButton.addEventListener('click', function () {
        Utils.loaderToast(Constants.ELEMENT_STATE.show);
        let emailOtpInputValue = document.getElementById('user_edit_email').value;
        let that = this;
        if (Utils.validatorEmail(emailOtpInputValue)) {
            let body = {
                "url": Constants.SERVICE_BASE_BACKOFFICE + "/v2/send-email",
                "req_type": "post",
                "mail": emailOtpInputValue
            }
            Utils.requestHandler('/core/api/authentication/sign-up/send-email-otp', Constants.REQUEST.post, body)
            .then(function (response) {
                if (response.message === 'OK') {
                  Utils.showToast(response.message, 'OTP Sent to Email', Constants.TOAST.success);
                } else {
                  Utils.showToast(response.message, 'Unable to Send Email OTP', Constants.TOAST.fail);
                }
            });
        } else {  
            Utils.showToast('Error', 'Incorrect Email', Constants.TOAST.fail);
        }
        Utils.loaderToast(Constants.ELEMENT_STATE.hide);   
    })
  }

  mobileOtpHandler(){
    const mobileOtpInputField = document.getElementById('user_mobile_otp_input');
    const sendMobileOtpButton = document.getElementById('user_edit_mobile_otp_btn');
    const resendMobileOtpButton = document.getElementById('resend_mobile_otp_btn');

    sendMobileOtpButton.addEventListener('click', function () {
      Utils.loaderToast(Constants.ELEMENT_STATE.show);
      let mobileOtpInputValue = document.getElementById('user_edit_mobile').value;
      let that = this;
      if (Utils.validatorMobile(mobileOtpInputValue)) {
          Utils.requestHandler('/core/api/authentication/sign-up/send-sms-otp', Constants.REQUEST.post, {mobile_number: mobileOtpInputValue})
          .then(function (response) {
              if (response.message === 'OK') {
                  sendMobileOtpButton.classList.add('no-display');
                  mobileOtpInputField.classList.remove('no-display');
                  resendMobileOtpButton.classList.remove('no-display');
                  Utils.showToast(response.message, 'OTP Sent to Mobile', Constants.TOAST.success);
              } else {
                  Utils.showToast(response.message, 'Unable to Send Mobile OTP', Constants.TOAST.fail);
              }
          });
      } else {
          Utils.showToast('Error', 'Incorrect Mobile Number', Constants.TOAST.fail);
      }  
      Utils.loaderToast(Constants.ELEMENT_STATE.hide);  
    })

    resendMobileOtpButton.addEventListener('click', function () {
        Utils.loaderToast(Constants.ELEMENT_STATE.show);
        let mobileOtpInputValue = document.getElementById('user_edit_mobile').value;
        let that = this;
        if (Utils.validatorMobile(mobileOtpInputValue)) {
            Utils.requestHandler('/core/api/authentication/sign-up/send-sms-otp', Constants.REQUEST.post, {mobile_number: mobileOtpInputValue})
            .then(function (response) {
                if (response.message === 'OK') {
                    Utils.showToast(response.message, 'OTP Sent to Mobile', Constants.TOAST.success);   
                } else {
                    Utils.showToast(response.message, 'Unable to Send Mobile OTP', Constants.TOAST.fail);
                }
            });
        } else {
            Utils.showToast('Error', 'Incorrect Mobile Number', Constants.TOAST.fail);
        }  
        Utils.loaderToast(Constants.ELEMENT_STATE.hide);
    })
  }
}