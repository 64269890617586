import {Utils} from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from "mustache";
import { ScfPermission } from "../scf_auth_handler";

export class ScfCounterParty {
	constructor(){
		Utils.pageSanityHandler();
		Utils.initializePagers('app-pager-parent');
		Utils.navPanelHandler('.raw-panel-link', '.raw-panel');
		this.counter_party_id = document.getElementById('counter_party_id');
		this.elem_applicant_details = document.getElementById('div_applicant_details');
    this.elem_applicant_details_counterparty = document.getElementById('div_applicant_details_counterparty');
    this.elem_applicant_details_counterparty_gst = document.getElementById('div_applicant_details_counterparty_gst');
    this.elem_srch_company_cin = document.getElementById('srch_company_cin_counterparty');
    this.elem_srch_company_name = document.getElementById('srch_company_name');
    this.elem_srch_company_list = document.getElementById('srch_company_list');
    this.scf_application_id = document.getElementById('scf_application_id');
    new PanelPd();
    this.panelDecision = new PanelDecision();
	}

	handler(){
    this.renderUserPermissionWise();
		this.applicationDetailsHandler();
    this.backButton();
		// this.gstFilingData();
		// this.getCrimeCheckCases();
		// this.requestGstFilingData();
		// this.requestCrimeCheckCases();
  }

  backButton(){
    document.getElementById('btn-back').addEventListener('click', function(){
    window.history.back();  
  })
}

  fetchApplications(){
    let that = this;
    Utils.requestHandler('/scf/api/counter_party/' + that.counter_party_id.value + '/dedupe', Constants.REQUEST.get, {})
        .then(function (response){
            if(response.message === 'OK'){
                that.renderApplications(response.data);
            }else{
                Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
        })
}

renderApplications(data){
    let that = this;
    let tmpl = document.getElementById('counter-party-details-tmpl');
    let dx = document.getElementById('tb-counterparty-de-app-tbody');
    if(data.applications.length > 1){
        dx.innerHTML = null;
    }
    data.applications.forEach(function (dd){
        if(dd.id !== that.scf_application_id.value){
            dd.formatted_created_at = Utils.formatTime(dd.created_at);
            dd.product = Utils.capitalizeFirstLetter(dd.product.split('_').join(' '));
            dd.status_human = Utils.capitalizeFirstLetter(dd.status.split('_').join(' '));
            let rendered = Mustache.render(tmpl.innerHTML, dd);
            dx.append($.parseHTML(rendered)[1]);
        }
    })
}

  renderUserPermissionWise(){
    if (ScfPermission.isScfLender() || ScfPermission.isScfCounterparty() || ScfPermission.isScfBorrower()) {
      document.getElementById("counter_party_confirmation_section").classList.add("no-display");
    }  
  }

	applicationDetailsHandler(){
        let that = this;
        Utils.requestHandler('/scf/api/counter_party/' + that.counter_party_id.value, Constants.REQUEST.get, {})
            .then(function (response){
                if(response.message === 'OK'){
                  if (response.data.scf_application.product != "purchase_invoice_discounting" && 
                    response.data.scf_application.product != "channel_finance" && 
                    response.data.scf_application.product != "vendor_discounting") {
                    document.getElementById('cp-conf-send-mail-section').classList.remove('no-display');
                  }
                
                  document.getElementById('scf_application_id').value = response.data.scf_application.id;
                  if (response.data.scf_application.anchor_leads_lot_id){
                    document.getElementById('is_anchor_product').value = true
                    document.getElementById('counter_party_confirmation_section').classList.add('no-display');
                  }else{
                    document.getElementById('is_anchor_product').value = false
                  }
                  document.getElementById('business_applicant_id').value = response.data.scf_business_applicant.id;
                  that.panelDecision.fillCounterPartyDecision(response);
                  let str= response.data.scf_counter_party.status;
                  let parts = str.split('_').map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
                  document.getElementById('counter-party-status').textContent = parts;
                  document.getElementById('cp_bank_details_confirmation_link').setAttribute('href', '/scf/counterparty/confirmation/' +  response.data.scf_counter_party.id + '?token=' + response.data.cp_banking_token);
                  that.renderApplicationDetailsCounterParty(response.data);
                  that.renderApplicationDetailsCounterPartyGst(response.data);
                  that.mcaCounterPartyDetails(response.data);
                  that.fetchApplications();
                  that.sendEmailConfirmation();
                }else{
                  Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
    }

    renderApplicationDetailsCounterParty(data){
        const that = this;
        let d = this.transformApplicationData(data);
        let tmpl = document.getElementById('applicant-details-tmpl-counterparty');
        let rendered = Mustache.render(tmpl.innerHTML, d);
        this.elem_applicant_details_counterparty.append($.parseHTML(rendered)[1]);
        var editButton = document.getElementById("edit_llp_name");
        //hiding edit button here for lender here
        if(ScfPermission.isScfLender()){
          editButton.classList.add('no-display');
        }
        that.cin_llp_modal = new bootstrap.Modal(document.getElementById('select_company_modal_cin'), {});
        editButton.addEventListener("click", function() {
            that.cin_llp_modal.show();
        });
        that.mcaSearchCinOrLlp();
        if((data.scf_business_applicant.address==null || data.scf_business_applicant.address==undefined) && data.gstins.length>0){
            document.getElementById("business_applicant_address").innerHTML = data.gstins[0].address + '<br><small class="text-grey text-italics">As reported in MCA</small>';
        }
    }

    renderApplicationDetailsCounterPartyGst(data){
        const that = this;
        let d = this.transformApplicationData(data);
        let tmpl = document.getElementById('applicant-details-tmpl-counterparty-gst');
        let rendered = Mustache.render(tmpl.innerHTML, d);
        this.elem_applicant_details_counterparty_gst.append($.parseHTML(rendered)[1]);
    }
    
    mcaSearchCinOrLlp() {
        let that = this;
        document.getElementById('btn-srch-company-id-counterparty').addEventListener('click', function () { 
            let val_cin = document.getElementById('srch_company_cin_counterparty').value;
            if(val_cin){
                Utils.loaderToast(Constants.ELEMENT_STATE.show);
                Utils.requestHandler("/scf/api/onboarding/company/get_company_from_id/" + document.getElementById('business_applicant_id').value + "/" + val_cin, Constants.REQUEST.get, {})
                    .then(function (response){
                        if(response.message === 'OK'){
                            that.cin_llp_modal.hide();
                            document.getElementById('srch_company_cin_counterparty').value = '';
                            document.getElementById('div_applicant_details_counterparty').innerHTML = '';
                            that.applicationDetailsHandler();
                        }else{
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                        Utils.loaderToast(Constants.ELEMENT_STATE.hide)
                    })
            }else{
                Utils.showToast('Input error', 'Provide a valid CIN/LLP Pin', Constants.TOAST.fail);
            }
        })
    }

    mcaCounterPartyDetails() {
        let that = this;
        Utils.requestHandler('/scf/api/counter_party/' + that.counter_party_id.value, Constants.REQUEST.get, {}).then(function (response) {
          if (response.message === "OK") {
            let container = document.getElementById("div_counter_party_details_mca");
            let mcaData = response.data.business_directors;
              if (response.data.scf_business_applicant.mca_data_json === null || response.data.scf_business_applicant.mca_data_json.length === 0) {
                let notFoundMsg = document.createElement("h1");
                notFoundMsg.textContent = "Not Found";
                container.appendChild(notFoundMsg);
              }
            that.createNestedTableUydam(
              response.data.scf_business_applicant.mca_data_json,
              container
            );
          } else {
            Utils.showToast(
              response.message,
              Utils.formatErrorMessage(response.errors),
              Constants.TOAST.fail
            );
          }
        });
      }

    createNestedTableUydam(jsonObj, parentElement) {
        let that = this;
        let table = document.createElement("table");    
        table.style.borderCollapse = "collapse";
        table.style.width = "100%";
        table.style.border = "1px solid #ddd";
        table.style.marginTop = "20px";
        table.style.fontFamily = "Arial, sans-serif";
        table.style.fontSize = "14px";
        for (let key in jsonObj) {
          if (jsonObj.hasOwnProperty(key)) {
            let tr = document.createElement("tr");
            let capitalizedKey = key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1');
            capitalizedKey = capitalizedKey.charAt(0).toUpperCase() + capitalizedKey.slice(1);        
            let tdKey = document.createElement("td");
            tdKey.style.border = "1px solid #ddd";
            tdKey.style.padding = "8px";
            tdKey.style.textAlign = "center";
            tdKey.style.backgroundColor = "#f2f2f2";
            tdKey.style.fontWeight = "bold";
            tdKey.style.width = "10%";
            tdKey.textContent = capitalizedKey;
            let tdValue = document.createElement("td");
            tdValue.style.border = "1px solid #ddd";
            tdValue.style.padding = "8px";
            tdValue.style.textAlign = "left";
            tdValue.style.width = "70%";
            if(jsonObj[key] === null || jsonObj[key] === ""){
              tdValue.textContent = "Not Found";
              tr.appendChild(tdKey);
              tr.appendChild(tdValue);
              table.appendChild(tr);
              continue;
            }
            if (typeof jsonObj[key] === "object") {
              if (Array.isArray(jsonObj[key])) {
                let ul = document.createElement("ul");
                ul.style.padding = "0";
                ul.style.margin = "0";
                ul.style.listStyleType = "none";
                jsonObj[key].forEach((item) => {
                  let li = document.createElement("li");
                  li.style.marginBottom = "5px";
                  if (typeof item === "object") {
                    that.createNestedTableUydam(item, li);
                  } else {
                    li.textContent = item;
                  }
                  ul.appendChild(li);
                });
                tdValue.appendChild(ul);
              } else {
                that.createNestedTableUydam(jsonObj[key], tdValue);
              }
            } else {
              tdValue.textContent = jsonObj[key];
            }
    
            tr.appendChild(tdKey);
            tr.appendChild(tdValue);
            table.appendChild(tr);
          }
        }
        parentElement.appendChild(table);
    }

    transformApplicationData(data) {
      const replaceWithNotAvailable = (obj) => {
          for (let key in obj) {
              if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
                  obj[key] = 'Not Available';
              }
          }
      };
  
      // Transform scf_business_applicant
      if (data.scf_business_applicant) {
          replaceWithNotAvailable(data.scf_business_applicant);
          data.scf_business_applicant.constitution = Utils.snakeToTitleCase(data.scf_business_applicant.constitution);
          let idx = 1;
          data.scf_business_applicant.idx = function() {
              return idx++;
          };
      }
  
      // Transform scf_application
      if (data.scf_application) {
          replaceWithNotAvailable(data.scf_application);
          data.scf_application.product = Utils.snakeToTitleCase(data.scf_application.product);
          data.scf_application.created_at = Utils.formatTime(data.scf_application.created_at);
          data.scf_application.relative_created_at = Utils.formatTimeRelative(data.scf_application.created_at);
          data.scf_application.required_amount_formatted = Utils.indianRepresentation(data.scf_application.required_amount);
          let idx = 1;
          data.scf_application.idx = function() {
              return idx++;
          };
      }
  
      // Transform consents
      if (data.consents) {
          let idx = 1;
          data.consents.forEach(function (row) {
              replaceWithNotAvailable(row);
              row.ctype = that.consentToTitleCase(row.ctype);
              row.updated_at = Utils.formatTime(row.updated_at);
              row.relative_updated_at = Utils.formatTimeRelative(row.updated_at);
              row.idx = function() {
                  return idx++;
              };
          });
      }
  
      // Transform business_directors
      if (data.business_directors) {
          let idx = 1;
          data.business_directors.forEach(function (row) {
              replaceWithNotAvailable(row);
              row.date_of_birth = Utils.formatDate(row.date_of_birth);
              if (row.date_of_birth && row.date_of_birth !== 'Not Available') {
                  row.age = Utils.formatTimeRelative(row.date_of_birth);
              }
              row.idx = function() {
                  return idx++;
              };
          });
      }
  
      // Transform gstins
      if (data.gstins) {
          let idx = 1;
          data.gstins.forEach(function (row) {
              replaceWithNotAvailable(row);
              row.registration_date = Utils.formatDate(row.registration_date);
              row.formatted_registration_date = Utils.formatTimeRelative(row.registration_date);
              row.last_updated = Utils.formatDate(row.last_updated);
              if (row.last_updated) {
                  row.formatted_last_updated = Utils.formatTimeRelative(row.last_updated);
              }
              row.idx = function() {
                  return idx++;
              };
          });
      }
  
      // Transform bank_accounts
      if (data.bank_accounts) {
          let idx = 1;
          data.bank_accounts.forEach(function (row) {
              replaceWithNotAvailable(row);
              row.created_at = Utils.formatTime(row.created_at);
              row.idx = function() {
                  return idx++;
              };
          });
      }
  
      return data;
    }
    
    

    renderApplicationDetails(data){
        const that = this;
        let d = this.transformApplicationData(data);
        let tmpl = document.getElementById('applicant-details-tmpl');
        let rendered = Mustache.render(tmpl.innerHTML, d);
        this.elem_applicant_details.append($.parseHTML(rendered)[1]);
    }

    sendEmailConfirmation(){
        let that = this;
        let body = {
          url: Constants.SERVICE_BASE_BACKOFFICE + "/v2/send-email",
          req_type: "post"
        }
        document.getElementById('btn-send-email-confirmation').addEventListener('click', function (){
            Utils.requestHandler('/scf/api/counter_party/' + that.counter_party_id.value + '/send-confirmation-link', Constants.REQUEST.post, body)
                .then(function (response){
                    if(response.message === 'OK'){
                        Utils.showToast(response.message, 'Email confirmation sent', Constants.TOAST.success);
                    }else{
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                })
        })
    }

    gstFilingData(){
        let that = this;
        document.getElementById('nav-scoring-gst').addEventListener('click', function (){
            Utils.requestHandler('/scf/api/counter_party/'+ that.counter_party_id.value +'/gst-filing-data', Constants.REQUEST.get).then(function (response){
                if(response.message === 'OK'){
                    let tmpl = document.getElementById('gst-filing-tmpl');
                    let dx = document.getElementById('tb_gstins');
                    dx.innerHTML = null;
                    response.data.gst_filings.forEach(function (dd){
                    	dd.status = dd.status == null ? "" : dd.status.toUpperCase();
                    	dd.gst_type = dd.gst_type == null ? "" : dd.gst_type.toUpperCase().split("_").join(" ");
                    	dd.filed_clr = "green";
                    	if (dd.status != "FILED"){
                    		dd.filed_clr = "red"
                    	}
                        let rendered = Mustache.render(tmpl.innerHTML, dd);
                        dx.append($.parseHTML(rendered)[1]);
                    })
                    Utils.showToast('Fetched', 'GST Filing Data', Constants.TOAST.success);
                }
            })
        })
    }

    getCrimeCheckCases() {
        let that = this;
        document.getElementById('nav-crime-check').addEventListener('click', function () {
            Utils.requestHandler('/scf/api/counter_party/' + that.counter_party_id.value + '/crime-cases', Constants.REQUEST.get).then(function (response) {
                if (response.message === 'OK') {
                    let tmpl = document.getElementById('cases-table-tmpl');
                    let dx = document.getElementById('all_cases');
                    let aml_tmpl = document.getElementById('aml-table-tmpl');
	                let aml_dx = document.getElementById('aml_cases');
	                aml_dx.innerHTML = null;
                    dx.innerHTML = null;
                    response.data.crime_check.forEach(obj => {
                        let rendered = Mustache.render(tmpl.innerHTML, obj);
                        dx.append($.parseHTML(rendered)[1]);
	                    rendered = Mustache.render(aml_tmpl.innerHTML, obj.aml);
	                    aml_dx.append($.parseHTML(rendered)[1]);
                    })
                    
                    Utils.showToast('Fetched', 'Cases fetched', Constants.TOAST.success);
                } else {
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
        })
    }

    requestCrimeCheckCases() {
        let that = this;
        document.getElementById('req-crime-check').addEventListener('click', function () {
            Utils.requestHandler('/scf/api/counter_party/' + that.counter_party_id.value + '/crime-cases', Constants.REQUEST.post).then(function (response) {
                if (response.message === 'OK') {
                    Utils.showToast('Requested', 'Crime Check data request initiated', Constants.TOAST.success);
                } else {
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
        })
    }

    requestGstFilingData(){
    	let that = this;
    	document.getElementById('req-gst-filings').addEventListener('click', function () {
    	    Utils.requestHandler('/scf/api/counter_party/' + that.counter_party_id.value + '/gst-filing-data', Constants.REQUEST.post).then(function (response) {
    	        if (response.message === 'OK') {
    	            Utils.showToast('Requested', 'GST Filing data request initiated', Constants.TOAST.success);
    	        } else {
    	            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
    	        }
    	    })
    	})
    }

}

class PanelPd {
    constructor(){
      this.counter_party_id = document.getElementById('counter_party_id');
      this.scf_application_id = document.getElementById('scf_application_id');
      this.is_anchor_product = document.getElementById('is_anchor_product');
      this.pass = true;
      this.getQuestions();
      this.submitQuestions();
      this.generatePdQuestionsPdf();
      this.validatePdValues();
      this.submitPDSectionQuestions();
    }

    getValues(){
      let data = {};
      document.querySelectorAll('.questions').forEach(question => {
        let id = question.getAttribute('data-label');
        data[id] = question.value;
      })
      return data;
    }

    validateValues(){
        let that = this;
        that.pass = true;
        const sectionsToValidate = ["Section---1---CPV", "Section---3---Audit-Trails"];

        sectionsToValidate.forEach(section => {
          document.querySelectorAll(`.${section}.questions`).forEach((question) => {
              let mandatory = question.getAttribute("data-mandatory");
              let label = question.getAttribute("data-label");
              let label_title = Utils.snakeToTitleCase(label);
              Utils.reInput(question);
  
              function toggleErrorMessage() {
                  let value = question.value;
                  if (mandatory === "true" && !value) {
                      Utils.showInputError(question, label_title + " is mandatory");
                      that.pass = false;
                  } else {
                      Utils.hideInputError(question);
                  }
                  if (label.endsWith("mobile_no") && value) {
                      if (!/^\d{10}$/.test(value)) {
                          Utils.showInputError(question, "Mobile number must be 10 digits");
                          that.pass = false;
                      } else {
                          Utils.hideInputError(question);
                      }
                  }
              }
  
              toggleErrorMessage();
              question.addEventListener("input", toggleErrorMessage);
          });
      });
  
        document
          .querySelectorAll('[data-code="photo_of_shop"]')
          .forEach((element) => {
            if (element.files.length === 0) {
              Utils.showToast(
                "Error",
                "Photo of the shop is mandatory",
                Constants.TOAST.fail
              );
              that.pass = false;
            }
          });
    }

    validatePdValues(){
      let that = this;
      that.pass = true;
      const sectionsToValidate = ["Section---2---PD"];

      sectionsToValidate.forEach(section => {
        document.querySelectorAll(`.${section}.questions`).forEach((question) => {
            let mandatory = question.getAttribute("data-mandatory");
            let label = question.getAttribute("data-label");
            let label_title = Utils.snakeToTitleCase(label);
            Utils.reInput(question);

            function toggleErrorMessage() {
                let value = question.value;
                if (mandatory === "true" && !value) {
                    Utils.showInputError(question, label_title + " is mandatory");
                    that.pass = false;
                } else {
                    Utils.hideInputError(question);
                }
                if (label.endsWith("mobile_no") && value) {
                    if (!/^\d{10}$/.test(value)) {
                        Utils.showInputError(question, "Mobile number must be 10 digits");
                        that.pass = false;
                    } else {
                        Utils.hideInputError(question);
                    }
                }
            }

            toggleErrorMessage();
            question.addEventListener("input", toggleErrorMessage);
        });
    });
  }

  getQuestions() {
    let that = this;
    document.getElementById('nav-pd').addEventListener('click', function () {
        Utils.requestHandler("/scf/api/counter_party/" + that.counter_party_id.value + '/pd-questions', Constants.REQUEST.get, {})
            .then(function (response) {
                if (response.message === 'OK') {
                    let all_quest_cpv = document.getElementById('all_questions_cpv');
                    let all_quest_pd = document.getElementById('all_questions_pd');
                    all_quest_cpv.innerHTML = null;
                    all_quest_pd.innerHTML = null;
                    let questions = {};
                    response.data.forEach(function (question) {
                        if (questions[question.section]) {
                            questions[question.section].push(question)
                        } else {
                            questions[question.section] = [question]
                        }
                    })
                    let tmpl = document.getElementById('question-tmpl')
                    let section;
                    if (that.is_anchor_product.value == 'true') {
                        section = 'PD';
                    } else {
                        section = 'Section_-_1_-_CPV';
                    }
                    all_quest_cpv.append($.parseHTML(Mustache.render(document.getElementById('question-header-tmpl').innerHTML, { name: section.split("_").join(" ") }))[1])
                    questions[section].forEach(function (question) {
                        question.section = section.split("_").join("-"); // Replacing underscores with hyphens for class
                        switch (question.type) {
                            case 'text':
                                question["text"] = true;
                                break;
                            case 'select':
                                question["select"] = true;
                                break;
                            case 'date':
                                question["date"] = true;
                                break;
                            case 'textbox':
                                question["textbox"] = true;
                                break;
                            case 'upload':
                                question["upload"] = true;
                                break;
                            case 'number':
                                question["number"] = true;
                                break;
                        }
                        let q_data = Mustache.render(tmpl.innerHTML, question);
                        all_quest_cpv.append($.parseHTML(q_data)[1]);
                    })
                    if (that.is_anchor_product.value == 'true') {
                        section = 'Audit Trails';
                    } else {
                        section = 'Section_-_2_-_PD';
                    }
                    all_quest_pd.append($.parseHTML(Mustache.render(document.getElementById('question-header-tmpl').innerHTML, { name: "Section -1 - PD" }))[1])
                    questions[section].forEach(function (question) {
                        question.section = section.split("_").join("-"); // Replacing underscores with hyphens for class
                        switch (question.type) {
                            case 'text':
                                question["text"] = true;
                                break;
                            case 'select':
                                question["select"] = true;
                                break;
                            case 'date':
                                question["date"] = true;
                                break;
                            case 'textbox':
                                question["textbox"] = true;
                                break;
                            case 'upload':
                                question["upload"] = true;
                                break;
                            case 'number':
                                question["number"] = true;
                                break;
                        }
                        let q_data = Mustache.render(tmpl.innerHTML, question);
                        all_quest_pd.append($.parseHTML(q_data)[1]);
                    })
                    if (that.is_anchor_product.value != 'true') {
                        section = 'Section_-_3_-_Audit_Trails';
                        all_quest_cpv.append($.parseHTML(Mustache.render(document.getElementById('question-header-tmpl').innerHTML, { name: "Section - 2 - Audit Trails" }))[1])
                        questions[section].forEach(function (question) {
                            question.section = section.split("_").join("-"); // Replacing underscores with hyphens for class
                            switch (question.type) {
                                case 'text':
                                    question["text"] = true;
                                    break;
                                case 'select':
                                    question["select"] = true;
                                    break;
                                case 'date':
                                    question["date"] = true;
                                    break;
                                case 'textbox':
                                    question["textbox"] = true;
                                    break;
                                case 'upload':
                                    question["upload"] = true;
                                    break;
                                case 'number':
                                    question["number"] = true;
                                    break;
                            }
                            let q_data = Mustache.render(tmpl.innerHTML, question);
                            all_quest_cpv.append($.parseHTML(q_data)[1]);
                        })
                        let maxDate = new Date().toISOString().split('T')[0];
                        document.getElementById('date_of_visit').setAttribute('max', maxDate);
                    }

                    // Adding support for Section_-_4_-_Audit_Trails
                    section = 'Section_-_4_-_Audit_Trails';
                    all_quest_pd.append($.parseHTML(Mustache.render(document.getElementById('question-header-tmpl').innerHTML, { name: "Section - 2 - Audit Trails" }))[1])
                    questions[section].forEach(function (question) {
                        question.section = section.split("_").join("-"); // Replacing underscores with hyphens for class
                        switch (question.type) {
                            case 'text':
                                question["text"] = true;
                                break;
                            case 'select':
                                question["select"] = true;
                                break;
                            case 'date':
                                question["date"] = true;
                                break;
                            case 'textbox':
                                question["textbox"] = true;
                                break;
                            case 'upload':
                                question["upload"] = true;
                                break;
                            case 'number':
                                question["number"] = true;
                                break;
                        }
                        let q_data = Mustache.render(tmpl.innerHTML, question);
                        all_quest_pd.append($.parseHTML(q_data)[1]);
                    })

                    that.uploadDocuments();
                    that.fetchDropDownValuesIndustry();
                    that.fetchDropDownLevel2();
                } else {
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
    })
}

    
    

    submitQuestions() {
        let that = this;
        document
          .getElementById("btn-quest-submit")
          .addEventListener("click", function () {
            that.validateValues();
            that.uploadDocuments();
            if (that.pass) {
              let values = that.getValues();
              Utils.requestHandler(
                "/scf/api/counter_party/" + that.counter_party_id.value + "/pd-questions",
                Constants.REQUEST.post,
                values
              ).then(function (response) {
                if (response.message === "OK") {
                  Utils.showToast(
                    response.message,
                    "Responses submitted",
                    Constants.TOAST.success
                  );
                  // that.clearForm();
                } else {
                  Utils.showToast(
                    response.message,
                    Utils.formatErrorMessage(response.errors),
                    Constants.TOAST.fail
                  );
                }
              });
            } else {
              Utils.showToast(
                "Error",
                "Please Enter Required field",
                Constants.TOAST.fail
              );
            }
          });
      }

      submitPDSectionQuestions() {
        let that = this;
        document
          .getElementById("btn-quest-pd-submit")
          .addEventListener("click", function () {
            that.validatePdValues();
            if (that.pass) {
              let values = that.getValues();
              Utils.requestHandler(
                "/scf/api/counter_party/" + that.counter_party_id.value + "/pd-questions",
                Constants.REQUEST.post,
                values
              ).then(function (response) {
                if (response.message === "OK") {
                  Utils.showToast(
                    response.message,
                    "Responses submitted",
                    Constants.TOAST.success
                  );
                  // that.clearForm();
                } else {
                  Utils.showToast(
                    response.message,
                    Utils.formatErrorMessage(response.errors),
                    Constants.TOAST.fail
                  );
                }
              });
            } else {
              Utils.showToast(
                "Error",
                "Please Enter Required field",
                Constants.TOAST.fail
              );
            }
          });
      }

    generatePdQuestionsPdf() {
      if(ScfPermission.isScfLender()){
        document.getElementById('btn_generate_pd_counter_party_pdf').classList.add('no-display');
        document.getElementById('btn-quest-submit').classList.add('no-display');
      }
        let that = this;
        document
          .getElementById("btn_generate_pd_counter_party_pdf")
          .addEventListener("click", function () {
            Utils.requestHandler(
              "/scf/api/counter_party/" +
              that.counter_party_id.value +
                "/generate-pd-questions-pdf",
              Constants.REQUEST.get
            ).then(function (response) {
              if (response.message === "OK") {
                Utils.showToast(
                  response.message,
                  "PDF Generation Started.",
                  Constants.TOAST.success
                );
              } else {
                Utils.showToast(
                  response.message,
                  Utils.formatErrorMessage(response.errors),
                  Constants.TOAST.fail
                );
              }
            });
          });
      }

      fetchDropDownValuesIndustry() {
        let that = this;
        document
          .getElementById("business_segment")
          .addEventListener("change", function () {
            document.getElementById("industry_classification").innerHTML = "";
            document.getElementById("industry_classification_2").innerHTML = "";
            let segment = "";
            if (
              document.getElementById("business_segment").value === "Manufacturing / Trader"
            ) {
              segment = "HSN";
            } else if (
              document.getElementById("business_segment").value === "Services"
            ) {
              segment = "SAC";
            }
            let body = {
              level: 1,
              segment: segment,
            };
            Utils.requestHandler(
              "/scf/api/application/pd-questions/industry-codes",
              Constants.REQUEST.get,
              body
            ).then(function (response) {
              if (response.message === "OK") {
                var dropdown = document.getElementById("industry_classification");
                document.getElementById("industry_classification").innerHTML = "";
                var option = document.createElement("option");
                option.value = "";
                option.text = "";
                dropdown.appendChild(option);
                var values = response.data.data;
                for (var i = 0; i < values.length; i++) {
                  var option = document.createElement("option");
                  option.value = values[i].code;
                  option.text = values[i].name;
                  dropdown.appendChild(option);
                }
              } else {
                Utils.showToast(
                  response.message,
                  Utils.formatErrorMessage(response.errors),
                  Constants.TOAST.fail
                );
              }
            });
          });
      }
    
      fetchDropDownLevel2() {
        let that = this;
        document
          .getElementById("industry_classification")
          .addEventListener("change", function () {
            let segment = "";
            if (
              document.getElementById("business_segment").value === "Manufacturing / Trader"
            ) {
              segment = "HSN";
            } else if (
              document.getElementById("business_segment").value === "Services"
            ) {
              segment = "SAC";
            }
            let body = {
              level: 2,
              segment: segment,
              parent_code: document.getElementById("industry_classification").value,
            };
            Utils.requestHandler(
              "/scf/api/application/pd-questions/industry-codes",
              Constants.REQUEST.get,
              body
            ).then(function (response) {
              if (response.message === "OK") {
                var dropdown = document.getElementById("industry_classification_2");
                document.getElementById("industry_classification_2").innerHTML = "";
                var option = document.createElement("option");
                option.value = "";
                option.text = "";
                dropdown.appendChild(option);
                var values = response.data.data;
                for (var i = 0; i < values.length; i++) {
                  var option = document.createElement("option");
                  option.value = values[i].code;
                  option.text = values[i].name;
                  dropdown.appendChild(option);
                }
              } else {
                Utils.showToast(
                  response.message,
                  Utils.formatErrorMessage(response.errors),
                  Constants.TOAST.fail
                );
              }
            });
          });
      }

    uploadDocuments(){
        let that = this;
        document.querySelectorAll('.pd-upload').forEach(element => {
            element.addEventListener('click', function(){
                let elem_file = element.parentElement.parentElement.firstElementChild;
                let file = elem_file.files[0];
                if (!file){
                    Utils.showToast("Not Found", "File is mandatory", Constants.TOAST.fail);
                    return;
                }
                let data = new FormData();
                let file_code = elem_file.getAttribute('data-code');
                data.set('file_data', file);
                data.set('file_name', file.name);
                data.set('file_password', null);
                Utils.requestHandler(
                    "/scf/api/application/" +
                        that.scf_application_id.value +
                      "/document/upload/" +
                      file_code,
                    Constants.REQUEST.post,
                    data
                  ).then(function (response){
                        if(response.message === 'OK'){
                            Utils.showToast(response.message, 'Document uploaded', Constants.TOAST.success);
                        }else{
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                    })
            })
        })
        if(ScfPermission.isScfLender()){
          document.querySelectorAll('.pd-upload').forEach(element => {
              element.classList.add('no-display');
          })
      }
    }

}

class PanelDecision {

  constructor(){
    this.counter_party_id = document.getElementById('counter_party_id');
    this.scf_application_id = document.getElementById('scf_application_id');
    this.elem_product_type = document.getElementById('product_type');
    this.elem_category_of_cp = document.getElementById('category_of_cp');
    this.elem_type_of_rating = document.getElementById('type_of_rating');
    this.elem_cp_rating_issuer = document.getElementById('cp_rating_issuer');
    this.elem_date_of_rating = document.getElementById('date_of_rating');
    this.elem_cp_rating = document.getElementById('cp_rating');
    this.elem_cp_exposure_limit = document.getElementById('cp_exposure_limit');
    this.elem_cp_tenure_cap = document.getElementById('cp_tenure_cap');
    this.elem_cp_margin_cap = document.getElementById('cp_margin_cap');
    this.elem_cp_allowed_underlying_instrument = document.getElementById('cp_allowed_underlying_instrument');
    this.elem_cp_require_escrow = document.getElementById('cp_require_escrow');
    this.elem_cp_status = document.getElementById('cp_status');
    this.elem_lender_hsn_list = document.getElementById('lender_hsn_list');
    this.elem_counter_party_beneficiary_id = document.getElementById('counter_party_beneficiary_id');
    this.handler();
  }

  handler(){
    this.submitDecision();
    this.initializeScorecardEventListeners();
  }

  getValues() {
    this.product_type = Utils.getter(this.elem_product_type);
    this.category_of_cp = Utils.getter(this.elem_category_of_cp);
    this.type_of_rating = Utils.getter(this.elem_type_of_rating);
    this.cp_rating_issuer =  Utils.getter(this.elem_cp_rating_issuer);
    this.date_of_rating = Utils.getter(this.elem_date_of_rating);
    this.cp_rating = Utils.getter(this.elem_cp_rating);
    this.cp_exposure_limit = Utils.getter(this.elem_cp_exposure_limit);
    this.cp_tenure_cap = Utils.getter(this.elem_cp_tenure_cap);
    this.cp_margin_cap = Utils.getter(this.elem_cp_margin_cap);
    this.cp_allowed_underlying_instrument = Utils.getter(this.elem_cp_allowed_underlying_instrument);
    this.cp_require_escrow = Utils.getter(this.elem_cp_require_escrow);
    this.cp_status = Utils.getter(this.elem_cp_status);
    this.lender_hsn_list = Utils.getter(this.elem_lender_hsn_list);
    this.counter_party_beneficiary_id = Utils.getter(this.elem_counter_party_beneficiary_id);
  }

  setValues() {
    Utils.setter(this.elem_product_type, this.product_type);
    Utils.setter(this.elem_category_of_cp, this.category_of_cp);
    Utils.setter(this.elem_type_of_rating, this.type_of_rating);
    Utils.setter(this.elem_cp_rating_issuer, this.cp_rating_issuer);
    Utils.setter(this.elem_date_of_rating, this.date_of_rating);
    Utils.setter(this.elem_cp_rating, this.cp_rating);
    Utils.setter(this.elem_cp_exposure_limit, this.cp_exposure_limit);
    Utils.setter(this.elem_cp_tenure_cap, this.cp_tenure_cap);
    Utils.setter(this.elem_cp_margin_cap, this.cp_margin_cap);
    Utils.setter(this.elem_cp_allowed_underlying_instrument, this.cp_allowed_underlying_instrument);
    Utils.setter(this.elem_cp_require_escrow, this.cp_require_escrow);
    Utils.setter(this.elem_cp_status, this.cp_status);
    Utils.setter(this.elem_lender_hsn_list, this.lender_hsn_list);
    Utils.setter(this.elem_counter_party_beneficiary_id, this.counter_party_beneficiary_id);
    return true;
  }  

  validateValues() {
    let that = this;
    that.pass = true;
    document.querySelectorAll(".counterparty-question").forEach((question) => {
      let label = question.getAttribute("data-label");
      let field_id=question.getAttribute("id");
      Utils.reInput(question);
      function toggleErrorMessage() {
        let value = question.value;
        if(field_id=="cp_exposure_limit" && parseInt(value)>9900){
          Utils.showInputError(question, "CP Exposure Limit is invalid Max - 9900 Lakhs");
          that.pass = false;
        }
        else if(field_id=="cp_tenure_cap" && parseInt(value)>365){
          Utils.showInputError(question, "CP Tenure Cap is invalid (Max - 365 Days)");
          that.pass = false;
        }
        else if(field_id=="cp_margin_cap" && parseInt(value)>100){
          Utils.showInputError(question,'CP Margin Cap is invalid (Max - 100%)');
          that.pass = false;
        }
        else if (!value) {
          Utils.showInputError(question, label + " is mandatory");
          that.pass = false;
        } else {
          Utils.hideInputError(question);
        }
      }
      toggleErrorMessage();
      question.addEventListener("input", toggleErrorMessage);
    });
    return that.pass;
  }

  getDecision(){
    let that = this;
    Utils.requestHandler('/scf/api/counter_party/' + that.counter_party_id.value , Constants.REQUEST.get, {})
        .then(function (response){
            if(response.message === 'OK'){
              that.fillCounterPartyDecision(response);
            }else{
              Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
        })
  }

  fillCounterPartyDecision(response) {
    let that = this;
    that.product_type = response.data.scf_application.product;
    that.category_of_cp = response.data.scf_counter_party.category;
    if(response.data.scf_counter_party.credit_rating!=null){
      that.type_of_rating = response.data.scf_counter_party.credit_rating.type_of_rating || '';
      that.cp_rating_issuer =  response.data.scf_counter_party.credit_rating.cp_rating_issuer || '';
      that.date_of_rating =  response.data.scf_counter_party.credit_rating.date_of_rating || '';
      that.cp_rating =  response.data.scf_counter_party.credit_rating.rating || '';
    }
    that.cp_exposure_limit = response.data.scf_counter_party.exposure_limit || '';
    that.cp_tenure_cap =  response.data.scf_counter_party.tenure_limit || '';
    that.cp_margin_cap =  response.data.scf_counter_party.margin_limit || '';
    that.cp_allowed_underlying_instrument =  response.data.scf_counter_party.allowed_underlying_instrument || '';
    that.cp_require_escrow = response.data.scf_counter_party.require_escrow || '';
    that.cp_status = response.data.scf_counter_party.status || '';
    that.lender_hsn_list = response.data.scf_counter_party.lender_hsn || '';
    that.counter_party_beneficiary_id = response.data.scf_counter_party.beneficiary_id || '';
    that.setValues();

    if (response.data.hsn_required !== 'Yes') {
        let lenderHsnListElement = document.getElementById('lender_hsn_list');
        lenderHsnListElement.parentNode.classList.add('d-none');
        lenderHsnListElement.classList.add('d-none');
    }
    if(response.data.scf_counter_party.counter_party_scorecard !=null){
      document.getElementById('cp_sc_borrower_rating').value = response.data.scf_counter_party.counter_party_scorecard.borrower_rating || '';
      document.getElementById('cp_sc_management_rating').value = response.data.scf_counter_party.counter_party_scorecard.management_rating || '';
      document.getElementById('cp_sc_financials_rating').value = response.data.scf_counter_party.counter_party_scorecard.financials_rating || '';
      document.getElementById('cp_sc_banking_rating').value = response.data.scf_counter_party.counter_party_scorecard.banking_rating || '';
      document.getElementById('cp_sc_bureau_p_rating').value = response.data.scf_counter_party.counter_party_scorecard.bureau_p_rating || '';
      document.getElementById('cp_sc_bureau_c_rating').value = response.data.scf_counter_party.counter_party_scorecard.bureau_c_rating || '';
      document.getElementById('cp_sc_gst_rating').value = response.data.scf_counter_party.counter_party_scorecard.gst_rating || '';
      document.getElementById('cp_sc_total_rating').value = response.data.scf_counter_party.counter_party_scorecard.total_rating || '';
    }
    document.getElementById('counter_party_beneficiary_id').value = response.data.scf_counter_party.beneficiary_id || '';
    document.getElementById('product_type').value = response.data.scf_application.product || '';
}


  submitDecision(){
    let that = this;
    if(ScfPermission.isScfLender() || ScfPermission.isScfCounterparty()){
      document.getElementById('btn-submit-cp-credit-decision').classList.add('no-display');
    }
    document.getElementById('btn-submit-cp-credit-decision').addEventListener('click', function(){
      that.getValues();
      let credit_rating = {
        type_of_rating: that.type_of_rating,
        cp_rating_issuer: that.cp_rating_issuer,
        date_of_rating: that.date_of_rating,
        rating: that.cp_rating
      }
      const cp_scorecard = {
        borrower_rating: document.getElementById('cp_sc_borrower_rating').value,
        management_rating: document.getElementById('cp_sc_management_rating').value,
        financials_rating: document.getElementById('cp_sc_financials_rating').value,
        banking_rating: document.getElementById('cp_sc_banking_rating').value,
        bureau_p_rating: document.getElementById('cp_sc_bureau_p_rating').value,
        bureau_c_rating: document.getElementById('cp_sc_bureau_c_rating').value,
        gst_rating: document.getElementById('cp_sc_gst_rating').value,
        total_rating: document.getElementById('cp_sc_total_rating').value
      };
      let body = {
        counter_party_scorecard: cp_scorecard,
        category: that.category_of_cp,
        credit_rating: credit_rating,
        exposure_limit: that.cp_exposure_limit,
        tenure_limit: that.cp_tenure_cap,
        margin_limit: that.cp_margin_cap,
        allowed_underlying_instrument: that.cp_allowed_underlying_instrument,
        require_escrow: that.cp_require_escrow,
        status: that.cp_status,
        lender_hsn: that.lender_hsn_list,
        beneficiary_id: that.counter_party_beneficiary_id
      }
      if (that.validateValues()) {
        Utils.requestHandler('/scf/api/counter_party/' + that.counter_party_id.value + '/credit-decision', Constants.REQUEST.post, body)
            .then(function (response){
                if(response.message === 'OK'){
                  that.getDecision();
                  Utils.showToast(response.message, 'Decision submitted', Constants.TOAST.success);
                }else{
                  Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
      }      
    })
  }

  initializeScorecardEventListeners() {
    const elements = document.querySelectorAll('.cp_scorecard_element');

    function calculateTotal() {
      let total = 0;
      elements.forEach(element => {
        total += Number(element.value) || 0;
      });
      document.getElementById('cp_sc_total_rating').value = total;
    }

    elements.forEach(element => {
      element.addEventListener('input', calculateTotal);
    });
  }

}