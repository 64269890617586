import {DocumentUtils, Utils} from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from 'mustache';
import { data } from "jquery";

class AbstractBanking {

    constructor(id){
        this.id = id;
        this.account_number = null;
        this.account_type = null;
        this.account_holder = null;
        this.ifsc_code = null;
        this.bank_branch = null;
        this.bank_name = null;
        this.bank_micr = null;
        this.verified_account_holder = null;
        this.elem_account_number = document.getElementById('account_number');
        this.elem_account_holder = document.getElementById('account_holder');
        this.elem_account_type = document.getElementById('account_type');
        this.elem_ifsc_code = document.getElementById('ifsc_code');
        this.elem_bank_branch = document.getElementById('bank_branch');
        this.elem_bank_name = document.getElementById('bank_name');
        this.elem_bank_micr = document.getElementById('bank_micr');
        this.elem_verified_account_holder = document.getElementById('verified_account_holder');
        this.elem_bank_branch_full = document.getElementById('bank_branch_full');
    }

    getValues(){
        this.account_number = Utils.getter(this.elem_account_number);
        this.account_type = Utils.getter(this.elem_account_type);
        this.account_holder = Utils.getter(this.elem_account_holder);
        this.ifsc_code = Utils.getter(this.elem_ifsc_code);
        this.bank_branch = Utils.getter(this.elem_bank_branch);
        this.bank_name = Utils.getter(this.elem_bank_name);
        this.bank_micr = Utils.getter(this.elem_bank_micr);
        this.account_type = Utils.getter(this.elem_account_type);
        this.verified_account_holder = Utils.getter(this.elem_verified_account_holder);
    }

    setValues() {
        Utils.setter(this.elem_account_number, this.account_number);
        Utils.setter(this.elem_account_type, this.account_type);
        Utils.setter(this.elem_account_holder, this.account_holder);
        Utils.setter(this.elem_ifsc_code, this.ifsc_code);
        Utils.setter(this.elem_bank_branch, this.bank_branch);
        Utils.setter(this.elem_bank_name, this.bank_name);
        Utils.setter(this.elem_bank_micr, this.bank_micr);
        Utils.setter(this.elem_account_type, this.account_type);
        Utils.setter(this.elem_verified_account_holder, this.verified_account_holder);
        Utils.setter(this.elem_bank_branch_full, this.bank_branch);
    }

    validateValues(){
        return true;
    }
}


export default class  ScfOnbPanelDocument{

    constructor() {
        this.obj_banking = [];
        let that = this;
        this.scf_application_id = document.getElementById('scf_application_id').value;
        this.business_applicant_id = localStorage.getItem('business_applicant_id');
        this.elem_document_details = document.getElementById('div_document_details');
        this.elem_password_modal = new bootstrap.Modal(document.getElementById('modal_doc_password'), {});
        this.elem_password = document.getElementById('doc_password');
        this.elem_btn_password = document.getElementById('btn-update-password');
        this.elem_banking_modal_body = document.getElementById('banking_modal_body');
        that.renderDocuments();
        that.documentGstHandler();
        that.documentBankingHandler();
        that.documentAadhaarHandler();
        that.documentPromoterHandler();
        that.documentCpHandler();
        that.renderConstitutionInputs();
        that.updatePasswordHandler();
    }

    renderConstitutionInputs() {
        let that = this;
        Utils.requestHandler("/scf/api/applicant/" + that.business_applicant_id, Constants.REQUEST.get, {})
            .then(function (response) {
                const constitution = response.data.scf_applicant['constitution'];
                let templateId;
                
                switch (constitution) {
                    case 'partnership':
                        templateId = 'partnershipDocUploadTemplate';
                        break;
                    case 'llp':
                        templateId = 'llpDocUploadTemplate';
                        break;
                    case 'private_limited':
                        templateId = 'privateLimitedDocUploadTemplate';
                }

                if (templateId) {
                    const template = document.getElementById(templateId).innerHTML;
                    const rendered = Mustache.render(template);
                    document.getElementById('company_constitution_target').innerHTML = rendered;
                    that.formCardsToggleBusinessConstitution();
                }
                
                that.uploadOnboardingDocuments();
            });
    }

    formCardsToggleBusinessConstitution(){
        const toggleIcons = document.querySelectorAll('[id$="_company_constitution_documents_card_toggle_icon"]');
        
        toggleIcons.forEach(icon => {
          icon.addEventListener('click', () => {

            const dynamicId = icon.id.replace('_toggle_icon', '');
      

            const dynamicElement = document.getElementById(dynamicId);

            if (dynamicElement) {
              dynamicElement.classList.toggle('d-none');
            }
      
            icon.classList.toggle('bi-chevron-down');
            icon.classList.toggle('bi-chevron-right');
          });
        });
    }

    renderDocuments(){
        let that = this;
        Utils.requestHandler("/scf/api/application/" + that.scf_application_id + '/documents', Constants.REQUEST.get, {})
                .then(function (response){
                    if(response.message === 'OK'){
                        that.renderAllDocuments(response.data);
                        // Utils.showToast(response.message, 'Document list fetched', Constants.TOAST.success);
                    }else{
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                })
    }

    transformDocumentData(ref, last_doc = null){
        let drop_ship = {}
        drop_ship.doc_title = ref.set_value.title;
        if(last_doc != null){
            drop_ship.doc_subtitle = "Last updated " + Utils.formatTimeRelative(last_doc.updated_at);
        } else {
            drop_ship.doc_subtitle = ref.set_value.title;
        }
        drop_ship.dtype = ref.set_key;
        drop_ship.ext = "";
        if(ref.set_value.ext){
            drop_ship.ext = ref.set_value.ext.map(function(x) {return x.toUpperCase()});
        }
        let ext = ref.set_value.ext[0];
        if(ext && ['jpeg', 'jpg', 'png'].includes(ext.toLowerCase())) {
            drop_ship.logo = "bi-file-earmark-image";
        }else if(ext && ['xls', 'xlsx'].includes(ext.toLowerCase())) {
            drop_ship.logo = "bi-filetype-xls";
        }else if(ext && ['pdf'].includes(ext.toLowerCase())){
            drop_ship.logo = "bi-file-earmark-pdf";
        }else{
            drop_ship.logo = "bi-file-earmark-text";
        }
        return drop_ship;
    }

    transformPreviewData(preview_data){
        preview_data.formatted_ts = Utils.formatTime(preview_data.created_at);
        preview_data.formatted_ts_ago = Utils.formatTimeRelative(preview_data.created_at);
        preview_data.name = preview_data.id;
        return preview_data;
    }

    renderAllDocuments(data){
        let that = this;
        let tmpl = document.getElementById('doc-tmpl');
        this.elem_document_details.innerHTML = '';
        
        //This is tmp fix to hide unwanted docs in demo
 

          
        for(let dtype in data.ref){
            let d = that.transformDocumentData(data.ref[dtype]);
            let rendered = Mustache.render(tmpl.innerHTML, d);
            that.elem_document_details.append($.parseHTML(rendered)[1]);
            let url = '/scf/api/application/' + that.scf_application_id + '/document/upload/' + dtype;
            let action_element = document.getElementById('btn_upload_'+dtype);
            DocumentUtils.bindInputElement(action_element);
            DocumentUtils.uploadFiles(action_element, url);
            that.showUploadPreview(action_element);
        }
        this.renderUploadedDocuments(data);
        // this.checkIfBusinessDirectorAndPromoter();
    }

    checkIfBusinessDirectorAndPromoter(){
        let that = this;
        let showDiv = false;
        let div = document.getElementById('ca-self-attestation-document');
        // Utils.requestHandler("/scf/api/onboarding/company/directors/" + that.business_applicant_id , Constants.REQUEST.get, {})
        //     .then(function (response){
        //     })
        if (showDiv) {
            div.style.display = 'block'; 
        } else {
            div.style.display = 'none'; 
        }
    }

    renderUploadedDocuments(data){
        let that = this;
        let p_tmpl = document.getElementById('doc-preview-tmpl');
        data.documents.forEach(function (doc){
            let dtype = doc.file_code;
            let prev = document.getElementById('prev-'+dtype);
            let d1 = that.transformPreviewData(doc);
            let rend = Mustache.render(p_tmpl.innerHTML, d1);
            prev.append($.parseHTML(rend)[1]);
        })
        document.querySelectorAll('.btn-doc-password').forEach(function (elem){
            elem.addEventListener('click', function (){
                that.passwordHandler(elem);
            })
        })
        this.deleteDocument();
    }


    showUploadPreview(action_element){
        let that = this;
        let p_tmpl = document.getElementById('doc-preview-tmpl');
        action_element.addEventListener('ev_document_uploaded', function (){
            let response = JSON.parse(action_element.getAttribute('data-upload-response'));
            let prev = document.getElementById('prev-'+response.file_code);
            let d1 = that.transformPreviewData(response);
            let rend = Mustache.render(p_tmpl.innerHTML, d1);
            prev.prepend($.parseHTML(rend)[1]);
                        let pass_elem = document.getElementById('btn_password_'+response.id);
            pass_elem.addEventListener('click', function (){
                that.passwordHandler(pass_elem);
            })
        })
    }

    deleteDocument() {
        let that = this;
        let elements = document.querySelectorAll('#delete-button-document');
    
        elements.forEach(function(element) {
            element.addEventListener('click', function(event) {
                let id = event.target.getAttribute("data-id");
                let confirmed = window.confirm('Are you sure you want to delete this document?');  
                if (confirmed) {
                    Utils.requestHandler('/scf/api/application/' + that.scf_application_id + '/document/destroy/' + id, Constants.REQUEST.get)
                        .then(function(response) {
                            if (response.message === 'OK') {
                                Utils.showToast(response.message, 'Document Deleted', Constants.TOAST.success);
                                that.renderDocuments();
                            } else {
                                Utils.showToast(response.message, 'Not Found', Constants.TOAST.fail);
                            }
                        });
                }
            });
        });
    }
    
    
    passwordHandler(elem){
        this.elem_password.value = elem.getAttribute('data-password') == null ? "" : elem.getAttribute('data-password') ;
        this.elem_btn_password.setAttribute('data-id',  elem.getAttribute('data-id'));
        this.elem_password_modal.show();
    }

    updatePasswordHandler(){
        let that = this;
        this.elem_btn_password.addEventListener('click', function (event){
            Utils.requestHandler('/core/api/document/'+ that.elem_btn_password.getAttribute('data-id'), Constants.REQUEST.post, {file_password: that.elem_password.value})
                .then(function (response){
                    if(response.message === 'OK'){
                        document.getElementById('btn_password_'+response.data.id).setAttribute('data-password', response.data.decrypted_digest);
                        let closeModalButton = document.getElementById("document-password-modal-close"); 
                        closeModalButton.click();
                        Utils.showToast(response.message, 'Password updated', Constants.TOAST.success);
                    }else{
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                })
        })
    }


    documentGstHandler(){
        let that = this;
        document.getElementById('btn_show_gst_numbers').addEventListener('click', function (event) {
            var modal = new bootstrap.Modal(document.getElementById('gstList'));
            modal.show();
            event.stopPropagation();
        });  
        Utils.requestHandler("/scf/api/onboarding/company/gst-all/" + that.business_applicant_id, Constants.REQUEST.get, {})
            .then(function (response){
                if(response.message === 'OK'){
                    that.documentGstInfoCards(response.data.gstins);
                    that.documentGstGetOtp();
                    that.documentGstSubmitOtp();
                    that.toggleGstotp();
                    
                    // Utils.showToast(response.message, 'All linked GST fetched', Constants.TOAST.success);
                }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            });
    }

    toggleGstotp() {
        document.getElementById('show-hide-gst-otp').addEventListener('change', function(event) {
            let inputElement = document.getElementById('gstin_otp');
            if (event.target.checked) {
                inputElement.type = 'text';
            } else {
                inputElement.type = 'password';
            }
        });
    }

    documentGstInfoCards(data){
        let that = this;
        let temp = document.getElementById('gst-info-tmpl');
        let all_cards = document.getElementById('all_gst_cards')
        let rendered = data.map(function (row){
            let d = {
                gstin: row.gstin,
                state: row.state
            }   
            return Mustache.render(temp.innerHTML, d);
        })

        all_cards.innerHTML = '';
        rendered.forEach(row => {
            all_cards.append($.parseHTML(row)[1])
        })
        let gst_modal = new bootstrap.Modal(document.getElementById('gst_modal'), {});
        let closeModalButtonSecond = document.getElementById("btn-gst-list-modal-close");
        document.querySelectorAll('.gst-ph').forEach(function (item){
           item.addEventListener('click', function (event){
                event.stopPropagation();
                let gstin = item.getAttribute('data-gstin');
                document.getElementById('gst_modal').setAttribute('data-gstin', gstin);
                document.getElementById('gst-fetch-otp').setAttribute('data-gstin', gstin);
                document.getElementById('gst-submit-otp').setAttribute('data-gstin', gstin); 
                closeModalButtonSecond.click();
                gst_modal.show();   
                document.getElementById('gst_modal').addEventListener('hide.bs.modal', () => {
                  document.querySelectorAll('#gst_modal input[type="text"], #gst_modal input[type="password"]').forEach(field => {
                    field.value = '';
                  });
                });
           })
        })
    }

    documentGstGetOtp(){
        let that = this;
    
        function handleGstFetchOtp(event){
            event.stopPropagation();
            let gstin = this.getAttribute('data-gstin');
            let username = document.getElementById('gstin_username');
            if(!username.value){
                Utils.showInputError(username, 'Username is mandatory');
                return;
            }
            let body = {
                gst_username: username.value
            };
            Utils.requestHandler("/scf/api/application/"+ that.scf_application_id + "/gst-access/initiate/" + gstin, Constants.REQUEST.post, body)
                .then(function (response){
                    if(response.message === 'OK'){
                        Utils.showToast('GST Access', response.data.message, Constants.TOAST.success);
                    }else{
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                });
        }
    
        document.getElementById('gst-fetch-otp').onclick = handleGstFetchOtp;
    }
    
    documentGstSubmitOtp(){
        let that = this;
        
        function handleGstSubmitOtp(event){
            let gstin = this.getAttribute('data-gstin');
            event.stopPropagation();
            let username = document.getElementById('gstin_username');
            let otp = document.getElementById('gstin_otp');
            if(!username.value){
                Utils.showInputError(username, 'Username is mandatory');
                return;
            }
            if(!otp.value){
                Utils.showInputError(otp, 'OTP is mandatory');
                return;   
            }
            let body = {
                gst_username: username.value,
                otp:  otp.value
            };
            Utils.requestHandler("/scf/api/application/" + that.scf_application_id + "/gst-access/authorize/" + gstin, Constants.REQUEST.post, body)
                .then(function (response){
                    if(response.message === 'OK'){
                        let closeModalButtonMain = document.getElementById("gst-otp-modal-close"); 
                        closeModalButtonMain.click();
                        let closeModalButtonSecond = document.getElementById("btn-gst-list-modal-close"); 
                        closeModalButtonSecond.click();
                        Utils.showToast('GST Access', response.data.message, Constants.TOAST.success);
                    }else{
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                });
        }

        document.getElementById('gst-submit-otp').onclick = handleGstSubmitOtp;
    }
    
    documentBankingHandler(){
        let that = this;
        this.editBankAccountsPanel();
    }

    editBankAccountsPanel(){
        let that = this;
        Utils.requestHandler("/scf/api/application/" + that.scf_application_id + '/bank-accounts', Constants.REQUEST.get, {})
            .then(function (response){
                if(response.message === 'OK'){
                    response.data.bank_accounts.forEach(bank_account => {
                        let abs_banking = new AbstractBanking(bank_account.id);
                        abs_banking.account_number = bank_account.account_number;
                        abs_banking.account_type = bank_account.account_type;
                        abs_banking.account_holder = bank_account.primary_account_holder_name;
                        abs_banking.verified_account_holder = bank_account.primary_account_holder_name;
                        abs_banking.ifsc_code = bank_account.ifsc_code;
                        abs_banking.bank_branch = bank_account.branch;
                        abs_banking.bank_name = bank_account.bank_name;
                        abs_banking.bank_micr = bank_account.micr_code;
                        that.obj_banking.push(abs_banking);
                    })
                    that.bankRenderVerifiedAccounts();
                    that.uploadOnboardingBankingDocuments();
                    // Utils.showToast('Fetched', 'Bank Accounts', Constants.TOAST.success);
                }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
    }

    bankRenderVerifiedAccounts(){
        let that = this;
        let temp = document.getElementById('banking-tr-tmpl-upload-docs');
        let ac_table = document.getElementById('verified_accounts');
        ac_table.innerHTML = null;
        this.obj_banking.forEach(function (abs_banking){
            if(abs_banking.account_holder != null && abs_banking.account_number != null){
                let rend_row = Mustache.render(temp.innerHTML, abs_banking);
                ac_table.append($.parseHTML(rend_row)[1]); 
            }
        })
       
    }

    documentCpHandler(){
        let that = this;
        that.renderVerifiedCpInTable();
    }

    renderVerifiedCpInTable(){
        let that = this;
        Utils.requestHandler('/scf/api/application/'+that.scf_application_id+'/counter-parties', Constants.REQUEST.get)
        .then(function (response){
            if(response.message === 'OK'){
                let tmpl = document.getElementById('cp-row-doc-upload-tmpl');
                let dx = document.getElementById('verified_cps');
                dx.innerHTML = null;
                response.data.counter_parties.forEach(function (dd){
                    dd.vintage = Utils.formatTimeRelative(dd.vintage);
                    let rendered = Mustache.render(tmpl.innerHTML, dd);
                    dx.append($.parseHTML(rendered)[1]);
                })
                that.uploadOnboardingCpDocuments();
            }else{
                // Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
        })
       
    }

    uploadOnboardingCpDocuments() {
        let that = this;
        const buttons = document.querySelectorAll('button[id^="btn_cp_file_upload"]');
      
        buttons.forEach(button => {
          const cp_id = button.getAttribute('cp-id');
          const buttonId = button.id;
          const documentPreview = document.querySelector(`[cp-id="${cp_id}_preview"][id="${buttonId}_preview"]`);
          DocumentUtils.bindInputElement(button);
          DocumentUtils.showOfflinePreview(button, documentPreview);
          const inp_elem = button.parentElement.querySelector('.file-input');
          let file_code;

          inp_elem.addEventListener('change', function () {
            if (inp_elem.files.length >= 1) {
                let more_params = {};
                more_params.cp_id = cp_id;
                more_params.document_for = "CounterParty";

                file_code = buttonId.substring("btn_cp_file_upload_".length);
                let url = `/scf/api/application/${that.scf_application_id}/document/upload/${file_code}`;
                DocumentUtils.uploadOnElementTrigger(inp_elem, inp_elem, url, more_params);
                setTimeout(function() {
                    that.renderDocuments();
                }, 5000);
                inp_elem.value = '';
            }
          });
        });
    }

    bankStatementUploadHandler(id){
        let data = {
            documents: {bank_statement_1: []},
            ref: {
                bank_statement_1: {
                    set_key: "bank_statement_1",
                    set_name: "Bank Statement",
                    set_value: {
                        title: "Bank Statement",
                        desc: "Bank Statement for last 6 months",
                        ext: ["pdf"]
                    }
                } 
            }
        }

        let that = this;
        let tmpl = document.getElementById('doc-tmpl');
        let p_tmpl = document.getElementById('doc-preview-tmpl');
        this.elem_banking_modal_body.innerHTML = '';
        let grped_data = data.documents;
        for(let dtype in grped_data){
            let d = that.transformDocumentData(data.ref[dtype], grped_data[dtype][0]);
            let rendered = Mustache.render(tmpl.innerHTML, d);
            that.elem_banking_modal_body.append($.parseHTML(rendered)[1]);
            let prev = document.getElementById('prev-'+dtype);
            let url = '/scf/api/application/' + that.scf_application_id + '/document/upload/bank_statement';
            let action_element = document.getElementById('btn_upload_'+dtype);
            let metadata = {
                "data-bank-account": id,
            }
            DocumentUtils.bindInputElement(action_element);
            DocumentUtils.uploadFiles(action_element, url, metadata);
            that.showUploadPreview(action_element);
            grped_data[dtype].forEach(function (doc_data){
                let d1 = that.transformPreviewData(doc_data);
                let rend = Mustache.render(p_tmpl.innerHTML, d1);
                prev.append($.parseHTML(rend)[1]);
            })
        }
        document.querySelectorAll('.btn-doc-password').forEach(function (elem){
            elem.addEventListener('click', function (){
                that.passwordHandler(elem);
            })
        })
    }

    documentAadhaarHandler(){
        let that = this;
        let company_type = document.getElementById('company_pan_type');
        if(!company_type.value || (company_type.value != "sole_proprietorship")){
            return
        }
        that.documentAadhaarInfoCards();
        that.documentAadhaarGetOtp();
        that.documentAadhaarSubmitOtp();
    }

    documentPromoterHandler(){
        let that = this;
        that.addPromoterDocumentInputFields();
    }

    addPromoterDocumentInputFields(){
        let that = this
        let promoterDocUploadSection = document.getElementById('promoterDocUploadTarget');
        Utils.requestHandler("/scf/api/onboarding/company/directors/" + that.business_applicant_id, Constants.REQUEST.get, {})
        .then(function (response){
            if(response.message === 'OK'){
                promoterDocUploadSection.innerHTML = '';
                response.data.forEach(item => {
                    const fullName = item.first_name + " " + item.last_name
                    const promoterId = item.id
                    const template = document.getElementById('promoterDocUploadTemplate').innerHTML;
                    const rendered = Mustache.render(template, { name: fullName, id: promoterId });
                    promoterDocUploadSection.innerHTML += rendered;
                });
                that.uploadOnboardingPromoterDocuments();
            }else{
                Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }

        })
    }

    uploadOnboardingDocuments() {
        let that = this;
        const buttons = document.querySelectorAll('button[id^="btn_doc_upload"]');
      
        buttons.forEach(button => {
          const buttonId = button.id;
          const documentPreview = document.getElementById(`${buttonId}_preview`);
          DocumentUtils.bindInputElement(button);
          DocumentUtils.showOfflinePreview(button, documentPreview);
          const inp_elem = button.parentElement.querySelector('.file-input');
          const selectField = document.getElementById(`${buttonId}_select`);
          let file_code;
      
          inp_elem.addEventListener('change', function () {
            if (inp_elem.files.length >= 1) {
              if (selectField) {
                const selectedOption = selectField.options[selectField.selectedIndex];
                file_code = selectedOption.value;
                if(file_code==="Select Type"){
                    documentPreview.innerHTML = "";
                }
              } else {
                file_code = buttonId.substring("btn_doc_upload_".length);
              }
              let url = `/scf/api/application/${that.scf_application_id}/document/upload/${file_code}`;
              DocumentUtils.uploadOnElementTrigger(inp_elem, inp_elem, url);
              setTimeout(function() {
                that.renderDocuments();
              }, 5000);
              if (file_code.startsWith("financial_statements_")){
                button.innerHTML = "Upload More";
              }
              inp_elem.value = '';
            }
          });
        });
    }

    uploadOnboardingBankingDocuments() {
        let that = this;
        const buttons = document.querySelectorAll('.upload-bank-statement');
        buttons.forEach(button => {
            const buttonId = button.id;
            const documentPreview = document.getElementById(`${buttonId}_preview`);
            DocumentUtils.bindInputElement(button);
            DocumentUtils.showOfflinePreview(button, documentPreview);
            const inp_elem = button.parentElement.querySelector('.file-input');
            const bankAccountNumber = button.getAttribute("data_bank_account_number");
            inp_elem.addEventListener('change', function () {
                if (inp_elem.files.length >= 1) {
                    let pdf_password_modal = new bootstrap.Modal(document.getElementById('pdf_password_modal'), {});
                    pdf_password_modal.show();   
                    document.getElementById('pdf_password_modal').addEventListener('hide.bs.modal', () => {
                        document.querySelectorAll('#pdf_password_modal input[type="text"]').forEach(field => {
                            field.value = '';
                        });
                    });
                    document.getElementById('statement-password-submit').addEventListener('click', function() {
                        let url = `/scf/api/application/${that.scf_application_id}/document/upload/bank_statement`;
                        let metadata = {
                            "data_bank_account_number": bankAccountNumber,
                            "file_password": document.getElementById('pdf_password').value
                        }
                        DocumentUtils.uploadOnElementTrigger(inp_elem, inp_elem, url, metadata);
                        setTimeout(function() {
                            that.renderDocuments();
                        }, 5000);
                        button.innerHTML = "Upload More";
                        inp_elem.value = '';
                        document.getElementById('pdf_password_modal').addEventListener('hide.bs.modal', () => {
                            document.querySelectorAll('#pdf_password_modal input[type="text"]').forEach(field => {
                              field.value = '';
                            });
                        });
                        pdf_password_modal.hide(); 
                    })
                }
            });
        });
    }

    uploadOnboardingPromoterDocuments() {
        let that = this;
        const buttons = document.querySelectorAll('button[id^="btn_file_upload"]');
      
        buttons.forEach(button => {
          const promoter_id = button.getAttribute('promoter-id');
          const buttonId = button.id;
          const documentPreview = document.querySelector(`[promoter-id="${promoter_id}_preview"][id="${buttonId}_preview"]`);
          DocumentUtils.bindInputElement(button);
          DocumentUtils.showOfflinePreview(button, documentPreview);
          const inp_elem = button.parentElement.querySelector('.file-input');
          const selectField = document.querySelector(`[promoter-id="${promoter_id}_select"][id="${buttonId}_select"]`);                     
          let file_code;
      
          inp_elem.addEventListener('change', function () {
            if (inp_elem.files.length >= 1) {
                let more_params = {};
                more_params.promoter_id = promoter_id;
                more_params.document_for = "promoter";
              if (selectField) {
                const selectedOption = selectField.options[selectField.selectedIndex];
                file_code = selectedOption.value;
                if(file_code==="Select Type"){
                    documentPreview.innerHTML = "";
                }
              } else {
                file_code = buttonId.substring("btn_file_upload_".length);
              }
              let url = `/scf/api/application/${that.scf_application_id}/document/upload/${file_code}`;
              DocumentUtils.uploadOnElementTrigger(inp_elem, inp_elem, url, more_params);
              setTimeout(function() {
                that.renderDocuments();
              }, 5000);
              inp_elem.value = '';
            }
          });
        });
    }
      
    documentAadhaarInfoCards(){
        let that = this;
        let temp = document.getElementById('aadhaar-info-tmpl');
        let all_cards = document.getElementById('aadhaar_card');
        let rendered = Mustache.render(temp.innerHTML, {aadhaar: '123412341234'});
        all_cards.innerHTML = '';
        all_cards.append($.parseHTML(rendered)[1]);
        
        let aadhaar_modal = new bootstrap.Modal(document.getElementById('aadhaar_modal'), {});
        document.querySelectorAll('.aadhaar-ph').forEach(function (item){
           item.addEventListener('click', function (event){
                event.stopPropagation();
                aadhaar_modal.show();
           })
        })
    }

    documentAadhaarGetOtp(){
        let that = this;
        document.getElementById('aadhaar-fetch-otp').addEventListener('click', function (event){
            event.stopPropagation();
            let aadhaar = document.getElementById('aadhaar');
            if(!aadhaar.value || (aadhaar.value && aadhaar.value.length != 12)){
                Utils.showInputError(aadhaar, 'Aadhaar is mandatory/wrong');
                return;
            }
            Utils.loaderToast(Constants.ELEMENT_STATE.show);
            Utils.requestHandler("/scf/api/application/" + that.scf_application_id + "/aadhaar-access/" + aadhaar.value + "/initiate", Constants.REQUEST.post, {})
                    .then(function (response){
                        if(response.message === 'OK'){
                            Utils.showToast('Aadhaar OTP sent to linked number', response.data.message, Constants.TOAST.success);
                        }else{
                            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                        }
                        Utils.loaderToast(Constants.ELEMENT_STATE.hide);
                    })
                    
        })
    }

    documentAadhaarSubmitOtp(){
        let that = this;
        document.getElementById('aadhaar-submit-otp').addEventListener('click', function (){
                let aadhaar = document.getElementById('aadhaar');
                let otp = document.getElementById('aadhaar_otp');
                if(!aadhaar.value){
                    Utils.showInputError(aadhaar, 'Aadhaar is mandatory');
                    return;
                }
                if(!otp.value){
                    Utils.showInputError(otp, 'OTP is mandatory');
                    return;   
                }
                Utils.loaderToast(Constants.ELEMENT_STATE.show);
                Utils.requestHandler("/scf/api/application/" + that.scf_application_id + "/aadhaar-access/" + aadhaar.value + "/authorize", Constants.REQUEST.post, {"otp": otp.value})
                        .then(function (response){
                            if(response.message === 'OK'){
                                Utils.showToast('Aadhaar XML downloaded', response.data.message, Constants.TOAST.success);
                            }else{
                                Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                            }
                            Utils.loaderToast(Constants.ELEMENT_STATE.hide);
                        })
        })
    }



}



