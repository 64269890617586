import {Utils} from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from "mustache";

export default class ScfUdyamPanel{

    constructor(scf_application_id, inputValue = null, business_applicant_id = null, udyam_reg_no = null) {
        this.scf_application_id = scf_application_id;
        this.inputValue = inputValue;
        this.business_applicant_id = business_applicant_id;
        this.udyam_reg_no = udyam_reg_no;
        if(udyam_reg_no){
            // this.fetchUdyamDetailsFromRegNo(udyam_reg_no);
        }
    }

    fetchUdyamDetails(){

        Utils.loaderToast(Constants.ELEMENT_STATE.show)
        return Utils.requestHandler('/scf/api/application/'+ this.scf_application_id + '/udyam', Constants.REQUEST.post, {udhyam_aadhaar: this.inputValue})
            .then(function (response){
                if(response.message === 'OK'){
                    // Show Success Toast 
                    Utils.loaderToast(Constants.ELEMENT_STATE.hide)
                    return response.data;
                }else{
                    Utils.loaderToast(Constants.ELEMENT_STATE.hide)
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
                
            })

    }

    // fetchUdyamDetailsFromRegNo(udyam_reg_no){
    //     this.elem_inp_udyam.value = udyam_reg_no;
    //     Utils.toggleInput(this.elem_inp_udyam, false)
    //     this.btn_srch_udyam.click();
    // }

}