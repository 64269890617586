import { DocumentUtils, Utils } from "../core/utils";
import * as Constants from "../core/constants";
import Mustache from 'mustache';

export class AllAnchorsList {
  constructor() {
    Utils.pageSanityHandler();
  }



  validateValues(){
    let pass = true;
    if(!this.anchor_id){
      Utils.showInputError(this.elem_anchor_id, 'Anchor is mandatory');
      pass = false;
    }
    if(!this.program_id){
      Utils.showInputError(this.elem_program_id, 'Program is mandatory');
      pass = false;
    }
    if(!this.uploaded_file){
      Utils.showInputError(this.elem_uploaded_file, 'File is mandatory');
      pass = false;
    }
    
    return pass;
  }

  handler() {
    this.fetchAllAnchorsList();
  }

  fetchAllAnchorsList(){
    let that = this;
    Utils.requestHandler('/anchor/api/applicant/get-anchors-list', Constants.REQUEST.get, {})
    .then(function (response){
      if(response.message === 'OK' ){
        that.renderAnchorTable(response);
      }
    }) 
  }

  renderAnchorTable(response) {
    let tmpl = document.getElementById("app-anchor-row-tmpl");
    let dx = document.getElementById("tb_anchor_details");
    dx.innerHTML = null;
    response.data.anchors.forEach(function (dd) {
        let rendered = Mustache.render(tmpl.innerHTML, dd);
        dx.append($.parseHTML(rendered)[1]);
    });
  }
}