import {DocumentUtils, Utils} from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from "mustache";
import {ScfPermission} from "../scf_auth_handler";
import {ScfAppPanelInvoice} from "../application/scf_app_panel_invoice";
import {SERVICE_BASE_BACKOFFICE} from "../../core/constants";

export class ScfInvoice {
  
  constructor(){
    Utils.initializePagers('pager-invoice');
    Utils.pageSanityHandler();
    this.userWisePanelHandler();
    this.userWisePermissoin();
    this.invoice_id = document.getElementById('invoice_id').value;
    this.scf_application_id = null;
    this.elem_invoice_document = document.getElementById('inv_document');
    this.elem_invoice_document_2 = document.getElementById('inv_document_2');
    this.elem_div_inv_data = document.getElementById('div_inv_data');
    this.scf_invoice_irn_document=null;
    
    this.elem_irn_modal_header = document.getElementById('irn_modal_header');
    this.elem_irn_modal = new bootstrap.Modal(document.getElementById('irn_modal'), {});
    
    this.invoice_no = null;
    this.invoice_po = null;
    this.invoice_date = null;
    this.due_date = null;
    this.irn = null;
    this.eway_bill = null;
    this.invoice_amount = null;
    this.amount_paid = null;
    this.amount_due = null;
    this.terms = null;
    this.buyer = null;
    this.buyer_gstin = null;
    this.seller = null;
    this.seller_gstin = null;
    this.verified = null;
    
    this.elem_scf_application_id = document.getElementById('scf_application_id');
    this.scf_invoice_irn_document=document.getElementById('irn_document');
    this.elem_scf_lender_application_id = document.getElementById('scf_lender_application_id');
    this.elem_scf_counter_party = document.getElementById('scf_counter_party');
    this.elem_scf_inv_status = document.getElementById('scf_inv_status');
    this.elem_invoice_no = document.getElementById('inv_no');
    this.elem_invoice_po = document.getElementById('inv_po');
    this.elem_invoice_date = document.getElementById('inv_date');
    this.elem_due_date = document.getElementById('inv_due_date');
    this.elem_irn = document.getElementById('inv_irn');
    this.elem_eway_bill = document.getElementById('inv_eway_bill');
    this.elem_invoice_amount = document.getElementById('inv_amount');
    this.elem_buyer = document.getElementById('buyer');
    this.elem_buyer_gstin = document.getElementById('buyer_gstin');
    this.elem_seller = document.getElementById('supplier');
    this.elem_seller_gstin = document.getElementById('supplier_gstin');
    this.elem_verified = document.getElementById('inv_verified');
    this.elem_e_invoice = document.getElementById('e_invoice_required');
    
    this.elem_div_irn_upload = document.getElementById('div_irn_upload');
    this.elem_btn_submit_irn = document.getElementById('btn-irn-upload-modal');
    this.elem_irn_preview = document.getElementById('irn_preview');
    let urlParams = new URLSearchParams(window.location.search);
    this.scf_application_invoice_id = urlParams.get('scf_application_id');
    this.lender_hsn = document.getElementById('inv_lender_hsn');
    
    new Drawdown(this.elem_scf_application_id);
    new BuyerVerification();
    new BankDetails();
  }
  
  userWisePermissoin() {
    if (ScfPermission.isScfLender()){
      document.getElementById("btn-invoice-submit").classList.add("no-display");
    }
  }
  
  userWisePanelHandler(){
    if(ScfPermission.isScfBorrower()){
      document.getElementById('pager-invoice').querySelectorAll('.pager-items').forEach(
        element => {
          element.classList.add('no-display')
        });
        document.getElementById('btn-inv-cp-verify').classList.add('no-display');
      }else if(ScfPermission.isScfCounterparty()){
        document.getElementById('pager-invoice').querySelectorAll('.pager-items').forEach(
          element => {
            element.classList.add('no-display')
          });
          document.getElementById('btn-inv-edit').classList.add('no-display');
        }
        // document.getElementById('nav-invoice-details').classList.remove('no-display');
        document.getElementById('nav-panel-drawdowns').classList.add("no-display");
      }
      
      getValues(){
        this.elem_invoice_no = document.getElementById('inv_no');
        this.elem_invoice_po = document.getElementById('inv_po');
        this.elem_invoice_date = document.getElementById('inv_date');
        this.elem_due_date = document.getElementById('inv_due_date');
        this.elem_irn = document.getElementById('inv_irn');
        this.elem_eway_bill = document.getElementById('inv_eway_bill');
        this.elem_invoice_amount = document.getElementById('inv_amount');
        this.elem_buyer = document.getElementById('buyer');
        this.elem_buyer_gstin = document.getElementById('buyer_gstin');
        this.elem_seller = document.getElementById('supplier');
        this.elem_seller_gstin = document.getElementById('supplier_gstin');
        this.elem_verified = document.getElementById('inv_verified');
        this.elem_e_invoice = document.getElementById('e_invoice_required');
        
        this.invoice_no = Utils.getter(this.elem_invoice_no);
        this.invoice_po = Utils.getter(this.elem_invoice_po);
        this.invoice_date = Utils.getter(this.elem_invoice_date);
        this.due_date = Utils.getter(this.elem_due_date);
        this.irn = Utils.getter(this.elem_irn);
        this.eway_bill = Utils.getter(this.elem_eway_bill);
        this.invoice_amount = Utils.getter(this.elem_invoice_amount);
        this.amount_paid = 0;
        this.amount_due = 0;
        this.terms = 0;
        this.buyer = Utils.getter(this.elem_buyer);
        this.buyer_gstin = Utils.getter(this.elem_buyer_gstin);
        this.seller = Utils.getter(this.elem_seller);
        this.seller_gstin = Utils.getter(this.elem_seller_gstin);
        this.verified = Utils.getter(this.elem_verified);
      }
      
      validateValues() {
        let that = this;
        that.pass = true;
        document.querySelectorAll('.invoice-details-question').forEach(function (elem) {
          let label = elem.getAttribute('data-label');
          Utils.reInput(elem);
          function toggleMessage() {
            let value = elem.value;
            if (!value) {
              Utils.showInputError(elem, label + ' is mandatory');
              that.pass = false;
            } else {
              if ((elem.id === 'inv_amount' || elem.id === 'inv_no') && parseFloat(value) < 0) {
                Utils.showInputError(elem, label + ' should be a positive number');
                that.pass = false;
              } 
              else if(elem.id==='inv_due_date' && value<=that.invoice_date ){
                Utils.showInputError(elem,'Due Date should be greater than Invoice Date');
                that.pass=false;
              }else{
                Utils.hideInputError(elem);
              }
            }
          }
          toggleMessage();
          elem.addEventListener('input', toggleMessage);
        })
        return that.pass;
      }
      
      handler(){
        this.getInvoiceData();
        this.updateInvoice();
      }
      
      invoiceBackbutton(){
        document.getElementById('btn-back').addEventListener('click', function(e){
          e.preventDefault();
          window.history.back();
          setTimeout(function() {
            document.getElementById('nav-invoice').click();
          }, 10);
        })
      }
      
      setInvoiceDocument(data) {
        if (!data.invoice_document || data.invoice_document.length === 0) {
          console.error('No invoice documents found in data');
          return;
        }
        function createOrUpdateElement(container, url, contentType) {
          if (contentType.startsWith('image/')) {
            container.innerHTML = `<img src="${url}" style="height: 100%; width: 100%;" alt="Invoice Image"/>`;
            container.classList.add('embed-image');
            container.classList.remove('embed-pdf');
          } else if (contentType.startsWith('application/pdf')) {
            container.innerHTML = `<embed src="${url}" type="${contentType}" style="height: 100%; width: 100%;" />`;
            container.classList.add('embed-pdf');
            container.classList.remove('embed-image');
          }
        }
        const documentContainer = document.getElementById('inv_document_container');
        const document2Container = document.getElementById('inv_document_2_container');
        createOrUpdateElement(documentContainer, data.invoice_document[0].service_url, data.invoice_document[0].content_type);
        if ((ScfPermission.isScfAdmin() || ScfPermission.isScfSuperAdmin()) && data.invoice_document.length > 1) {
          createOrUpdateElement(document2Container, data.invoice_document[1].service_url, data.invoice_document[1].content_type);
          document2Container.style.height = '45%';
          documentContainer.style.height = '45%';
        } else {
          document2Container.style.display = 'none';
          documentContainer.style.height = '100%';
        }
      }
      
      
      transformData(data){
        data.invoice.amount_due = 0;
        if(data.invoice.total_invoice_value && data.invoice.amount_paid){
          data.invoice.amount_due = parseInt(parseFloat(data.invoice.total_invoice_value) - parseFloat(data.invoice.amount_paid));
        }
        return data;
      }
      
      setInvoiceData(data){
        let that = this;
        let tmpl = document.getElementById('invoice-tmpl');
        let d = this.transformData(data);
        let rendered = Mustache.render(tmpl.innerHTML, d);
        this.elem_div_inv_data.append($.parseHTML(rendered)[1]);
        document.getElementById('cp_inovice_confirmation_link').setAttribute('href', '/scf/invoice/' + data.invoice.id + '/cp-verification' +  '?token=' + data.invoice.invoice_token + '&scf_application_id=' + that.scf_application_invoice_id);
        document.getElementById('buyer').value = data.invoice.buyer_id;
        this.getBuyerGstins();
        const e = new Event("change");
        const element = document.getElementById('buyer');
        element.dispatchEvent(e);
        
        
        this.buyer_gstin = data.invoice.buyer_gstin;
        document.getElementById('cp_id').value = data.invoice.buyer_id;
        document.getElementById('supplier_gstin').value = data.invoice.supplier_gstin;
        document.getElementById('inv_verified').value = data.invoice.verified;
        document.getElementById('e_invoice_required').value = data.invoice.e_invoice;
        document.getElementById('inv_lender_hsn').value = data.invoice.invoice_hsn;
        if (data.hsn_required === 'No') {
          document.getElementById('inv_lender_hsn').classList.add("no-display");
          document.getElementById('invoice_hsn_required').classList.add("no-display");
        }
        
        if (data.e_invoice == 'No') {
          document.getElementById('envoce_required').classList.add("no-display");
          document.getElementById('e_invoice_required').classList.add("no-display");
        }
        
        if (data.e_invoice === 'No'){
          document.getElementById('e_invoice_and_hsn').classList.add("no-display");
        }
        
        this.uploadIrn();
        this.sendEmail();
        this.invoiceBackbutton();
      }
      
      getIrnDocument(){
        let that = this;
        Utils.requestHandler('/scf/api/invoice/' + that.invoice_id, Constants.REQUEST.get, {inv_type: 'invoice_discounting', scf_application_id: that.scf_application_invoice_id})
        .then(function (response){
          if(response.message === 'OK'){
            that.scf_invoice_irn_document=response.data.invoice.irn_document;
            document.getElementById('irn_document').classList.remove("no-display");
            let irnDocumentLink = document.getElementById('irn_document');
            irnDocumentLink.href = that.scf_invoice_irn_document;
            irnDocumentLink.textContent = "View Here";
          }
        })
      }
      
      getInvoiceData(){
        let that = this;
        let urlParams = new URLSearchParams(window.location.search);
        Utils.requestHandler('/scf/api/invoice/' + that.invoice_id, Constants.REQUEST.get, {inv_type: 'invoice_discounting', scf_application_id: that.scf_application_invoice_id})
        .then(function (response){
          if(response.message === 'OK'){
            that.scf_application_id = response.data.invoice.application_id;
            that.scf_invoice_irn_document = response.data.invoice.irn_document;
            if(response.data.invoice.status === 'verified'){
              document.getElementById('btn-invoice-submit').classList.add('d-none');
              // that.invoiceBuyerApprove();
            }
            if(response.data.invoice.status === 'buyer_approved'){
              document.getElementById('btn-invoice-submit').classList.add('d-none');
              // that.invoiceBuyerReject();
            }

            if(response.data.invoice.status === 'buyer_approved'){
              document.getElementById('btn-invoice-reject').classList.remove('d-none');
            }
            if(response.data.invoice.irn_document){
              setTimeout(function(){
                let irnDocumentLink = document.getElementById('irn_document');
                irnDocumentLink.href = that.scf_invoice_irn_document;
                irnDocumentLink.textContent = "View Here";
              }, 100);
            }
            
            if(response.data.invoice.status === 'verified' || response.data.invoice.status === 'buyer_approved' || response.data.invoice.status === 'buyer_rejected' || response.data.invoice.status === 'flexi_approved' || response.data.invoice.status === 'flexi_rejected' || response.data.invoice.status === 'sent_to_checker'){
              document.getElementById('btn-invoice-submit').classList.add('d-none');
            }
            else{
              setTimeout(function(){
                document.getElementById('irn_document').classList.add("no-display");
              }, 100);
            }
            that.elem_scf_application_id.value = that.scf_application_id;
            document.getElementById('scf_application_id_info').innerText = `Application Id: ${response.data.application_id}`;
            document.getElementById('trade_name_info').innerText = `Trade Name: ${response.data.trade_name}`;
            document.getElementById('business_applicant_info').innerText = `Business Applicant: ${response.data.business_applicant_name}`;
            that.elem_scf_lender_application_id.value = response.data.invoice.scf_lender_application_id;
            that.elem_scf_inv_status.value = response.data.invoice.status;
            if (response.data.invoice.status == 'flexi_approved' || response.data.invoice.status == 'sent_to_checker' || response.data.invoice.status === 'sent_for_ddr') {
              document.getElementById('nav-panel-drawdowns').classList.remove("no-display");
            }
            if (response.data.invoice.status == 'created' || response.data.invoice.status == 'finance_requested' || response.data.invoice.status === 'buyer_approved' || response.data.invoice.status === 'buyer_rejected'){
              document.getElementById('nav-panel-scoring').classList.add("no-display");
            }
            that.setInvoiceData(response.data);
            that.setInvoiceDocument(response.data);
            new Score(response.data);
            if (response.data.product == 'purchase_invoice_discounting'){
              document.getElementById('verified_by_cp_modal').classList.add("no-display");
            }            
            if(response.data.invoice.status === 'flexi_approved'){
              document.getElementById('btn-inv-sent-to-checker').classList.remove('d-none');
              document.getElementById('btn-inv-decision').classList.add('d-none');
              document.getElementById('inv-submit-score').classList.add('d-none');
            }
            if(response.data.invoice.status === 'sent_to_checker'){
              document.getElementById('send-for-draw-down').classList.remove('d-none')
              document.getElementById('reject-from-ddr').classList.remove('d-none')
            }
            if(response.data.invoice.status === 'sent_for_ddr'){
              document.getElementById('btn-inv-decision').classList.add('d-none')
              document.getElementById('send-for-draw-down').classList.add('d-none')
              document.getElementById('reject-from-ddr').classList.add('d-none')
            }
          }else{
            window.history.back();
            setTimeout(function() {
              document.getElementById('nav-invoice').click();
            }, 10);
            Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
          }
        })
      }
      
      invoiceFlexiApproveReject(){
        let that = this;
        document.getElementById('btn-invoice-flexi-approve').classList.remove('d-none');
        document.getElementById('btn-invoice-flexi-reject').classList.remove('d-none');
        document.getElementById('btn-invoice-flexi-approve').addEventListener('click', function() {
          Utils.requestHandler('/scf/api/invoice/' + that.invoice_id + '/buyer-approve', Constants.REQUEST.post, {
            inv_type: 'invoice_discounting',
            scf_application_id: that.scf_application_invoice_id
          }).then(function(response) {
            if(response.message === 'OK'){
              Utils.showToast('Approved', response.data.invoice, Constants.TOAST.success);
              document.getElementById('btn-invoice-flexi-approve').classList.add('d-none');
              document.getElementById('btn-invoice-flexi-reject').classList.add('d-none');
            }else{
              Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
          })
        });
        
        document.getElementById('btn-invoice-flexi-reject').addEventListener('click', function() {
          Utils.requestHandler('/scf/api/invoice/' + that.invoice_id + '/buyer-reject', Constants.REQUEST.post, {
            inv_type: 'invoice_discounting',
            scf_application_id: that.scf_application_invoice_id
          }).then(function(response) {
            if(response.message === 'OK'){
              Utils.showToast('Rejected', response.data.invoice, Constants.TOAST.fail);
              document.getElementById('btn-invoice-flexi-approve').classList.add('d-none');
              document.getElementById('btn-invoice-flexi-reject').classList.add('d-none');
            }else{
              Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
          })
        });
      };
      
      invoiceBuyerApprove(){
        let that = this;
        document.getElementById('btn-invoice-approve').classList.remove('d-none');
        document.getElementById('btn-invoice-reject').classList.remove('d-none');
        document.getElementById('btn-invoice-approve').addEventListener('click', function() {
          Utils.requestHandler('/scf/api/invoice/' + that.invoice_id + '/buyer-approve', Constants.REQUEST.post, {
            inv_type: 'invoice_discounting',
            scf_application_id: that.scf_application_invoice_id
          }).then(function(response) {
            if(response.message === 'OK'){
              Utils.showToast('Approved', response.data.invoice, Constants.TOAST.success);
              document.getElementById('btn-invoice-approve').classList.add('d-none');
              document.getElementById('btn-invoice-reject').classList.add('d-none');
            }else{
              Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
          })
        });
      };
      
      invoiceBuyerReject(){
        let that = this;
        document.getElementById('btn-invoice-reject').addEventListener('click', function() {
          Utils.requestHandler('/scf/api/invoice/' + that.invoice_id + '/buyer-reject', Constants.REQUEST.post, {
            inv_type: 'invoice_discounting',
            scf_application_id: that.scf_application_invoice_id
          }).then(function(response) {
            if(response.message === 'OK'){
              Utils.showToast('Rejected', response.data.invoice, Constants.TOAST.fail);
              document.getElementById('btn-invoice-approve').classList.add('d-none');
              document.getElementById('btn-invoice-reject').classList.add('d-none');
            }else{
              Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
          })
        });
      }
      updateInvoice(){
        let that = this;
        document.getElementById('btn-invoice-submit').addEventListener('click', function(){
          that.getValues();
          if(that.validateValues()){
            let body = {
              inv_type: 'invoice_discounting',
              invoice_no: that.invoice_no,
              purchase_order_no: that.invoice_po,
              invoice_date: that.invoice_date,
              due_date: that.due_date,
              irn: that.irn,
              e_way_bill_number: that.eway_bill,
              total_invoice_value: that.invoice_amount,
              amount_paid: that.amount_paid,
              pay_terms: that.terms,
              buyer_id: that.buyer,
              buyer_gstin: that.buyer_gstin,
              supplier_id: that.seller,
              supplier_gstin: that.seller_gstin,
              invoice_line_items: [],
              verified: that.verified,
              scf_application_id: that.scf_application_invoice_id,
              lender_hsn: document.getElementById('inv_lender_hsn').value,
              e_invoice: document.getElementById('e_invoice_required').value
            }
            Utils.requestHandler('/scf/api/invoice/' + that.invoice_id, Constants.REQUEST.post, body)
            .then(function (response){
              if(response.message === 'OK'){
                Utils.showToast('Updated', response.data.invoice, Constants.TOAST.success);
                document.getElementById('btn-invoice-submit').disabled=true;
              }else{
                Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
              }
            })
          }
        })
      }
      
      
      getBuyerGstins(){
        let that = this;
        document.getElementById('buyer').addEventListener('change', function(){
          let buyer = document.getElementById('buyer');
          let option = buyer.options[buyer.selectedIndex];
          let buyer_cp_id = option.getAttribute('value')
          Utils.requestHandler('/scf/api/counter_party/' + buyer_cp_id + '/gst-all', Constants.REQUEST.get, {})
          .then(function (response){
            let buyer_gstin = document.getElementById('buyer_gstin');
            buyer_gstin.innerHTML = '';
            if(response.message === 'OK'){
              let opt = document.createElement('option');
              opt.value = '';
              opt.innerHTML = 'Select GSTIN';
              buyer_gstin.append(opt);
              response.data.gstins.forEach(gst => {
                let opt = document.createElement('option');
                opt.value = gst.gstin;
                opt.innerHTML = gst.gstin;
                buyer_gstin.append(opt);
              })
              buyer_gstin.value = that.buyer_gstin;
              Utils.showToast('Fetched', 'GSTINs fetched', Constants.TOAST.success);
            }else{
              Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
          })
        })
      }
      
      
      uploadIrn(){
        let that = this;
        DocumentUtils.bindInputElement(that.elem_div_irn_upload, null);
        DocumentUtils.showOfflinePreview(that.elem_div_irn_upload, that.elem_irn_preview);
        document.getElementById('btn-irn-upload').addEventListener('click', function (){
          document.querySelectorAll('.invoice-stage').forEach(function (elem){
            elem.classList.add('no-display')
          })
          document.getElementById('irn_stage_upload').classList.remove('no-display');
          that.elem_irn_modal_header.innerHTML = 'Upload IRN photo';
          that.elem_irn_modal.show();
          that.uploadIrnImage();
        })
      }
      
      static bindInputElement(action_element){
        action_element.addEventListener('click', function(){
          let inp_elem = action_element.parentElement.querySelector('.file-input');
          if (!inp_elem.files || inp_elem.files.length === 0) {
            inp_elem.dispatchEvent(new MouseEvent('click'));
          }
        })
      }
      
      static getFileData(inp_elem, more_params = null){
        let file_data = [];
        Array.from(inp_elem.files).forEach(function (fls){
          let body = new FormData();
          body.set('file_data', fls)
          body.set('file_name', fls.name);
          if(more_params){
            for (const m in more_params) {
              body.set(m, more_params[m]);
            }
          }
          file_data.push(body);
        })
        return file_data;
      }
      
      uploadIrnImage(){
        const inp_elem = this.elem_div_irn_upload.parentElement.querySelector('.file-input');
        const that = this;
        document.getElementById('btn-irn-upload-modal').addEventListener('click', function(){
          let body = {
            scf_application_id: that.scf_application_invoice_id
          };
          let fileData = ScfInvoice.getFileData(inp_elem, body);
          if (fileData.length > 0) {
            fileData.forEach(function(fileBody) {
              Utils.requestHandler('/scf/api/invoice/' + that.invoice_id + '/irn-upload', Constants.REQUEST.post, fileBody)
              .then(function (response){
                if(response.message === 'OK'){
                  Utils.showToast('Uploaded', 'Document uploaded', Constants.TOAST.success);
                  that.elem_irn_modal.hide();
                  setTimeout(function() {
                    that.getIrnDocument();
                  }, 4000);
                } else {
                  Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
              });
            });
          } else {
            Utils.showToast('Error', 'No files selected', Constants.TOAST.fail);
          }
        });
      }  
      
      uploadIrnHelper() {
        const that = this;
        const inp_elem = that.elem_div_irn_upload.parentElement.querySelector('.file-input');
        this.elem_btn_submit_irn.addEventListener('click', function() {
          let body = {
            scf_application_id: that.scf_application_invoice_id
          };
          DocumentUtils.uploadOnElementTrigger(that.elem_btn_submit_irn, inp_elem, '/scf/api/invoice/' + that.invoice_id + '/irn-upload', body);
        });
        that.elem_btn_submit_irn.addEventListener('ev_document_uploaded', function() {
          Utils.clean(document.getElementById('irn_stage_upload'));
          that.elem_irn_preview.innerHTML = null;
          that.elem_irn_modal.hide();
        });
      }
      
      
      sendEmail(){
        let that = this;
        let body = {
          url: Constants.SERVICE_BASE_BACKOFFICE + "/v2/send-email",
          req_type: "post",
          scf_application_id:that.scf_application_invoice_id
        }
        document.getElementById('cp_invoice_send_email_confirmation').addEventListener('click', function() {
          Utils.requestHandler('/scf/api/invoice/' + that.invoice_id + '/send-confirmation-link', Constants.REQUEST.post, body)
          .then(function (response){
            if(response.message === 'OK'){
              Utils.showToast(response.message, 'Email confirmation sent', Constants.TOAST.success);
            }else{
              Utils.showToast(response.message, response.errors.message, Constants.TOAST.fail);
            }
          })
        })
      }
    }
    
    class BankDetails {
      
      constructor(){
        this.account_no = null;
        this.ifsc_code = null;
        this.account_holder_name = null;
        this.account_type = null;
        
        this.elem_account_no = null;
        this.elem_ifsc_code = null;
        this.elem_account_holder_name = null;
        this.elem_account_type = null;
        this.elem_banking_details = document.getElementById('banking_details');
        
        this.setBankingDetails();
        this.getBankingDetails();
        if(!ScfPermission.isScfLender()){
          document.getElementById('btn-sanction').classList.add("no-display");
        }
      }
      
      getBankingDetails(){
        document.getElementById('nav-panel-drawdowns').addEventListener('click', function(){
          Utils.requestHandler("/scf/api/counter_party/" + document.getElementById('cp_id').value, Constants.REQUEST.get, {}).then(function (response){
            document.getElementById('account_no').value = response.data.cp_confirmed_bank_account.account_number;
            document.getElementById('ifsc_code').value = response.data.cp_confirmed_bank_account.ifsc_code;
            document.getElementById('account_holder_name').value = response.data.cp_confirmed_bank_account.primary_account_holder_name;
          });
        });
      }
      
      setBankingDetails(){
        let tmpl = document.getElementById('invoice-banking-tmpl');
        let rendered = Mustache.render(tmpl.innerHTML, {});
        
        this.elem_banking_details.append($.parseHTML(rendered)[1]);
        this.elem_account_no = document.getElementById('account_no');
        this.elem_ifsc_code = document.getElementById('ifsc_code');
        this.elem_account_holder_name = document.getElementById('account_holder_name');
        this.elem_account_type = document.getElementById('account_type');
        
        this.elem_account_no.addEventListener('input', function (){
          Utils.reInput(this);
        })
        this.elem_ifsc_code.addEventListener('input', function (){
          Utils.reInput(this);
        })
        this.elem_account_holder_name.addEventListener('input', function (){
          Utils.reInput(this);
        })
        // this.saveBankingDetails();
      }
      
      getValues(){
        this.account_no = Utils.getter(this.elem_account_no);
        this.ifsc_code = Utils.getter(this.elem_ifsc_code);
        this.account_holder_name = Utils.getter(this.elem_account_holder_name);
        this.account_type = Utils.getter(this.elem_account_type);
      }
      
      validateValues(){  
        let pass = true; 
        if(!this.account_no){
          Utils.showInputError(this.elem_account_no, 'Account no is mandatory');
          pass = false;
        }
        
        if(!this.ifsc_code){
          Utils.showInputError(this.elem_ifsc_code, 'IFSC code is mandatory');
          pass = false;
        }
        
        if(!this.account_holder_name){
          Utils.showInputError(this.elem_account_holder_name, 'Account Holder name is mandatory');
          pass = false;
        }
        
        if(!this.account_type){
          Utils.showInputError(this.elem_account_type, 'Account Type is mandatory');
          pass = false;
        }
        return pass;
      }
    }
    
    class BuyerVerification {
      
      constructor() {
        let that = this;
        this.invoice_id = document.getElementById('invoice_id').value;
        this.elem_submit_buyer_btn = document.getElementById('btn-buyer-inv-decision');
        let urlParams = new URLSearchParams(window.location.search);
        this.scf_application_invoice_id = urlParams.get('scf_application_id');
        this.buyerVerify();
      }
      
      buyerVerify(){
        let that = this;
        that.elem_submit_buyer_btn.addEventListener('click', function (){
          Utils.requestHandler("/scf/api/invoice/" + that.invoice_id + '/buyer-verified', Constants.REQUEST.post, {inv_type: 'invoice_discounting', scf_application_id: that.scf_application_invoice_id})
          .then(function (response) {
            if (response.message === 'OK') {
              Utils.showToast('Success', 'Verified by maker', Constants.TOAST.success);
            }else{
              Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
          })
        })
      }
    }
    
    class Drawdown {
      constructor(elem_scf_application_id){
        this.elem_scf_application_id = elem_scf_application_id;
        this.elem_scf_lender_application_id = document.getElementById('scf_lender_application_id');
        this.elem_scf_inv_status = document.getElementById('scf_inv_status');
        this.elem_sanction_checker = document.getElementById('btn-sanction');
        this.invoice_id = document.getElementById('invoice_id').value;
        this.submitChecker();
        this.financingPartnerChange();
        this.disbursedInvoice();
        let urlParams = new URLSearchParams(window.location.search);
        this.scf_application_invoice_id = urlParams.get('scf_application_id');
      }
      
      checkerLenderFinance(){
        let that = this;
        document.getElementById('nav-panel-drawdowns').addEventListener('click', function (){
          document.querySelectorAll('.invoice-stage').forEach(function (elem){
            elem.classList.add('no-display')
          })
          document.getElementById('inv_stage_req_fin_1').classList.remove('no-display');
          Utils.requestHandler("/scf/api/application/" + that.elem_scf_application_id.value + '/app-limits', Constants.REQUEST.get, {})
          .then(function (response){
            if(response.message === 'OK'){
              if(response.data.lenders.length > 0){
                let dd = document.getElementById('inv_scf_lender_app');
                dd.innerHTML = null;
                let option = document.createElement('option');
                option.value = "";
                option.text = "";
                dd.add(option);
                response.data.lenders.forEach(function (limit_data){
                  // if(limit_data.limit_type === 'lender_limit') {
                  let option = document.createElement('option');
                  option.value = limit_data.scf_lender_applications_id;
                  option.setAttribute('data-foobar', JSON.stringify(limit_data));
                  option.text = limit_data.lender_name;
                  dd.add(option);
                  // }
                })
              }
            }
          })
          if(that.elem_scf_inv_status.value === 'sanctioned') {
            that.elem_sanction_checker.setAttribute('disabled', 'disabled');
            that.elem_sanction_checker.innerHTML = 'Disbursement initiated'
            that.elem_sanction_checker.classList.remove('no-display');
          }
        })
      }
      
      financingPartnerChange(){
        let that = this;
        document.getElementById('inv_scf_lender_app').addEventListener('change', function () {
          Utils.requestHandler("/scf/api/invoice/" + that.invoice_id + '/disburstment-data', Constants.REQUEST.get, {scf_lender_applications_id: document.getElementById('inv_scf_lender_app').value, inv_type: 'invoice_discounting', scf_application_id: that.scf_application_invoice_id})
          .then(function (response) {
            document.getElementById('inv_sap_transaction_fee').textContent = "-";
            document.getElementById('processing_fee_value_label').textContent;
            document.getElementById('inv_sap_amount').textContent = "-"
            document.getElementById('inv_sap_funding_requested').textContent = "-";
            document.getElementById('inv_sap_margin').textContent = "-";
            document.getElementById('inv_sap_damount').textContent = "-";
            document.getElementById('inv_sap_activedis').textContent = "-";
            document.getElementById('inv_sap_roi').textContent = "-";
            document.getElementById('inv_sap_repay_date').textContent = "-";
            document.getElementById('inv_sap_repay').textContent = "-";
            document.getElementById('inv_sap_tenure').textContent = "-";
            document.getElementById('invoice_int_mode').textContent = "-";
            if(response.data.disbursal_data!=null && response.data.disbursal_data!=undefined){
              if (response.data.program_rule != null && response.data.program_rule != undefined){
                document.getElementById('inv_sap_transaction_fee').textContent = response.data.program_rule.transaction_fee_amount.max_value;
                document.getElementById('invoice_int_mode').textContent = response.data.program_rule.int_mode_value;
                document.getElementById('trans_fee_value_label').textContent = "Transaction Fee [" + response.data.program_rule.invoice_transcation_amount_applied_on + "]";
                if (response.data.program_rule.invoice_transcation_fee_applied_on != 'Yes') {
                  document.getElementById('trans_fee_value_label').classList.add('no-display')
                  document.getElementById('inv_sap_transaction_fee').classList.add('no-display')
                }
              }
              document.getElementById('inv_sap_amount').textContent = response.data.disbursal_data.invoice_amount;
              document.getElementById('inv_sap_funding_requested').textContent = response.data.disbursal_data.funding_amount;
              document.getElementById('inv_sap_margin').textContent = response.data.disbursal_data.margin;
              document.getElementById('inv_sap_damount').textContent = response.data.disbursal_data.disbursment_permissible;
              document.getElementById('inv_sap_activedis').textContent = response.data.disbursal_data.active_disbursment;
              document.getElementById('inv_sap_roi').textContent = response.data.disbursal_data.roi;
              document.getElementById('inv_sap_repay_date').textContent = response.data.disbursal_data.repayment_date;
              document.getElementById('inv_sap_repay').textContent = response.data.disbursal_data.disbursment_amount;
              document.getElementById('inv_sap_tenure').textContent = response.data.disbursal_data.tenure;
            }
          });
        })
      }
      
      disbursedInvoice(){
        let that = this;
        document.getElementById('nav-panel-drawdowns').addEventListener('click', function (){
          Utils.requestHandler("/scf/api/invoice/" + that.invoice_id + '/disburstment-data', Constants.REQUEST.get,{scf_application_id: that.scf_application_invoice_id})
          .then(function (response){
            if(response.message === 'OK'){
              Utils.showToast(response.message, 'Disburshed fetched Sucessfully', Constants.TOAST.success);
              if(response.data.disbursal_data!=null && response.data.disbursal_data!=undefined){
                document.getElementById('inv_scf_lender_app').textContent = response.data.disbursal_data.lender_name;
                document.getElementById('inv_scf_lender_app').disabled = true;
                document.getElementById('inv_sap_amount').textContent = response.data.disbursal_data.invoice_amount;
                document.getElementById('inv_sap_funding_requested').textContent = response.data.disbursal_data.funding_amount;
                document.getElementById('inv_sap_margin').textContent = response.data.disbursal_data.margin;
                document.getElementById('inv_sap_damount').textContent = response.data.disbursal_data.disbursment_permissible;
                document.getElementById('inv_sap_activedis').textContent = response.data.disbursal_data.active_disbursment;
                document.getElementById('inv_sap_roi').textContent = response.data.disbursal_data.roi;
                document.getElementById('inv_sap_repay_date').textContent = response.data.disbursal_data.repayment_date;
                document.getElementById('inv_sap_repay').textContent = response.data.disbursal_data.disbursment_amount;
                document.getElementById('inv_sap_tenure').textContent = response.data.disbursal_data.tenure;
                document.getElementById('inv_sap_transaction_fee').textContent = response.data.program_rule.transaction_fee_amount;
                document.getElementById('invoice_int_mode').textContent = response.data.program_rule.int_mode_value;
                if (response.data.program_rule.invoice_transcation_fee_applied_on != 'Yes') {
                  document.getElementById('trans_fee_value_label').classList.add('d-none')
                  document.getElementById('inv_sap_transaction_fee').classList.add('d-none')
                }
                if (response.data.program_rule.invoice_transcation_fee_applied_on == 'Yes'){
                  document.getElementById('trans_fee_value_label').innerHTML += " ["+response.data.program_rule.invoice_transcation_fee_applied_on+" ]";
                  document.getElementById('inv_sap_transaction_fee').innerHTML = response.data.program_rule.transaction_fee_amount;
                }
                document.getElementById('inv_sap_transaction_fee').textContent = response.data.program_rule.transaction_fee_amount;
                document.getElementById('inv_sap_transaction_fee').setAttribute('disabled', 'disabled');
              }
            }else{
              Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
              document.getElementById('nav-panel-scoring').click();
            }
          })
        })
      }
      
      submitChecker(){
        let that = this;
        that.elem_sanction_checker.addEventListener('click', function (){
          Utils.requestHandler("/scf/api/invoice/" + that.invoice_id + '/checker/sanction', Constants.REQUEST.post, {inv_type: 'invoice_discounting', scf_application_id: that.scf_application_invoice_id})
          .then(function (response) {
            if (response.message === 'OK') {
              Utils.showToast('Success', 'Invoice verified by checker', Constants.TOAST.success);
              that.elem_sanction_maker.classList.add('no-display');
              that.elem_sanction_checker.classList.remove('no-display');
            }else{
              Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
          })
        })
      }
    }
    
    class Score {
      constructor(inv_data){
        this.score = null;
        this.decision = null;
        this.comment = null;
        this.invoice_id = inv_data.invoice.id;
        
        this.elem_score = null;
        this.elem_decision = null;
        this.elem_comment = null;
        
        this.div_panel_score = document.getElementById('panel-score');
        
        this.renderInvoiceScore(inv_data);
        this.handler();
        this.invoice_status = document.getElementById('scf_inv_status').value;
        if(this.invoice_status == 'flexi_approved' || this.invoice_status == 'flexi_rejected' || this.invoice_status == 'sent_to_checker' || this.invoice_status == 'sent_for_ddr' || this.invoice_status === 'rejected_from_ddr' || this.invoice_status === 'lender_sanctioned' || this.invoice_status === 'lender_rejected' || this.invoice_status === 'lender_disbursed' || this.invoice_status === 'sent_for_ddr'){
          document.getElementById('btn-inv-decision').classList.add("no-display");
        }
        if(this.invoice_status != 'approved'){
          document.getElementById('verified_by_cp_modal').classList.add("no-display");
        }
        let urlParams = new URLSearchParams(window.location.search);
        this.scf_application_invoice_id = urlParams.get('scf_application_id');
      }
      
      handler(){
        let that=this;
        that.userWisePermissoin();
      }
      
      userWisePermissoin() {
        if (ScfPermission.isScfLender()){
          document.getElementById("inv-submit-score").classList.add("no-display");
          document.getElementById("verified_by_cp_modal").classList.add("no-display");
        }
      }
      
      
      getValues(){
        this.elem_score = document.getElementById('inv_score');
        this.elem_decision = document.getElementById('inv_decision');
        this.elem_comment = document.getElementById('inv_comment');
        
        this.score = Utils.getter(this.elem_score);
        this.decision = Utils.getter(this.elem_decision);
        this.comment = this.elem_comment.value;
      }
      
      renderInvoiceScore(inv_data){
        let tmpl = document.getElementById('tmpl-inv-score');
        let rendered = Mustache.render(tmpl.innerHTML, inv_data);
        this.div_panel_score.innerHTML = '';
        this.div_panel_score.append($.parseHTML(rendered)[1]);
        document.getElementById('inv_comment').value = inv_data.invoice.remarks;
        document.getElementById('inv_decision').value = inv_data.invoice.status;
        this.submitScore();
        this.submitDecision();
        this.sendTOChecker();
        this.submitDrawDownRequest();
        this.invoiceDDRreject();
      }
      
      validateScoring(){
        let that = this;
        that.pass = true;
        document.querySelectorAll('.scoring-details-question').forEach(function (elem){
          let label = elem.getAttribute('data-label');
          Utils.reInput(elem);
          function toggleMessage(){
            let value = elem.value;
            if(!value){
              Utils.showInputError(elem, label + ' is mandatory');
              that.pass = false;
            }else{
              Utils.hideInputError(elem);
            }
          }
          toggleMessage();
          elem.addEventListener('input', toggleMessage);
        })
        return that.pass;
        
      }
      
      validateDecision(){
        let that = this;
        that.pass = true;
        document.querySelectorAll('.invocie-decision-question').forEach(function (elem){
          let label = elem.getAttribute('data-label');
          Utils.reInput(elem);
          function toggleDecision(){
            let value = elem.value;
            if(!value){
              Utils.showInputError(elem, label + ' is mandatory');
              that.pass = false;
            } else {
              Utils.hideInputError(elem);
            }
          }
          toggleDecision();
          elem.addEventListener('input', toggleDecision);
        });
        return that.pass;
      }
      
      submitScore(){
        let that = this;
        document.getElementById('inv-submit-score').addEventListener('click', function(){
          that.getValues();
          if (that.score <= 0) {
            Utils.showToast('Error', 'Invalid Score', Constants.TOAST.fail);
            return;
          }
          that.validateScoring();
          if(that.pass){
            Utils.requestHandler('/scf/api/invoice/' + that.invoice_id + '/score', Constants.REQUEST.post, {score: that.score, scf_application_id: that.scf_application_invoice_id})
            .then(function (response){
              if(response.message === 'OK'){
                Utils.showToast('Submitted', 'Invoice Score', Constants.TOAST.success);
              }else{
                Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
              }
            })
          }
        })
      }

      invoiceDDRreject(){
        let that = this;
        document.getElementById('reject-from-draw-down').addEventListener('click', function() {
          Utils.requestHandler('/scf/api/invoice/' + that.invoice_id + '/reject-draw-down-request', Constants.REQUEST.post, {
            inv_type: 'invoice_discounting',
            scf_application_id: that.scf_application_invoice_id
          }).then(function(response) {
            if(response.message === 'OK'){
              Utils.showToast('Rejected', response.data.invoice, Constants.TOAST.fail);
              document.getElementById('btn-invoice-flexi-approve').classList.add('d-none');
              document.getElementById('btn-invoice-flexi-reject').classList.add('d-none');
            }else{
              Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
          })
        });
      }
      
      submitDecision(){
        let that = this;
        document.getElementById('btn-inv-decision').addEventListener('click', function(){
          that.getValues();
          that.validateDecision();
          if(that.pass){
            Utils.requestHandler('/scf/api/invoice/' + that.invoice_id + '/decision', Constants.REQUEST.post, {decision: that.decision, remarks: that.comment, inv_type: 'invoice_discounting', scf_application_id: that.scf_application_invoice_id})
            .then(function (response){
              if(response.message === 'OK'){
                Utils.showToast('Submitted', 'Invoice decision', Constants.TOAST.success);
              }else{
                Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
              }
            })
          }
        })
      }

      sendTOChecker(){
        let that = this;
        document.getElementById('btn-inv-sent-to-checker').addEventListener('click', function(){
          that.getValues();
          that.validateDecision();
          if(that.pass){
            Utils.requestHandler('/scf/api/invoice/' + that.invoice_id + '/invoice-checker', Constants.REQUEST.post, {scf_application_id: that.scf_application_invoice_id})
            .then(function (response){
              if(response.message === 'OK'){
                document.getElementById('btn-inv-decision').classList.add('d-none')
                document.getElementById('inv-submit-score').classList.add('d-none')
                Utils.showToast('Invoice', response.data.invoice, Constants.TOAST.success);
              }else{
                Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
              }
            })
          }
        })
      }

      submitDisbursement(){
        let that = this;
        document.getElementById('btn-disbursement-submit').addEventListener('click', function() {
          let body = {
            invoice_amount: document.getElementById('inv_sap_amount').innerText,
            funding_requested: document.getElementById('inv_sap_funding_requested').innerText,
            disbursement_permissible: document.getElementById('inv_sap_damount').innerText,
            active_disbursement: document.getElementById('inv_sap_activedis').innerText,
            repayment_date: document.getElementById('inv_sap_repay_date').innerText,
            repayment_amount: document.getElementById('inv_sap_repay').innerText,
            scf_lender_applications_id: document.getElementById('inv_scf_lender_app').value, 
            inv_type: 'invoice_discounting',
            scf_application_id: that.scf_application_invoice_id
          }
          Utils.requestHandler("/scf/api/invoice/" + that.invoice_id + '/submit-disbursment', Constants.REQUEST.post, body)
          .then(function (response) {
            if(response.message === 'OK'){
              Utils.showToast(response.message, 'Financing Partner Submitted Successfully', Constants.TOAST.success);
            }else{
              Utils.showToast('Error','Select Financing Partner', Constants.TOAST.fail);
            }
          });
        });
        document.getElementById('btn-disbursement-submit').click();
      }
      
      submitDrawDownRequest(){
        let that = this;
        document.getElementById('send-for-draw-down').addEventListener('click', function(){
          let body = {
            url: SERVICE_BASE_BACKOFFICE + '/v2/draw-down-request',
            scf_application_id: that.scf_application_invoice_id
          }
          Utils.requestHandler('/scf/api/invoice/' + that.invoice_id + '/send-draw-down-request', Constants.REQUEST.post, body)
          .then(function (response){
            if(response.message === 'OK'){
              Utils.showToast('DDR', 'DDR sent successfully', Constants.TOAST.success);
              document.getElementById('send-for-draw-down').classList.add('d-none');
              that.submitDisbursement();
            } else {
              Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
          })
        })
      }

      rejectDrawDownRequest(){
        let that = this;
        document.getElementById('reject-from-ddr').addEventListener('click', function(){
          let body = {
            url: SERVICE_BASE_BACKOFFICE + '/v2/draw-down-request',
            scf_application_id: that.scf_application_invoice_id
          }
          Utils.requestHandler('/scf/api/invoice/' + that.invoice_id + '/send-draw-down-request', Constants.REQUEST.post, body)
          .then(function (response){
            if(response.message === 'OK'){
              Utils.showToast('DDR', 'DDR sent successfully', Constants.TOAST.success);
              document.getElementById('reject-from-ddr').classList.add('d-none');
              that.submitDisbursement();
            } else {
              Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
          })
        })
      }
    }
