import { DocumentUtils, Utils } from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from 'mustache';

export class ScfSalesNewApplicationPage {
  constructor() {
    Utils.pageSanityHandler();
    this.new_applicant_pan_number = null;
    this.new_applicant_pan_name = null;
    this.new_applicant_email = null;
    this.new_applicant_mobile = null;

    this.elem_new_applicant_pan_number = document.getElementById('sales_enter_company_pan');
    this.elem_new_applicant_pan_name= document.getElementById('sales_new_company_pan_name');
    this.elem_new_applicant_email= document.getElementById('sales_new_company_email');
    this.elem_new_applicant_mobile= document.getElementById('sales_new_company_mobile');

    this.elem_new_applicant_pan_number.addEventListener('input', function (){
      Utils.reInput(this);
    })

    this.elem_new_applicant_email.addEventListener('input', function (){
      Utils.reInput(this);
    })
    this.elem_new_applicant_mobile.addEventListener('input', function (){
      Utils.reInput(this);
    })
  }

  setValues(){
    Utils.setter(this.elem_new_applicant_pan_number, this.new_applicant_pan_number);
    Utils.setter(this.elem_new_applicant_pan_name, this.new_applicant_pan_name);
    Utils.setter(this.elem_new_applicant_email, this.new_applicant_email);
    Utils.setter(this.elem_new_applicant_mobile, this.new_applicant_mobile);
  }

  getValues(){
    let that = this;
    this.new_applicant_pan_number = Utils.getter(this.elem_new_applicant_pan_number);
    this.new_applicant_pan_name = Utils.getter(this.elem_new_applicant_pan_name);
    this.new_applicant_email = Utils.getter(this.elem_new_applicant_email);
    this.new_applicant_mobile = Utils.getter(this.elem_new_applicant_mobile);
  }

  validateValuesForPanSubmit(){
    let pass = true;
    if(!this.new_applicant_pan_number){
      Utils.showInputError(this.elem_new_applicant_pan_number, 'Pan is Mandatory');
      pass = false;
    }
    if(!Utils.validatorPan(this.new_applicant_pan_number)){
      Utils.showInputError(this.elem_new_applicant_pan_number, 'Wrong pan format');
      pass = false;
    }
    
    return pass;
  }

  validateValuesForNewUserSubmit(){
    let pass = true;
    if(!this.new_applicant_pan_number){
      Utils.showInputError(this.elem_new_applicant_pan_number, 'Pan is Mandatory');
      pass = false;
    }
    if(!Utils.validatorPan(this.new_applicant_pan_number)){
      Utils.showInputError(this.elem_new_applicant_pan_number, 'Wrong pan format');
      pass = false;
    }

    if(!this.new_applicant_pan_name){
      Utils.showInputError(this.elem_new_applicant_pan_name, 'Pan name is Mandatory');
      pass = false;
    }

    if(!this.new_applicant_email){
      Utils.showInputError(this.elem_new_applicant_email, 'Email is Mandatory');
      pass = false;
    }

    if(!this.new_applicant_mobile){
      Utils.showInputError(this.elem_new_applicant_mobile, 'Mobile is Mandatory');
      pass = false;
    }

    if(!Utils.validatorEmail(this.new_applicant_email)){
      Utils.showInputError(this.elem_new_applicant_email, 'Wrong email format');
      pass = false;
    }

    if(!Utils.validatorMobile(this.new_applicant_mobile)){
      Utils.showInputError(this.elem_new_applicant_mobile, 'Wrong mobile format');
      pass = false;
    }

    
    return pass;
  }



  handler() {
   this.submitPanNumber();
   this.createNewBorrower();
  }

  submitPanNumber(){
    let that = this;
    document.getElementById('btn-submit-sales-new-pan').addEventListener('click', function(){
      that.getValues();
      let panNumber = that.new_applicant_pan_number;
      if(that.validateValuesForPanSubmit()){
        Utils.loaderToast(Constants.ELEMENT_STATE.show);
        Utils.requestHandler('/scf/api/applicant/applicant-from-pan/' + panNumber, Constants.REQUEST.get, {})
          .then(function (response){
            if(response.message === 'OK' && response.data.business_applicant){
              document.getElementById('sales_enter_company_pan').setAttribute('readonly', true);
              document.getElementById('sales_new_company_pan_name').value = response.data.business_applicant.name
              document.getElementById('btn-submit-sales-new-pan').classList.add('no-display')
              document.getElementById('btn-submit-sales-new-application').classList.remove('no-display')
              let newApplicationUrl = "/scf/application/onboarding?applicant_id=" + response.data.business_applicant.id + "&pan_name=" + response.data.business_applicant.pan_name + "&pan=" + response.data.business_applicant.pan
              document.getElementById('btn-submit-sales-new-application').href = newApplicationUrl;
              Utils.showToast('Success', 'Business Applicant: fetched', Constants.TOAST.success);
              Utils.loaderToast(Constants.ELEMENT_STATE.hide);
            }else if (response.message === 'OK' && response.data.full_name) {
              document.getElementById('sales_enter_company_pan').setAttribute('readonly', true);
              document.getElementById('btn-submit-sales-new-pan').classList.add('no-display')
              document.getElementById('btn-submit-sales-new-applicant').classList.remove('no-display')
              document.getElementById('sales_new_company_email_form').classList.remove('no-display')
              document.getElementById('sales_new_company_mobile_form').classList.remove('no-display')
              document.getElementById('sales_new_company_pan_name').value = response.data.full_name
              document.getElementById('sales_new_company_email').removeAttribute('readonly');
              document.getElementById('sales_new_company_mobile').removeAttribute('readonly');   
              Utils.showToast('User Not Found', 'Please Create New Applicant', Constants.TOAST.info);
              Utils.loaderToast(Constants.ELEMENT_STATE.hide);
            } else {
              Utils.loaderToast(Constants.ELEMENT_STATE.hide);
              Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
          })    
      }    
    });
  }

  createNewBorrower(){
    let that = this;
    document.getElementById('btn-submit-sales-new-applicant').addEventListener('click', function(){
      that.getValues();
      if(that.validateValuesForNewUserSubmit()){ 
        Utils.loaderToast(Constants.ELEMENT_STATE.show); 
        let body = {
          pan:  that.new_applicant_pan_number,
          pan_name: that.new_applicant_pan_name, 
          email: that.new_applicant_email,
          mobile: that.new_applicant_mobile
        }
        Utils.requestHandler('/scf/api/applicant/create_without_otp', Constants.REQUEST.post, body)
          .then(function (response){
              if(response.message === 'OK'){
                document.getElementById('sales_new_company_pan_name').value = response.data.business_applicant.name
                document.getElementById('sales_new_company_email').value = response.data.user.email
                document.getElementById('sales_new_company_mobile').value = response.data.user.mobile
                document.getElementById('sales_new_company_email_form').classList.remove('no-display')
                document.getElementById('sales_new_company_mobile_form').classList.remove('no-display')
                document.getElementById('sales_new_company_email').setAttribute('readonly', true);
                document.getElementById('sales_new_company_mobile').setAttribute('readonly', true);
                document.getElementById('btn-submit-sales-new-pan').classList.add('no-display')
                document.getElementById('btn-submit-sales-new-application').classList.remove('no-display')
                document.getElementById('btn-submit-sales-new-applicant').classList.add('no-display')
                let newApplicationUrl = "/scf/application/onboarding?applicant_id=" + response.data.business_applicant.id + "&pan_name=" + response.data.business_applicant.pan_name + "&pan=" + response.data.business_applicant.pan
                document.getElementById('btn-submit-sales-new-application').href = newApplicationUrl;
                Utils.showToast('Success', 'New Business Applicant Created', Constants.TOAST.success);
                Utils.loaderToast(Constants.ELEMENT_STATE.hide);
              }else {
                Utils.loaderToast(Constants.ELEMENT_STATE.hide);
                Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
              }
          })
      }          
    });
  }

}