import { DocumentUtils, Utils } from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from 'mustache';

export class ScfUserNewBorrower {
  constructor() {
    let that = this;
    Utils.pageSanityHandler();
    this.new_applicant_pan_number = null;
    this.new_applicant_pan_name = null;
    this.new_applicant_email = null;
    this.new_applicant_mobile = null;
    this.new_applicant_cin = null;
    this.category_of_cp = null;
    this.type_of_rating = null;
    this.cp_rating_issuer = null;
    this.date_of_rating = null;
    this.cp_rating = null;

    this.elem_new_applicant_pan_number = document.getElementById('new_borrower_enter_company_pan');
    this.elem_new_applicant_pan_name= document.getElementById('new_borrower_new_company_pan_name');
    this.elem_new_applicant_email= document.getElementById('new_borrower_new_company_email');
    this.elem_new_applicant_mobile= document.getElementById('new_borrower_new_company_mobile');
    this.elem_new_applicant_cin = document.getElementById('new_borrower_new_company_cin');
    this.elem_category_of_cp = document.getElementById('category_of_cp');
    this.elem_type_of_rating = document.getElementById('type_of_rating');
    this.elem_cp_rating_issuer = document.getElementById('cp_rating_issuer');
    this.elem_date_of_rating = document.getElementById('date_of_rating');
    this.elem_cp_rating = document.getElementById('cp_rating');

    this.elem_new_applicant_pan_number.addEventListener('input', function (){
      Utils.reInput(this);
    })

    this.elem_new_applicant_email.addEventListener('input', function (){
      Utils.reInput(this);
    })
    this.elem_new_applicant_mobile.addEventListener('input', function (){
      Utils.reInput(this);
    })
    this.elem_new_applicant_cin.addEventListener('input', function (){
      Utils.reInput(this);
    })
    this.elem_category_of_cp.addEventListener('input', function (){
      Utils.reInput(this);
    })
    this.elem_type_of_rating.addEventListener('input', function (){
      Utils.reInput(this);
    })
    this.elem_cp_rating_issuer.addEventListener('input', function (){
      Utils.reInput(this);
    })
    this.elem_date_of_rating.addEventListener('input', function (){
      Utils.reInput(this);
    })
    this.elem_cp_rating.addEventListener('input', function (){
      Utils.reInput(this);
    })

    this.elem_category_of_cp.addEventListener('input', function (){
      Utils.reInput(that.elem_type_of_rating);
      Utils.reInput(that.elem_cp_rating_issuer);
      Utils.reInput(that.elem_date_of_rating);
      Utils.reInput(that.elem_cp_rating);
    })
  }

  setValues(){
    Utils.setter(this.elem_new_applicant_pan_number, this.new_applicant_pan_number);
    Utils.setter(this.elem_new_applicant_pan_name, this.new_applicant_pan_name);
    Utils.setter(this.elem_new_applicant_email, this.new_applicant_email);
    Utils.setter(this.elem_new_applicant_mobile, this.new_applicant_mobile);
    Utils.setter(this.elem_new_applicant_cin, this.new_applicant_cin);

    Utils.setter(this.elem_category_of_cp, this.category_of_cp);
    Utils.setter(this.elem_type_of_rating, this.type_of_rating);
    Utils.setter(this.elem_cp_rating_issuer, this.cp_rating_issuer);
    Utils.setter(this.elem_date_of_rating, this.date_of_rating);
    Utils.setter(this.elem_cp_rating, this.cp_rating);
  }

  getValues(){
    let that = this;
    this.new_applicant_pan_number = Utils.getter(this.elem_new_applicant_pan_number);
    this.new_applicant_pan_name = Utils.getter(this.elem_new_applicant_pan_name);
    this.new_applicant_email = Utils.getter(this.elem_new_applicant_email);
    this.new_applicant_mobile = Utils.getter(this.elem_new_applicant_mobile);
    this.new_applicant_cin = Utils.getter(this.elem_new_applicant_cin);

    this.category_of_cp = Utils.getter(this.elem_category_of_cp);
    this.type_of_rating = Utils.getter(this.elem_type_of_rating);
    this.cp_rating_issuer =  Utils.getter(this.elem_cp_rating_issuer);
    this.date_of_rating = Utils.getter(this.elem_date_of_rating);
    this.cp_rating = Utils.getter(this.elem_cp_rating);
  }

  validateValuesForPanSubmit(){
    let pass = true;
    if(!this.new_applicant_pan_number){
      Utils.showInputError(this.elem_new_applicant_pan_number, 'Pan is Mandatory');
      pass = false;
    }
    if(!Utils.validatorPan(this.new_applicant_pan_number)){
      Utils.showInputError(this.elem_new_applicant_pan_number, 'Wrong pan format');
      pass = false;
    }
    
    return pass;
  }

  validateValuesForNewUserSubmit(){
    let pass = true;
    if(!this.new_applicant_pan_number){
      Utils.showInputError(this.elem_new_applicant_pan_number, 'Pan is Mandatory');
      pass = false;
    }
    if(!Utils.validatorPan(this.new_applicant_pan_number)){
      Utils.showInputError(this.elem_new_applicant_pan_number, 'Wrong pan format');
      pass = false;
    }

    if(!this.new_applicant_pan_name){
      Utils.showInputError(this.elem_new_applicant_pan_name, 'Pan name is Mandatory');
      pass = false;
    }

    if(!this.new_applicant_email){
      Utils.showInputError(this.elem_new_applicant_email, 'Email is Mandatory');
      pass = false;
    }

    if(!this.category_of_cp){
      Utils.showInputError(this.elem_category_of_cp, 'Category is Mandatory');
      pass = false;
    }

    if(this.category_of_cp == 'rated_corporate'){
      if(!this.type_of_rating){
        Utils.showInputError(this.elem_type_of_rating, 'Type is Mandatory');
        pass = false;
      }

      if(!this.cp_rating_issuer){
        Utils.showInputError(this.elem_cp_rating_issuer, 'Issuer is Mandatory');
        pass = false;
      }

      if(!this.date_of_rating){
        Utils.showInputError(this.elem_date_of_rating, 'Date is Mandatory');
        pass = false;
      }

      if(!this.cp_rating){
        Utils.showInputError(this.elem_cp_rating, 'Rating is Mandatory');
        pass = false;
      }
    }

    if(!this.new_applicant_mobile){
      Utils.showInputError(this.elem_new_applicant_mobile, 'Mobile is Mandatory');
      pass = false;
    }

    if( this.new_applicant_pan_number[3] == 'C' && !this.new_applicant_cin){
      Utils.showInputError(this.elem_new_applicant_cin, 'CIN is Mandatory');
      pass = false;
    }

    if(!Utils.validatorEmail(this.new_applicant_email)){
      Utils.showInputError(this.elem_new_applicant_email, 'Wrong email format');
      pass = false;
    }

    if(!Utils.validatorMobile(this.new_applicant_mobile)){
      Utils.showInputError(this.elem_new_applicant_mobile, 'Wrong mobile format');
      pass = false;
    }

    
    return pass;
  }



  handler() {
   this.submitPanNumber();
   this.createNewBorrower();
  }

  submitPanNumber(){
    let that = this;
    document.getElementById('btn-submit-sales-new-pan').addEventListener('click', function(){
      that.getValues();
      let panNumber = that.new_applicant_pan_number;
      if(that.validateValuesForPanSubmit()){
        Utils.loaderToast(Constants.ELEMENT_STATE.show);
        Utils.requestHandler('/scf/api/applicant/applicant-from-pan/' + panNumber, Constants.REQUEST.get, {})
          .then(function (response){
            if(response.message === 'OK' && response.data.business_applicant){
              document.getElementById('new_borrower_enter_company_pan').setAttribute('readonly', true);
              document.getElementById('new_borrower_new_company_pan_name').value = response.data.business_applicant.name
              document.getElementById('btn-submit-sales-new-pan').classList.add('no-display');
              document.getElementById('business_applicant_id').value = response.data.business_applicant.id
              if (response.data.anchor_applicant){
                document.getElementById('anchor_applicant_id').value = response.data.anchor_applicant.id
                document.getElementById('anchor_pd_section').classList.remove('no-display');
              }  
              new ScfBankingModule();
              new ScfPromoterModule();
              new AnchorPdModule();
              document.getElementById('new_borrower_banking_details').classList.remove('no-display');
              document.getElementById('new_borrower_promoter_details').classList.remove('no-display');
              Utils.showToast('Success', 'Business Applicant: fetched', Constants.TOAST.success);
              Utils.loaderToast(Constants.ELEMENT_STATE.hide);
            }else if (response.message === 'OK' && response.data.full_name) {
              document.getElementById('new_borrower_enter_company_pan').setAttribute('readonly', true);
              document.getElementById('btn-submit-sales-new-pan').classList.add('no-display')
              document.getElementById('btn-submit-sales-new-applicant').classList.remove('no-display')
              document.getElementById('new_borrower_new_company_email_form').classList.remove('no-display')
              document.getElementById('new_borrower_new_company_mobile_form').classList.remove('no-display')
              document.getElementById('new_borrower_new_company_cin_form').classList.remove('no-display')
              document.getElementById('new_borrower_new_company_pan_name').value = response.data.full_name
              document.getElementById('new_borrower_new_company_email').removeAttribute('readonly');
              document.getElementById('new_borrower_new_company_mobile').removeAttribute('readonly');   

              that.elem_category_of_cp.removeAttribute('disabled');   
              that.elem_type_of_rating.removeAttribute('disabled');  
              that.elem_cp_rating_issuer.removeAttribute('disabled');  
              that.elem_date_of_rating.removeAttribute('readonly');  
              that.elem_cp_rating.removeAttribute('disabled');  

              document.getElementById('new_borrower_new_company_cin').removeAttribute('readonly');   
              document.getElementById('category_of_anchor_section').classList.remove('no-display');
              document.getElementById('borrower-rating-section').classList.remove('no-display');
              Utils.showToast('User Not Found', 'Please Create New Applicant', Constants.TOAST.info);
              Utils.loaderToast(Constants.ELEMENT_STATE.hide);
            } else {
              Utils.loaderToast(Constants.ELEMENT_STATE.hide);
            }
          })    
      }    
    });
  }

  createNewBorrower(){
    let that = this;
    document.getElementById('btn-submit-sales-new-applicant').addEventListener('click', function(){
      that.getValues();
      if(that.validateValuesForNewUserSubmit()){ 
        Utils.loaderToast(Constants.ELEMENT_STATE.show); 
        let credit_rating = {
          type_of_rating: that.type_of_rating,
          cp_rating_issuer: that.cp_rating_issuer,
          date_of_rating: that.date_of_rating,
          rating: that.cp_rating
        }
        let body = {
          pan:  that.new_applicant_pan_number,
          pan_name: that.new_applicant_pan_name, 
          email: that.new_applicant_email,
          mobile: that.new_applicant_mobile,
          is_anchor: true,
          cin: that.new_applicant_cin,
          credit_rating: credit_rating,
          category: that.category_of_cp
        }
        Utils.requestHandler('/scf/api/applicant/create_without_otp', Constants.REQUEST.post, body)
          .then(function (response){
              if(response.message === 'OK'){
                document.getElementById('new_borrower_new_company_pan_name').value = response.data.business_applicant.name
                document.getElementById('new_borrower_new_company_email').value = response.data.user.email
                document.getElementById('new_borrower_new_company_mobile').value = response.data.user.mobile
                document.getElementById('new_borrower_new_company_cin').value = response.data.business_applicant.corporate_id
                document.getElementById('new_borrower_new_company_email_form').classList.remove('no-display')
                document.getElementById('new_borrower_new_company_mobile_form').classList.remove('no-display')
                document.getElementById('new_borrower_new_company_cin_form').classList.remove('no-display')
                document.getElementById('new_borrower_new_company_email').setAttribute('readonly', true);
                document.getElementById('new_borrower_new_company_mobile').setAttribute('readonly', true);
                document.getElementById('new_borrower_new_company_cin').setAttribute('readonly', true);
                document.getElementById('btn-submit-sales-new-pan').classList.add('no-display')
                document.getElementById('btn-submit-sales-new-applicant').classList.add('no-display')
                document.getElementById('business_applicant_id').value = response.data.business_applicant.id
                document.getElementById('anchor_applicant_id').value = response.data.anchor_applicant.id
                that.elem_category_of_cp.setAttribute('disabled', true);
                that.elem_type_of_rating.setAttribute('disabled', true);
                that.elem_cp_rating_issuer.setAttribute('disabled', true);
                that.elem_date_of_rating.setAttribute('readonly', true);
                that.elem_cp_rating.setAttribute('disabled', true);
                new ScfBankingModule();
                new ScfPromoterModule();
                new AnchorPdModule();
                document.getElementById('anchor_pd_section').classList.remove('no-display');
                document.getElementById('new_borrower_banking_details').classList.remove('no-display');
                document.getElementById('new_borrower_promoter_details').classList.remove('no-display');
                Utils.showToast('Success', 'New Business Applicant Created', Constants.TOAST.success);
                Utils.loaderToast(Constants.ELEMENT_STATE.hide);
              }else {
                Utils.loaderToast(Constants.ELEMENT_STATE.hide);
                Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
              }
          })
      }          
    });
  }

}

class AnchorPdModule {
  constructor(){
    let that = this;
    this.business_applicant_id = document.getElementById('business_applicant_id').value;
    that.showPdModal();
    that.getQuestions();
    that.submitQuestions();
  }

  showPdModal(){
    let that = this;
    document.getElementById('anchor_modal_pd_button').addEventListener('click', function () {
      var modal = new bootstrap.Modal(document.getElementById('anchorPDModal'), {});
      modal.show();
    });
  }

  getQuestions(){
    let anchor_id = document.getElementById('anchor_applicant_id').value
    let that = this;
    Utils.requestHandler("/anchor/api/applicant/" + anchor_id + '/pd-questions', Constants.REQUEST.get, {})
        .then(function (response){
            if(response.message === 'OK'){
                let all_quest =  document.getElementById('all_questions');
                all_quest.innerHTML = null;
                let questions = {};
                response.data.forEach(function (question){
                    if(questions[question.section]){
                        questions[question.section].push(question)
                    }else{
                        questions[question.section] = [question]
                    }
                })
                let tmpl = document.getElementById('question-tmpl')
                let section = 'PD';
                all_quest.append($.parseHTML(Mustache.render(document.getElementById('question-header-tmpl').innerHTML, {name: section.split("_").join(" ")}))[1])
                questions[section].forEach(function (question){
                    switch(question.type){
                        case 'text':
                            question["text"] = true;
                            break;
                        case 'select':
                            question["select"] = true;
                            break;
                        case 'date':
                            question["date"] = true;
                            break;
                        case 'textbox':
                            question["textbox"] = true;
                            break;
                        case 'upload':
                            question["upload"] = true;
                            break;
                        case 'number':
                            question["number"] = true;
                            break;
                    }
                    let q_data = Mustache.render(tmpl.innerHTML, question);
                    all_quest.append($.parseHTML(q_data)[1]);
                })
                section = 'Audit Trails';
                all_quest.append($.parseHTML(Mustache.render(document.getElementById('question-header-tmpl').innerHTML, {name: section.split("_").join(" ")}))[1])
                questions[section].forEach(function (question){
                    switch(question.type){
                        case 'text':
                            question["text"] = true;
                            break;
                        case 'select':
                            question["select"] = true;
                            break;
                        case 'date':
                            question["date"] = true;
                            break;
                        case 'textbox':
                            question["textbox"] = true;
                            break;
                        case 'upload':
                            question["upload"] = true;
                            break;
                        case 'number':
                            question["number"] = true;
                            break;
                    }
                    let q_data = Mustache.render(tmpl.innerHTML, question);
                    all_quest.append($.parseHTML(q_data)[1]);
                })

                that.fetchDropDownValuesIndustry();
                that.fetchDropDownLevel2();
                
                let maxDate = new Date().toISOString().split('T')[0];
                // document.getElementById('date_of_visit').setAttribute('max', maxDate);
            }else{
                Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
        })
  }

  submitQuestions() {
    let that = this;
    let anchor_id = document.getElementById('anchor_applicant_id').value
    document
      .getElementById("btn-quest-submit")
      .addEventListener("click", function () {
        that.validateValues();
        if (that.pass) {
          let values = that.getValues();
          Utils.requestHandler(
            "/anchor/api/applicant/" + anchor_id + "/pd-questions",
            Constants.REQUEST.post,
            values
          ).then(function (response) {
            if (response.message === "OK") {
              Utils.showToast(
                response.message,
                "Responses submitted",
                Constants.TOAST.success
              );
              // that.clearForm();
            } else {
              Utils.showToast(
                response.message,
                Utils.formatErrorMessage(response.errors),
                Constants.TOAST.fail
              );
            }
          });
        } else {
          Utils.showToast(
            "Error",
            "Please Enter Required field",
            Constants.TOAST.fail
          );
        }
      });
  }

  getValues(){
    let data = {};
    document.querySelectorAll('.questions').forEach(question => {
        let id = question.getAttribute('data-label');
        data[id] = question.value;
    })
    return data;
  }

  validateValues(){
    let that = this;
    that.pass = true;
    document.querySelectorAll(".questions").forEach((question) => {
      let mandatory = question.getAttribute("data-mandatory");
      let label = question.getAttribute("data-label");
      let label_title =  Utils.snakeToTitleCase(label);
      Utils.reInput(question);
      function toggleErrorMessage() {
        let value = question.value;
        if (mandatory === "true" && !value) {
          Utils.showInputError(question, label_title + " is mandatory");
          that.pass = false;
        } else {
          Utils.hideInputError(question);
        }
        if (label.endsWith("mobile_no") && value) {
          if (!/^\d{10}$/.test(value)) {
              Utils.showInputError(question, "Mobile number must be 10 digits");
              that.pass = false;
          } else {
              Utils.hideInputError(question);
          }
        }
      }
      toggleErrorMessage();
      question.addEventListener("input", toggleErrorMessage);
    });
    document
      .querySelectorAll('[data-code="photo_of_shop"]')
      .forEach((element) => {
        if (element.files.length === 0) {
          Utils.showToast(
            "Error",
            "Photo of the shop is mandatory",
            Constants.TOAST.fail
          );
          that.pass = false;
        }
      });
}

  uploadDocuments(){
    let that = this;
    document.querySelectorAll('.pd-upload').forEach(element => {
        element.addEventListener('click', function(){
            let elem_file = element.parentElement.parentElement.firstElementChild;
            let file = elem_file.files[0];
            if (!file){
                Utils.showToast("Not Found", "File is mandatory", Constants.TOAST.fail);
                return;
            }
            let data = new FormData();
            let file_code = elem_file.getAttribute('data-code');
            data.set('file_data', file);
            data.set('file_name', file.name);
            data.set('file_password', null);
            Utils.requestHandler(
                "/scf/api/application/" +
                    that.scf_application_id.value +
                  "/document/upload/" +
                  file_code,
                Constants.REQUEST.post,
                data
              ).then(function (response){
                    if(response.message === 'OK'){
                        Utils.showToast(response.message, 'Document uploaded', Constants.TOAST.success);
                    }else{
                        Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                    }
                })
        })
    })
  }

  fetchDropDownValuesIndustry() {
    let that = this;
    document
      .getElementById("business_segment")
      .addEventListener("change", function () {
        document.getElementById("industry_classification").innerHTML = "";
        document.getElementById("industry_classification_2").innerHTML = "";
        let segment = "";
        if (
          document.getElementById("business_segment").value === "Manufacturing / Trader"
        ) {
          segment = "HSN";
        } else if (
          document.getElementById("business_segment").value === "Services"
        ) {
          segment = "SAC";
        }
        let body = {
          level: 1,
          segment: segment,
        };
        Utils.requestHandler(
          "/scf/api/application/pd-questions/industry-codes",
          Constants.REQUEST.get,
          body
        ).then(function (response) {
          if (response.message === "OK") {
            var dropdown = document.getElementById("industry_classification");
            document.getElementById("industry_classification").innerHTML = "";
            var option = document.createElement("option");
            option.value = "";
            option.text = "";
            dropdown.appendChild(option);
            var values = response.data.data;
            for (var i = 0; i < values.length; i++) {
              var option = document.createElement("option");
              option.value = values[i].code;
              option.text = values[i].name;
              dropdown.appendChild(option);
            }
          } else {
            Utils.showToast(
              response.message,
              Utils.formatErrorMessage(response.errors),
              Constants.TOAST.fail
            );
          }
        });
      });
  }

  fetchDropDownLevel2() {
    let that = this;
    document
      .getElementById("industry_classification")
      .addEventListener("change", function () {
        let segment = "";
        if (
          document.getElementById("business_segment").value === "Manufacturing / Trader"
        ) {
          segment = "HSN";
        } else if (
          document.getElementById("business_segment").value === "Services"
        ) {
          segment = "SAC";
        }
        let body = {
          level: 2,
          segment: segment,
          parent_code: document.getElementById("industry_classification").value,
        };
        Utils.requestHandler(
          "/scf/api/application/pd-questions/industry-codes",
          Constants.REQUEST.get,
          body
        ).then(function (response) {
          if (response.message === "OK") {
            var dropdown = document.getElementById("industry_classification_2");
            document.getElementById("industry_classification_2").innerHTML = "";
            var option = document.createElement("option");
            option.value = "";
            option.text = "";
            dropdown.appendChild(option);
            var values = response.data.data;
            for (var i = 0; i < values.length; i++) {
              var option = document.createElement("option");
              option.value = values[i].code;
              option.text = values[i].name;
              dropdown.appendChild(option);
            }
          } else {
            Utils.showToast(
              response.message,
              Utils.formatErrorMessage(response.errors),
              Constants.TOAST.fail
            );
          }
        });
      });
  }
}


export class ScfBankingModule{

  constructor(){
    this.obj_banking = [];
    let that = this;
    this.business_applicant_id = document.getElementById('business_applicant_id').value;
    that.BankingPanelHandler();
  }

  BankingPanelHandler(){
    let that = this;
    that.editBankAccountsPanel();
    that.showBankingModal();
    that.bankingAddBankAccount();
    that.bankingValidateBankAccount();
    that.bankGetIfscDetails();
    that.bankAddBankAccount();
  }

  editBankAccountsPanel(){
    let that = this;
    Utils.requestHandler("/scf/api/applicant/" + that.business_applicant_id + '/bank-accounts', Constants.REQUEST.get, {})
      .then(function (response){
        if(response.message === 'OK'){
            response.data.bank_accounts.forEach(bank_account => {
                let abs_banking = new AbstractBanking(bank_account.id);
                abs_banking.account_number = bank_account.account_number;
                abs_banking.account_type = bank_account.account_type;
                abs_banking.account_holder = bank_account.primary_account_holder_name;
                abs_banking.verified_account_holder = bank_account.primary_account_holder_name;
                abs_banking.ifsc_code = bank_account.ifsc_code;
                abs_banking.bank_branch = bank_account.branch;
                abs_banking.bank_name = bank_account.bank_name;
                abs_banking.bank_micr = bank_account.micr_code;
                that.bankingSetupButtonIds(abs_banking.id);
                that.obj_banking.push(abs_banking);
            })
            that.bankRenderVerifiedAccounts();
            //   Utils.showToast('Fetched', 'Bank Accounts', Constants.TOAST.success);
          }else{
            //   Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
          }
      })
  }

  showBankingModal(){
    let that = this;
    document.getElementById('btn_show_add_new_bank_account').addEventListener('click', function () {
      var modal = new bootstrap.Modal(document.getElementById('bankingList'), {});
      modal.show();
      document.getElementById('account_number').readOnly = false;
      document.getElementById('ifsc_code').readOnly = false;
      document.getElementById('add_bank_ac').classList.remove('no-display');
      document.getElementById('add_bank_ac_pane').classList.add('no-display');
    });
  }

  bankingAddBankAccount(){
    let that = this;
    document.getElementById('add_bank_ac').addEventListener('click', function (){
        let abs_banking = new AbstractBanking(Math.random().toString(36));
        abs_banking.setValues();
        that.bankingSetupButtonIds(abs_banking.id);
        document.querySelectorAll('.btn-banking').forEach(element => {
            element.classList.add('no-display');
        });
        document.getElementById('btn-validate-ifsc').classList.remove('no-display');
        document.getElementById('add_bank_ac_pane').classList.remove('no-display');

        that.obj_banking.push(abs_banking);
        this.classList.add('no-display');
    })
  }

  bankingSetupButtonIds(btn_id){
      let bt1 = document.getElementById('btn-validate-account');
      bt1.setAttribute('data-id', btn_id);
      bt1.classList.add('no-display');
      let bt2 = document.getElementById('btn-submit-account');
      bt2.setAttribute('data-id', btn_id);
      bt1.classList.add('no-display');
      let bt3 = document.getElementById('btn-validate-ifsc')
      bt3.setAttribute('data-id', btn_id);
      bt3.classList.remove('no-display');
  }

  bankingValidateBankAccount(){
    let that = this;
    document.getElementById('btn-validate-account').addEventListener('click', function(){
      let abs_banking = null;
      let elm_btn = this;
      let active_id = this.getAttribute('data-id');
      if(!active_id){
          Utils.showToast("Search", "Error finding bank account", Constants.TOAST.fail);
      }
      that.obj_banking.forEach(function (abs_bank){
          if(abs_bank.id === active_id){
              abs_banking = abs_bank;
              return false;
          }
      })
      abs_banking.getValues();
      if(abs_banking.validateValues()){
        let body = {
          bank_ac_no: abs_banking.account_number,
          bank_ifsc: abs_banking.ifsc_code
        }
        Utils.loaderToast(Constants.ELEMENT_STATE.show);
        Utils.requestHandler("/scf/api/onboarding/company/" + that.business_applicant_id + "/validate/bank-account", Constants.REQUEST.post, body)
          .then(function (response){
            if(response.message === 'OK'){
                if(response.data.status === 'active'){
                    abs_banking.verified_account_holder = response.data.bank_ac_holder_name;
                    abs_banking.setValues();
                    document.querySelectorAll('.btn-banking').forEach(element => {
                        element.classList.add('no-display');
                    });
                    document.getElementById('account_number').readOnly = true;
                    document.getElementById('ifsc_code').readOnly = true;
                    document.getElementById('btn-submit-account').classList.remove('no-display');
                    Utils.showToast(response.message, 'Bank account validated', Constants.TOAST.success);
                }else{
                    abs_banking.verified_account_holder = abs_banking.account_holder;
                    abs_banking.setValues();
                  //   elm_btn.classList.add('no-display');
                  //   document.getElementById('btn-submit-account').classList.remove('no-display');
                    Utils.showToast("Inactive", "Bank account is inactive", Constants.TOAST.fail);
                }
            } else{
              Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
          }).then(function (){
            Utils.loaderToast(elm_btn, Constants.ELEMENT_STATE.hide);
          })
}            
    })
  }

  bankGetIfscDetails(){
    let that = this;
    document.getElementById('btn-validate-ifsc').addEventListener('click', function (){
      let abs_banking = null;
      let btn_elem = this;
      let active_id = this.getAttribute('data-id');
      if(!active_id){
          Utils.showToast("Search", "Error finding bank account", Constants.TOAST.fail);
      }
      that.obj_banking.forEach(function (abs_bank){
          if(abs_bank.id === active_id){
              abs_banking = abs_bank;
              return false;
          }
      })
      abs_banking.getValues();
      Utils.loaderToast(Constants.ELEMENT_STATE.show);
      Utils.requestHandler("/scf/api/onboarding/company/validate/bank-ifsc", Constants.REQUEST.get, {bank_ifsc: abs_banking.ifsc_code})
        .then(function (response){
            if(response.message === 'OK'){
                abs_banking.ifsc_code = response.data.ifsc_data.ifsc;
                abs_banking.bank_micr = response.data.ifsc_data.micr;
                abs_banking.bank_name = response.data.ifsc_data.bank_name;
                abs_banking.bank_branch = response.data.ifsc_data.branch + ", " + response.data.ifsc_data.city + ", " + response.data.ifsc_data.state;
                Utils.setter(abs_banking.elem_ifsc_code, abs_banking.ifsc_code);
                Utils.setter(abs_banking.elem_bank_micr, abs_banking.bank_micr);
                Utils.setter(abs_banking.elem_bank_name, abs_banking.bank_name);
                Utils.setter(abs_banking.elem_bank_branch, abs_banking.bank_branch);
                Utils.setter(abs_banking.elem_bank_branch_full, abs_banking.bank_branch);
                document.querySelectorAll('.btn-banking').forEach(element => {
                    element.classList.add('no-display');
                });
                document.getElementById('btn-validate-account').classList.remove('no-display');                        
                Utils.showToast(response.message, 'IFSC Details fetched', Constants.TOAST.success);
            }else{
                Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
        }).then(function (){
            Utils.loaderToast(Constants.ELEMENT_STATE.hide);
      })
    })
  }

  bankAddBankAccount(){
      let that = this;
      document.getElementById('btn-submit-account').addEventListener('click', function (){
          let abs_banking = null;
          let active_id = this.getAttribute('data-id');
          if(!active_id){
              Utils.showToast("Search", "Error finding bank account", Constants.TOAST.fail);
          }
          that.obj_banking.forEach(function (abs_bank){
              if(abs_bank.id === active_id){
                  abs_banking = abs_bank;
                  return false;
              }
          })
          let body = {
              "bank_ifsc": abs_banking.ifsc_code,
              "bank_name": abs_banking.bank_name,
              "holder_name": abs_banking.verified_account_holder,
              "bank_branch": abs_banking.bank_branch,
              "bank_ac_type": abs_banking.account_type,
              "bank_ac_no": abs_banking.account_number
          }

          Utils.requestHandler("/scf/api/onboarding/company/bank-account/" + that.business_applicant_id, Constants.REQUEST.post, body)
              .then(function (response){
                  if(response.message === 'OK'){
                    new ScfBankingModule();
                    document.getElementById('add_bank_ac_pane').classList.add('no-display');
                    document.getElementById('add_bank_ac').classList.remove('no-display');
                    let closeModalButton = document.getElementById("btn-banking-modal-close"); 
                    closeModalButton.click();
                    Utils.showToast(response.message, 'Bank Details added', Constants.TOAST.success);
                  }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                  }
              })
      })
  }

  bankRenderVerifiedAccounts(){
      let that = this;
      let temp = document.getElementById('banking-tr-tmpl-business-details');
      let ac_table = document.getElementById('verified_accounts_business_details');
      ac_table.innerHTML = null;
      this.obj_banking.forEach(function (abs_banking){
          if(abs_banking.account_holder != null && abs_banking.account_number != null){
              let rend_row = Mustache.render(temp.innerHTML, abs_banking);
              ac_table.append($.parseHTML(rend_row)[1]);
              that.bankEditButton(abs_banking.id);    
          }
      })
    
  }

  bankEditButton(active_id){
      let that = this;
      document.getElementById('btn-edit-account-'+active_id).addEventListener('click', function () {
        var modal = new bootstrap.Modal(document.getElementById('bankingList'), {});
        modal.show();
          document.querySelectorAll('.btn-banking').forEach(element => {
              element.classList.add('no-display');
          });
          document.getElementById('btn-validate-ifsc').classList.remove('no-display');
          let abs_banking = null;
          if(!active_id){
              Utils.showToast("Search", "Error finding bank account", Constants.TOAST.fail);
          }
          that.obj_banking.forEach(function (abs_bank){
              if(abs_bank.id === active_id){
                  abs_banking = abs_bank;
                  return false;
              }
          })
          abs_banking.elem_account_number.disabled = false;
          abs_banking.elem_account_holder.disabled = false;
          abs_banking.elem_account_type.disabled = false;
          abs_banking.elem_ifsc_code.disabled = false; 
          that.bankingSetupButtonIds(active_id);
          abs_banking.setValues();
          document.getElementById('add_bank_ac_pane').classList.remove('no-display');
          document.getElementById('add_bank_ac').classList.add('no-display');
      })
  }

}

export class ScfPromoterModule{

  constructor(){
    this.obj_promoter = [];
    let that = this;
    this.business_applicant_id = document.getElementById('business_applicant_id').value;
    that.pomoterPanelHandler();
  }

  pomoterPanelHandler(){
    let that = this;
    that.getAllPromoters();
    that.showNewPromoterModal();
    that.populateTitleFromPanType();
    that.populateTitleRelationShipwith();
    that.populateRelationship();
    that.promoterDataSubmit();
    that.populateCityStateFromPincode();
    that.populateNameFromPan();
  }

  promoterDataSubmit(){
    let promoterSave = document.getElementById("promoter-save");
    let that = this;

    promoterSave.addEventListener('click', function () {

      let titleOfBorrower = document.getElementById("titleOfBorrower").value;
      let promoterPan = document.getElementById("promoterPan").value;
      let promoterName = document.getElementById("promoterName").value;
      let promoterFirstName = document.getElementById("promoterFirstName").value;
      let promoterLastName = document.getElementById("promoterLastName").value;
      let promoterBirthDate = document.getElementById("promoterBirthDate").value;
      let promoterGender = document.getElementById("promoterGender").value;
      let promoterEmail = document.getElementById("promoterEmail").value;
      let promoterMobile = document.getElementById("promoterMobile").value;
      let promoterAddress = document.getElementById("promoterAddress").value;
      let promoterPincode = document.getElementById("promoterPincode").value;
      let promoterCity = document.getElementById("promoterCity").value;
      let promoterState = document.getElementById("promoterState").value;
      let promoterYearsAtCurrentAddress = document.getElementById("promoterYearsAtCurrentAddress").value;
      let promotorShareHodling = document.getElementById("promotershareholding").value;
      let relationship = document.getElementById('titleRelationShipwith').value;

      let body = {
        ownership_type: titleOfBorrower,
        pan_number: promoterPan,
        first_name: promoterFirstName,
        last_name: promoterLastName,
        full_name: promoterName,
        date_of_birth: promoterBirthDate,
        gender: promoterGender,
        email: promoterEmail,
        mobile: promoterMobile,
        address: promoterAddress,
        zipcode: promoterPincode,
        city: promoterCity,
        state: promoterState,
        years_at_current_address: promoterYearsAtCurrentAddress,
        share_holding: promotorShareHodling,
        relationship: relationship
      };

      Utils.requestHandler('/scf/api/onboarding/company/director/' + that.business_applicant_id, Constants.REQUEST.post, body)
      .then(function (response){
          if(response.message === 'OK'){
            document.getElementById("titleOfBorrower").value = "";
            document.getElementById("promoterName").removeAttribute("readonly");
            document.getElementById("promoterName").value = "";
            document.getElementById("promoterName").setAttribute("readonly", "true");
            document.getElementById("promoterPan").value = "";
            document.getElementById("promoterFirstName").value = "";
            document.getElementById("promoterLastName").value = "";
            document.getElementById("promoterBirthDate").value = "";
            document.getElementById("promoterGender").value = "";
            document.getElementById("promoterEmail").value = "";
            document.getElementById("promoterMobile").value = "";
            document.getElementById("promoterAddress").value = "";
            document.getElementById("promoterPincode").value = "";
            document.getElementById("promoterCity").value = "";
            document.getElementById("promoterState").value = "";
            document.getElementById("promoterYearsAtCurrentAddress").value = "";
            document.getElementById('titleRelationShipwith').value = "";
            document.getElementById('promotershareholding').value = "";
            that.obj_promoter = [];
            that.getAllPromoters();
            Utils.showToast('Added', 'Business Promoter Added', Constants.TOAST.success);
            let closeModalButton = document.getElementById("btn-promoter-modal-close"); 
            closeModalButton.click();
          }else{
              Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
          }
      })
    });

  }


  populateCityStateFromPincode(){
    const promoterPincode = document.getElementById("promoterPincode");
    const promoterCity = document.getElementById("promoterCity");
    const promoterState = document.getElementById("promoterState");

    promoterPincode.addEventListener('input', function () {
        const promoterPincodeValue = promoterPincode.value;
        
        if (promoterPincodeValue.length === 6) {
            Utils.getZipcodeDetails(promoterPincodeValue).then(function (response){
                if(response.message === 'OK'){
                    promoterCity.value = response.data.set_value.district
                    promoterState.value = response.data.set_value.state_name
                } else {
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            });

        } else {
            promoterCity.value = "";
            promoterState.value = "";
        }
    });
  }

  populateNameFromPan(){
      const promoterPan = document.getElementById("promoterPan");
      const promoterName = document.getElementById("promoterName");
      const promoterFirstName = document.getElementById("promoterFirstName");
      const promoterLastName = document.getElementById("promoterLastName");
      promoterPan.addEventListener('input', function () {
          const inputValue = promoterPan.value;
          
          if (inputValue.length === 10) {
              Utils.requestHandler("/scf/api/onboarding/name/" + inputValue, Constants.REQUEST.get, {})
              .then(function (response){
                  if(response.message === 'OK'){
                      promoterName.value = response.data.full_name
                      promoterFirstName.value = response.data.first_name
                      promoterLastName.value = response.data.last_name
                  }else{
                      Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                      
                  }
              })


          } else {
              promoterName.value = "";
              promoterFirstName.value = "";
              promoterLastName.value = "";
          }
      });

  }

  populateTitleRelationShipwith() {
    const titleRelationShipwith = document.getElementById("titleRelationShipwith");
    const relationships = ["","Father", "Mother", "Son", "Daughter", "Sister", "Brother", "Husband", "Wife", "Others"];
    
    titleRelationShipwith.innerHTML = ""; 

    relationships.forEach((relationship) => {
        const option = document.createElement("option");
        option.text = relationship;
        option.value = relationship.toLowerCase().replace(' ', '_');

        titleRelationShipwith.add(option);
    });
  }

  populateRelationship(){
    document.getElementById('titleOfBorrower').addEventListener('change', function(){
        let title_of_borrower = document.getElementById('titleOfBorrower').value;
        if(title_of_borrower==='CO APPLICANT'){
            document.getElementById('relationshipHide').classList.remove("no-display");
            document.getElementById('labelPromoterPan').classList.add("mt-4");
        }
        else{
            document.getElementById('relationshipHide').classList.add("no-display");
            document.getElementById('labelPromoterPan').classList.remove("mt-4");
        }
    })
  }

  populateTitleFromPanType() {
    Utils.requestHandler("/scf/api/applicant/" + this.business_applicant_id, Constants.REQUEST.get, {}).then(function (response) {
        const constitution = response.data.scf_applicant['constitution'];
        const titleOfBorrower = document.getElementById("titleOfBorrower");        const titleOptionsMap = {
          "partnership": ["Partner", "Authorized Signatory","Co applicant"],
          "llp": ["Partner", "Authorized Signatory","Co applicant"],
          "private_limited": ["Director","Authorized Signatory","Co applicant"],
          "public_company": ["Director", "Authorized Signatory","Co applicant"],
          "sole_proprietorship": ["Proprietor","Co applicant"]
        };
        titleOfBorrower.innerHTML = "";
        if (constitution in titleOptionsMap) {
            titleOptionsMap[constitution].forEach((optionText) => {
                const option = document.createElement("option");
                option.text = optionText;
                switch(optionText) {
                    case "Director":
                        option.value = "DIRECTOR";
                        break;
                    case "Co applicant":
                        option.value = "CO APPLICANT";
                        break;
                    case "Proprietor":
                        option.value = "PROPRIETOR";
                        break;
                    case "Partner":
                        option.value = "PARTNER";
                        break;
                    case "Authorized Signatory":
                        option.value = "AUTHORIZED_SIGNATORY";
                        break;
                    default:
                        option.value = "";
                        break;
                }
                titleOfBorrower.add(option);
            });
        }
    });
  }


  showNewPromoterModal(){
    let pan = document.getElementById('new_borrower_enter_company_pan').value;
    if (pan[3] == 'C') {
      document.getElementById('btn_show_add_new_promoter').classList.add('no-display')
    }
    document.getElementById('btn_show_add_new_promoter').addEventListener('click', function (){
      let abs_promoter = new AbstractPromoter(Math.random().toString(36));
      abs_promoter.setValues();
    })
  }

  getAllPromoters(){
    let that = this;
    Utils.requestHandler("/scf/api/onboarding/company/directors/" + that.business_applicant_id , Constants.REQUEST.get, {})
        .then(function (response){
            if(response.message === 'OK'){
              response.data.forEach(promoter => {
                  let abs_promoter = new AbstractPromoter(promoter.id);
                  abs_promoter.title = promoter.ownership_type;
                  abs_promoter.pan = promoter.pan_number;
                  abs_promoter.din=promoter.din_number;
                  abs_promoter.full_name = promoter.first_name + " " + promoter.last_name;
                  abs_promoter.first_name = promoter.first_name;
                  abs_promoter.last_name = promoter.last_name;
                  abs_promoter.dob = promoter.date_of_birth;
                  abs_promoter.gender = promoter.gender;
                  abs_promoter.email = promoter.email;
                  abs_promoter.mobile = promoter.mobile;
                  abs_promoter.address = promoter.address;
                  abs_promoter.pincode = promoter.zipcode;
                  abs_promoter.city = promoter.city;
                  abs_promoter.state = promoter.state;
                  abs_promoter.years_at_current_address = promoter.years_at_current_address;
                  abs_promoter.relationship = promoter.relationship;
                  that.obj_promoter.push(abs_promoter);
              })
              that.rederVerifiedPromoters();
              // Utils.showToast('Fetched', 'Promoters', Constants.TOAST.success);
            }else{
              // Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
        })
  }

  rederVerifiedPromoters(){
    let that = this;
    let temp = document.getElementById('promoters-tr-tmpl-business-details');
    let ac_table = document.getElementById('verified_promoters_details');
    ac_table.innerHTML = null;
    this.obj_promoter.forEach(function (obj_promoter_ind){
        if(obj_promoter_ind.full_name != null || obj_promoter_ind.pan != null){
          if(obj_promoter_ind['title']==="PROPRIETOR"){
            let dropdown = document.getElementById("titleOfBorrower");
            let options = dropdown.options;
            for (let i = 0; i < options.length; i++) {
                if (options[i].value === "PROPRIETOR") {
                    dropdown.remove(i);
                    break; 
                }
            }
          }
          let rend_row = Mustache.render(temp.innerHTML, obj_promoter_ind);
          ac_table.append($.parseHTML(rend_row)[1]);
          that.promoterEditButton(obj_promoter_ind.id);    
        }
    })
  }

  promoterEditButton(active_id){
    let that = this;
    document.getElementById('btn-edit-promoter-'+active_id).addEventListener('click', function () {
      var modal = new bootstrap.Modal(document.getElementById('promoterList'), {});
      modal.show();
      let abs_promoter = null;
      if(!active_id){
        Utils.showToast("Search", "Error finding Promoter", Constants.TOAST.fail);
      }
      that.obj_promoter.forEach(function (promoter){
        if(promoter.id === active_id){
          abs_promoter = promoter;
          return false;
        }
      })
      abs_promoter.setValues();
    })
  }

}  

class AbstractBanking {

  constructor(id){
      this.id = id;
      this.account_number = null;
      this.account_type = null;
      this.account_holder = null;
      this.ifsc_code = null;
      this.bank_branch = null;
      this.bank_name = null;
      this.bank_micr = null;
      this.verified_account_holder = null;
      this.elem_account_number = document.getElementById('account_number');
      this.elem_account_holder = document.getElementById('account_holder');
      this.elem_account_type = document.getElementById('account_type');
      this.elem_ifsc_code = document.getElementById('ifsc_code');
      this.elem_bank_branch = document.getElementById('bank_branch');
      this.elem_bank_name = document.getElementById('bank_name');
      this.elem_bank_micr = document.getElementById('bank_micr');
      this.elem_verified_account_holder = document.getElementById('verified_account_holder');
      this.elem_verified_account_holder_name = document.getElementById('account_holder');
      this.elem_bank_branch_full = document.getElementById('bank_branch_full');
  }

  getValues(){
      this.account_number = Utils.getter(this.elem_account_number);
      this.account_type = Utils.getter(this.elem_account_type);
      this.account_holder = Utils.getter(this.elem_account_holder);
      this.ifsc_code = Utils.getter(this.elem_ifsc_code);
      this.bank_branch = Utils.getter(this.elem_bank_branch);
      this.bank_name = Utils.getter(this.elem_bank_name);
      this.bank_micr = Utils.getter(this.elem_bank_micr);
      this.account_type = Utils.getter(this.elem_account_type);
      this.verified_account_holder = Utils.getter(this.elem_verified_account_holder);
  }

  setValues() {
      Utils.setter(this.elem_account_number, this.account_number);
    //   Utils.setter(this.elem_account_type, this.account_type);
      Utils.setter(this.elem_account_type, "current");
      Utils.setter(this.elem_account_holder, this.account_holder);
      Utils.setter(this.elem_ifsc_code, this.ifsc_code);
      Utils.setter(this.elem_bank_branch, this.bank_branch);
      Utils.setter(this.elem_bank_name, this.bank_name);
      Utils.setter(this.elem_bank_micr, this.bank_micr);
    //   Utils.setter(this.elem_account_type, this.account_type);
      Utils.setter(this.elem_account_type, "current");
      Utils.setter(this.elem_verified_account_holder, this.verified_account_holder);
      Utils.setter(this.elem_verified_account_holder_name, this.verified_account_holder);
      Utils.setter(this.elem_bank_branch_full, this.bank_branch);
  }

  validateValues(){
    return true;
  }
}

class AbstractPromoter {

  constructor(id){
    this.id = id;
    this.title = null;
    this.pan = null;
    this.full_name = null;
    this.first_name = null;
    this.last_name = null;
    this.dob = null;
    this.gender = null;
    this.email = null;
    this.mobile = null;
    this.address = null;
    this.pincode = null;
    this.city = null;
    this.state = null;
    this.years_at_current_address = null;
    this.elem_title = document.getElementById('titleOfBorrower');
    this.elem_pan = document.getElementById('promoterPan');
    this.elem_full_name = document.getElementById('promoterName');
    this.elem_first_name = document.getElementById('promoterFirstName');
    this.elem_last_name = document.getElementById('promoterLastName');
    this.elem_dob = document.getElementById('promoterBirthDate');
    this.elem_gender = document.getElementById('promoterGender');
    this.elem_email = document.getElementById('promoterEmail');
    this.elem_mobile = document.getElementById('promoterMobile');
    this.elem_address = document.getElementById('promoterAddress');
    this.elem_pincode = document.getElementById('promoterPincode');
    this.elem_city = document.getElementById('promoterCity');
    this.elem_state = document.getElementById('promoterState');
    this.elem_years_at_current_address = document.getElementById('promoterYearsAtCurrentAddress');
    this.elem_share_holding = document.getElementById('promotershareholding');
    this.elem_relationship = document.getElementById('titleRelationShipwith');
  }

  getValues(){
    this.title = Utils.getter(this.elem_title);
    this.pan = Utils.getter(this.elem_pan);
    this.full_name = Utils.getter(this.elem_full_name);
    this.first_name = Utils.getter(this.elem_first_name);
    this.last_name = Utils.getter(this.elem_last_name);
    this.dob = Utils.getter(this.elem_dob);
    this.gender = Utils.getter(this.elem_gender);
    this.email = Utils.getter(this.elem_email);
    this.mobile = Utils.getter(this.elem_mobile);
    this.address = Utils.getter(this.elem_address);
    this.pincode = Utils.getter(this.elem_pincode);
    this.city = Utils.getter(this.elem_city);
    this.state = Utils.getter(this.elem_state);
    this.years_at_current_address = Utils.getter(this.elem_years_at_current_address);
    this.promoter_share_holdong = Utils.getter(this.elem_share_holding)
    this.relationship = Utils.getter(this.elem_relationship);
  }

  setValues() {
    Utils.setter(this.elem_title, this.title);
    Utils.setter(this.elem_pan, this.pan);
    Utils.setter(this.elem_full_name, this.full_name);
    Utils.setter(this.elem_first_name, this.first_name);
    Utils.setter(this.elem_last_name, this.last_name);
    Utils.setter(this.elem_dob, this.dob);
    Utils.setter(this.elem_gender, this.gender);
    Utils.setter(this.elem_email, this.email);
    Utils.setter(this.elem_mobile, this.mobile);
    Utils.setter(this.elem_address, this.address);
    Utils.setter(this.elem_pincode, this.pincode);
    Utils.setter(this.elem_city, this.city);
    Utils.setter(this.elem_state, this.state);
    Utils.setter(this.elem_years_at_current_address, this.years_at_current_address);
    Utils.setter(this.elem_relationship, this.relationship);
    Utils.setter(this.elem_share_holding, this.promoter_share_holdong);
  }

  validateValues(){
    return true;
  }
}


