import {Utils} from "../../core/utils";
import * as Constants from "../../core/constants";
import Mustache from "mustache";

export default class ScfAppPanelDedupe{

    constructor() {
        let that = this;
        this.scf_application_id = document.getElementById('scf_application_id');
        this.business_applicant_id = document.getElementById('business_applicant_id');
        document.getElementById('nav-dedupe').addEventListener('click', function (){
            that.fetchApplications();
        })
    }

    fetchApplications(){
        let that = this;
        Utils.requestHandler('/scf/api/applicant/'+ that.business_applicant_id.value + '/applications', Constants.REQUEST.get, {})
            .then(function (response){
                if(response.message === 'OK'){
                    that.renderApplications(response.data);
                }else{
                    Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
                }
            })
    }

    renderApplications(data){
        let that = this;
        let tmpl = document.getElementById('de-application-details-tmpl');
        let dx = document.getElementById('tb-de-app-tbody');
        if(data.applications.length > 1){
            dx.innerHTML = null;
        }
        data.applications.forEach(function (dd){
            if(dd.id !== that.scf_application_id.value){
                dd.application_details_link = "/scf/application/details/" + dd.id;
                if(dd.trade_name != null){
                    dd.business_applicant_name = dd.trade_name;
                } else {
                    dd.business_applicant_name = data.business_applicant_name;
                }
                dd.formatted_created_at = Utils.formatTime(dd.created_at);
                dd.product = Utils.capitalizeFirstLetter(dd.product.split('_').join(' '));
                dd.status_human = Utils.capitalizeFirstLetter(dd.status.split('_').join(' '));
                let rendered = Mustache.render(tmpl.innerHTML, dd);
                dx.append($.parseHTML(rendered)[1]);
            }
        })
    }

}