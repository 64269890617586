import {Utils} from "../../core/utils";
import * as Constants from "../../core/constants";

export class ScfInvoiceDashboard {

    constructor() {
        Utils.pageSanityHandler();
    }

    handler() {
        this.dtHandler();
    }

    mainTableTransformer(data){
        data.formatted_created_at = Utils.formatTime(data.created_at);
        data.formatted_updated_at = Utils.formatTimeRelative(data.updated_at);
        data.total_invoice_value = data.total_invoice_value ? data.total_invoice_value.toLocaleString('en-IN') : "NA" ;
        data.formatted_due_date = data.due_date ? Utils.formatDate(data.due_date) : 'NA';
        data.formatted_due_date_relative = data.due_date ? Utils.formatTimeRelative(data.due_date) : 'NA';
        data.status = Utils.capitalizeFirstLetter(data.status.split('_').join(' '));
        data.formatted_purpose = Utils.capitalizeFirstLetter(data.purpose.split('_').join(' '));
        data.badge_purpose = 'badge-primary';
        if(data.purpose === 'finance'){
            data.badge_purpose = 'badge-green';
        }
        return data;
    }

    dtHandler(){
        let table_container_id = '#dt-table-application';
        let dt_instance = Utils.renderDataTable(table_container_id + '> table');
        Utils.initializeDataTable(
            table_container_id,
            dt_instance,
            '/scf/api/invoices',
            Constants.REQUEST.get,
            {},
            'dt-main-tmpl',
            this.mainTableTransformer,
            'data',
            {});
    }

}
