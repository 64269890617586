import { DocumentUtils, Utils } from "../core/utils";
import * as Constants from "../core/constants";
import Mustache from 'mustache';

export class AnchorNewLeadsLot {
  constructor() {
    Utils.pageSanityHandler();
    this.anchor_id = null;
    this.program_id = null;
    this.uploaded_file = null;

    this.elem_anchor_id = document.getElementById('select_anchor');
    this.elem_program_id = document.getElementById('select_program');
    this.elem_uploaded_file = document.getElementById('anchor_lead_lot_upload');

    this.elem_anchor_id.addEventListener('input', function (){
      Utils.reInput(this);
    })

    this.elem_program_id.addEventListener('input', function (){
      Utils.reInput(this);
    })

    this.elem_uploaded_file.addEventListener('input', function (){
      Utils.reInput(this);
    })
  }

  setValues(){
    Utils.setter(this.elem_anchor_id, this.anchor_id);
    Utils.setter(this.elem_program_id, this.program_id);
    Utils.setter(this.elem_uploaded_file, this.uploaded_file);
  }

  getValues(){
    this.anchor_id = Utils.getter(this.elem_anchor_id);
    this.program_id = Utils.getter(this.elem_program_id);
    this.uploaded_file = Utils.getter(this.elem_uploaded_file);
  }

  validateValues(){
    let pass = true;
    if(!this.anchor_id){
      Utils.showInputError(this.elem_anchor_id, 'Anchor is mandatory');
      pass = false;
    }
    if(!this.program_id){
      Utils.showInputError(this.elem_program_id, 'Program is mandatory');
      pass = false;
    }
    if(!this.uploaded_file){
      Utils.showInputError(this.elem_uploaded_file, 'File is mandatory');
      pass = false;
    }
    
    return pass;
  }

  handler() {
    this.fetchAllAnchorsList();
    this.submitAnchorLeadLot();
    this.fetchAllPrograms();
    this.renderAnchorLeadsLot()
  }

  fetchAllPrograms(){
    let that = this;
    Utils.requestHandler('/scf/api/program/anchor-programs', Constants.REQUEST.get, {})
    .then(function (response){
      if(response.message === 'OK' ){
        let selectField = that.elem_program_id;
        
        while (selectField.options.length > 1) {
          selectField.remove(1);
        }
    
        response.data.program.forEach(program => {
          let option = document.createElement('option');
          option.value = program.id;
          option.text = program.name;
          selectField.add(option);
        });
      }
    }) 
  }


  fetchAllAnchorsList(){
    let that = this;
    Utils.requestHandler('/anchor/api/applicant/get-anchors-list', Constants.REQUEST.get, {})
    .then(function (response){
      if(response.message === 'OK' ){
        let selectField = that.elem_anchor_id;
        
        while (selectField.options.length > 1) {
          selectField.remove(1);
        }
    
        response.data.anchors.forEach(anchor => {
          let option = document.createElement('option');
          option.value = anchor.id;
          option.text = anchor.name;
          selectField.add(option);
        });
        that.renderAnchorTable(response);
      }
    }) 
  }

  renderAnchorTable(response) {
    let tmpl = document.getElementById("app-anchor-row-tmpl");
    let dx = document.getElementById("tb_anchor_details");
    dx.innerHTML = null;
    response.data.anchors.forEach(function (dd) {
        let rendered = Mustache.render(tmpl.innerHTML, dd);
        dx.append($.parseHTML(rendered)[1]);
    });
  }

  renderAnchorLeadsLotTable(response) {
    let tmpl = document.getElementById("app-anchor-leads-lot-row-tmpl");
    let dx = document.getElementById("tb_anchor_leads_lots");
    dx.innerHTML = null;
    response.data.anchors.forEach(function (dd) {
      dd.anchor_leads_lot.created_at = Utils.formatDate(dd.anchor_leads_lot.created_at)
      let rendered = Mustache.render(tmpl.innerHTML, dd);
      dx.append($.parseHTML(rendered)[1]);
    });
  }

  renderAnchorLeadsLot(){
    let that = this;
    let selectElement = document.getElementById('select_anchor');

    selectElement.addEventListener('change', function() {
      const selectedValue = this.value;
      that.getAnchorLeadsLot(selectedValue);
    });
  }

  getAnchorLeadsLot(anchor_leads_lot_id){
    let that = this;
    Utils.requestHandler('/anchor/api/anchor_leads_lots/' + anchor_leads_lot_id  + '/get-leads-by-anchor-applicant', Constants.REQUEST.get, {})
    .then(function (response){
      if(response.message === 'OK' ){
        that.renderAnchorLeadsLotTable(response);
      }
    })
  }

  submitAnchorLeadLot(){
    let that = this;
    document.getElementById('btn-submit-details').addEventListener('click', function(){
      that.getValues();
      if(that.validateValues()){
        let file = that.elem_uploaded_file.files[0];
        if (!file) {
          Utils.showToast(
            "Not Found",
            "File is mandatory",
            Constants.TOAST.fail
          );
          return;
        }
        let fileData = new FormData();
        fileData.append("file_data", file);
        fileData.append("file_name", file.name);
        fileData.append("file_password", null);
        fileData.append("file_code", "anchor_leads_csv");
        fileData.append("anchor_id", that.anchor_id);
        fileData.append("program_id", that.program_id);
        Utils.loaderToast(Constants.ELEMENT_STATE.show);
        Utils.requestHandler('/anchor/api/anchor_leads_lot/new', Constants.REQUEST.post, fileData)
          .then(function (response){
            if(response.message === 'OK' ){
              Utils.showToast(
                "Success",
                "Data Upload Stated",
                Constants.TOAST.success
              );
              Utils.loaderToast(Constants.ELEMENT_STATE.hide);
              document.getElementById('btn-submit-details').disabled = true;
            }else {
              Utils.loaderToast(Constants.ELEMENT_STATE.hide);
              Utils.showToast(response.message, Utils.formatErrorMessage(response.errors), Constants.TOAST.fail);
            }
          })   
      }     
    });
  }

}